import React from "react";

const optionsKey = "fontWeight";

const defaults = {
  h1: "600",
  h2: "600",
  h3: "600",
};

const values = ["100", "300", "600", "800"];

function ControlFontWeight({ item, onUpdate }) {
  const getValue = () => {
    let value = defaults[item.type];
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onEdit = (value) => {
    onUpdate(`options.${optionsKey}`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <select value={getValue()} onChange={(e) => onEdit(e.target.value)}>
        {values.map((valueValue, index) => {
          return (
            <option key={index} value={valueValue}>
              {valueValue}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default ControlFontWeight;
