import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";

class Index extends Component {
  state = {};

  componentDidMount() {
    //const _c = this.props.context;
    //this.props.history.push(_c.config("urls.home.truepowerausbildung"));
    //this.init();
    //window.location.href = "/week-of-remembering";
    window.location.href = "/home";
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("home.home");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === 200) {
            _c.handleApiResponse(response.data, true, () => {
              /*let entryPage = _c.userSetting("entrypage");
              if (!entryPage) {
                entryPage = "";
              }
              //console.log("entrypage", entryPage);
              */

              //let url = "/courses";
              /*if (entryPage === "courses") {
                url = _c.config("urls.courses");
              } else if (entryPage === "ausbildung") {
                url = _c.config("urls.home.truepowerausbildung");
              } else if (entryPage === "truepowerflow") {
                url = "/course/truepowerflow/page";
              } else if (
                entryPage === "truepowerkurs5" ||
                entryPage === "free"
              ) {
                url = _c
                  .config("urls.coursePage")
                  .replace(":courseSlug", entryPage);
              }*/
              let url = "/course/loauniverse/page";

              //console.log("url", url);
              window.location.href = url;
            });
          }
        } catch (error) {
          console.log(error);
          return _c.handleError({ status: "AXIOS_RESPONSE_ERROR" }, "index:init:response");
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "index:init");
        }
      });
  }
}

export default withContext(Index);
