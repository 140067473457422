import React from "react";
import Box from "../../../layout/Box";
import AdminDataField from "../../AdminDataField";

function ControlVideo({ item, onUpdate }) {
  const getValue = () => {
    let value = item.content || "";
    return value;
  };

  const getLabel = () => {
    if (item.type === "vimeo") return "Vimeo Video ID";
    else if (item.type === "youtube") return "Youtube Video ID";
  };

  const onEdit = (value) => {
    onUpdate(`content`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-videoselect}`}>
      <Preview type={item.type} videoId={item.content} />
      <Box size="small">
        <AdminDataField
          value={item.content}
          editValue={item.content}
          editType="text"
          label={getLabel()}
          placeholder={getLabel()}
          editable={true}
          edit={true}
          onUpdate={(newValue) => onEdit(newValue)}
        />
        <OnlyIdAlert type={item.type} />
      </Box>
    </div>
  );
}

const OnlyIdAlert = ({ type }) => {
  let content;
  if (type === "vimeo")
    content = (
      <>
        <strong>Wichtig:</strong> Du benötigst <em>nur</em> die Video ID (z.B.
        249803021). Um den Rest kümmert sich der Mitgliederbereich.
      </>
    );
  if (type === "youtube")
    content = (
      <>
        <strong>Wichtig:</strong> Du benötigst <em>nur</em> die Video ID (z.B.
        uOzwwZxjPRs). Um den Rest kümmert sich der Mitgliederbereich.
      </>
    );

  return <div className="small warning callout">{content}</div>;
};

const Preview = ({ type, videoId }) => {
  if (!videoId) return null;

  if (type === "vimeo") {
    let embedUrl = `https://player.vimeo.com/video/${videoId}`;
    return (
      <div className="responsive-embed widescreen">
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="vimeoPlayer"
        ></iframe>
      </div>
    );
  } else if (type === "youtube") {
    let embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <div className="responsive-embed widescreen">
        <div id="youtubePlayer">
          <iframe
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YoutubePlayer"
          ></iframe>
        </div>
      </div>
    );
  }
};

export default ControlVideo;
