import membermateConfig from "../config/membermate.config.json";

const ApiHelper = {
  apiBaseUrl: null,
  apiUrlKey: "dev",
  env: "dev",
  debug: true,

  init: function () {
    // Check if host is local
    if (
      window.location.hostname === "localhost" ||
      window.location.hostname === "127.0.0.1"
    ) {
      // Local Delopment Environment
      this.apiUrlKey = "dev";
      this.env = "dev";
      this.debug = true;
      this.apiBaseUrl = membermateConfig.apiBaseUrl.dev;
    } else if (window.location.hostname.substr(0, 11) === "devuniverse") {
      // Development Environment on Production Server / Staging
      this.apiUrlKey = "prod";
      this.env = "dev";
      this.debug = true;
      this.apiBaseUrl = membermateConfig.apiBaseUrl.staging;
    } else {
      // Production Environment
      this.apiUrlKey = "prod";
      this.env = "prod";
      this.debug = false;
      this.apiBaseUrl = membermateConfig.apiBaseUrl.prod;
    }

    return {
      apiBaseUrl: this.apiBaseUrl,
      apiUrlKey: this.apiBaseUrl,
      env: this.env,
      debug: this.debug,
    };
  },
  url: function (path, urlParams) {
    let result = membermateConfig.apiEndpoints;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    if (result && urlParams) {
      Object.keys(urlParams).map((urlParamKey) => {
        result = result.replace(`{${urlParamKey}}`, urlParams[urlParamKey]);
        return null;
      });
    }

    result = `${this.apiBaseUrl}${membermateConfig.apiVersion.replace(
      "{hostname}",
      window.location.hostname
    )}${result}`;

    return result;
  },
  getHeaders: function () {
    const headers = {};
    headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    return headers;
  },
  isDebug: function () {
    return this.isDebug;
  },
};

ApiHelper.init();

export default ApiHelper;
