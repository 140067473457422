import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import { Icon } from "../../_foundation/_buttons";
import Loading from "../../_reusables/Loading";
import ColorPicker from "../../_reusables/ColorPicker";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import SizePicker from "../../_reusables/SizePicker";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import qs from "query-string";

class AdminDesign extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_design"],
    theme: null,
    styles: null,
    fonts: null,
    status: "LOADING",
    changesWereMade: false,
    delayTimer: null,
    delayTime: 500, // Milliseconds
    reset: null,
    previewUrl: `${window.location.protocol}//${window.location.hostname}:${window.location.port}/home`,
    categories: {
      default_colors: {
        items: [
          {
            key: "$primaryColor",
            type: "color",
          },
          {
            key: "$secondaryColor",
            type: "color",
          },
          {
            type: "separator",
          },
          {
            key: "$bodyBackground",
            type: "color",
          },
          {
            type: "separator",
          },
          {
            key: "$textColor",
            type: "color",
          },
          {
            key: "$textGray",
            type: "color",
          },
          {
            key: "$textLightGray",
            type: "color",
          },
          {
            key: "$linkColor",
            type: "color",
          },
          {
            key: "$headingColor",
            type: "color",
          },
          {
            key: "$hoverColor",
            type: "color",
          },
          {
            key: "$modalBackground",
            type: "color",
          },
        ],
      },
      fonts: {
        items: [
          {
            key: "primaryFontFamily",
            type: "font",
          },
          {
            type: "separator",
          },
          {
            key: "headingFontFamily",
            type: "font",
          },
        ],
      },
      boxes: {
        items: [
          {
            key: "$boxBackground",
            type: "color",
          },
          {
            key: "$boxBorderColor",
            type: "color",
          },
        ],
      },
      buttons: {
        items: [
          {
            key: "buttons",
            type: "title",
          },
          {
            key: "$buttonBackground",
            type: "color",
          },
          {
            key: "$buttonColor",
            type: "color",
          },
          {
            key: "$buttonBorderColor",
            type: "color",
          },
          {
            key: "hollowButtons",
            type: "title",
          },
          {
            key: "$hollowButtonBackground",
            type: "color",
          },
          {
            key: "$hollowButtonColor",
            type: "color",
          },
          {
            key: "$hollowButtonBorderColor",
            type: "color",
          },
        ],
      },
      topbar: {
        items: [
          {
            key: "topbar",
            type: "title",
          },
          {
            key: "$topHeight",
            type: "size",
          },
          {
            key: "$topBackground",
            type: "color",
          },
          {
            key: "$topUsernameColor",
            type: "color",
          },
          {
            key: "$topBorderColor",
            type: "color",
          },
          {
            key: "$topSearchIconColor",
            type: "color",
          },
          {
            key: "breadcrumbs",
            type: "title",
          },
          {
            key: "$breadcrumbsHeight",
            type: "size",
          },
          {
            key: "$breadcrumbsBackground",
            type: "color",
          },
          {
            key: "$breadcrumbsColor",
            type: "color",
          },
          {
            key: "$breadcrumbsLinkColor",
            type: "color",
          },
          {
            key: "$breadcrumbsBottomBorderColor",
            type: "color",
          },
        ],
      },
      main_menu: {
        items: [
          {
            key: "$menuLinkColor",
            type: "color",
          },
          {
            key: "$menuLinkHoverBackground",
            type: "color",
          },
          {
            key: "$menuLinkHoverColor",
            type: "color",
          },
        ],
      },
      forms: {
        items: [
          {
            key: "$inputBackground",
            type: "color",
          },
          {
            key: "$inputColor",
            type: "color",
          },
        ],
      },
      tabs: {
        items: [
          {
            key: "$tabBackground",
            type: "color",
          },
          {
            key: "$tabColor",
            type: "color",
          },
          {
            key: "$tabSelectedBackground",
            type: "color",
          },
          {
            key: "$tabSelectedColor",
            type: "color",
          },
        ],
      },
      home: {
        items: [
          {
            key: "$homeHelloPadding",
            type: "size",
          },
        ],
      },
      community: {
        items: [
          {
            key: "$communityPostLink",
            type: "color",
          },
          {
            key: "$communityFormBackground",
            type: "color",
          },
          {
            key: "$communityFormColor",
            type: "color",
          },
        ],
      },
      routines: {
        items: [
          {
            key: "thisMonth",
            type: "title",
          },
          {
            key: "$routinesBorderColorThisMonth",
            type: "color",
          },
          {
            key: "$routinesTextColorThisMonth",
            type: "color",
          },
          {
            key: "notThisMonth",
            type: "title",
          },
          {
            key: "$routinesBorderColorNotThisMonth",
            type: "color",
          },
          {
            key: "$routinesInactiveBackground",
            type: "color",
          },
          {
            key: "$routinesInactiveColor",
            type: "color",
          },
          {
            key: "routinesToday",
            type: "title",
          },
          {
            key: "$routinesBorderColorToday",
            type: "color",
          },
          {
            key: "routinesWeekend",
            type: "title",
          },
          {
            key: "$routinesWeekendBackground",
            type: "color",
          },
          {
            key: "$routinesWeekendColor",
            type: "color",
          },
        ],
      },
      admin: {
        items: [
          {
            key: "adminNav",
            type: "title",
          },
          {
            key: "$adminMenuLinkColor",
            type: "color",
          },
          {
            key: "$adminMenuLinkHoverBackground",
            type: "color",
          },
          {
            key: "$adminMenuLinkHoverColor",
            type: "color",
          },
          {
            key: "$adminMenuLinkSelectedBackground",
            type: "color",
          },
          {
            key: "$adminMenuLinkSelectedColor",
            type: "color",
          },
          {
            key: "adminTable",
            type: "title",
          },
          {
            key: "$adminListStripedOddBackground",
            type: "color",
          },
          {
            key: "$adminListStripedOddColor",
            type: "color",
          },
          {
            key: "$adminListStripedEvenBackground",
            type: "color",
          },
          {
            key: "$adminListStripedEvenColor",
            type: "color",
          },
          {
            key: "adminMisc",
            type: "title",
          },
          {
            key: "$adminDataEditBackground",
            type: "color",
          },
          {
            key: "$adminDataEditColor",
            type: "color",
          },
          {
            key: "$adminDataLabelColor",
            type: "color",
          },
        ],
      },
      footer: {
        items: [
          {
            key: "$footerBackground",
            type: "color",
          },
          {
            key: "$footerColor",
            type: "color",
          },
        ],
      },
    },
    view: "lobby",
    showThemePopup: false,
    showLightbox: false,
    themes: null,
    themesCount: 0,
    selectedTheme: null,
    screenshotIndex: 0,
    screenshots: [],
    screenshotCaptions: [],
  };

  componentDidMount() {
    /*window.onbeforeunload = function () {
      return "Änderungen werden nicht gespeichert. Möchtest Du wirklich fortfahren?";
    };*/
    const _c = this.props.context;
    _c.showLoadingScreen();

    const handler = (event) => {
      const { previewUrl } = this.state;
      try {
        if (event.data) {
          const data = JSON.parse(event.data);
          //console.log("URL?", data.url);
          //console.log("PreviewURL?", previewUrl);
          if (data && data.url && data.url !== previewUrl) {
            //console.log("WHY NOT HERE?");
            this.setState({ previewUrl: data.url });
          }
        }
      } catch {}
    };

    window.addEventListener("message", handler);

    this.init();
  }

  render() {
    const { breadcrumbs, changesWereMade } = this.state;
    const _c = this.props.context;

    return (
      <div className="hide-nav">
        <AdminLayout nav="design" breadcrumbs={breadcrumbs} fullpage={true}>
          <div className="admin-design-menu">
            {this.view__showButtons()}
            <div className="admin-design-menu-content">
              <div className="admin-back-button">
                <Link
                  to={_c.config("urls.admin.admin")}
                  onClick={(e) => {
                    if (
                      changesWereMade === true &&
                      !window.confirm(
                        _c.translate("admin.design.leave.confirm")
                      )
                    ) {
                      e.preventDefault();
                    }
                  }}
                >
                  <small>
                    <Icon icon="angle-double-left" left /> Zurück zum
                    Administrationsbereich
                  </small>
                </Link>
              </div>
              <h3>{_c.translate("admin.design.lobby.title")}</h3>
              <div>{_c.translate("admin.design.lobby.text")}</div>
              <hr />
              {this.view__showMenu()}
            </div>
          </div>
          <div className="admin-design-content">{this.view__showIframe()}</div>
          {this.view__showThemePopup()}
        </AdminLayout>
      </div>
    );
  }

  view__showThemePopup() {
    const {
      showThemePopup,
      showLightbox,
      themesCount,
      themes,
      selectedTheme,
      screenshots,
      screenshotCaptions,
      screenshotIndex,
    } = this.state;
    const _c = this.props.context;

    if (!themes) {
      return null;
    }

    let v__output;

    let v__lightbox;
    if (showLightbox === true) {
      v__lightbox = (
        <Lightbox
          imageTitle={`Theme: ${screenshotCaptions[screenshotIndex]}`}
          mainSrc={screenshots[screenshotIndex]}
          nextSrc={screenshots[(screenshotIndex + 1) % screenshots.length]}
          prevSrc={
            screenshots[
              (screenshotIndex + screenshots.length - 1) % screenshots.length
            ]
          }
          onCloseRequest={() =>
            this.setState({ showLightbox: false, showThemePopup: true })
          }
          onMovePrevRequest={() =>
            this.setState({
              screenshotIndex:
                (screenshotIndex + screenshots.length - 1) % screenshots.length,
            })
          }
          onMoveNextRequest={() =>
            this.setState({
              screenshotIndex: (screenshotIndex + 1) % screenshots.length,
            })
          }
        />
      );
    }

    if (showThemePopup === true) {
      let buttonDisabled = true;
      if (selectedTheme) {
        buttonDisabled = false;
      }

      let v__buttons = (
        <div className="popup-buttons">
          <button
            className="small primary hollow button"
            onClick={() => {
              this.setState({ showThemePopup: false });
            }}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button
            className="small primary button"
            disabled={buttonDisabled}
            onClick={this.handle__selectTheme}
          >
            <Icon icon="check" left />{" "}
            {_c.translate("admin.design.themes.selectButton.label")}
          </button>
        </div>
      );

      v__output = (
        <MMPopup
          size="large"
          show={true}
          handleClose={() => {
            this.setState({ showThemePopup: false });
          }}
        >
          <h3>{_c.translate("admin.design.themes.title")}</h3>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.design.themes.text"),
            }}
          />
          <div
            className="padding text-center"
            dangerouslySetInnerHTML={{
              __html: _c
                .translate("admin.design.themes.found")
                .replace("{themesCount}", themesCount),
            }}
          />
          {v__buttons}
          <div className="admin-design-themes-popup-themes">
            <Grid type="full">
              <Row margin="xy">
                {Object.keys(themes).map((themeCategory, index) => {
                  return (
                    <React.Fragment key={index}>
                      {themes[themeCategory].themes.map((theme, index) => {
                        let classes = "admin-design-theme-wrapper";

                        if (selectedTheme === parseInt(theme.id)) {
                          classes = `selected ${classes}`;
                        }

                        return (
                          <Cell sm={24} md={6} key={index}>
                            <div className={classes}>
                              <span className="admin-design-theme-selected-label">
                                {_c.translate("admin.design.themes.selected")}
                              </span>
                              <div className="admin-design-theme">
                                <button
                                  className="admin-design-theme-screenshot"
                                  onClick={() => {
                                    this.setState({
                                      screenshotIndex: theme.screenshotIndex,
                                      showLightbox: true,
                                      showThemePopup: false,
                                    });
                                  }}
                                >
                                  <img
                                    src={theme.screenshot}
                                    alt="Screenshot"
                                  />
                                </button>
                                <button
                                  className="admin-design-theme-text"
                                  onClick={() => {
                                    if (selectedTheme === theme.id) {
                                      this.setState({ selectedTheme: null });
                                    } else {
                                      this.setState({
                                        selectedTheme: theme.id,
                                      });
                                    }
                                  }}
                                >
                                  <div className="admin-design-theme-name">
                                    {theme.name}
                                  </div>
                                  <div className="admin-design-theme-description">
                                    {theme.description}
                                  </div>
                                </button>
                              </div>
                            </div>
                          </Cell>
                        );
                      })}
                    </React.Fragment>
                  );
                })}
              </Row>
            </Grid>
          </div>
          {v__buttons}

          {v__lightbox}
        </MMPopup>
      );
    }

    return (
      <React.Fragment>
        {v__output}
        {v__lightbox}
      </React.Fragment>
    );
  }

  view__showButtons() {
    const _c = this.props.context;

    return (
      <div className="admin-design-menu-buttons">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        {/*<button
          className="small secondary hollow button"
          data-tip={_c.translate("admin.design.buttons.reset.tooltip")}
        >
          <Icon icon="refresh" left />{" "}
          {_c.translate("admin.design.buttons.reset.label")}
        </button>*/}
        <button
          onClick={this.handle__save}
          className="small primary hollow button"
          data-tip={_c.translate("admin.design.buttons.save.tooltip")}
        >
          <Icon icon="check" left />{" "}
          {_c.translate("admin.design.buttons.save.label")}
        </button>
      </div>
    );
  }

  view__showMenu() {
    const { styles, categories, view } = this.state;

    if (!styles) {
      return null;
    }

    if (categories[view]) {
      return this.view__showCategory(view);
    } else {
      return this.view__showLobby();
    }
  }

  view__showLobby() {
    const { categories } = this.state;
    const _c = this.props.context;

    return (
      <div className="admin-design-menu-lobby">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <button
          className="admin-design-menu-theme-button primary hollow button"
          data-tip={_c.translate("admin.design.lobby.themeButton.tooltip")}
          onClick={() => {
            this.setState({ showThemePopup: true });
          }}
        >
          <Icon icon="paint-brush" left />{" "}
          {_c.translate("admin.design.lobby.themeButton.label")}
        </button>
        <hr />
        <ul>
          {Object.keys(categories).map((categoryKey, index) => {
            if (categoryKey === "community" && !_c.hasModule("community")) {
              return null;
            }
            if (categoryKey === "routines" && !_c.hasModule("routines")) {
              return null;
            }

            return (
              <li
                key={index}
                onClick={() => {
                  this.setState({ view: categoryKey });
                }}
              >
                <div className="float-right">
                  <Icon icon="angle-right" />
                </div>
                {_c.translate(`admin.design.categories.${categoryKey}.title`)}
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showCategory(categoryKey) {
    const _c = this.props.context;
    const { categories } = this.state;
    const category = categories[categoryKey];

    return (
      <div className="admin-design-menu-category">
        <button
          className="admin-design-menu-title"
          onClick={() => {
            this.setState({ view: "lobby" });
          }}
        >
          <Icon icon="angle-left" left />{" "}
          {_c.translate(`admin.design.categories.${categoryKey}.title`)}
        </button>
        <div className="admin-design-menu-text">
          {_c.translate(`admin.design.categories.${categoryKey}.text`)}
        </div>
        <div>&nbsp;</div>
        {category.items.map((item, index) => {
          return (
            <div key={index}>{this.view__showStyle(item.key, item.type)}</div>
          );
        })}
      </div>
    );
  }

  view__showStyle(key, type) {
    const { styles, reset } = this.state;
    const _c = this.props.context;

    let v__output;

    if (type === "separator") {
      v__output = <hr />;
    } else if (type === "title") {
      v__output = (
        <div className="admin-design-subtitle">
          {_c.translate(`admin.design.titles.${key}`)}
        </div>
      );
    } else if (type === "color") {
      v__output = (
        <Grid type="full">
          <Row>
            <Cell sm={6}>
              <ColorPicker
                size="small"
                color={styles[key].admin_value}
                reset={reset}
                onChange={(color) => {
                  this.handle__saveStyle(key, color);
                }}
              />
            </Cell>
            <Cell sm={18}>
              <span className="admin-design-color-text">
                {_c.translate(`admin.design.styles.${key.replace("$", "")}`)}
              </span>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "size") {
      let min = 0;
      let max = 100;
      let step = 1;
      let number = styles[key].admin_value;

      if (key === "$topHeight") {
        min = 30;
        max = 100;
      } else if (key === "$homeHelloPadding") {
        min = 0;
        max = 10;
        step = 0.1;

        let numberArray = number.split(" ");
        number = numberArray[0];

        console.log("number", number);
      }

      v__output = (
        <Grid type="full">
          <Row>
            <Cell sm={6}>
              <SizePicker
                size="small"
                number={number}
                min={min}
                max={max}
                step={step}
                reset={reset}
                onChange={(number) => {
                  this.handle__saveStyle(key, number);
                }}
              />
            </Cell>
            <Cell sm={18}>
              <span className="admin-design-color-text">
                {_c.translate(`admin.design.styles.${key.replace("$", "")}`)}
              </span>
            </Cell>
          </Row>
        </Grid>
      );
    } else if (type === "font") {
      const { fonts } = this.state;

      v__output = (
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <div className="admin-design-font-select">
                <select
                  value={styles[key].admin_value}
                  onChange={(e) => {
                    const fontKey = e.target.value;
                    this.handle__saveStyle(key, fontKey);
                  }}
                >
                  {Object.keys(fonts).map((fontKey, index) => {
                    return (
                      <option key={index} value={fontKey}>
                        {fonts[fontKey].name}
                      </option>
                    );
                  })}
                </select>
              </div>
              <div className="admin-design-font-text">
                {_c.translate(`admin.design.styles.${key.replace("$", "")}`)}
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }

  view__showIframe() {
    const { styles, status, previewUrl } = this.state;

    if (status === "LOADING") {
      return (
        <div>
          <Loading show={true} />
        </div>
      );
    }

    if (!styles) {
      return null;
    }

    return (
      <React.Fragment>
        <iframe
          ref={this.iframeRef}
          title="Design"
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          src={previewUrl}
        ></iframe>
      </React.Fragment>
    );
  }

  handle__selectTheme = () => {
    const { selectedTheme } = this.state;
    const _c = this.props.context;

    if (selectedTheme) {
      let url = `${_c.config("urls.admin.design")}?theme=${selectedTheme}`;
      window.location.href = url;
    }
  };

  saveStyle = (key, value) => {
    this.setState({ status: "LOADING" });

    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.designStyle");

    axios
      .patch(
        apiUrl,
        {
          key,
          value,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { styles } = response.data.data;
            this.setState({ styles, status: "LOADED", changesWereMade: true });
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:design:style:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:design:style");
      });
  };

  handle__saveStyle = (key, value) => {
    let { delayTimer, delayTime, styles } = this.state;

    styles[`$bodyBackground`].admin_value = value;
    this.setState({ styles });

    clearTimeout(delayTimer);
    delayTimer = setTimeout(
      function () {
        this.saveStyle(key, value);
      }.bind(this),
      delayTime
    );
    this.setState({ delayTimer });
  };

  handle__save = () => {
    const _c = this.props.context;

    if (!window.confirm(_c.translate("admin.design.buttons.save.confirm"))) {
      return null;
    }

    const apiUrl = _c.apiUrl("admin.design");

    axios
      .patch(
        apiUrl,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { styles } = response.data.data;
            this.setState({ styles, status: "LOADED", changesWereMade: false });

            _c.createNotifictation(
              _c.translate("admin.design.buttons.save.saved.title"),
              _c.translate("admin.design.buttons.save.saved.text"),
              "success"
            );
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:design:save:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:design:save");
      });
  };

  init() {
    const _c = this.props.context;

    const queryParams = qs.parse(this.props.location.search);
    let theme = null;
    if (queryParams.theme) {
      theme = queryParams.theme;
    }

    let apiUrl = _c.apiUrl("admin.design");

    this.setState({ theme }, () => {
      if (theme) {
        apiUrl = `${apiUrl}?theme=${theme}`;
      }

      axios
        .get(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const { styles, fonts, themes, themesCount } = response.data.data;

              let screenshots = [];
              let screenshotCaptions = [];
              let indexCount = 0;
              Object.keys(themes).map((themeKey) => {
                themes[themeKey].themes.map((theme) => {
                  if (theme.screenshot) {
                    screenshots.push(theme.screenshot);
                    screenshotCaptions.push(theme.name);
                    theme.screenshotIndex = indexCount;
                    indexCount++;
                  }
                  return null;
                });
                return null;
              });

              this.setState({
                styles,
                fonts,
                themes,
                themesCount,
                screenshots,
                screenshotCaptions,
                status: "LOADED",
              });
            }
            _c.setPageTitle("pageTitles.admin.design", true);
          } catch (error) {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:design:init:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:design:init");
        });
    });
  }
}

export default withContext(AdminDesign);
