import React from "react";
import PageViewHelper from "../PageViewHelper";

function ElementVideo({ element }) {
  return (
    <>
      <div style={PageViewHelper.applyOptions(element)}>
        <Video type={element.type} videoId={element.content} />
      </div>
    </>
  );
}

const Video = ({ type, videoId }) => {
  if (!videoId) return null;

  if (type === "vimeo") {
    let embedUrl = `https://player.vimeo.com/video/${videoId}`;
    return (
      <div className="responsive-embed widescreen">
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="vimeoPlayer"
        ></iframe>
      </div>
    );
  } else if (type === "youtube") {
    let embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return (
      <div className="responsive-embed widescreen">
        <div id="youtubePlayer">
          <iframe
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YoutubePlayer"
          ></iframe>
        </div>
      </div>
    );
  }

  return null;
};

export default ElementVideo;
