import { cloneDeep } from "lodash";
import React, { useState } from "react";
import ReactTooltip from "react-tooltip";
import ConfigHelper from "../../../../helpers/ConfigHelper";
import MMPopup from "../../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import ColumnSchemaIcon from "../ColumnSchemaIcon";
import PagebuilderHelper from "../PagebuilderHelper";

const tooltips = {
  area: "Neuen Bereich hinzufügen",
  row: "Neue Zeile hinzufügen",
  element: "Neues Element hinzufügen",
};

function AddButton({
  type,
  position,
  structure,
  page,
  area,
  row,
  column,
  onUpdate,
}) {
  const [showNewRowPopup, setShowNewRowPopup] = useState(false);
  const [showNewElementPopup, setShowNewElementPopup] = useState(false);

  const onAdd = () => {
    let updatedStructure = cloneDeep(structure);
    if (!updatedStructure || !updatedStructure.areas)
      updatedStructure.areas = [];

    if (type === "area") {
      const result = PagebuilderHelper.addArea(
        position,
        updatedStructure,
        page
      );

      updatedStructure = result.updatedStructure;
      const { area } = result;

      //PagebuilderHelper.logStructure(updatedStructure);

      if (updatedStructure) onUpdate({ updatedStructure, area });
    } else if (type === "row") {
      setShowNewRowPopup(true);
      return;
    } else if (type === "element") {
      setShowNewElementPopup(true);
      return;
    }
  };

  const onSelectColumnSchema = ({ columnSchema }) => {
    let updatedStructure = cloneDeep(structure);

    const result = PagebuilderHelper.addRow(
      position,
      columnSchema,
      updatedStructure,
      page,
      area
    );

    updatedStructure = result.updatedStructure;
    const { row } = result;

    //PagebuilderHelper.logStructure(updatedStructure);

    if (updatedStructure) onUpdate({ updatedStructure, row });
    setShowNewRowPopup(false);
  };

  const onSelectElementType = ({ elementType }) => {
    let updatedStructure = cloneDeep(structure);

    const result = PagebuilderHelper.addElement(
      position,
      elementType,
      updatedStructure,
      page,
      column
    );

    updatedStructure = result.updatedStructure;
    const { element } = result;

    PagebuilderHelper.logStructure(updatedStructure);

    if (updatedStructure)
      onUpdate({ updatedStructure, action: "addElement", element });
    setShowNewElementPopup(false);
  };

  const actions = {
    setShowNewRowPopup,
    setShowNewElementPopup,
    onSelectColumnSchema,
    onSelectElementType,
  };

  return (
    <>
      <div className="text-center">
        <ReactTooltip
          place={ConfigHelper.get("tooltips.position")}
          type={ConfigHelper.get("tooltips.type")}
          effect={ConfigHelper.get("tooltips.effect")}
        />
        <button
          onClick={onAdd}
          className={`pagebuilder-add-button add-button-${type}`}
          data-tip={tooltips[type]}
        >
          <Icon icon="plus-circle" />
        </button>
      </div>
      {showNewRowPopup ? <NewRowPopup actions={actions} /> : null}
      {showNewElementPopup ? <NewElementPopup actions={actions} /> : null}
    </>
  );
}

const NewRowPopup = ({ actions }) => {
  const pagebuilderColumnSchemas = ConfigHelper.get(
    "pagebuilder.columnSchemas"
  );

  return (
    <MMPopup
      show={true}
      size="small"
      handleClose={() => actions.setShowNewRowPopup(false)}
    >
      <h3>Neue Zeile hinzufügen</h3>
      <p>
        Bitte wähle aus, welche Spalten die neue Zeile bekommen soll. Du kannst
        die Anzahl und Breite der Spalten später jederzeit anpassen.
      </p>

      <Grid type="full">
        <Row>
          {Object.keys(pagebuilderColumnSchemas).map((schemaKey, index) => {
            return (
              <Cell sm={12} md={6} key={index}>
                <button
                  className="pagebuilder-select-schema"
                  onClick={() =>
                    actions.onSelectColumnSchema({
                      columnSchema: pagebuilderColumnSchemas[schemaKey].schema,
                    })
                  }
                >
                  <ColumnSchemaIcon
                    schema={pagebuilderColumnSchemas[schemaKey].schema}
                  />
                </button>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </MMPopup>
  );
};

const NewElementPopup = ({ actions }) => {
  const pagebuilderElementTypes = ConfigHelper.get("pagebuilder.elements");

  return (
    <MMPopup
      show={true}
      size="small"
      handleClose={() => actions.setShowNewElementPopup(false)}
    >
      <h3>Neues Element hinzufügen</h3>
      <p>Füge ein neues Element zu dieser Seite hinzu.</p>

      <Grid type="full">
        <Row>
          {Object.keys(pagebuilderElementTypes).map((elementType, index) => {
            return (
              <Cell sm={12} md={6} key={index}>
                <button
                  className="pagebuilder-select-element"
                  onClick={() =>
                    actions.onSelectElementType({
                      elementType,
                    })
                  }
                >
                  <div className="pagebuilder-select-element-icon">
                    <Icon icon={pagebuilderElementTypes[elementType].icon} />
                  </div>
                  <div className="pagebuilder-select-element-title">
                    {pagebuilderElementTypes[elementType].title}
                  </div>
                </button>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </MMPopup>
  );
};

export default AddButton;
