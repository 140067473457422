import React from "react";
import Box from "../../../../layout/Box";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import AdminDataField from "../../../AdminDataField";

function ElementSettings(props) {
  const { item, itemType } = props;

  const responsiveIsSelected = (size) => {
    if (item && item.responsive && item.responsive[size] === true) return true;
    return false;
  };

  const toggleResponsive = (size) => {
    const { responsive } = item;
    responsive[size] = !responsive[size];
    props.actions.onEdit("responsive", responsive);
  };

  return (
    <div className="pagebuilder-element-settings">
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24}>
            <Box size="small">
              <AdminDataField
                value={item.name}
                editValue={item.name}
                editType="text"
                label={"Interner Name (nur für Admins sichtbar, zur Orientierung im Pagebuilder)"}
                placeholder={"Gib einen Namen ein..."}
                editable={true}
                edit={true}
                onUpdate={(newValue) => props.actions.onEdit("name", newValue)}
              />
            </Box>
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24}>
            <Box size="small">
              <p>Anzeigen auf:</p>
              <div className="pagebuilder-element-settings-responsive">
                <label>
                  <input
                    type="checkbox"
                    checked={responsiveIsSelected("lg")}
                    onChange={() => toggleResponsive("lg")}
                  />{" "}
                  Desktop
                </label>
              </div>
              <div className="pagebuilder-element-settings-responsive">
                <label>
                  <input
                    type="checkbox"
                    checked={responsiveIsSelected("md")}
                    onChange={() => toggleResponsive("md")}
                  />{" "}
                  Tablets
                </label>
              </div>
              <div className="pagebuilder-element-settings-responsive">
                <label>
                  <input
                    type="checkbox"
                    checked={responsiveIsSelected("sm")}
                    onChange={() => toggleResponsive("sm")}
                  />{" "}
                  Smartphones
                </label>
              </div>
            </Box>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

export default ElementSettings;
