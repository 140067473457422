import React, { useContext, useEffect, useState } from "react";
import PageBuilderContext from "../../../../context/PageBuilderContext";
import AddButton from "./AddButton";
import DragOver from "./DragOver";
import PageItemName from "./PageItemName";
import PageRow from "./PageRow";

import {
  DragOverlay,
  DndContext,
  closestCenter,
  useSensor,
  useSensors,
  KeyboardSensor,
  PointerSensor,
} from "@dnd-kit/core";
import {
  SortableContext,
  verticalListSortingStrategy,
  sortableKeyboardCoordinates,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import { Icon } from "../../../_foundation/_buttons";

function PageArea(props) {
  const { draggedItem } = props;

  const _p = useContext(PageBuilderContext);
  const [area, setArea] = useState(null);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  useEffect(() => {
    setArea(props.area);
  }, [props.area]);

  if (!area) return null;

  return (
    <div className="pagebuilder-item pagebuilder-area">
      {_p.isDragging && _p.draggingItem.identifier === area.identifier && (
        <DragOver />
      )}
      <PageItemName
        type="area"
        item={area}
        onEditButton={() => props.actions.onSelectArea(area)}
        onDuplicateButton={() => props.actions.onDuplicateItem("area", area)}
        onDeleteButton={() => props.actions.onDelete("area", area)}
        onMoveButton={(direction) =>
          props.actions.onMoveItem(area.identifier, direction)
        }
        listeners={props.listeners}
        attributes={props.attributes}
      />
      <div className="area-content">
        {/*[AREA {area.identifier}] [{area.position}]*/}
        {_p.isDragging && _p.draggingType === "area" ? null : (
          <Rows
            area={area}
            structure={props.structure}
            actions={props.actions}
            draggedItem={draggedItem}
            sensors={sensors}
            _p={_p}
          />
        )}
      </div>
    </div>
  );
}

const Rows = ({ area, structure, draggedItem, _p, sensors, actions }) => {
  let rowPosition = 1;
  return (
    <>
      {!draggedItem && (
        <AddButton
          type="row"
          area={area.identifier}
          position={rowPosition}
          structure={structure}
          onUpdate={actions.onUpdate}
        />
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={actions.onDragStart}
        onDragMove={actions.onDragMove}
        onDragEnd={actions.onDragEnd}
      >
        <SortableContext
          items={structure.areas.map((area) => area.identifier)}
          strategy={verticalListSortingStrategy}
        >
          {area.rows.map((row) => {
            rowPosition++;
            return (
              <React.Fragment key={row.identifier}>
                <SortableRow
                  key={row.identifier}
                  id={row.identifier}
                  row={row}
                  structure={structure}
                  actions={actions}
                />

                {!draggedItem && (
                  <AddButton
                    type="row"
                    area={area.identifier}
                    position={rowPosition}
                    structure={structure}
                    onUpdate={actions.onUpdate}
                  />
                )}
              </React.Fragment>
            );
          })}
        </SortableContext>
        <DragOverlay>
          {draggedItem ? <SortableRowWrapper _p={_p} id={draggedItem} /> : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

const SortableRowWrapper = ({ _p }) => (
  <div className="pagebuilder-sort-wrapper pagebuilder-row-sort-wrapper">
    <Icon icon="arrows" left />{" "}
    {_p.draggingItem && _p.draggingItem.name && (
      <span>{_p.draggingItem.name}</span>
    )}
  </div>
);

const SortableRow = ({ row, structure, actions }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: row.identifier });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <PageRow
        row={row}
        structure={structure}
        actions={actions}
        listeners={listeners}
        attributes={attributes}
      />
    </div>
  );
};

export default PageArea;
