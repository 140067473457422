import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";

import Box from "../../layout/Box";
import ImageUpload from "../../_reusables/ImageUpload";
import AdminDataField from "../AdminDataField";
import { Icon } from "../../_foundation/_buttons";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class AdminHome extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_home"],
    homeInfo: null,
    homeItems: {},
    newsLink: "",
    wordsAudioLoading: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="home" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              <h1>Home-Seite</h1>
            </Cell>
            <Cell
              sm={24}
              md={12}
              className="text-center medium-text-right"
            ></Cell>
          </Row>
        </Grid>
        {this.view__showHomeItems()}
      </AdminLayout>
    );
  }

  view__showHomeItems() {
    const { homeItems } = this.state;

    if (!homeItems) {
      return null;
    }

    return (
      <div className="admin-home">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              {this.view__showBoxWordsTitle()}
              {this.view__showBoxWords()}
              {this.view__showBoxWordsAudio()}
              {this.view__showBoxNews()}
              {this.view__showBoxBelowCountdown()}
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={12}>
              {this.view__showBoxMap()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showBoxAgenda()}
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={24}>
              {this.view__showBoxFooterBottom()}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showBoxWordsTitle() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.wordstitle) {
      return (
        <Box>
          <h3>Words from Loa Überschrift</h3>

          <ImageUpload
            image={homeItems.wordstitle.image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_wordstitle", image);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxWords() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.words && homeItems.words[0]) {
      return (
        <Box>
          <h3>Words from Loa</h3>

          <ImageUpload
            image={homeItems.words[0].image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_words", image);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxWordsAudio() {
    const { homeItems, wordsAudioLoading } = this.state;

    if (homeItems && homeItems.wordsaudio) {
      let v__audioPlayer;

      if (homeItems.wordsaudio.content) {
        v__audioPlayer = (
          <div className="audio-player" style={{ marginBottom: "20px" }}>
            <AudioPlayer src={homeItems.wordsaudio.content} />
            <div>
              <button
                className="small primary hollow button"
                onClick={() => {
                  this.updateAudio("home_wordsaudio", "delete");
                }}
              >
                <Icon icon="trash" left /> Audio löschen
              </button>
            </div>
          </div>
        );
      }

      if (wordsAudioLoading === true) {
        v__audioPlayer = (
          <div style={{ textAlign: "center", padding: "10px" }}>
            <Icon icon="circle-o-notch fa-spin" style={{ fontSize: "2rem" }} />
          </div>
        );
      }

      return (
        <Box>
          <h3>Words from Loa (Audio)</h3>

          {v__audioPlayer}

          <input
            type="file"
            onChange={(e) => {
              this.updateAudio("home_wordsaudio", e.target.files[0]);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxNews() {
    const { homeItems, newsLink } = this.state;

    if (homeItems && homeItems.news) {
      return (
        <Box>
          <h3>News</h3>

          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <ImageUpload
                  image={homeItems.news.image}
                  aspect={null}
                  editMode={true}
                  onUpdate={(image) => {
                    this.updateImage("home_news", image);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={20}>
                <AdminDataField
                  value={newsLink || ""}
                  editValue={newsLink || ""}
                  editType="text"
                  label="Link für News"
                  placeholder="Feld freilassen, wenn keine Verlinkung benötigt"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.setState({ newsLink: newValue });
                  }}
                />
              </Cell>
              <Cell sm={24} md={4}>
                <button
                  className="small primary button"
                  onClick={() => {
                    const _c = this.props.context;

                    let apiUrl = _c.apiUrl("home.item");

                    axios
                      .patch(
                        apiUrl,
                        {
                          type: "newslink",
                          content: newsLink,
                        },
                        {
                          headers: _c.getHeaders(),
                        }
                      )
                      .then((response) => {
                        try {
                          if (_c.isDebug()) {
                            console.log("API RESPONSE", response.data);
                          }

                          const { status } = response.data;
                          _c.handleApiResponse(response.data, true);

                          if (status === "SUCCESS") {
                            _c.createNotifictation(
                              "News Link gespeichert",
                              "Die Verlinkung für die News wurde erfolgreich gespeichert.",
                              "success"
                            );
                          }
                        } catch {
                          return _c.handleError(
                            { status: "AXIOS RESPONSE ERROR" },
                            "home:item:response"
                          );
                        }
                      })
                      .catch((error) => {
                        if (error.response) {
                          return _c.handleError(error, "home:item");
                        }
                      });
                  }}
                >
                  <Icon icon="check" left /> Speichern
                </button>
              </Cell>
            </Row>
          </Grid>
        </Box>
      );
    }
  }

  view__showBoxBelowCountdown() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.map) {
      return (
        <Box>
          <h3>Unter Countdown</h3>

          <ImageUpload
            image={homeItems.belowcountdown.image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_belowcountdown", image);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxMap() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.map) {
      return (
        <Box>
          <h3>Karte</h3>

          <ImageUpload
            image={homeItems.map.image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_map", image);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxAgenda() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.agenda) {
      return (
        <Box>
          <h3>Ablauf</h3>

          <ImageUpload
            image={homeItems.agenda.image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_agenda", image);
            }}
          />
        </Box>
      );
    }
  }

  view__showBoxFooterBottom() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.footerbottom) {
      return (
        <Box>
          <h3>Bild ganz unten über Footer</h3>

          <ImageUpload
            image={homeItems.footerbottom.image}
            aspect={null}
            editMode={true}
            onUpdate={(image) => {
              this.updateImage("home_footerbottom", image);
            }}
          />
        </Box>
      );
    }
  }

  updateImage = (type, image) => {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("updateImage");
    axios
      .post(
        apiUrl,
        {
          image,
          type,
        },
        {
          headers: _c.headers,
        }
      )
      .then((response) => {
        _c.handleApiResponse(response.data, true);
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  updateAudio = (type, file) => {
    const _c = this.props.context;

    if (
      file === "delete" &&
      !window.confirm("Möchtest Du die Audiodatei wirklich entfernen?")
    ) {
      return null;
    }

    const formData = new FormData();
    formData.append("type", type);

    if (file === "delete") {
      formData.append("action", file);
    } else {
      formData.append("file", file);
    }

    this.setState({ wordsAudioLoading: true });

    const apiUrl = _c.apiUrl("updateAudio");
    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        _c.handleApiResponse(response.data, true);
        const { homeInfo } = response.data.data;

        const homeItems = this.initHomeItems(homeInfo);

        this.setState({
          homeInfo,
          homeItems,
          wordsAudioLoading: false,
        });
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.home");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { homeInfo } = apiResponseData;

            const homeItems = this.initHomeItems(homeInfo);

            this.setState({
              homeInfo,
              homeItems,
            });

            _c.setPageTitle("Admin Home", true);
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:home:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:home:init");
      });
  }

  initHomeItems(homeInfo) {
    let homeItems = {
      words: [],
    };

    if (homeInfo && homeInfo.homeItems) {
      homeInfo.homeItems.map((homeItem) => {
        if (homeItem.type === "news") {
          homeItems.news = homeItem;
        } else if (homeItem.type === "map") {
          homeItems.map = homeItem;
        } else if (homeItem.type === "belowcountdown") {
          homeItems.belowcountdown = homeItem;
        } else if (homeItem.type === "agenda") {
          homeItems.agenda = homeItem;
        } else if (homeItem.type === "words") {
          homeItems.words.push(homeItem);
        } else if (homeItem.type === "wordsaudio") {
          homeItems.wordsaudio = homeItem;
        } else if (homeItem.type === "footerbottom") {
          homeItems.footerbottom = homeItem;
        } else if (homeItem.type === "wordstitle") {
          homeItems.wordstitle = homeItem;
        } else if (homeItem.type === "newslink") {
          this.setState({ newsLink: homeItem.content });
        }

        return null;
      });
    }

    return homeItems;
  }
}

export default withContext(AdminHome);
