import React, { Component } from "react";
import Dropzone from "react-dropzone";
import withContext from "../../context/contextHOC";
import MMDialog from "../mmcomponents/mmdialog/MMDialog";
import MMPopup from "../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../_foundation/_buttons";
import { Cell, Grid, Row } from "../_foundation/_grid";
import axios from "axios";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

class ImageUpload extends Component {
  state = {
    editMode: this.props.editMode,
    allowCrop: this.props.allowCrop,
    uploadImageStatus: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: this.props.x,
      y: this.props.y,
      height: this.props.height,
      width: this.props.width,
      aspect: this.props.aspect,
    },
    image: this.props.image,
    showDialogDelete: false,
  };

  componentDidMount() {
    let { allowCrop, crop } = this.state;

    if (!allowCrop) {
      allowCrop = true;
    }

    if (!crop.x) {
      crop.x = 0;
    }

    if (!crop.y) {
      crop.y = 0;
    }

    if (!crop.height && crop.height !== null) {
      crop.height = 100;
    }

    if (!crop.width && crop.width !== null) {
      crop.width = 100;
    }

    if (!crop.aspect && crop.aspect !== null) {
      crop.aspect = 16 / 10;
    }

    this.setState({ allowCrop, crop });
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.editMode !== prevProps.editMode) {
      this.setState({ editMode: this.props.editMode });
    }

    if (this.props.image !== prevProps.image) {
      this.setState({ image: this.props.image });
    }
  }

  render() {
    return (
      <React.Fragment>
        {this.view__showImage()}
        {this.view__showDialogs()}
        {this.view__showPopups()}
      </React.Fragment>
    );
  }

  view__showImage() {
    const { image, editMode } = this.state;
    const _c = this.props.context;

    let v__image = (
      <div className="admin-livecall-noimage wrapper">
        <div className="center-vertically">
          {_c.translate("admin.image.noImage")}
        </div>
      </div>
    );
    if (image) {
      v__image = <img src={image} alt="Vorschaubild" />;
    }

    let uploadButton = (
      <button
        className="button"
        style={{ marginBottom: "0" }}
        onClick={this.showPopupImageUpload}
      >
        <Icon icon="upload" left /> {_c.translate("admin.image.uploadImage")}
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupImageDelete}>
        <Icon icon="trash" left /> {_c.translate("admin.image.deleteImage")}
      </button>
    );

    if (!editMode) {
      uploadButton = null;
    }

    if (!editMode || !image) {
      deleteButton = null;
    }

    return (
      <div>
        <div className="admin-livecall-image">{v__image}</div>
        <div className="admin-livecall-image-upload-buttons">
          <div
            className="small primary hollow no-gaps button-group"
            style={{ marginBottom: "0" }}
          >
            {deleteButton}
            {uploadButton}
          </div>
        </div>
      </div>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, image } = this.state;
    const _c = this.props.context;

    if (!image) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.image.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("admin.image.delete.buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("admin.image.delete.buttons.yes"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__deleteImage}
        />
      </React.Fragment>
    );
  }

  view__showPopups() {
    const { uploadImageStatus, cropUrl, crop } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <div {...getRootProps()}>
                <input {...getInputProps()} />
                <div id="avatarDropZone">
                  <h3>{_c.translate("admin.image.uploadPopup.title")}</h3>
                  <div id="avatarDropZoneText">
                    <p>
                      <Icon icon="upload" />
                    </p>
                    <div
                      dangerouslySetInnerHTML={{
                        __html: _c.translate("admin.image.uploadPopup.text"),
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>{_c.translate("admin.image.cropPopup.title")}</h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate("admin.image.cropPopup.buttons.save.label")}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  handle__deleteImage = () => {
    this.setState({
      image: null,
      uploadImageStatus: null,
      showDialogDelete: false,
    });
    if (this.props.onUpdate) {
      this.props.onUpdate(null);
    }
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ showDialogDelete: true });
  };

  hidePopupImageDelete = () => {
    this.setState({ showDialogDelete: false });
  };

  uploadImage = () => {
    const { cropImage, crop, type } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("type", type);
    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    const apiUrl = _c.apiUrl("imageUpload");

    _c.setIsSaving(true, 4);
    this.hidePopupImageCrop();

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          this.setState({ image: path, changesWereMade: true });
          if (this.props.onUpdate) {
            this.props.onUpdate(path);
          }
          this.hidePopupImageCrop();
          _c.setIsSaving(false);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };
}

export default withContext(ImageUpload);
