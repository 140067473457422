import { cloneDeep } from "lodash";
import React, { useEffect } from "react";
import TRBLPicker from "./components/TRBLPicker";

const optionsKey = "margin";

const defaults = {
  t: "0px",
  r: "0px",
  b: "0px",
  l: "0px",
};

const min = 0;
const max = 200;
const step = 1;

function ControlMargin({ item, onUpdate }) {
  useEffect(() => {
    if (!item.options[optionsKey])
      onUpdate(`options.${optionsKey}`, cloneDeep(defaults));
  }, []);

  const getValue = () => {
    let value = defaults;
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onEdit = (value) => {
    onUpdate(`options.${optionsKey}`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <TRBLPicker
        value={getValue()}
        min={min}
        max={max}
        step={step}
        onUpdate={onEdit}
      />
    </div>
  );
}

export default ControlMargin;
