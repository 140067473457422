import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import Avatar from "../../user/Avatar";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";

class AdminAdmins extends Component {
  state = {
    status: "INIT",
    loadingError: false,
    admins: undefined,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    return (
      <AdminLayout
        nav="admins"
        breadcrumbs={["admin_dashboard", "admin_admins"]}
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showList()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    const _c = this.props.context;

    return <h1>{_c.translate("pageTitles.admin.admins")}</h1>;
  }

  view__showPageActions() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <Link to={_c.config("urls.admin.members")} className="button">
            <Icon icon="plus" left /> {_c.translate("admin.admins.addAdmin")}
          </Link>
        </div>
      </React.Fragment>
    );
  }

  view__showList() {
    const { admins, loadingError } = this.state;
    const _c = this.props.context;

    if (!admins) {
      return _c.loading(loadingError);
    }

    if (admins && isArray(admins) && admins.length === 0) {
      return (
        <p className="text-center">
          {_c.translate("admin.admins.noAdminsFound")}
        </p>
      );
    }

    return (
      <React.Fragment>
        <p className="text-center">
          {_c
            .translate("admin.admins.adminsFound")
            .replace("{total}", admins.length)
            .replace(
              "{admins}",
              admins.length === 1
                ? _c.translate("admin.admins.admin")
                : _c.translate("admin.admins.admins")
            )}
        </p>

        <Box size="nopadding" margin={false}>
          {this.view__showListTitle()}
          {this.view__showListDesktop()}
          {this.view__showListMobile()}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <Grid type="full">
          <Row>
            <Cell md={2} className="text-right">
              <div className="padding">
                {_c.translate("admin.admins.cellTitles.id")}
              </div>
            </Cell>
            <Cell md={2}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={18}>
              <div className="padding">
                {_c.translate("admin.admins.cellTitles.name")}
              </div>
            </Cell>
            <Cell md={2} className="text-center">
              <div className="padding">
                <span
                  data-tip={_c.translate(
                    "admin.admins.cellTitles.fulladmin.tooltip"
                  )}
                >
                  <Icon icon="star" />
                </span>
              </div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop() {
    const { admins } = this.state;
    const _c = this.props.context;

    const user = _c.getUser();

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {admins.map((member, index) => {
            let v__fulladmin = (
              <span className="admin-permission-no">
                <Icon icon="times" />
              </span>
            );

            if (member.admin.fulladmin === true) {
              v__fulladmin = (
                <span className="admin-permission-yes">
                  <Icon icon="check" />
                </span>
              );
            }

            if (parseInt(member.id) === parseInt(user.id)) {
              v__fulladmin = (
                <div className="wrapper">
                  <div className="center-vertically">
                    <div className="padding">
                      <div className="text-center">{v__fulladmin}</div>
                    </div>
                  </div>
                </div>
              );
            } else {
              v__fulladmin = (
                <button
                  className="clickable"
                  data-tip={_c.translate(
                    "admin.admins.fulladmin.toggle.yes.tooltip"
                  )}
                  onClick={() => {
                    this.handle__togglePermission(member, "fulladmin");
                  }}
                >
                  <div className="text-center">{v__fulladmin}</div>
                </button>
              );
            }

            let classes = "admin-list-item";
            if (!member.active) {
              classes = `inactive ${classes}`;
            }

            return (
              <li className={classes} key={index}>
                <Grid type="full">
                  <Row>
                    <Cell md={22}>
                      <Link
                        to={_c
                          .config("urls.admin.member")
                          .replace(":id", member.id)}
                        className="clickable"
                      >
                        <Grid type="full">
                          <Row>
                            <Cell md={2} className="text-right">
                              <div className="wrapper">
                                <div className="center-vertically">
                                  <div className="padding">
                                    <span className="monospace">
                                      {member.id}
                                    </span>
                                  </div>
                                </div>
                              </div>
                            </Cell>
                            <Cell md={2} className="text-center">
                              <div className="padding">
                                <Avatar size="admin-user-list" user={member} />
                              </div>
                            </Cell>
                            <Cell md={18}>
                              <div className="padding">
                                <div className="admin-list-item-title">
                                  {_c.getFullName(member)}
                                </div>
                                <div className="admin-list-item-subtitle">
                                  {member.email}
                                </div>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </Link>
                    </Cell>
                    <Cell md={2}>{v__fulladmin}</Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    const { admins } = this.state;
    const _c = this.props.context;

    const user = _c.getUser();

    return (
      <div className="show-for-small-only">
        <ul className="striped admin-list">
          {admins.map((member, index) => {
            let v__fulladmin = (
              <span className="admin-permission-no">
                <Icon icon="times" />
              </span>
            );

            if (member.admin.fulladmin === true) {
              v__fulladmin = (
                <span className="admin-permission-yes">
                  <Icon icon="check" />
                </span>
              );
            }

            if (parseInt(member.id) === parseInt(user.id)) {
              v__fulladmin = (
                <span>
                  {v__fulladmin}{" "}
                  {_c.translate("admin.admins.cellTitles.fulladmin.text")}
                </span>
              );
            } else {
              v__fulladmin = (
                <button
                  style={{ display: "inline", width: "auto" }}
                  className="clickable text-center"
                  data-tip={_c.translate(
                    "admin.admins.fulladmin.toggle.yes.tooltip"
                  )}
                  onClick={() => {
                    this.handle__togglePermission(member, "fulladmin");
                  }}
                >
                  {v__fulladmin}{" "}
                  {_c.translate("admin.admins.cellTitles.fulladmin.text")}
                </button>
              );
            }

            let classes = "admin-list-item";
            if (!member.active) {
              classes = `inactive ${classes}`;
            }

            return (
              <li className={classes} key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24}>
                      <Link
                        to={_c
                          .config("urls.admin.member")
                          .replace(":id", member.id)}
                        className="clickable"
                      >
                        <Grid type="full">
                          <Row>
                            <Cell sm={4} className="text-center">
                              <div className="padding">
                                <Avatar size="admin-user-list" user={member} />
                              </div>
                            </Cell>
                            <Cell sm={20}>
                              <div className="padding">
                                <div className="admin-list-item-title">
                                  {_c.getFullName(member)}
                                </div>
                                <div className="admin-list-item-subtitle">
                                  {member.email}
                                </div>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </Link>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} className="text-center">
                      {v__fulladmin}
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  handle__togglePermission(member, type) {
    const _c = this.props.context;

    if (
      window.confirm(_c.translate("admin.admins.fulladmin.toggle.yes.confirm"))
    ) {
      const _c = this.props.context;

      let apiUrl = _c.apiUrl("admin.adminsToggle", {
        memberId: member.id,
        type,
      });

      axios
        .patch(
          apiUrl,
          {},
          {
            headers: _c.getHeaders(),
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { admins } = apiResponseData;
              this.setState(
                {
                  admins,
                  status: "LOADED",
                },
                () => {
                  _c.createNotifictation(
                    _c.translate(
                      "admin.admins.fulladmin.toggle.yes.saved.title"
                    ),
                    _c
                      .translate("admin.admins.fulladmin.toggle.yes.saved.text")
                      .replace("{member}", _c.getFullName(member)),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "admin:admins:toggle:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "admin:admins:toggle");
          }
        });
    }
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.admins");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { admins } = apiResponseData;
            this.setState({
              admins,
              status: "LOADED",
            });
          }
          _c.setPageTitle("pageTitles.admin.admins", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:admins:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:admins:init");
      });
  }
}

export default withContext(AdminAdmins);
