import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Icon } from "../../_foundation/_buttons";
import ElementPopup from "./elements/popups/ElementPopup";
import AddButton from "./items/AddButton";
import PageArea from "./items/PageArea";
import AreaPopup from "./items/popups/AreaPopup";
import ColumnPopup from "./items/popups/ColumnPopup";
import RowColumnSchemaPopup from "./items/popups/RowColumnSchemaPopup";
import RowPopup from "./items/popups/RowPopup";
import PagebuilderHelper from "./PagebuilderHelper";

import {
  DndContext,
  DragOverlay,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  arrayMove,
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import { useSortable } from "@dnd-kit/sortable";
import { CSS } from "@dnd-kit/utilities";
import PageBuilderContext from "../../../context/PageBuilderContext";
import PagebuilderTest from "./PagebuilderTest";

function Builder({
  page,
  extra,
  onStructureUpdate,
  unsavedChanges,
  setUnsavedChanges,
}) {
  const _p = useContext(PageBuilderContext);

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, { coordinateGetter: sortableKeyboardCoordinates })
  );

  const [structure, setStructure] = useState(page.structure);

  const [draggedItem, setDraggedItem] = useState(null);

  const [selectedElement, setSelectedElement] = useState(null);
  const [selectedArea, setSelectedArea] = useState(null);
  const [selectedRow, setSelectedRow] = useState(null);
  const [selectedColumn, setSelectedColumn] = useState(null);
  const [selectedRowColumnSchema, setSelectedRowColumnSchema] = useState(null);

  useEffect(() => {
    if (!structure || !structure.areas) setStructure({ areas: [] });
  }, []);

  useEffect(() => {
    if (onStructureUpdate)
      onStructureUpdate(PagebuilderHelper.prepareStructure(structure));
  }, [structure]);

  const onUpdate = ({ updatedStructure, action, element }) => {
    if (updatedStructure) {
      setUnsavedChanges(true);
      setStructure(cloneDeep(updatedStructure));

      if (action === "addElement") {
        setSelectedElement(element);
      }
    }
  };

  const onSelectElement = (element) => {
    setSelectedElement(element);
  };

  const onUnselectElement = () => setSelectedElement(null);

  const onUpdateElement = (element) => {
    const updatedStructure = PagebuilderHelper.updateElement(
      element,
      structure
    );
    setStructure(cloneDeep(updatedStructure));
    setSelectedElement(null);
    setUnsavedChanges(true);
  };

  const onSelectArea = (area) => {
    setSelectedArea(area);
  };

  const onUnselectArea = () => setSelectedArea(null);

  const onUpdateArea = (area) => {
    const updatedStructure = PagebuilderHelper.updateArea(area, structure);
    setStructure(cloneDeep(updatedStructure));
    setSelectedArea(null);
    setUnsavedChanges(true);
  };

  const onSelectRow = (row) => {
    setSelectedRow(row);
  };

  const onUnselectRow = () => setSelectedRow(null);

  const onUpdateRow = (row) => {
    const updatedStructure = PagebuilderHelper.updateRow(row, structure);
    setStructure(cloneDeep(updatedStructure));
    setSelectedRow(null);
    setSelectedRowColumnSchema(null);
    setUnsavedChanges(true);
  };

  const onSelectColumn = (column) => {
    setSelectedColumn(column);
  };

  const onUnselectColumn = () => setSelectedColumn(null);

  const onUpdateColumn = (column) => {
    const updatedStructure = PagebuilderHelper.updateColumn(column, structure);
    setStructure(cloneDeep(updatedStructure));
    setSelectedColumn(null);
    setUnsavedChanges(true);
  };

  const onSelectRowColumnSchema = (row) => {
    setSelectedRowColumnSchema(row);
  };

  const onUnselectRowColumnSchema = () => setSelectedRowColumnSchema(null);

  const onUpdateRowColumnSchema = (row) => {
    const updatedStructure = PagebuilderHelper.updateRow(row, structure);
    setStructure(cloneDeep(updatedStructure));
    setSelectedRowColumnSchema(null);
    setUnsavedChanges(true);
  };

  const onMoveItem = (item, direction) => {
    const updatedStructure = PagebuilderHelper.moveItem(
      item,
      direction,
      structure
    );
    setStructure(cloneDeep(updatedStructure));
  };

  const onDuplicateItem = (type, item) => {
    const updatedStructure = PagebuilderHelper.duplicateItem(item, structure);
    setStructure(cloneDeep(updatedStructure));
  };

  const onDelete = (itemType, item) => {
    let updatedStructure;
    if (itemType === "element")
      updatedStructure = PagebuilderHelper.updateElement(item, structure, true);
    if (itemType === "row")
      updatedStructure = PagebuilderHelper.updateRow(item, structure, true);
    if (itemType === "area")
      updatedStructure = PagebuilderHelper.updateArea(item, structure, true);
    setStructure(cloneDeep(updatedStructure));
    setUnsavedChanges(true);
  };

  const onDragStart = (event) => {
    const { active } = event;
    setDraggedItem(active.id);

    let draggingType = active.id.split("_");
    if (draggingType && draggingType[0]) {
      draggingType = draggingType[0];
      const draggingItem = PagebuilderHelper.findItem(active.id, structure);
      _p.startDragging(draggingType, draggingItem);
    }
  };

  const onDragMove = (event) => {
    const { over } = event;
    if (!over) return;
    _p.setDraggingOver(over.id);
    console.log("_p", _p);

    //console.log("OVER: ", over.id);
  };

  const onDragEnd = (event) => {
    const { active, over } = event;
    _p.stopDragging();

    if (!over) return;

    const items = structure.areas;

    //console.log(active.id, over.id, items);

    if (active.id !== over.id) {
      const oldIndex = items.findIndex((item) => item.identifier === active.id);
      const newIndex = items.findIndex((item) => item.identifier === over.id);

      const updatedItems = arrayMove(items, oldIndex, newIndex);
      //console.log("updated", updatedItems);
      setStructure(
        PagebuilderHelper.replaceItemsArrayAfterSort(
          "areas",
          updatedItems,
          null,
          structure
        )
      );
      setUnsavedChanges(true);
    }

    setDraggedItem(null);
  };

  const actions = {
    onUpdate,
    onSelectElement,
    onUnselectElement,
    onUpdateElement,
    onSelectArea,
    onUnselectArea,
    onUpdateArea,
    onSelectRow,
    onUnselectRow,
    onUpdateRow,
    onSelectColumn,
    onUnselectColumn,
    onUpdateColumn,
    onSelectRowColumnSchema,
    onUnselectRowColumnSchema,
    onUpdateRowColumnSchema,
    onMoveItem,
    onDuplicateItem,
    onDelete,
    onDragStart,
    onDragMove,
    onDragEnd,
  };

  if (page.identifier === "new")
    return (
      <div className="text-center">
        Bitte speichere die Seite, um den Builder zu aktivieren.
      </div>
    );

  return (
    <>
      <div
        className={`pagebuilder-builder ${
          _p.isDragging && `is-dragging is-dragging-${_p.draggingType}`
        }`}
      >
        {unsavedChanges && <UnsavedChanges />}
        <Areas
          structure={structure}
          actions={actions}
          sensors={sensors}
          draggedItem={draggedItem}
          _p={_p}
        />

        {selectedElement && (
          <ElementPopup
            element={selectedElement}
            actions={actions}
            extra={extra}
            onCancel={() => setSelectedElement(null)}
          />
        )}
        {selectedArea && (
          <AreaPopup
            area={selectedArea}
            actions={actions}
            onCancel={() => onUnselectArea()}
          />
        )}
        {selectedRow && (
          <RowPopup
            row={selectedRow}
            actions={actions}
            onCancel={() => onUnselectRow()}
          />
        )}
        {selectedColumn && (
          <ColumnPopup
            column={selectedColumn}
            actions={actions}
            onCancel={() => onUnselectColumn()}
          />
        )}
        {selectedRowColumnSchema && (
          <RowColumnSchemaPopup
            row={selectedRowColumnSchema}
            actions={actions}
            onCancel={() => onUnselectRowColumnSchema()}
          />
        )}
      </div>
    </>
  );
}

const UnsavedChanges = () => (
  <div className="pagebuilder-unsaved-changes small warning callout">
    <Icon icon="info-circle" left /> Achtung: Es gibt ungespeicherte Änderungen.
    Nicht vergessen zu speichern!
  </div>
);

const Areas = ({ structure, sensors, draggedItem, actions, _p }) => {
  let areaPosition = 1;

  return (
    <>
      {!draggedItem && (
        <AddButton
          type="area"
          position={areaPosition}
          structure={structure}
          onUpdate={actions.onUpdate}
        />
      )}
      <DndContext
        sensors={sensors}
        collisionDetection={closestCenter}
        onDragStart={actions.onDragStart}
        onDragMove={actions.onDragMove}
        onDragEnd={actions.onDragEnd}
      >
        <SortableContext
          items={structure.areas.map((area) => area.identifier)}
          strategy={verticalListSortingStrategy}
        >
          {structure.areas.map((area) => (
            <React.Fragment key={area.identifier}>
              <SortableArea
                key={area.identifier}
                id={area.identifier}
                area={area}
                structure={structure}
                draggedItem={draggedItem}
                actions={actions}
              />

              {!draggedItem && (
                <AddButton
                  type="area"
                  position={areaPosition}
                  structure={structure}
                  onUpdate={actions.onUpdate}
                />
              )}
            </React.Fragment>
          ))}
        </SortableContext>
        <DragOverlay>
          {draggedItem ? (
            <SortableAreaWrapper _p={_p} id={draggedItem} />
          ) : null}
        </DragOverlay>
      </DndContext>
    </>
  );
};

const SortableAreaWrapper = ({ _p }) => (
  <div className="pagebuilder-sort-wrapper pagebuilder-area-sort-wrapper">
    <Icon icon="arrows" left />{" "}
    {_p.draggingItem && _p.draggingItem.name && (
      <span>{_p.draggingItem.name}</span>
    )}
  </div>
);

const SortableArea = ({ area, structure, draggedItem, actions }) => {
  const { attributes, listeners, setNodeRef, transform, transition } =
    useSortable({ id: area.identifier });

  const style = {
    transform: CSS.Transform.toString(transform),
    transition,
  };

  return (
    <div ref={setNodeRef} style={style}>
      <PageArea
        draggedItem={draggedItem}
        area={area}
        structure={structure}
        actions={actions}
        listeners={listeners}
        attributes={attributes}
      />
    </div>
  );
};

export default Builder;
