import React, { useState } from "react";
import { Icon } from "../../_foundation/_buttons";
import PageViewHelper from "../PageViewHelper";

function ElementButton({ element }) {
  const [mouseHoverStatus, setMouseHoverStatus] = useState(false);

  let buttonUrl;
  if (element.options && element.options.url) buttonUrl = element.options.url;

  return (
    <div
      style={PageViewHelper.applyOptions(
        element,
        ["margin", "alignh"],
        null,
        mouseHoverStatus
      )}
    >
      <ButtonContent
        element={element}
        mouseHoverStatus={mouseHoverStatus}
        setMouseHoverStatus={setMouseHoverStatus}
      />
    </div>
  );
}

const ButtonContent = ({ element, mouseHoverStatus, setMouseHoverStatus }) => {
  let hasIcon = false;
  let icon = null;
  let iconPosition = "left";

  if (element.options) {
    if (element.options.icon) {
      hasIcon = true;
      icon = element.options.icon;
      if (element.options.iconposition)
        iconPosition = element.options.iconposition;
    }
  }

  return (
    <span
      style={PageViewHelper.applyOptions(element, null, null, mouseHoverStatus)}
      onMouseOver={() => setMouseHoverStatus(true)}
      onMouseOut={() => setMouseHoverStatus(false)}
    >
      {hasIcon && iconPosition === "left" && (
        <span className="pageview-button-icon-left">
          <Icon icon={icon} />
        </span>
      )}
      {element.content}
      {hasIcon && iconPosition === "right" && (
        <span className="pageview-button-icon-right">
          <Icon icon={icon} />
        </span>
      )}
    </span>
  );
};

export default ElementButton;
