import axios from "axios";
import ApiHelper from "../../../helpers/ApiHelper";
import ConfigHelper from "../../../helpers/ConfigHelper";

const PageBuilderAPI = {
  getPages: function (_c, callback) {
    axios
      .get(ApiHelper.url("admin.pagebuilderPages"), {
        headers: ApiHelper.getHeaders(),
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          const { pages, extra } = response.data.data;

          _c.handleApiResponse(response.data, true);

          if (callback) callback({ pages, extra });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
  getPage: function (identifier, _c, callback) {
    axios
      .get(ApiHelper.url("admin.pagebuilderPage", { identifier }), {
        headers: ApiHelper.getHeaders(),
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          const { page, extra } = response.data.data;

          _c.handleApiResponse(response.data, true);

          if (callback) callback({ page, extra });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
  getPageBySlug: function (slug, _c, callback) {
    axios
      .get(ApiHelper.url("pagebuilder.page", { slug }), {
        headers: ApiHelper.getHeaders(),
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          if (
            response &&
            response.data &&
            response.data.status &&
            response.data.status === "NOTFOUND"
          )
            window.location.href = ConfigHelper.get("redirect.afterLogin");
          const { page } = response.data.data;

          _c.handleApiResponse(response.data, true);

          if (callback) callback({ page });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
  savePage: function (identifier, page, _c, callback) {
    const formData = {
      page,
    };
    axios
      .post(ApiHelper.url("admin.pagebuilderPage", { identifier }), formData, {
        headers: ApiHelper.getHeaders(),
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          const { page } = response.data.data;

          _c.handleApiResponse(response.data, true);

          if (callback) callback({ page });
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
  deletePage: function (identifier, _c, callback) {
    axios
      .delete(ApiHelper.url("admin.pagebuilderPage", { identifier }), {
        headers: ApiHelper.getHeaders(),
      })
      .then((response) => {
        if (response && response.data && response.data.data) {
          _c.handleApiResponse(response.data, true);
          if (callback) callback();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
  clonePage: function (identifier, _c, callback) {
    axios
      .post(
        ApiHelper.url("admin.pagebuilderClonePage", { identifier }),
        {},
        {
          headers: ApiHelper.getHeaders(),
        }
      )
      .then((response) => {
        if (response && response.data && response.data.data) {
          _c.handleApiResponse(response.data, true);

          const { clonedPageIdentifier } = response.data.data;

          if (callback) callback(clonedPageIdentifier);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  },
};

export default PageBuilderAPI;
