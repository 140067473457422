import React, { Component } from "react";

class Avatar extends Component {
  state = {
    user: this.props.user,
    size: this.props.size,
  };

  componentDidUpdate(prevProps) {
    if (this.props.user !== prevProps.user) {
      this.setState({
        user: this.props.user,
      });
    }
  }

  render() {
    const { user } = this.state;

    if (user) {
      if (user.avatar && user.avatar !== "null") {
        return (
          <img src={user.avatar} alt="" className={this.getAvatarClasses()} />
        );
      } else {
        return (
          <div className={this.getAvatarClasses()}>
            {this.getFirstname()}
            {this.getLastname()}
          </div>
        );
      }
    } else {
      return <div className={this.getAvatarClasses()}>&nbsp;&nbsp;</div>;
    }
  }

  getAvatarClasses() {
    let classes = "avatar avatar-no-image ";
    classes += this.getAvatarSize();
    return classes;
  }

  getAvatarSize() {
    const { size } = this.state;

    return `avatar-size-${size}`;
  }

  getFirstname() {
    const { user } = this.state;

    if (user.firstname) {
      return user.firstname.charAt(0);
    }
  }

  getLastname() {
    const { user } = this.state;

    if (user.lastname) {
      return user.lastname.charAt(0);
    }
  }
}

export default Avatar;
