import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../_foundation/_grid";
import Box from "../layout/Box";

class DataPrivacy extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    _c.init(true);
  }

  render() {
    const _c = this.props.context;

    let dataprivacyHtml = <p></p>;

    if (_c.setting("dataprivacy_html")) {
      dataprivacyHtml = _c.setting("dataprivacy_html");
    }

    return (
      <DefaultLayout breadcrumbs={["home", "dataprivacy"]}>
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <Box>
                <div dangerouslySetInnerHTML={{ __html: dataprivacyHtml }} />
              </Box>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }
}

export default withContext(DataPrivacy);
