import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import AdminDataField from "../AdminDataField";
import { cloneDeep } from "lodash";
import { Icon } from "../../_foundation/_buttons";
import axios from "axios";
import AdminTags from "../AdminTags";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";

import { Editor } from "react-draft-wysiwyg";
import { EditorState, convertFromRaw, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import AdminCourseDownloads from "./AdminCourseDownloads";

import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import ImageUpload from "../../_reusables/ImageUpload";

import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class AdminCourseLessons extends Component {
  state = {
    _updatableProps: [
      "course",
      "newLesson",
      "newCategory",
      "newDownload",
      "subCategories",
    ],
    course: this.props.course,
    selectedCategory: null,
    selectedEditCategory: null,
    selectedLesson: null,
    changesWereMade: false,
    lessonTabs: {},
    selectedLessonTab: "info",
    newLesson: this.props.newLesson,
    newCategory: this.props.newCategory,
    newDownload: this.props.newDownload,
    subCategories: this.props.subCategories,
    uploadProgressInterval: null,
    editorState: undefined,
    uploadImageStatus: null,
    uploadType: "image",
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
      aspect: 50 / 28,
    },
  };

  componentDidMount() {
    const _c = this.props.context;

    this.setState({
      editorState: EditorState.createEmpty(),
      lessonTabs: {
        info: {
          name: _c.translate("admin.courses.lessons.lesson.tabs.info"),
          icon: "info-circle",
        },
        content: {
          name: _c.translate("admin.courses.lessons.lesson.tabs.content"),
          icon: "youtube-play",
        },
        publish: {
          name: _c.translate("admin.courses.lessons.lesson.tabs.publish"),
          icon: "clock-o",
        },
        downloads: {
          name: _c.translate("admin.courses.lessons.lesson.tabs.downloads"),
          icon: "download",
        },
        tags: {
          name: _c.translate("admin.courses.lessons.lesson.tabs.tags"),
          icon: "tags",
        },
      },
    });
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { course } = this.state;
    const _c = this.props.context;

    if (!course || !course.categories) {
      return;
    }

    if (!course.id || course.id === "new") {
      return (
        <p>
          <em>{_c.translate("admin.courses.lessons.noNew")}</em>
        </p>
      );
    }

    return (
      <div className="admin-course-lessons">
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={12}>
              {this.view__showLeftSide()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showRightSide()}
            </Cell>
          </Row>
        </Grid>

        {this.view__showPopups()}
        {this.view__showPopupsCategory()}

        <ul id="adminSortCategories" className="admin-course-categories" />
        <ul id="adminSortLessons" className="admin-course-lessons-list" />
      </div>
    );
  }

  view__showLessonTabs() {
    const { lessonTabs, selectedLessonTab } = this.state;

    return (
      <div className="admin-tabs">
        <MMTabs
          tabs={lessonTabs}
          selected={selectedLessonTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedLessonTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPopups() {
    const {
      selectedCategory,
      selectedLesson,
      selectedEditCategory,
    } = this.state;
    const _c = this.props.context;

    if (selectedCategory && selectedLesson) {
      return (
        <MMPopup show={true} size="large" handleClose={this.handle__cancel}>
          <h3>
            {_c.translate("admin.courses.lessons.lesson.lesson")}:{" "}
            {selectedLesson.name}
          </h3>
          {this.view__showLessonEditButtons("top")}
          {this.view__showLessonTabs()}
          {this.view__showLessonPopupContent()}
          {this.view__showLessonEditButtons()}
        </MMPopup>
      );
    } else if (selectedEditCategory) {
      let popupTitle = _c.translate("admin.courses.lessons.categories.edit");
      if (selectedEditCategory.id === "new") {
        popupTitle = _c.translate("admin.courses.lessons.categories.add");
      }

      return (
        <MMPopup show={true} size="medium" handleClose={this.handle__cancel}>
          <h3>{popupTitle}</h3>
          {this.view__showCategoryEdit()}
        </MMPopup>
      );
    }
  }

  view__showCategoryEdit() {
    const { selectedEditCategory } = this.state;
    const _c = this.props.context;

    return (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={12}>
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  <input
                    type="text"
                    value={selectedEditCategory.name || ""}
                    onChange={(e) => {
                      selectedEditCategory.name = e.target.value;
                      this.setState({ selectedEditCategory });
                    }}
                    placeholder={_c.translate(
                      "admin.courses.lessons.categories.name"
                    )}
                  />
                  <div className="admin-data-label">
                    {_c.translate("admin.courses.lessons.categories.name")}
                  </div>
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  {this.view__showBoxImage()}
                  {this.view__showBoxBanner()}
                </Cell>
              </Row>
            </Grid>
          </Cell>
          <Cell sm={24} md={12}>
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  <textarea
                    rows={3}
                    value={selectedEditCategory.short_description || ""}
                    onChange={(e) => {
                      selectedEditCategory.short_description = e.target.value;
                      this.setState({ selectedEditCategory });
                    }}
                    placeholder={_c.translate(
                      "admin.courses.lessons.categories.short_description"
                    )}
                  />
                  <div className="admin-data-label">
                    {_c.translate(
                      "admin.courses.lessons.categories.short_description"
                    )}
                  </div>
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  <textarea
                    rows={8}
                    value={selectedEditCategory.description_html || ""}
                    onChange={(e) => {
                      selectedEditCategory.description_html = e.target.value;
                      this.setState({ selectedEditCategory });
                    }}
                    placeholder={_c.translate(
                      "admin.courses.lessons.categories.description"
                    )}
                  />
                  <div className="admin-data-label">
                    {_c.translate(
                      "admin.courses.lessons.categories.description"
                    )}
                  </div>
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  <AdminDataField
                    value={selectedEditCategory.featured}
                    editValue={selectedEditCategory.featured}
                    editType="singleCheckbox"
                    label="Kategorie auf Home-Seite anzeigen"
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      selectedEditCategory.featured = newValue;
                      this.setState({ selectedEditCategory });
                    }}
                  />
                </Cell>
              </Row>
            </Grid>
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24} md={24} className="text-right">
            <div className="page-buttons">
              <button
                className="small primary hollow button"
                onClick={this.handle__cancelCategory}
              >
                <Icon icon="times" left /> {_c.translate("buttons.cancel")}
              </button>
              <button
                className="small primary button"
                onClick={this.handle__saveCategory}
              >
                <Icon icon="check" left /> {_c.translate("buttons.save")}
              </button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonPopupContent() {
    const { selectedLessonTab } = this.state;

    if (selectedLessonTab === "info") {
      return this.view__showLessonPopupInfo();
    } else if (selectedLessonTab === "content") {
      return this.view__showLessonContent();
    } else if (selectedLessonTab === "publish") {
      return this.view__showLessonPopupPublish();
    } else if (selectedLessonTab === "downloads") {
      return this.view__showLessonPopupDownloads();
    } else if (selectedLessonTab === "tags") {
      return this.view__showLessonPopupTags();
    }
  }

  view__showLessonPopupInfo() {
    const { course, selectedLesson, subCategories } = this.state;
    const _c = this.props.context;

    let availableCategories = {};
    course.categories.map((category) => {
      availableCategories[category.id] = category.name;
      return null;
    });

    const selectValuesSubCategories = {};
    if (subCategories[selectedLesson.category]) {
      subCategories[selectedLesson.category].map((subcategory) => {
        selectValuesSubCategories[subcategory.id] = subcategory.name;

        if (!selectedLesson.subcategory) {
          selectedLesson.subcategory = subcategory.id;
        }
      });
    }

    let v__subcategory;
    if (
      selectValuesSubCategories &&
      Object.keys(selectValuesSubCategories).length > 0
    ) {
      v__subcategory = (
        <Row margin="y">
          <Cell sm={24} md={24}>
            <AdminDataField
              value={selectedLesson.subcategory}
              editValue={selectedLesson.subcategory}
              editType="select"
              selectValues={selectValuesSubCategories}
              label={_c.translate("admin.courses.lessons.lesson.subcategory")}
              editable={true}
              edit={true}
              onUpdate={(newValue) => {
                this.handle__edit("subcategory", newValue);
              }}
            />
          </Cell>
        </Row>
      );
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            <Box>
              <h3>{_c.translate("admin.courses.lessons.lesson.category")}</h3>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.category}
                      editValue={selectedLesson.category}
                      editType="select"
                      selectValues={availableCategories}
                      label={_c.translate(
                        "admin.courses.lessons.lesson.category"
                      )}
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("category", newValue);
                      }}
                    />
                  </Cell>
                </Row>
                {v__subcategory}
              </Grid>
            </Box>
            <Box>
              <h3>{_c.translate("admin.courses.lessons.lesson.lesson")}</h3>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24} md={24}>
                    <AdminDataField
                      value={selectedLesson.name}
                      editValue={selectedLesson.name}
                      editType="text"
                      label={_c.translate("admin.courses.lessons.lesson.name")}
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) => {
                        this.handle__edit("name", newValue);
                      }}
                    />
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </Cell>
          <Cell sm={24} md={12}>
            <Box>
              <h3>Thumbnail</h3>
              <ImageUpload
                image={selectedLesson.image}
                aspect={50 / 28}
                editMode={true}
                onUpdate={(image) => {
                  selectedLesson.image = image;
                  this.setState({ selectedLesson });
                }}
              />
            </Box>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonContent() {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    let availableVideoTypes = _c.getVideoTypes();
    let availableLessonTypes = {};

    Object.keys(availableVideoTypes).map((videoTypeKey) => {
      availableLessonTypes[videoTypeKey] = `${_c.translate(
        "admin.courses.lessons.lesson.lessonTypes.video"
      )}: ${availableVideoTypes[videoTypeKey]}`;
      return null;
    });

    availableLessonTypes.text = _c.translate(
      "admin.courses.lessons.lesson.lessonTypes.text"
    );

    availableLessonTypes.audio = _c.translate(
      "admin.courses.lessons.lesson.lessonTypes.audio"
    );

    let v__contentContent, v__contentExtra;

    if (
      selectedLesson.type === "vimeo" ||
      selectedLesson.type === "youtube" ||
      selectedLesson.type === "embed" ||
      selectedLesson.type === "upload" ||
      selectedLesson.type === "audio"
    ) {
      v__contentContent = this.view__showContentVideo();
      v__contentExtra = (
        <Box>
          <h3>
            {_c.translate("admin.courses.lessons.lesson.shownotes.label")}
          </h3>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                {this.view__showDescriptionBox()}
              </Cell>
            </Row>
          </Grid>
        </Box>
      );
    } else if (selectedLesson.type === "text") {
      v__contentContent = this.view__showContentText();
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            <Box>
              <h3>{_c.translate("admin.courses.lessons.lesson.lessonType")}</h3>
              <AdminDataField
                value={selectedLesson.type}
                editValue={selectedLesson.type}
                editType="select"
                selectValues={availableLessonTypes}
                label={_c.translate("admin.courses.lessons.lesson.lessonType")}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("type", newValue);
                }}
              />
            </Box>

            {v__contentExtra}
          </Cell>
          <Cell sm={24} md={12}>
            {v__contentContent}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showContentVideo() {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    let videoTypeInputType = "text";
    if (selectedLesson.type === "embed") {
      videoTypeInputType = "textarea";
    }

    return (
      <React.Fragment>
        {this.view__showVideoPreview()}
        <Box>
          <h3>
            {selectedLesson.type === "audio"
              ? _c.translate("admin.courses.lessons.lesson.audio")
              : _c.translate("admin.courses.lessons.lesson.video")}
          </h3>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <div
                  style={{
                    display: selectedLesson.type === "audio" ? "block" : "none",
                  }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      this.handle__edit("file", e.target.files[0]);
                    }}
                  />
                </div>
                <div
                  style={{
                    display: ["upload", "audio"].includes(selectedLesson.type)
                      ? "none"
                      : "block",
                  }}
                >
                  <AdminDataField
                    value={selectedLesson.content}
                    editValue={selectedLesson.content}
                    editType={videoTypeInputType}
                    label={_c.translate(
                      `admin.courses.lessons.lesson.videoIds.${selectedLesson.type}`
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      this.handle__edit("content", newValue);
                    }}
                  />
                </div>
                <div
                  style={{
                    display:
                      selectedLesson.type === "upload" ? "block" : "none",
                  }}
                >
                  <input
                    type="file"
                    onChange={(e) => {
                      this.handle__edit("file", e.target.files[0]);
                    }}
                  />
                </div>
                <div
                  className="small warning callout"
                  style={{
                    marginTop: "10px",
                    marginBottom: "0",
                    display:
                      selectedLesson.type === "youtube" ||
                      selectedLesson.type === "vimeo"
                        ? "block"
                        : "none",
                  }}
                >
                  {_c.translate(
                    "admin.courses.lessons.lesson.videoTypeMessage"
                  )}
                </div>
              </Cell>
            </Row>
            <Row
              margin="y"
              style={{
                display: selectedLesson.type === "audio" ? "none" : "block",
              }}
            >
              <Cell
                sm={24}
                md={24}
                className="text-center"
                style={{ paddingTop: "10px" }}
              >
                <AdminDataField
                  value={selectedLesson.live}
                  editValue={selectedLesson.live}
                  editType="singleCheckbox"
                  label={_c.translate("admin.courses.lessons.lesson.livecall")}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("live", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </Box>
      </React.Fragment>
    );
  }

  view__showContentText() {
    const _c = this.props.context;

    return (
      <Box>
        <h3>{_c.translate("admin.courses.lessons.lesson.textLesson.title")}</h3>
        <Grid type="full">
          <Row margin="y">
            <Cell sm={24} md={24}>
              <div className="large-editor">
                {this.view__showDescriptionBox()}
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showDescriptionBox() {
    const { editorState } = this.state;
    const _c = this.props.context;

    return (
      <Editor
        editorState={editorState}
        wrapperClassName="editor-wrapper"
        editorClassName="editor-editor"
        onEditorStateChange={this.onEditorStateChange}
        localization={{
          locale: "de",
        }}
        toolbar={{
          options: _c.config("adminEditor.settings.replyFormOptions"),
          inline: _c.config("adminEditor.settings.replyInlineOptions"),
          blockType: _c.config("adminEditor.settings.replyBlockTypeOptions"),
        }}
      />
    );
  }

  onEditorStateChange = (editorState) => {
    const { selectedLesson } = this.state;

    if (editorState) {
      selectedLesson.description = convertToRaw(
        editorState.getCurrentContent()
      );
      selectedLesson.description_html = draftToHtml(selectedLesson.description);
    }

    this.setState({ editorState, selectedLesson });
  };

  view__showLessonPopupPublish() {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    let publishTypes = ["instantly", "date", "period"];

    let periodAmounts = [];
    for (let i = 1; i <= 24; i++) {
      periodAmounts.push(i);
    }
    let periodUnits = _c.getLessonPeriodUnits();

    if (!periodUnits) {
      return;
    }

    let showBeforeValues = {
      show: _c.translate(
        "admin.courses.lessons.lesson.publish.showBefore.options.show"
      ),
      hide: _c.translate(
        "admin.courses.lessons.lesson.publish.showBefore.options.hide"
      ),
    };

    let showBeforeValue;
    if (selectedLesson.publish_show_before === true) {
      showBeforeValue = "show";
    } else {
      showBeforeValue = "hide";
    }

    let v__title = _c.translate(
      `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.title`
    );

    let v__content;

    let v__showBefore = (
      <Box>
        <p>
          <strong>
            {_c.translate(
              `admin.courses.lessons.lesson.publish.showBefore.title`
            )}
          </strong>
        </p>
        <p>
          {_c.translate(`admin.courses.lessons.lesson.publish.showBefore.text`)}
        </p>

        <AdminDataField
          value={selectedLesson.publish_show_before}
          editValue={showBeforeValue}
          editType="select"
          selectValues={showBeforeValues}
          label={_c.translate(
            `admin.courses.lessons.lesson.publish.showBefore.title`
          )}
          editable={true}
          edit={true}
          onUpdate={(newValue) => {
            if (newValue === "show") {
              this.handle__edit("publish_show_before", true);
            } else {
              this.handle__edit("publish_show_before", false);
            }
          }}
        />
      </Box>
    );

    if (selectedLesson.publish_type === "instantly") {
      v__content = (
        <p>
          {_c.translate(
            `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.text`
          )}
        </p>
      );
      v__showBefore = null;
    } else if (selectedLesson.publish_type === "date") {
      v__content = (
        <div>
          <p>
            {_c.translate(
              `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.text2`
            )}
          </p>

          <AdminDataField
            value={selectedLesson.publish_date_f}
            editValue={selectedLesson.publish_date}
            editType="datetime"
            label={_c.translate(
              `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.form.datetime.label`
            )}
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              this.handle__edit("publish_date", newValue);
            }}
          />
        </div>
      );
    } else if (selectedLesson.publish_type === "period") {
      v__content = (
        <div>
          <p>
            {_c.translate(
              `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.text2`
            )}
          </p>

          <Grid type="full">
            <Row margin="x">
              <Cell sm={4} md={6}>
                <AdminDataField
                  value={selectedLesson.publish_period_amount || ""}
                  editValue={selectedLesson.publish_period_amount}
                  editType="number"
                  label={_c.translate(
                    `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.form.amount.label`
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("publish_period_amount", newValue);
                  }}
                />
              </Cell>
              <Cell sm={10} md={18}>
                <AdminDataField
                  value={selectedLesson.publish_period_unit}
                  editValue={selectedLesson.publish_period_unit}
                  editType="select"
                  selectValues={periodUnits}
                  label={_c.translate(
                    `admin.courses.lessons.lesson.publish.types.${selectedLesson.publish_type}.form.unit.label`
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("publish_period_unit", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    }

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={24}>
            <h3>
              {_c.translate("admin.courses.lessons.lesson.publish.title")}
            </h3>
            <p>{_c.translate("admin.courses.lessons.lesson.publish.text")}</p>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24} md={12}>
            {publishTypes.map((publishType, index) => {
              return (
                <Box key={index}>
                  <label>
                    <Grid type="full">
                      <Row margin="y">
                        <Cell sm={4} md={2}>
                          <input
                            type="radio"
                            name={`access_${selectedLesson.id}`}
                            value={publishType}
                            checked={
                              selectedLesson.publish_type === publishType
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              selectedLesson.publish_type = e.target.value;
                              this.setState({
                                changesWereMade: true,
                                selectedLesson,
                              });
                            }}
                          />
                        </Cell>
                        <Cell sm={20} md={22}>
                          <div>
                            <strong>
                              {_c.translate(
                                `admin.courses.lessons.lesson.publish.types.${publishType}.title`
                              )}
                            </strong>
                          </div>
                          <div>
                            {_c.translate(
                              `admin.courses.lessons.lesson.publish.types.${publishType}.text`
                            )}
                          </div>
                        </Cell>
                      </Row>
                    </Grid>
                  </label>
                </Box>
              );
            })}
          </Cell>
          <Cell sm={24} md={12}>
            <Box>
              <h4>{v__title}</h4>

              {v__content}
            </Box>

            {v__showBefore}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonPopupDownloads() {
    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            {this.view__showDownloads()}
          </Cell>
          <Cell sm={24} md={12}>
            &nbsp;
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonPopupTags() {
    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={12}>
            {this.view__showTags()}
          </Cell>
          <Cell sm={24} md={12}>
            &nbsp;
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showLessonEditButtons(where) {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    let v__deleteButton;
    if (where === "top" && selectedLesson.id !== "new") {
      v__deleteButton = (
        <button
          className="small primary hollow button"
          onClick={this.handle__delete}
          style={{ marginRight: "20px" }}
        >
          <Icon icon="trash" left />{" "}
          {_c.translate("admin.courses.lessons.lesson.delete.button.label")}
        </button>
      );
    }

    return (
      <div className="page-buttons">
        {v__deleteButton}
        <button
          className="small primary hollow button"
          onClick={this.handle__cancel}
        >
          <Icon icon="times" left /> {_c.translate("buttons.cancel")}
        </button>
        <button className="small primary button" onClick={this.handle__save}>
          <Icon icon="check" left /> {_c.translate("buttons.save")}
        </button>
      </div>
    );
  }

  view__showVideoPreview() {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    if (selectedLesson.type && selectedLesson.content) {
      if (selectedLesson.type === "vimeo") {
        let embedUrl = `https://player.vimeo.com/video/${selectedLesson.content}`;
        return (
          <div className="responsive-embed widescreen">
            <div id="vimeoPlayer">
              <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="vimeoPlayer"
              ></iframe>
            </div>
          </div>
        );
      } else if (selectedLesson.type === "youtube") {
        let embedUrl = `https://www.youtube.com/embed/${selectedLesson.content}`;
        return (
          <div className="responsive-embed widescreen">
            <div id="youtubePlayer">
              <iframe
                src={embedUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YoutubePlayer"
              ></iframe>
            </div>
          </div>
        );
      } else if (selectedLesson.type === "embed") {
        return (
          <div className="responsive-embed widescreen">
            <div dangerouslySetInnerHTML={{ __html: selectedLesson.content }} />
          </div>
        );
      } else if (selectedLesson.type === "audio" && selectedLesson.content) {
        return (
          <div className="audio-player">
            <Box>
              <AudioPlayer src={selectedLesson.content} />
            </Box>
          </div>
        );
      } else if (selectedLesson.type === "upload") {
        if (selectedLesson._video) {
          if (selectedLesson._video.progress !== 100) {
            return (
              <div className="video-converting">
                <div className="center-vertically">
                  <div className="video-converting-icon">
                    <Icon icon="circle-o-notch fa-spin" />
                  </div>
                  <div className="video-converting-text">
                    {_c
                      .translate(
                        "admin.courses.lessons.lesson.upload.converting"
                      )
                      .replace("{progress}", selectedLesson._video.progress)}
                  </div>
                </div>
              </div>
            );
          } else {
            let videoFile = _c.apiUrl("files.video", {
              type: "lesson",
              id: selectedLesson.id,
            });

            return (
              <div className="responsive-embed1 widescreen2">
                <Player
                  ref={(player) => {
                    this.player = player;
                  }}
                  videoId="video-1"
                >
                  <ControlBar>
                    <ReplayControl seconds={10} order={1.1} />
                    <ForwardControl seconds={30} order={1.2} />
                    <CurrentTimeDisplay order={4.1} />
                    <TimeDivider order={4.2} />
                    <PlaybackRateMenuButton
                      rates={[2, 1.75, 1.5, 1.25, 1, 0.5]}
                      order={7.1}
                    />
                    <VolumeMenuButton disabled />
                  </ControlBar>
                  <source src={videoFile} />
                </Player>
              </div>
            );
          }
        }
      }
    }

    return null;
  }

  view__showLeftSide() {
    const { course, newCategory } = this.state;
    const _c = this.props.context;

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-categories">{children}</ul>;
    });

    const SortableItem = sortableElement(({ category }) => {
      const { selectedCategory } = this.state;

      let v__icon = <Icon icon="folder-o" left />;
      if (parseInt(selectedCategory) === parseInt(category.id)) {
        v__icon = <Icon icon="folder-open-o" left />;
      }

      return (
        <li>
          <Grid type="full">
            <Row>
              <Cell sm={2} md={2}>
                <div className="course-categories-drag-handle-wrapper">
                  <DragHandle />
                </div>
              </Cell>
              <Cell sm={22} md={22}>
                <Box
                  size="small"
                  style={{ marginBottom: "10px" }}
                  onClick={() => {
                    if (this.handle__cancel() === true) {
                      this.setState({ selectedCategory: category.id });
                    }
                  }}
                >
                  <h4 style={{ marginBottom: "0" }}>
                    {v__icon} {category.name}
                  </h4>
                </Box>
              </Cell>
            </Row>
          </Grid>
        </li>
      );
    });

    return (
      <React.Fragment>
        <Box>
          <h3 style={{ marginBottom: "0" }}>
            {_c.translate("admin.courses.lessons.categories.title")}
          </h3>
        </Box>
        <SortableContainer
          onSortEnd={this.onCategoriesSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSortCategories")}
        >
          {course.categories.map((category, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                category={category}
              />
            );
          })}
        </SortableContainer>
        <div className="admin-courses-add-category">
          <Box
            size="small"
            onClick={() => {
              this.setState({ selectedEditCategory: cloneDeep(newCategory) });
            }}
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.courses.lessons.categories.add")}
          </Box>
        </div>
      </React.Fragment>
    );
  }

  view__showRightSide() {
    const { course, selectedCategory, newLesson } = this.state;
    const _c = this.props.context;

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-lessons-list">{children}</ul>;
    });

    const SortableItem = sortableElement(
      ({ lesson, lessonIndex, lessonBoxStyle, lessonStyle }) => {
        const _c = this.props.context;

        let v__publish = `${_c.translate(
          "admin.courses.lessons.lesson.publish.info.intro"
        )}`;
        if (lesson.publish_type === "instantly") {
          v__publish = `${v__publish} ${_c.translate(
            "admin.courses.lessons.lesson.publish.info.instantly"
          )}`;
        } else if (lesson.publish_type === "date") {
          v__publish = `${v__publish} ${lesson.publish_date_f}`;
        } else if (lesson.publish_type === "period") {
          v__publish = `${v__publish} ${
            lesson.publish_period_amount
          } ${_c.translate(
            `admin.courses.lessons.lesson.publish.info.period.units.${lesson.publish_period_unit}`
          )} ${_c.translate(
            "admin.courses.lessons.lesson.publish.info.period.outro"
          )}`;
        }

        return (
          <li style={lessonStyle}>
            <Grid type="full">
              <Row>
                <Cell sm={2} md={2}>
                  <div className="course-lessons-drag-handle-wrapper">
                    <DragHandle />
                  </div>
                </Cell>
                <Cell sm={22} md={22}>
                  <Box
                    size="small"
                    style={lessonBoxStyle}
                    onClick={() => {
                      this.selectLesson(lesson);

                      this.checkProgressInterval(lesson);
                    }}
                  >
                    <div className="admin-course-lessons-list-lesson-name">
                      {lessonIndex + 1}. {lesson.name}
                    </div>
                    <div className="admin-course-lessons-list-lesson-publish">
                      <Icon icon="unlock" left /> {v__publish}
                    </div>
                  </Box>
                </Cell>
              </Row>
            </Grid>
          </li>
        );
      }
    );

    return (
      <React.Fragment>
        <Box style={{ visibility: "hidden" }}>
          <h3 style={{ marginBottom: "0" }}>
            {_c.translate("admin.courses.lessons.categories.title")}
          </h3>
        </Box>
        {course.categories.map((category, index) => {
          let categoryBoxStyle = {
            marginBottom: "10px",
          };

          let lessonBoxStyle = {
            marginBottom: "10px",
          };

          let lessonStyle = {};

          if (parseInt(selectedCategory) !== parseInt(category.id)) {
            categoryBoxStyle.visibility = "hidden";
            lessonStyle.display = "none";
          }

          let v__icon = <Icon icon="folder-open-o" left />;

          return (
            <React.Fragment key={index}>
              <Box size="small" style={categoryBoxStyle}>
                <h4 style={{ marginBottom: "0" }}>
                  <div className="float-right">
                    <button
                      className="tiny primary hollow button admin-course-edit-category"
                      onClick={() => {
                        this.handle__deleteCategory(category);
                      }}
                    >
                      <Icon icon="trash" left />{" "}
                      {_c.translate("buttons.delete")}
                    </button>
                    <button
                      className="tiny primary hollow button admin-course-edit-category"
                      onClick={() => {
                        this.setState({
                          selectedEditCategory: cloneDeep(category),
                        });
                      }}
                    >
                      <Icon icon="cog" left /> {_c.translate("buttons.edit")}
                    </button>
                  </div>
                  {v__icon} {category.name}
                </h4>
              </Box>
              <SortableContainer
                onSortEnd={this.onLessonsSortEnd}
                useDragHandle
                helperContainer={document.getElementById("adminSortLessons")}
              >
                {category.lessons.map((lesson, lessonIndex) => {
                  return (
                    <SortableItem
                      key={`lesson-item-${lessonIndex}`}
                      index={lessonIndex}
                      lessonIndex={lessonIndex}
                      lesson={lesson}
                      lessonBoxStyle={lessonBoxStyle}
                      lessonStyle={lessonStyle}
                    />
                  );
                })}
                <li className="admin-courses-add-lesson" style={lessonStyle}>
                  <Box
                    size="small"
                    onClick={() => {
                      newLesson.category = category.id;
                      this.selectLesson(newLesson);
                    }}
                  >
                    <Icon icon="plus" left />{" "}
                    {_c.translate("admin.courses.lessons.lesson.add")}
                  </Box>
                </li>
              </SortableContainer>
            </React.Fragment>
          );
        })}
      </React.Fragment>
    );
  }

  view__showDownloads() {
    const {
      course,
      newDownload,
      selectedLesson,
      selectedCategory,
    } = this.state;
    const _c = this.props.context;

    if (!course) {
      return null;
    }

    let category = {};
    course.categories.map((cat) => {
      if (cat.id === selectedCategory) {
        category = cat;
      }
      return null;
    });

    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24}>
            <h3>
              {_c.translate("admin.courses.lessons.lesson.tabs.downloads")}
            </h3>
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24}>
            <AdminCourseDownloads
              course={course}
              downloads={course.downloads}
              newDownload={newDownload}
              lesson={selectedLesson}
              category={category}
              onUpdate={(course) => {
                this.setState({ course });
              }}
            />
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showTags() {
    const { selectedLesson } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>{_c.translate("admin.courses.lessons.lesson.tabs.tags")}</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <AdminTags
                tags={selectedLesson.tags || []}
                editMode={true}
                onUpdate={(tags) => {
                  this.handle__edit("tags", tags);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxImage() {
    const { selectedEditCategory } = this.state;
    const _c = this.props.context;

    let categoryImage = (
      <div className="admin-livecall-noimage wrapper">
        <div className="center-vertically">
          {_c.translate("admin.livecalls.image.noImage")}
        </div>
      </div>
    );
    if (selectedEditCategory.image) {
      categoryImage = (
        <img src={selectedEditCategory.image} alt="Vorschaubild" />
      );
    }

    let uploadButton = (
      <button className="button" onClick={this.showPopupImageUpload}>
        <Icon icon="upload" left />{" "}
        {_c.translate("admin.livecalls.image.uploadImage")}
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupImageDelete}>
        <Icon icon="trash" left />{" "}
        {_c.translate("admin.livecalls.image.deleteImage")}
      </button>
    );

    if (!selectedEditCategory.image) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Kategorie Bild</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-livecall-image">{categoryImage}</div>
              <div className="admin-livecall-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showBoxBanner() {
    const { selectedEditCategory } = this.state;
    const _c = this.props.context;

    let categoryImage = (
      <div className="admin-livecall-noimage wrapper">
        <div className="center-vertically">
          {_c.translate("admin.livecalls.image.noBanner")}
        </div>
      </div>
    );
    if (selectedEditCategory.banner) {
      categoryImage = <img src={selectedEditCategory.banner} alt="Banner" />;
    }

    let uploadButton = (
      <button className="button" onClick={this.showPopupBannerUpload}>
        <Icon icon="upload" left />{" "}
        {_c.translate("admin.livecalls.image.uploadImage")}
      </button>
    );

    let deleteButton = (
      <button className="button" onClick={this.showPopupBannerDelete}>
        <Icon icon="trash" left />{" "}
        {_c.translate("admin.livecalls.image.deleteImage")}
      </button>
    );

    if (!selectedEditCategory.banner) {
      deleteButton = null;
    }

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Kategorie Banner</h3>
            </Cell>
          </Row>

          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-livecall-image">{categoryImage}</div>
              <div className="admin-livecall-image-upload-buttons">
                <div className="small primary hollow no-gaps button-group">
                  {deleteButton}
                  {uploadButton}
                </div>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showPopupsCategory() {
    const { uploadImageStatus, cropUrl, crop, uploadType } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();

                  if (uploadType === "banner") {
                    this.showPopupBannerCrop();
                  } else if (uploadType === "image") {
                    this.showPopupImageCrop();
                  }
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h3>
                      {_c.translate("admin.livecalls.image.uploadPopup.title")}
                    </h3>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate(
                            "admin.livecalls.image.uploadPopup.text"
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>{_c.translate("admin.livecalls.image.cropPopup.title")}</h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate(
                      "admin.livecalls.image.cropPopup.buttons.save.label"
                    )}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
        <MMDialog
          show={uploadImageStatus === "delete"}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.livecalls.image.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("buttons.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("buttons.deletePermanently"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ uploadImageStatus: null });
          }}
          onButton_2={this.handle__deleteImage}
        />
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  handle__deleteImage = () => {
    const { uploadType, selectedEditCategory } = this.state;
    if (uploadType === "image") {
      selectedEditCategory.image = null;
    } else if (uploadType === "banner") {
      selectedEditCategory.banner = null;
    }
    this.setState({ selectedEditCategory, uploadImageStatus: null });

    /*const _c = this.props.context;

    axios
      .patch(_c.apiEndpoints.deleteAvatar, {}, { headers: _c.headers })
      .then(response => {
        if (response.data === "OK") {
          const { account } = this.state;
          account.avatar = null;
          this.setState({ account, showDialogDeleteAvatar: false });
        } else {
          console.log(response);
        }
      })
      .catch(error => {
        console.log("ERROR", error);
      });*/
  };

  showPopupImageUpload = () => {
    const { crop } = this.state;
    crop.aspect = 50 / 28;
    this.setState({ crop, uploadType: "image", uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadType: "image", uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadType: "image", uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupBannerUpload = () => {
    const { crop } = this.state;
    crop.aspect = null;
    this.setState({ crop, uploadType: "banner", uploadImageStatus: "upload" });
  };

  hidePopupBannerUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupBannerCrop = () => {
    this.setState({ uploadType: "banner", uploadImageStatus: "crop" });
  };

  hidePopupBannerCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupBannerDelete = () => {
    this.setState({ uploadType: "banner", uploadImageStatus: "delete" });
  };

  hidePopupBannerDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  uploadImage = () => {
    const {
      cropImage,
      crop,
      selectedEditCategory,
      requestedId,
      uploadType,
    } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    formData.append("file", cropImage);
    formData.append("name", cropImage.name);
    formData.append("crop", JSON.stringify(crop));

    const apiUrl = _c.apiUrl("admin.courseCategoryImageUpload", {
      id: requestedId,
    });

    console.log("type", uploadType);

    _c.setIsSaving(true, 4);

    this.hidePopupImageCrop();

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { path } = response.data;
        if (path) {
          if (uploadType === "banner") {
            selectedEditCategory.banner = path;
          } else if (uploadType === "image") {
            selectedEditCategory.image = path;
          }
          this.setState({ selectedEditCategory, changesWereMade: true });
          this.hidePopupImageCrop();
          _c.setIsSaving(false);
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  onCategoriesSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    const { course } = this.state;
    let { categories } = course;
    categories = arrayMove(categories, oldIndex, newIndex);

    const newOrder = [];
    categories.map((category) => {
      return newOrder.push(category.id);
    });

    course.categories = categories;

    this.setState({ course }, () => {
      const apiUrl = _c.apiUrl("admin.courseSortCategories", {
        courseId: course.id,
      });

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    course,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.courses.lessons.categories.messages.sorted.title"
                      ),
                      _c.translate(
                        "admin.courses.lessons.categories.messages.sorted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:course:categories:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:course:categories:sort");
          });
      }, 200);
    });
  };

  onLessonsSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    const { course, selectedCategory } = this.state;
    let { categories } = course;

    let category;
    categories.map((tmpCategory) => {
      if (parseInt(tmpCategory.id) === parseInt(selectedCategory)) {
        category = tmpCategory;
      }
      return null;
    });

    if (!category) {
      return;
    }

    let { lessons } = category;

    lessons = arrayMove(lessons, oldIndex, newIndex);

    category.lessons = lessons;

    const newOrder = [];
    categories.map((category) => {
      return category.lessons.map((lesson) => {
        return newOrder.push(lesson.id);
      });
    });

    course.categories = categories;

    this.setState({ course }, () => {
      const apiUrl = _c.apiUrl("admin.courseSortLessons", {
        courseId: course.id,
      });

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    course,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.courses.lessons.messages.sorted.title"
                      ),
                      _c.translate(
                        "admin.courses.lessons.messages.sorted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:course:categories:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:course:categories:sort");
          });
      }, 200);
    });
  };

  checkProgressInterval = (lesson) => {
    const _c = this.props.context;

    if (lesson._video && lesson._video.progress !== 100) {
      let { uploadProgressInterval } = this.state;
      clearInterval(uploadProgressInterval);
      this.setState({ uploadProgressInterval });

      uploadProgressInterval = setInterval(() => {
        let apiUrl = _c.apiUrl("admin.courseLessonVideo", {
          videoIdentifier: lesson._video.identifier,
        });

        axios
          .get(apiUrl, {
            headers: _c.getHeaders(),
          })
          .then((response) => {
            try {
              if (_c.isDebug()) {
                console.log("API RESPONSE", response.data);
              }

              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const { selectedLesson } = this.state;
                const { _video } = response.data.data;
                selectedLesson._video = _video;
                this.setState({ selectedLesson });

                if (_video.progress === 100) {
                  clearInterval(uploadProgressInterval);
                  this.setState({ uploadProgressInterval });
                }
              }
            } catch {}
          })
          .catch((error) => {});
      }, 5000);
      this.setState({ uploadProgressInterval });
    }
  };

  selectLesson(lesson) {
    this.setState(
      { selectedLesson: cloneDeep(lesson), changesWereMade: false },
      () => {
        this.initEditorState();
      }
    );
  }

  deselectLesson() {
    this.setState({ selectedLesson: null, changesWereMade: false }, () => {
      this.initEditorState();
    });
  }

  initEditorState = () => {
    const { selectedLesson } = this.state;

    let editorState = EditorState.createEmpty();
    if (selectedLesson && selectedLesson.description) {
      editorState = EditorState.createWithContent(
        convertFromRaw(selectedLesson.description)
      );
    }

    this.setState({ editorState });
  };

  handle__edit(property, value) {
    const { selectedLesson } = this.state;
    selectedLesson[property] = value;
    this.setState({ selectedLesson, changesWereMade: true });
  }

  handle__delete = () => {
    const { selectedLesson, course } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        _c.translate("admin.courses.lessons.lesson.delete.confirm")
      )
    ) {
      const apiUrl = _c.apiUrl("admin.courseLesson", {
        courseId: course.id,
        lessonId: selectedLesson.id,
      });

      axios
        .delete(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          try {
            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;
              this.setState({ course });

              _c.createNotifictation(
                _c.translate("admin.courses.lessons.messages.deleted.title"),
                _c.translate("admin.courses.lessons.messages.deleted.text"),
                "success"
              );
              this.setState({ selectedLesson: null });
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:lesson:delete:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:lesson:delete");
        });
    }
  };

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let confirmCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(
          _c.translate("admin.courses.lessons.lesson.cancel.confirm")
        )
      ) {
        confirmCancel = true;
      }
    } else {
      confirmCancel = true;
    }

    if (confirmCancel === true) {
      this.deselectLesson();
      this.setState({
        selectedLessonTab: "info",
      });
    }

    return confirmCancel;
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, selectedLesson } = this.state;

    if (!selectedLesson.name) {
      window.alert(_c.translate("admin.courses.lessons.lesson.errors.noName"));

      return;
    }

    if (selectedLesson.publish_type === "date") {
      if (!selectedLesson.publish_date) {
        window.alert(
          _c.translate("admin.courses.lessons.lesson.errors.publish.date")
        );

        return;
      } else {
        // Prepare Publish Date
        if (selectedLesson.publish_date) {
          selectedLesson.publish_date = _c.formatDatetimeForSaving(
            selectedLesson.publish_date
          );
        }
      }
    } else if (selectedLesson.publish_type === "period") {
      if (!selectedLesson.publish_period_amount) {
        window.alert(
          _c.translate(
            "admin.courses.lessons.lesson.errors.publish.periodAmount"
          )
        );

        return;
      }
    }

    const formData = new FormData();

    this.deselectLesson();
    this.setState({ selectedLesson: null }, () => {
      if (selectedLesson.file) {
        formData.append("file", selectedLesson.file);
        formData.append("name", selectedLesson.file.name);

        _c.setIsSaving(true, 30);
        _c.setSavingType("videoupload");
      } else {
        _c.setIsSaving(true, 2);
        _c.setSavingType();
      }
      delete selectedLesson.file;
      formData.append("lesson", JSON.stringify(selectedLesson));

      setTimeout(() => {
        const apiUrl = _c.apiUrl("admin.courseLesson", {
          courseId: course.id,
          lessonId: selectedLesson.id,
        });

        axios
          .post(apiUrl, formData, {
            headers: _c.headers,
          })
          .then((response) => {
            try {
              if (_c.isDebug()) {
                console.log("API RESPONSE", response.data);
              }

              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course, video, lesson } = apiResponseData;

                _c.setIsSaving(false);

                if (video) {
                  axios
                    .get(
                      _c.apiUrl("files.video", { type: "convert", id: video }),
                      {
                        headers: _c.getHeaders(),
                      }
                    )
                    .then((response) => {
                      try {
                        if (_c.isDebug()) {
                          console.log("API RESPONSE", response.data);
                        }
                      } catch {
                        //
                      }
                    })
                    .catch((error) => {
                      //
                    });
                }

                _c.setIsSaving(false);

                let selectedLesson = null;
                if (video && lesson) {
                  selectedLesson = lesson;
                  this.checkProgressInterval(lesson);
                } else {
                  this.setState({ selectedLessonTab: "info" });
                }

                this.selectLesson(selectedLesson);
                this.setState(
                  {
                    course,
                  },
                  () => {
                    if (this.props.onUpdate) {
                      this.props.onUpdate(course);
                    }
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.courses.lessons.messages.saved.title"
                      ),
                      _c.translate("admin.courses.lessons.messages.saved.text"),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:course:lesson:save:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:course:lesson:save");
          });
      }, 200);
    });
  };

  handle__deleteCategory = (category) => {
    const { course } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        _c.translate("admin.courses.lessons.categories.delete.confirm")
      )
    ) {
      const apiUrl = _c.apiUrl("admin.courseCategory", {
        courseId: course.id,
        categoryId: category.id,
      });

      axios
        .delete(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          try {
            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;
              this.setState({ course });

              _c.createNotifictation(
                _c.translate(
                  "admin.courses.lessons.categories.messages.deleted.title"
                ),
                _c.translate(
                  "admin.courses.lessons.categories.messages.deleted.text"
                ),
                "success"
              );
              this.setState({ selectedLesson: null });
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:lesson:delete:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:lesson:delete");
        });
    }
  };

  handle__cancelCategory = () => {
    this.setState({ selectedEditCategory: null });
  };

  handle__saveCategory = () => {
    const _c = this.props.context;
    const { course, selectedEditCategory } = this.state;

    if (!selectedEditCategory.name) {
      window.alert(
        _c.translate("admin.courses.lessons.categories.errors.noName")
      );
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.courseCategory", {
        courseId: course.id,
        categoryId: selectedEditCategory.id,
      });

      axios
        .post(
          apiUrl,
          {
            category: selectedEditCategory,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedEditCategory: null,
                  selectedLessonTab: "info",
                  course,
                },
                () => {
                  if (this.props.onUpdate) {
                    this.props.onUpdate(course);
                  }
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate(
                      "admin.courses.lessons.categories.messages.saved.title"
                    ),
                    _c.translate(
                      "admin.courses.lessons.categories.messages.saved.text"
                    ),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:category:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:category:save");
        });
    }, 200);
  };
}

export default withContext(AdminCourseLessons);
