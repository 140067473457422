import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import { Icon } from "../../_foundation/_buttons";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";

class AdminSupport extends Component {
  state = {
    digistoreIpnUrl: "",
    elopageIpnUrl: "",
    answers: null,
    tutorials: null,
    supportDocument: null,
    supportMail: "support@membermate.de",
    supportPhone: "0175 - 75 60 706",
    tabs: null,
    selectedTab: "tutorials",
    selectedTutorial: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    const tabs = {
      /*answers: {
        name: _c.translate("support.answers.tab"),
        icon: "question-circle",
      },*/
      tutorials: {
        name: _c.translate("support.tutorials.tab"),
        icon: "file-text-o",
      },
      membermate: {
        name: _c.translate("support.membermate.tab"),
        icon: "whatsapp",
      },
    };
    this.setState({ tabs });
    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <div className="admin-support">
        <AdminLayout
          nav="support"
          breadcrumbs={["admin_dashboard", "admin_support"]}
        >
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24} md={24}>
                <h1>{_c.translate("pageTitles.admin.support")}</h1>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24} md={24}>
                {this.view__showTabs()}
                {this.view__showContent()}
                {this.view__showPopups()}
              </Cell>
            </Row>
          </Grid>
        </AdminLayout>
      </div>
    );
  }

  view__showPopups() {
    const { selectedTutorial, digistoreIpnUrl, elopageIpnUrl } = this.state;

    if (selectedTutorial) {
      selectedTutorial.text_html = selectedTutorial.text_html
        .replace("%digistore_url%", digistoreIpnUrl)
        .replace("%elopage_url%", elopageIpnUrl);

      return (
        <React.Fragment>
          <MMPopup
            show={true}
            size="medium"
            handleClose={this.handle__closeTutorial}
          >
            <div className="support-tutorial-content">
              <h3>{selectedTutorial.title}</h3>
              <div
                dangerouslySetInnerHTML={{ __html: selectedTutorial.text_html }}
              />
            </div>
            {this.view__showTutorialCloseButton()}
          </MMPopup>
        </React.Fragment>
      );
    }
  }

  view__showTutorialCloseButton() {
    const _c = this.props.context;

    return (
      <div className="text-center">
        <button
          className="tiny primary hollow button"
          onClick={this.handle__closeTutorial}
        >
          <Icon icon="times" left /> {_c.translate("buttons.close")}
        </button>
      </div>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    if (!tabs) {
      return;
    }

    return (
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { selectedTab } = this.state;

    if (selectedTab === "answers") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>
                {this.view__showTabHeader()}
                {this.view__showAnswers()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "tutorials") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>
                {this.view__showTabHeader()}
                {this.view__showTutorials()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    } else if (selectedTab === "membermate") {
      return (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24}>
                {this.view__showTabHeader()}
                {this.view__showMembermateSupport()}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  }

  view__showTabHeader() {
    const { selectedTab } = this.state;
    const _c = this.props.context;

    return (
      <div>
        <h3>{_c.translate(`support.${selectedTab}.title`)}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: _c.translate(`support.${selectedTab}.text`),
          }}
        />
      </div>
    );
  }

  view__showAnswers() {
    return <div>Folgt.</div>;
  }

  view__showTutorials() {
    const { tutorials } = this.state;
    const _c = this.props.context;

    if (!tutorials) {
      return (
        <div className="text-center">
          {_c.translate("support.tutorials.nodocuments")}
        </div>
      );
    }

    return (
      <ul className="support-tutorials">
        {tutorials.map((tutorial, index) => {
          return (
            <li key={index}>
              <button
                onClick={() => {
                  this.handle__selectTutorial(tutorial);
                }}
              >
                {index + 1}. {tutorial.title}
              </button>
            </li>
          );
        })}
      </ul>
    );
  }

  view__showMembermateSupport() {
    const { supportDocument, supportMail, supportPhone } = this.state;

    if (!supportDocument) {
      return;
    }

    let content = supportDocument.text_html;
    content = content
      .replace("%email%", supportMail)
      .replace("%email%", supportMail)
      .replace("%phone%", supportPhone);

    return (
      <div>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </div>
    );
  }

  handle__selectTutorial = (tutorial) => {
    this.setState({ selectedTutorial: tutorial });
  };

  handle__closeTutorial = () => {
    this.setState({ selectedTutorial: null });
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.support");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { support, digistoreIpnUrl, elopageIpnUrl } = apiResponseData;

            let tutorials = [];
            let supportDocument = null;
            const { documents } = support;

            documents.map((document) => {
              if (!document.type) {
                document.type = "unknown";
              }

              if (document.type.substring(0, 9) === "tutorial_") {
                tutorials.push(document);
              } else if (document.type === "support") {
                supportDocument = document;
              }
              return null;
            });

            this.setState({
              tutorials,
              digistoreIpnUrl,
              elopageIpnUrl,
              supportDocument,
            });
          }

          _c.setPageTitle("pageTitles.admin.support", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:support:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:support:init");
      });
  }
}

export default withContext(AdminSupport);
