import React, { Component } from "react";
import withContext from "../../context/contextHOC";

class HomeVideo extends Component {
  state = {
    video: this.props.video,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.video !== prevProps.video) {
      this.setState({ video: this.props.video });
    }
  }

  render() {
    const { video } = this.state;

    if (!video) {
      return null;
    }

    return <React.Fragment>{this.view__showVideo()}</React.Fragment>;
  }

  view__showVideo() {
    const { video } = this.state;
    const _c = this.props.context;

    let v__video;

    if (video.type === "vimeo") {
      let embedUrl = `https://player.vimeo.com/video/${video.content}`;
      v__video = (
        <div className="responsive-embed widescreen">
          <iframe
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="vimeoPlayer"
          ></iframe>
        </div>
      );
    } else if (video.type === "youtube") {
      let embedUrl = `https://www.youtube.com/embed/${video.content}`;
      v__video = (
        <div className="responsive-embed widescreen">
          <div id="youtubePlayer">
            <iframe
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YoutubePlayer"
            ></iframe>
          </div>
        </div>
      );
    } else if (video.type === "embed") {
      v__video = (
        <div className="responsive-embed widescreen">
          <div dangerouslySetInnerHTML={{ __html: video.content }} />
        </div>
      );
    }

    return (
      <div className="category-image">
        <div className="home-latestvideo">
          <div className="home-latestvideo-video">
            {v__video}
            <a
              href={_c
                .config("urls.courseLesson")
                .replace(":courseSlug", video.courseSlug)
                .replace(":lessonSlug", video.slug)}
              className="home-latestvideo-overlay"
            >
              <div className="wrapper">
                <div className="center-vertically">
                  <div className="home-latestvideo-overlay-content"></div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>
    );
  }
}

export default withContext(HomeVideo);
