import React, { Component } from "react";
import { Link } from "react-router-dom";
import withContext from "../../context/contextHOC";
import { Icon } from "../_foundation/_buttons";
import Countdown from "../_reusables/Countdown";
import { DateTime } from "luxon";
import { Cell, Grid, Row } from "../_foundation/_grid";

class HomeCountdown extends Component {
  state = {
    livecall: this.props.livecall,
    showZoomLink: true,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.livecall !== prevProps.livecall) {
      this.setState({ livecall: this.props.livecall });
    }
  }

  render() {
    const { livecall, showZoomLink } = this.state;
    const _c = this.props.context;

    if (!livecall) {
      return null;
    }

    let v__content;

    let v__zoomLink;

    if (showZoomLink === true) {
      let buttonText = "Jetzt per Zoom dabei sein";
      if (livecall.button_text) {
        buttonText = livecall.button_text;
      }

      let extraText = null;
      if (livecall.extra_text) {
        extraText = (
          <div className="home-countdown-extratext">
            <div dangerouslySetInnerHTML={{ __html: livecall.extra_text_f }} />
          </div>
        );
      }

      v__zoomLink = (
        <div className="home-countdown-button">
          <a
            href={livecall.external_id}
            target="_blank"
            rel="noopener noreferrer"
            className="large primary hollow button"
            style={{ fontWeight: 600 }}
          >
            {buttonText} <Icon icon="angle-double-right" right />
          </a>
          {extraText}
        </div>
      );
    }

    if (livecall.status === "ONAIR") {
      v__content = (
        <div className="home-countdown-content">
          <span className="home-countdown-started">
            <Icon icon="youtube-play" left /> LOA IST JETZT LIVE!
          </span>
          {v__zoomLink}
        </div>
      );

      v__content = (
        <Link to={_c.config("urls.live.next")} className="home-countdown">
          {v__content}
        </Link>
      );
    } else if (livecall.status === "UPCOMING") {
      v__content = (
        <div className="home-countdown-content">
          <h2>Next Live Session:</h2>
          <div>
            <Grid>
              <Row>
                <Cell sm={24} md={8} mdo={8}>
                  <Countdown
                    date={DateTime.fromISO(livecall.countdown_date, {
                      setZone: true,
                    })}
                    preAction={10 * 60}
                    onPreAction={() => {
                      if (showZoomLink !== true) {
                        this.setState({ showZoomLink: true });
                      }
                    }}
                    onZero={() => {
                      livecall.status = "ONAIR";
                      this.setState({ livecall });
                    }}
                    type="home-countdown"
                  />
                  {v__zoomLink}
                </Cell>
              </Row>
            </Grid>
          </div>
        </div>
      );
    }

    return <React.Fragment>{v__content}</React.Fragment>;
  }
}

export default withContext(HomeCountdown);
