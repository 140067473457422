import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import MMTabs from "../../../../mmcomponents/mmtabs/MMTabs";
import ElementSettings from "./ElementSettings";
import ElementDesign from "./ElementDesign";
import Loading from "../../../../_reusables/Loading";
import Box from "../../../../layout/Box";
import AdminDataField from "../../../AdminDataField";
import { PtContext } from "../../../../../context/ptProvider";

const tabs = {
  content: {
    name: "Inhalt",
    icon: "pencil-square-o",
  },
  design: {
    name: "Design",
    icon: "paint-brush",
  },
  settings: {
    name: "Einstellungen",
    icon: "cog",
  },
};

function PopupLesson(props) {
  const _c = useContext(PtContext);

  const [element, setElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState("content");

  useEffect(() => {
    setElement(cloneDeep(props.element));
  }, [props.element]);

  useEffect(() => {}, [element]);

  const onEdit = (property, value) => {
    if (props.actions.onEdit) props.actions.onEdit(property, value);
  };
  const actions = {
    onEdit,
  };

  if (!element)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="element-popup-text">
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
        />
      </div>
      {selectedTab === "content" && (
        <TabContent element={element} extra={props.extra} _c={_c} actions={actions} />
      )}
      {selectedTab === "design" && (
        <ElementDesign item={element} itemType="element" actions={actions} />
      )}
      {selectedTab === "settings" && <ElementSettings item={element} actions={actions} />}
    </div>
  );
}

const TabContent = ({ element, extra, _c, actions }) => {
  const selectValuesCourses = {
    none: "--- Kein Kurs ausgewählt ---",
  };
  extra.courses.map((course) => {
    selectValuesCourses[`course_${course.id}`] = course.name;
    return null;
  });

  const selectValuesLessons = {
    none: "--- Keine Lektion ausgewählt",
  };

  if (element.options.course && element.options.course !== "none") {
    extra.courses.map((course) => {
      if (parseInt(course.id) === parseInt(element.options.course)) {
        course.categories.map((category) => {
          category.lessons.map((lesson) => {
            selectValuesLessons[`lesson_${lesson.id}`] = `${category.name}  ==>  ${lesson.name}`;
            return null;
          });
          return null;
        });
      }
      return null;
    });
  }

  const selectValuesViews = _c.config("pagebuilder.options.lesson.views");

  const selectValuesIfNotPurchased = {
    show: "Lektion immer anzeigen",
    thumbnail: "Stattdessen Thumbnail der Lektion mit Kauflink anzeigen",
  };

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <Box size="small">
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={`course_${element.options.course}`}
                    editValue={`course_${element.options.course}`}
                    editType="select"
                    selectValues={selectValuesCourses}
                    label={"Kurs"}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) =>
                      actions.onEdit(`options.course`, newValue.replace("course_", ""))
                    }
                  />
                </Cell>
              </Row>
              {element.options.course && element.options.course !== "none" && (
                <Row margin="xy">
                  <Cell sm={24}>
                    <AdminDataField
                      value={`lesson_${element.options.lesson}`}
                      editValue={`lesson_${element.options.lesson}`}
                      editType="select"
                      selectValues={selectValuesLessons}
                      label={"Angezeigte Lektion"}
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) =>
                        actions.onEdit(`options.lesson`, newValue.replace("lesson_", ""))
                      }
                    />
                  </Cell>
                </Row>
              )}
              {element.options.lesson && element.options.lesson !== "none" && (
                <>
                  <Row margin="xy">
                    <Cell sm={24}>
                      <AdminDataField
                        value={element.options.view}
                        editValue={element.options.view}
                        editType="select"
                        selectValues={selectValuesViews}
                        label={"Art der Darstellung"}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) => actions.onEdit(`options.view`, newValue)}
                      />
                    </Cell>
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24}>
                      <AdminDataField
                        value={element.ifnotpurchased || "show"}
                        editValue={element.ifnotpurchased || "show"}
                        editType="select"
                        selectValues={selectValuesIfNotPurchased}
                        label={"Anzeigen, wenn noch nicht freigeschaltet"}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) => actions.onEdit(`ifnotpurchased`, newValue)}
                      />
                    </Cell>
                  </Row>
                </>
              )}
            </Grid>
          </Box>
        </Cell>
      </Row>
    </Grid>
  );
};

export default PopupLesson;
