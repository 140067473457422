import React from "react";
import PagebuilderHelper from "../../PagebuilderHelper";

function PreviewHeading(props) {
  const element = props.element;

  return (
    <div
      className={`pagebuilder-element-preview pagebuilder-element-preview-heading`}
    >
      {PagebuilderHelper.getPreview(element)}
    </div>
  );
}

export default PreviewHeading;
