import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../../_foundation/foundation";
import Box from "../../layout/Box";
import Loading from "../../_reusables/Loading";
import SignupForm from "./views/SignupForm";
import axios from "axios";

import qs from "query-string";
import { isUndefined } from "util";
import PasswordForm from "./views/PasswordForm";

import EmailValidator from "email-validator";

class Signup extends Component {
  state = {
    _updatableProps: ["view", "errors", "messages", "input"],
    view: this.props.view || null,
    status: null,
    input: {
      firstname: "",
      lastname: "",
      email: "",
      password1: "",
      password2: "",
    },
    highlighted: {
      firstname: false,
      lastname: false,
      email: false,
    },
    errors: {
      empty: false,
      not_valid: false,
      code_notfound: false,
      code_expired: false,
      password_empty: false,
      password_not_equal: false,
      password_error: false,
    },
    messages: {
      success: false,
      password_success: false,
    },
    code: null,
    userName: "",
  };

  componentDidMount() {
    const _c = this.props.context;

    const queryParams = qs.parse(this.props.location.search);

    if (!isUndefined(queryParams.code)) {
      this.setState({ code: queryParams.code }, () => {
        _c.init(true, _c.translate("pageTitles.signup"), () => {
          this.handle__checkCode();
          _c.initFinished();
        });
      });
    } else {
      _c.init(true, _c.translate("pageTitles.signup"), () => {
        if (_c.setting("signup_enabled") !== true) {
          window.location.href = _c.config("urls.auth.login");
        } else {
          _c.initFinished();
        }
      });
    }

    this.init();
  }

  render() {
    const { status, view, input, highlighted } = this.state;
    const _c = this.props.context;

    let v__logo;
    const logoLogin = _c.setting("logo_login");

    if (logoLogin) {
      v__logo = <img src={logoLogin} alt="Logo" />;
    }

    let mainContentStyles = {};

    let backgroundUrl = _c.setting(`design_bg_signup`);
    if (backgroundUrl) {
      mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
    }

    return (
      <div className="page page-signup">
        {_c.loadStylesheets()}
        <main className="content" style={mainContentStyles}>
          <Grid>
            <Row>
              <Cell sm={24} md={12} mdo={6}>
                <Box>
                  <div className="login-logo">
                    <Link to="/">{v__logo}</Link>
                  </div>

                  {this.view__showTitle()}
                  {this.view__showIntro()}

                  {this.view__showMessages()}
                  {this.view__showErrors()}

                  <Loading show={status === "LOADING"} />

                  <SignupForm
                    show={
                      view === "signup" &&
                      status !== "LOADING" &&
                      status !== "FINISHED"
                        ? true
                        : false
                    }
                    status={status}
                    input={input}
                    highlighted={highlighted}
                    onUpdateInputChange={this.onUpdateInputChange}
                    onUpdateStatus={this.props.onUpdateStatus}
                    onUpdateView={this.props.onUpdateView}
                    onAttemptSignup={this.attemptSignup}
                  />

                  <PasswordForm
                    show={
                      view === "password" &&
                      status !== "LOADING" &&
                      status !== "FINISHED"
                        ? true
                        : false
                    }
                    status={status}
                    input={input}
                    onUpdateInputChange={this.onUpdateInputChange}
                    onUpdateStatus={this.props.onUpdateStatus}
                    onUpdateView={this.props.onUpdateView}
                    onAttemptPassword={this.attemptPassword}
                  />
                </Box>
              </Cell>
            </Row>
          </Grid>
        </main>
      </div>
    );
  }

  view__showTitle() {
    const { view } = this.state;
    const _c = this.props.context;

    let v__title = _c.translate("auth.signup.views.signup.title");

    if (view === "code///INPUT") {
      v__title = _c.translate("auth.signup.views.code.title");
    } else if (view === "password") {
      v__title = _c.translate("auth.signup.views.password.title");
    } else if (view === "signup") {
      v__title = _c.translate("auth.signup.views.signup.title");
    }

    return (
      <div className="login-title">
        <h1>{v__title}</h1>
      </div>
    );
  }

  view__showIntro() {
    const { view, userName } = this.state;
    const _c = this.props.context;

    let v__intro = "";

    if (view === "code") {
      v__intro = _c
        .translate("auth.signup.views.code.intro")
        .replace("{loginUrl}", _c.config("urls.auth.login"));
    } else if (view === "password") {
      v__intro = _c
        .translate("auth.signup.views.password.intro")
        .replace("{name}", userName);
    } else if (view === "signup") {
      v__intro = _c
        .translate("auth.signup.views.signup.intro")
        .replace("{loginUrl}", _c.config("urls.auth.login"));
    }

    return (
      <div
        className="login-intro"
        dangerouslySetInnerHTML={{ __html: v__intro }}
      />
    );
  }

  view__showMessages() {
    let { messages } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(messages).map((messageKey) => {
      if (messages[messageKey] === true) {
        let v__message = (
          <div className="login-message success callout" key={messageKey}>
            <div>
              <strong>
                {_c.translate(`auth.signup.messages.${messageKey}.title`)}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c
                  .translate(`auth.signup.messages.${messageKey}.text`)
                  .replace(
                    "{loginButton}",
                    `<p class="text-center"><a href="${_c.config(
                      "urls.auth.login"
                    )}" class="primary hollow button">${_c.translate(
                      "auth.signup.views.password.loginButton"
                    )} <i class="fa fa-angle-double-right"/></a></p>`
                  ),
              }}
            />
          </div>
        );

        if (messageKey === "success" || messageKey === "password_success") {
          v__output.push(v__message);
        }
      }
      return null;
    });

    return <div className="login-messages">{v__output}</div>;
  }

  view__showErrors() {
    let { errors } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(errors).map((errorKey) => {
      if (errors[errorKey] === true) {
        let v__error = (
          <div className="login-error alert callout" key={errorKey}>
            <div>
              <strong>
                {_c.translate(`auth.signup.errors.${errorKey}.title`)}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate(`auth.signup.errors.${errorKey}.text`),
              }}
            />
          </div>
        );

        if (
          errorKey === "notfound" ||
          errorKey === "invalid" ||
          errorKey === "empty" ||
          errorKey === "not_valid" ||
          errorKey === "forgottenempty" ||
          errorKey === "denied" ||
          errorKey === "code_notfound" ||
          errorKey === "code_expired" ||
          errorKey === "password_empty" ||
          errorKey === "password_not_equal" ||
          errorKey === "password_error"
        ) {
          v__output.push(v__error);
        }
      }
      return null;
    });

    return <div className="login-errors">{v__output}</div>;
  }

  onUpdateInputChange = (property, newValue) => {
    const { input } = this.state;

    input[property] = newValue;
    this.setState({ input });
  };

  onUpdateStatus = (newStatus) => {
    this.setState({ status: newStatus });
  };

  onUpdateView = (newView) => {
    this.setState({ view: newView });
  };

  setHighlighted = (key, value) => {
    const { highlighted } = this.state;
    highlighted[key] = value;
    this.setState({ highlighted });
  };

  setError = (errorKey, value) => {
    const { errors } = this.state;
    errors[errorKey] = value;
    this.setState({ errors });
  };

  setMessage = (messageKey, value) => {
    const { messages } = this.state;
    messages[messageKey] = value;
    this.setState({ messages });
  };

  resetErrors = () => {
    const { errors, messages, highlighted } = this.state;
    Object.keys(errors).map((errorKey) => {
      errors[errorKey] = false;
      return null;
    });

    Object.keys(messages).map((messageKey) => {
      messages[messageKey] = false;
      return null;
    });

    Object.keys(highlighted).map((hightlightKey) => {
      highlighted[hightlightKey] = false;
      return null;
    });
  };

  attemptPassword = () => {
    this.setState({ status: "LOADING" });
    this.resetErrors();

    const { input, code } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("signup.password");

    if (!input.password1 || !input.password2) {
      this.setError("password_empty", true);
      this.setState({ status: null });
      return null;
    }

    if (input.password1 !== input.password2) {
      this.setError("password_not_equal", true);
      this.setState({ status: null });
      return null;
    }

    axios
      .post(apiUrl, {
        code,
        password: input.password1,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;

        if (status === "ERROR") {
          this.setError("password_error", true);
          return;
        } else if (status === "SUCCESS") {
          this.setMessage("password_success", true);
          this.setState({ status: "FINISHED" });
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  };

  attemptSignup = () => {
    this.setState({ status: "LOADING" });
    this.resetErrors();

    const { input } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("signup.signup");

    if (
      !input.email ||
      (_c.setting("signup_firstname") === true && !input.firstname) ||
      (_c.setting("signup_lastname") === true && !input.lastname)
    ) {
      if (!input.firstname) {
        this.setHighlighted("firstname", true);
      }
      if (!input.lastname) {
        this.setHighlighted("lastname", true);
      }
      if (!input.email) {
        this.setHighlighted("email", true);
      }
      this.setError("empty", true);
      this.setState({ status: null });
      return null;
    }

    if (input.email && !EmailValidator.validate(input.email)) {
      this.setHighlighted("email", true);
      this.setError("not_valid", true);
      this.setState({ status: null });
      return null;
    }

    axios
      .post(apiUrl, {
        email: input.email,
        firstname: input.firstname,
        lastname: input.lastname,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;

        if (status === "EXISTS") {
          let url = `${_c.config("urls.auth.login")}?status=exists&email=${
            input.email
          }`;
          window.location.href = url;
          return;
        } else if (status === "SUCCESS") {
          this.setMessage("success", true);
          this.setState({ status: "FINISHED" });
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  };

  handle__checkCode = () => {
    const { code } = this.state;
    this.setState({ status: "LOADING" });
    this.resetErrors();

    const _c = this.props.context;

    let apiUrl = _c.apiUrl("signup.code");

    if (!code) {
      return;
    }

    axios
      .post(apiUrl, {
        code: code,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;
        const { user } = response.data.data;

        if (status === "NOTFOUND") {
          this.setError("code_notfound", true);
          this.setState({ status: null });
        } else if (status === "EXPIRED") {
          this.setError("code_expired", true);
          this.setState({ status: null });
        } else if (status === "SUCCESS") {
          this.setState({
            status: null,
            view: "password",
            userName: user.firstname,
          });
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  };

  init() {
    const _c = this.props.context;
    _c.initFinished();
  }
}

export default withContext(Signup);
