import React from "react";
import MMPopup from "../../../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../../../_foundation/_buttons";

function DeletePopup({ item, itemType, actions }) {
  let title, text;
  if (itemType === "area") {
    title = "Bereich löschen";
    text = `Möchtest Du den Bereich [${item.name}] wirklich löschen? Diese Aktion kann nach dem Speichern der Seite nicht mehr rückgängig gemacht werden.`;
  }
  if (itemType === "row") {
    title = "Zeile löschen";
    text = `Möchtest Du die Zeile [${item.name}] wirklich löschen? Diese Aktion kann nach dem Speichern der Seite nicht mehr rückgängig gemacht werden.`;
  }
  if (itemType === "element") {
    title = "Element löschen";
    text = `Möchtest Du das Element [${item.name}] wirklich löschen? Diese Aktion kann nach dem Speichern der Seite nicht mehr rückgängig gemacht werden.`;
  }

  return (
    <MMPopup size="small" show={true} handleClose={actions.onCancel}>
      <h3>{title}</h3>
      <p>{text}</p>
      <div className="popup-buttons">
        <button
          onClick={actions.onCancel}
          className="small primary hollow button"
        >
          <Icon icon="times" left /> Abbrechen
        </button>
        <button onClick={actions.onDelete} className="small primary button">
          <Icon icon="trash" left /> Ja, unwiderruflich löschen
        </button>
      </div>
    </MMPopup>
  );
}

export default DeletePopup;
