import React, { Component } from "react";
import { Grid, Row, Cell } from "../../../../_foundation/foundation";

class Layout1 extends Component {
  state = {};

  render() {
    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={18}>
            {this.props.children}
          </Cell>
          <Cell sm={24} md={6}>
            <p>Kram an der Seite</p>
          </Cell>
        </Row>
      </Grid>
    );
  }
}

export default Layout1;
