import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import DefaultLayout from "../../layout/DefaultLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Icon } from "../../_foundation/_buttons";

import MMPopup from "../../mmcomponents/mmpopup/MMPopup";

import "react-image-lightbox/style.css";
import VisionboardGrid from "./VisionboardGrid";

class Visionboard extends Component {
  state = {
    breadcrumbs: ["home", "visionboard"],
    visionboard: null,
    newImage: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("visionboard");

    this.init();
  }

  render() {
    const { breadcrumbs, visionboard, newImage } = this.state;
    const _c = this.props.context;

    if (!visionboard || !visionboard.images) {
      return null;
    }

    return (
      <React.Fragment>
        <DefaultLayout
          breadcrumbs={breadcrumbs}
          page="visionboard"
          help="visionboard"
        >
          <h1>{_c.translate("visionboard.title")}</h1>
          <p
            className="lead"
            dangerouslySetInnerHTML={{
              __html: _c.translate("visionboard.text"),
            }}
          />

          {this.view__showSelectLayout()}
          <VisionboardGrid
            type="user"
            visionboard={visionboard}
            newImage={newImage}
            onUpdate={(visionboard) => {
              this.setState({ visionboard });
            }}
          />
        </DefaultLayout>
      </React.Fragment>
    );
  }

  view__showSelectLayout() {
    const { showLayoutSelect, layoutSelectLoading } = this.state;
    const _c = this.props.context;

    const userSettingVisionboardLayout = _c.userSetting(
      "visionboard_layout",
      "layout1"
    );

    const layouts = _c.config(`visionboard.layouts`);

    let v__popupContent = (
      <div className="visionboard-select-layout-select">
        <Grid type="full">
          <Row margin="y">
            {Object.keys(layouts).map((layoutKey, index) => {
              let layoutClasses = "visionboard-select-layout-select-layout";
              let v__icon = (
                <div className="visionboard-select-layout-select-layout-icon">
                  <Icon icon="check" />
                </div>
              );

              if (layoutKey === userSettingVisionboardLayout) {
                layoutClasses = `selected ${layoutClasses}`;
              }

              return (
                <Cell sm={12} md={6} key={index}>
                  <button
                    className={layoutClasses}
                    onClick={() => {
                      this.setState({ layoutSelectLoading: true }, () => {
                        _c.setUserSetting(
                          "visionboard_layout",
                          layoutKey,
                          () => {
                            this.setState({ layoutSelectLoading: false });
                          }
                        );
                      });
                    }}
                  >
                    <img src={layouts[layoutKey].image} alt="" />
                    {v__icon}
                  </button>
                </Cell>
              );
            })}
          </Row>
        </Grid>
        <div>&nbsp;</div>
      </div>
    );

    if (layoutSelectLoading === true) {
      v__popupContent = (
        <div className="visionboard-select-layout-loading">
          <Icon icon="circle-o-notch fa-spin" />
        </div>
      );
    }

    return (
      <React.Fragment>
        <div className="visionboard-select-layout">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24} className="text-right">
                <button
                  className="small primary hollow button"
                  onClick={() => {
                    this.setState({ showLayoutSelect: true });
                  }}
                >
                  <Icon icon="th" left />{" "}
                  {_c.translate("visionboard.layouts.button.label")}
                </button>
              </Cell>
            </Row>
          </Grid>
        </div>
        <MMPopup
          size="small"
          show={showLayoutSelect === true}
          handleClose={() => {
            this.setState({ showLayoutSelect: false });
          }}
        >
          <h3>{_c.translate("visionboard.layouts.popup.title")}</h3>
          <p>{_c.translate("visionboard.layouts.popup.text")}</p>
          {v__popupContent}
        </MMPopup>
      </React.Fragment>
    );
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("visionboard.visionboard");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, data } = response.data;
          _c.handleApiResponse(response.data, false);

          if (status === "SUCCESS") {
            const { visionboard, newImage } = data;

            visionboard.images.push("new");

            newImage.id = "new";

            this.setState({ visionboard, newImage });
            _c.setPageTitle("pageTitles.visionboard", true);
            _c.hideLoadingScreen();
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "visionboard:init:response"
          );
        }
        _c.setPageTitle("Visionboard");
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "visionboard:init");
        }
      });
  }
}

export default withContext(Visionboard);
