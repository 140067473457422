import React, { useState, useEffect } from "react";
import PreviewButton from "../elements/previews/PreviewButton";
import PreviewCountdown from "../elements/previews/PreviewCountdown";
import PreviewHeading from "../elements/previews/PreviewHeading";
import PreviewImage from "../elements/previews/PreviewImage";
import PreviewLesson from "../elements/previews/PreviewLesson";
import PreviewLessonGrid from "../elements/previews/PreviewLessonGrid";
import PreviewText from "../elements/previews/PreviewText";
import PreviewVideo from "../elements/previews/PreviewVideo";
import PageItemName from "./PageItemName";

function PageElement(props) {
  const [element, setElement] = useState(props.element);

  useEffect(() => {
    setElement(props.element);
  }, [props.element]);

  return (
    <div className="pagebuilder-item pagebuilder-element">
      <PageItemName
        type="element"
        item={element}
        onEditButton={() => props.actions.onSelectElement(element)}
        onDuplicateButton={() =>
          props.actions.onDuplicateItem("element", element)
        }
        onDeleteButton={() => props.actions.onDelete("element", element)}
        onMoveButton={(direction) =>
          props.actions.onMoveItem(element.identifier, direction)
        }
      />
      <div className="element-content">
        {/*[ELEMENT {element.identifier}] [{element.position}]*/}
        <button
          className="pagebuilder-element-previewbutton"
          onClick={() => props.actions.onSelectElement(element)}
        >
          <Preview element={element} />
        </button>
      </div>
    </div>
  );
}

const Preview = ({ element }) => {
  if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.type))
    return <PreviewHeading element={element} />;
  else if (element.type === "text") return <PreviewText element={element} />;
  else if (element.type === "image") return <PreviewImage element={element} />;
  else if (["vimeo", "youtube"].includes(element.type))
    return <PreviewVideo element={element} />;
  else if (element.type === "button")
    return <PreviewButton element={element} />;
  else if (element.type === "lesson")
    return <PreviewLesson element={element} />;
  else if (element.type === "lessongrid")
    return <PreviewLessonGrid element={element} />;
  else if (element.type === "countdown")
    return <PreviewCountdown element={element} />;

  return null;
};

export default PageElement;
