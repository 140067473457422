import { cloneDeep, update } from "lodash";
import React, { useEffect, useState } from "react";
import ConfigHelper from "../../../../../helpers/ConfigHelper";
import MMPopup from "../../../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../../../_foundation/_buttons";
import PopupHeading from "./PopupHeading";
import PopupImage from "./PopupImage";
import PopupText from "./PopupText";
import PopupVideo from "./PopupVideo";
import PopupButton from "./PopupButton";
import PopupLesson from "./PopupLesson";
import PopupLessonGrid from "./PopupLessonGrid";
import PopupCountdown from "./PopupCountdown";

function ElementPopup(props) {
  const [element, setElement] = useState(cloneDeep(props.element));
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    setElement(cloneDeep(props.element));
  }, [props.element]);

  const onEdit = (property, value) => {
    const updatedElement = cloneDeep(element);

    if (property.substring(0, 8) === "options.") {
      let { options } = updatedElement;
      if (!options) options = {};
      options[property.replace("options.", "")] = value;
      updatedElement.options = options;
    } else {
      console.log("test", property, value);
      updatedElement[property] = value;
    }

    setUnsavedChanges(true);
    //console.log("ElementPopup Updated", updatedElement);
    setElement(updatedElement);
  };

  const onEditHtml = (value, html) => {
    const updatedElement = cloneDeep(element);
    updatedElement.content = value;
    updatedElement.content_html = html;
    setUnsavedChanges(true);
    //console.log("ElementPopup Content/HTML Updated", updatedElement);
    setElement(updatedElement);
  };

  const onUpdate = () => {
    console.log("update element", element);
    if (props.actions.onUpdateElement) props.actions.onUpdateElement(element);
  };

  const onCancel = () => {
    if (
      !unsavedChanges ||
      (unsavedChanges &&
        window.confirm(
          "Möchtest Du wirklich abbrechen? Änderungen gehen unwiderruflich verloren."
        ) &&
        props.onCancel)
    )
      props.onCancel();
  };

  const actions = {
    onUpdate,
    onEdit,
    onEditHtml,
  };

  const elementTypeTitle =
    ConfigHelper.get(`pagebuilder.elements.${element.type}.title`) || "Element";

  return (
    <div className="pagebuilder-element-popup">
      <MMPopup show={true} size="medium" handleClose={onCancel}>
        <h3>{elementTypeTitle}</h3>
        <PopupContent element={element} actions={actions} extra={props.extra} />
        <div className="text-right">
          <button className="small primary hollow button" onClick={onCancel}>
            <Icon icon="times" left /> Abbrechen
          </button>
          <button className="small primary button" onClick={onUpdate}>
            <Icon icon="check" left /> Übernehmen
          </button>
        </div>
      </MMPopup>
    </div>
  );
}

const PopupContent = ({ element, actions, extra }) => {
  if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.type))
    return <PopupHeading element={element} actions={actions} extra={extra} />;
  else if (element.type === "text")
    return <PopupText element={element} actions={actions} extra={extra} />;
  else if (element.type === "image")
    return <PopupImage element={element} actions={actions} extra={extra} />;
  else if (["vimeo", "youtube"].includes(element.type))
    return <PopupVideo element={element} actions={actions} extra={extra} />;
  else if (element.type === "button")
    return <PopupButton element={element} actions={actions} extra={extra} />;
  else if (element.type === "lesson")
    return <PopupLesson element={element} actions={actions} extra={extra} />;
  else if (element.type === "lessongrid")
    return (
      <PopupLessonGrid element={element} actions={actions} extra={extra} />
    );
  else if (element.type === "countdown")
    return <PopupCountdown element={element} actions={actions} extra={extra} />;

  return null;
};

export default ElementPopup;
