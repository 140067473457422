import React, { Component } from "react";
import { Link } from "react-router-dom";

class Banner extends Component {
  state = {
    banner: this.props.banner,
    logoUrl: this.props.logoUrl,
    topBannerPercent: undefined,
  };

  componentDidMount() {
    window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.banner !== this.props.banner) {
      this.setState({ banner: this.props.banner });
    }
    if (prevProps.logoUrl !== this.props.logoUrl) {
      this.setState({ logoUrl: this.props.logoUrl });
    }
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    return <React.Fragment>{this.view__showBanner()}</React.Fragment>;
  }

  view__showBanner() {
    const { banner, logoUrl } = this.state;
    let output;

    return null;

    // if (banner === "ausbildung") {
    //   output = (
    //     <div>
    //       <div className="top-banner hide-for-small-only" id="topBanner">
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-logo.png"
    //                 alt="TRUE POWER AUSBILDUNG"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //       <div className="top-banner show-for-small-only">
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <img
    //             src="https://universeapi.loahelser.com/storage/loa/Banner_MGB_1.jpg"
    //             alt="TRUE POWER AUSBILDUNG"
    //           />
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "truepowerflow________") {
    //   output = (
    //     <div className="banner-truepowerflow">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           backgroundImage: `url('https://universeapi.loahelser.com/storage/loa/TPFlow-BG.jpg')`,
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/TPFlow-Text.png"
    //                 alt="TRUE POWER AUSBILDUNG"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "truepowerflow_______") {
    //   output = (
    //     <div className="banner-truepowerflow">
    //       <Link to="/">
    //         <img
    //           className="narrowbanner"
    //           src="https://universeapi.loahelser.com/storage/loa/banner_tpf3.jpg"
    //           alt=""
    //         />
    //       </Link>
    //     </div>
    //   );
    // } else if (banner === "truepowerkurs5________") {
    //   output = (
    //     <div className="banner-truepowerflow">
    //       <Link to="/">
    //         <img
    //           className="narrowbanner"
    //           src="https://universeapi.loahelser.com/storage/loa/banner_tp.jpg"
    //           alt=""
    //         />
    //       </Link>
    //     </div>
    //   );
    // } else if (banner === "loauniverse" || banner === "truepowerflow") {
    //   output = (
    //     <div className="mainbanner-universe">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           backgroundImage: `url('https://universeapi.loahelser.com/storage/loa/banner-loauniverse-aug2021.jpg')`,
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/mainbanner_loauniverse.png"
    //                 alt="LOA UNIVERSE"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "truepowerkurs" || banner === "truepowerkurs5" || banner === "549118") {
    //   output = (
    //     <div className="mainbanner-universe">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           backgroundImage: `url('https://universeapi.loahelser.com/storage/loa/mainbanner_bg.jpg')`,
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/mainbanner_truepower.png"
    //                 alt="LOA UNIVERSE"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "livingyourtruthworkshop") {
    //   output = (
    //     <div className="mainbanner-universe">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           backgroundImage: `url('https://universeapi.loahelser.com/storage/loa/banner_artofliving_bg_v2.jpg')`,
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner_artofliving_text_v2.png"
    //                 alt="LOA UNIVERSE"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "tpmeetsbreathwork") {
    //   output = (
    //     <div className="banner-bw">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           backgroundImage: `url('https://universeapi.loahelser.com/storage/loa/banner_bw_bg.jpg')`,
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner_bw_text.png"
    //                 alt="LOA UNIVERSE"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // } else if (banner === "449154") {
    //   output = (
    //     <div className="banner-bw">
    //       <div
    //         className="top-banner hide-for-small-only"
    //         id="topBanner"
    //         style={{
    //           background: "white",
    //         }}
    //       >
    //         <Link onClick={this.props.hideMenu} to={logoUrl}>
    //           <div id="topBannerImages">
    //             <div id="topBannerByLoaEmpty">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //             <div id="topBannerLogo">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/rot_banner.png"
    //                 alt="LOA UNIVERSE"
    //               />
    //             </div>
    //             <div id="topBannerByLoa">
    //               <img
    //                 src="https://universeapi.loahelser.com/storage/loa/banner-byloa-empty.png"
    //                 alt="by Loa"
    //               />
    //             </div>
    //           </div>
    //         </Link>
    //       </div>
    //     </div>
    //   );
    // }

    // return output;
  }

  handleScroll = () => {
    let { topBannerPercent, banner } = this.state;

    let maxPadding = 15;
    let minPadding = 5;
    let maxLogoHeight = 90;
    let minLogoHeight = 60;
    let maxByLoaHeight = 65;
    let minByLoaHeight = 0;
    let minScroll = 0;
    let maxScroll = 400;

    if (banner === "tpmeetsbreathwork") {
      maxByLoaHeight = 20;
    }

    const paddingRange = maxPadding - minPadding;
    const logoHeightRange = maxLogoHeight - minLogoHeight;
    const byLoaHeightRange = maxByLoaHeight - minByLoaHeight;
    const scrollRange = maxScroll - minScroll;

    const scrollY = parseInt(window.scrollY);

    const elBanner = document.getElementById("topBanner");
    const elBannerByLoaEmpty = document.getElementById("topBannerByLoaEmpty");
    const elBannerLogo = document.getElementById("topBannerLogo");
    const elBannerByLoa = document.getElementById("topBannerByLoa");

    if (!elBanner) {
      return null;
    }

    let percent = 0;

    if (scrollY <= minScroll) {
      percent = 100;
    } else if (scrollY > minScroll && scrollY < maxScroll) {
      percent = parseInt((scrollY / scrollRange) * 100);
      if (percent === 0) {
        percent = 1;
      }
    } else if (scrollY >= maxScroll) {
      percent = 0;
    }

    if (percent > 100) {
      percent = 100;
    }

    if (percent < 0) {
      percent = 0;
    }

    if (percent !== topBannerPercent) {
      //console.log("scrollY:", scrollY, "percent:", percent);

      let padding = minPadding;
      let logoHeight = minLogoHeight;
      let byLoaHeight = minByLoaHeight;
      let byLoaOpacity = 0;

      if (percent === 100) {
        padding = maxPadding;
        logoHeight = maxLogoHeight;
        byLoaHeight = maxByLoaHeight;
        byLoaOpacity = 1;
      } else if (percent === 0) {
        padding = minPadding;
        logoHeight = minLogoHeight;
        byLoaHeight = minByLoaHeight;
        byLoaOpacity = 0;
      } else {
        padding = maxPadding - (paddingRange * percent) / 100;
        logoHeight = parseInt(maxLogoHeight - (logoHeightRange * percent) / 100);
        byLoaHeight = parseInt(maxByLoaHeight - (byLoaHeightRange * percent) / 100);
        byLoaOpacity = 1 - (1 * percent) / 100;
      }

      if (elBanner) {
        elBanner.style.paddingTop = `${padding}px`;
        elBanner.style.paddingBottom = `${padding}px`;
      }

      if (elBannerByLoaEmpty) {
        elBannerByLoaEmpty.style.height = `${byLoaHeight}px`;
      }

      if (elBannerLogo) {
        elBannerLogo.style.height = `${logoHeight}px`;
      }

      if (elBannerByLoa) {
        elBannerByLoa.style.height = `${byLoaHeight}px`;
        elBannerByLoa.style.opacity = `${byLoaOpacity}`;
      }

      //console.log("opacity", byLoaOpacity);

      /*console.log("scrollY", scrollY);
      padding = parseInt((elHeight * logoPadding) / 100);

      elBanner.style.height = `${elHeight}px`;
      elBanner.style.paddingTop = `${padding}px`;
      elBanner.style.paddingBottom = `${padding}px`;
      elBannerLogo.style.height = `${elHeight - padding - padding}px`;*/

      this.setState({ topBannerHeight: percent });
    }
  };
}

export default Banner;
