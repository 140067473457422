import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import { Icon } from "../../_foundation/_buttons";
import ReactTooltip from "react-tooltip";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

class AdminCourses extends Component {
  state = {
    courses: null,
    status: "INIT",
    breadcrumbs: ["admin_dashboard", "admin_courses"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("courses");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;

    return (
      <AdminLayout nav="courses" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        <div className="admin-courses">{this.view__showList()}</div>
        <ul id="adminSortCourses" className="admin-list" />
      </AdminLayout>
    );
  }

  view__showHeadline() {
    const _c = this.props.context;
    return <h1>{_c.translate("pageTitles.admin.courses")}</h1>;
  }

  view__showPageActions() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={_c.config("urls.admin.course").replace(":id", "new")}
            className="button"
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.courses.buttons.addCourse.label")}
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showList() {
    const { courses } = this.state;
    const _c = this.props.context;

    if (!courses) {
      return _c.loading();
    }

    if (courses && isArray(courses) && courses.length === 0) {
      return (
        <p className="text-center">
          {_c.translate("admin.courses.noCoursesFound")}
        </p>
      );
    }

    return (
      <React.Fragment>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop(courses)}
          {this.view__showListMobile(courses)}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <Grid type="full">
          <Row>
            <Cell md={2}>&nbsp;</Cell>
            <Cell md={22}>
              <Grid type="full">
                <Row>
                  <Cell md={3}>
                    <div className="padding">&nbsp;</div>
                  </Cell>
                  <Cell md={13}>
                    <div className="padding">
                      {_c.translate("admin.courses.cellTitles.name")}
                    </div>
                  </Cell>
                  <Cell md={4}>
                    <div className="padding">
                      {_c.translate(
                        "admin.courses.cellTitles.digistoreProductIds"
                      )}
                    </div>
                  </Cell>
                  <Cell md={2} className="text-center">
                    <div className="padding">
                      <span
                        data-tip={_c.translate(
                          "admin.courses.cellTitles.published"
                        )}
                      >
                        <Icon icon="unlock-alt" />
                      </span>
                    </div>
                  </Cell>
                  <Cell md={2} className="text-center">
                    <div className="padding">
                      <span
                        data-tip={_c.translate(
                          "admin.courses.cellTitles.visible"
                        )}
                      >
                        <Icon icon="eye" />
                      </span>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(courses) {
    const _c = this.props.context;

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="striped admin-list">{children}</ul>;
    });

    const SortableItem = sortableElement(({ course }) => {
      let courseImage;
      if (course.image) {
        courseImage = (
          <img
            src={course.image}
            alt="Preview"
            className="admin-course-image"
          />
        );
      }

      let v__description;
      if (course.description) {
        v__description = (
          <div className="course-description">{course.description}</div>
        );
      }

      let v__published, v__visible;

      if (course.published === true) {
        v__published = <Icon icon="check" />;
      }

      if (course.visible === true) {
        v__visible = <Icon icon="check" />;
      }

      let v__digistoreIds;
      if (course.digistore_product_ids) {
        course.digistore_product_ids.map((digistoreId, index) => {
          if (index === 0) {
            v__digistoreIds = digistoreId;
          } else {
            v__digistoreIds = `${v__digistoreIds}, ${digistoreId}`;
          }
          return null;
        });
      }

      let v__stats = (
        <div className="admin-courses-stats">
          <span
            data-tip={_c.translate("admin.courses.stats.categories.tooltip")}
          >
            <Icon icon="folder-open-o" left />{" "}
            <strong>{course.stats.categories}</strong>
          </span>
          <span
            style={{ paddingLeft: "16px" }}
            data-tip={_c.translate("admin.courses.stats.lessons.tooltip")}
          >
            <Icon icon="file-o" left /> <strong>{course.stats.lessons}</strong>
          </span>
        </div>
      );

      return (
        <li className="admin-list-item">
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <Grid type="full">
            <Row>
              <Cell md={2}>
                <div className="wrapper text-center">
                  <div className="center-vertically">
                    <DragHandle />
                  </div>
                </div>
              </Cell>
              <Cell md={22}>
                <Link
                  to={_c.config("urls.admin.course").replace(":id", course.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={3} className="text-center">
                        <div className="padding">{courseImage}</div>
                      </Cell>
                      <Cell md={13}>
                        <div className="padding">
                          <div className="course-name">{course.name}</div>
                          {v__description}
                          {v__stats}
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="padding">{v__digistoreIds}</div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">{v__published}</div>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">{v__visible}</div>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </Cell>
            </Row>
          </Grid>
        </li>
      );
    });

    return (
      <div className="hide-for-small-only">
        <SortableContainer
          onSortEnd={this.onSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSortCourses")}
        >
          {courses.map((course, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                course={course}
              />
            );
          })}
        </SortableContainer>
      </div>
    );
  }

  view__showListMobile(courses) {
    const _c = this.props.context;

    return (
      <div className="show-for-small-only">
        <ul className="striped admin-list">
          {courses.map((course, index) => {
            let courseImage;
            if (course.image) {
              courseImage = (
                <img
                  src={course.image}
                  alt="Preview"
                  className="admin-course-image"
                />
              );
            }

            let v__description;
            if (course.description) {
              v__description = (
                <div className="course-description">{course.description}</div>
              );
            }

            let v__published, v__visible;

            if (course.published === true) {
              v__published = (
                <span>
                  <Icon icon="check" left />{" "}
                  {_c.translate("admin.courses.published.yes")}
                </span>
              );
            } else {
              v__published = (
                <span>
                  <Icon icon="times" left />{" "}
                  {_c.translate("admin.courses.published.no")}
                </span>
              );
            }

            if (course.visible === true) {
              v__visible = (
                <span>
                  <Icon icon="check" left />{" "}
                  {_c.translate("admin.courses.visible.yes")}
                </span>
              );
            } else {
              v__visible = (
                <span>
                  <Icon icon="times" left />{" "}
                  {_c.translate("admin.courses.visible.no")}
                </span>
              );
            }

            let v__digistoreIds;
            if (course.digistore_product_ids) {
              course.digistore_product_ids.map((digistoreId, index) => {
                if (index === 0) {
                  v__digistoreIds = digistoreId;
                } else {
                  v__digistoreIds = `${v__digistoreIds}, ${digistoreId}`;
                }
                return null;
              });
            }

            if (v__digistoreIds) {
              v__digistoreIds = `${_c.translate(
                "admin.courses.cellTitles.digistoreProductIds"
              )}: ${v__digistoreIds}`;
            }

            return (
              <li className="admin-list-item" key={index}>
                <Link
                  to={_c.config("urls.admin.course").replace(":id", course.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={3} className="text-center">
                        <div className="padding">{courseImage}</div>
                      </Cell>
                      <Cell md={13}>
                        <div className="padding">
                          <div className="course-name">{course.name}</div>
                          {v__description}
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="padding">{v__digistoreIds}</div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">{v__published}</div>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">{v__visible}</div>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    let { courses } = this.state;
    courses = arrayMove(courses, oldIndex, newIndex);

    const newOrder = [];
    courses.map((course) => {
      return newOrder.push(course.id);
    });

    this.setState({ courses }, () => {
      const apiUrl = _c.apiUrl("admin.courseSort");

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { courses } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    courses,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate("admin.courses.messages.sorted.title"),
                      _c.translate("admin.courses.messages.sorted.text"),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:courses:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:courses:sort");
          });
      }, 200);
    });
  };

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.courses");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { courses } = apiResponseData;
            this.setState({
              courses,
              status: "LOADED",
            });
          }
          _c.setPageTitle("pageTitles.admin.courses", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:courses:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:courses:init");
      });
  }
}

export default withContext(AdminCourses);
