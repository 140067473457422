import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import ConfigHelper from "../../../../../helpers/ConfigHelper";
import MMPopup from "../../../../mmcomponents/mmpopup/MMPopup";
import { Grid, Row, Cell, Icon } from "../../../../_foundation/foundation";
import ColumnSchemaIcon from "../../ColumnSchemaIcon";
import PagebuilderHelper from "../../PagebuilderHelper";

function RowColumnSchemaPopup(props) {
  const [row, setRow] = useState(cloneDeep(props.row));
  const [selectedColumnSchema, setSelectedColumnSchema] = useState(null);

  useEffect(() => {
    setRow(cloneDeep(props.row));
    setSelectedColumnSchema(props.row.column_schema);
  }, [props.row]);

  const pagebuilderColumnSchemas = ConfigHelper.get(
    "pagebuilder.columnSchemas"
  );

  const onSelectColumnSchema = (columnSchema) => {
    setSelectedColumnSchema(columnSchema);
  };

  const onCancel = () => {
    props.actions.onUnselectRowColumnSchema();
  };

  const onUpdate = () => {
    const updatedRow = PagebuilderHelper.updateRowColumnSchema(
      row,
      selectedColumnSchema
    );
    if (props.actions.onUpdateRow) props.actions.onUpdateRow(updatedRow);
  };

  return (
    <MMPopup
      show={true}
      size="small"
      handleClose={() => props.actions.onUnselectRowColumnSchema()}
    >
      <h3>Anordnung der Spalten ändern</h3>
      <p>
        Bitte wähle aus, welches neue Schema die Zeile bekommen soll. Auch wenn
        Du ein Schema auswählst, das weniger Spalten hat als das aktuell
        ausgewählte, bleiben alle Element erhalten. Elemente in wegfallenden
        Spalten werden automatisch zu übrig gebliebenen Spalten hinzugefügt.
      </p>

      <Grid type="full">
        <Row>
          {Object.keys(pagebuilderColumnSchemas).map((schemaKey, index) => {
            return (
              <Cell sm={12} md={6} key={index}>
                <button
                  className={`${
                    selectedColumnSchema ===
                    pagebuilderColumnSchemas[schemaKey].schema
                      ? "selected"
                      : null
                  } pagebuilder-select-schema`}
                  onClick={() =>
                    onSelectColumnSchema(
                      pagebuilderColumnSchemas[schemaKey].schema
                    )
                  }
                >
                  <ColumnSchemaIcon
                    schema={pagebuilderColumnSchemas[schemaKey].schema}
                  />
                </button>
              </Cell>
            );
          })}
        </Row>
      </Grid>
      <div className="text-right">
        <button className="small primary hollow button" onClick={onCancel}>
          <Icon icon="times" left /> Abbrechen
        </button>
        <button className="small primary button" onClick={onUpdate}>
          <Icon icon="check" left /> Übernehmen
        </button>
      </div>
    </MMPopup>
  );
}

export default RowColumnSchemaPopup;
