import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import DefaultLayout from "../layout/DefaultLayout";
import { Grid, Row, Cell } from "../_foundation/_grid";
import Box from "../layout/Box";

class LegalInfo extends Component {
  state = {};

  componentDidMount() {
    const _c = this.props.context;
    _c.init(true);
  }

  render() {
    const _c = this.props.context;

    let legalinfoHtml = <p></p>;

    if (_c.setting("legalinfo_html")) {
      legalinfoHtml = _c.setting("legalinfo_html");
    }

    return (
      <DefaultLayout breadcrumbs={["home", "legalinfo"]}>
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <Box>
                <div dangerouslySetInnerHTML={{ __html: legalinfoHtml }} />
              </Box>
            </Cell>
          </Row>
        </Grid>
      </DefaultLayout>
    );
  }
}

export default withContext(LegalInfo);
