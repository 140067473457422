import React from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import SizePicker from "./SizePicker";
import ColorPicker from "./ColorPicker";

const settings = {
  sr: {
    min: -50,
    max: 50,
  },
  sd: {
    min: -50,
    max: 50,
  },
  s: {
    min: -30,
    max: 50,
  },
  b: {
    min: 0,
    max: 50,
  },
};

function BoxShadowPicker({ value, onEdit }) {
  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={12} md={8}>
          Ausdehnung
        </Cell>
        <Cell sm={12} md={16}>
          <SizePicker
            size="small"
            number={value.s}
            min={settings.s.min}
            max={settings.s.max}
            step={1}
            onChange={(value) => {
              onEdit("s", value);
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={12} md={8}>
          Unschärfe
        </Cell>
        <Cell sm={12} md={16}>
          <SizePicker
            size="small"
            number={value.b}
            min={settings.b.min}
            max={settings.b.max}
            step={1}
            onChange={(value) => {
              onEdit("b", value);
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={12} md={8}>
          Versatz rechts
        </Cell>
        <Cell sm={12} md={16}>
          <SizePicker
            size="small"
            number={value.sr}
            min={settings.sr.min}
            max={settings.sr.max}
            step={1}
            onChange={(value) => {
              onEdit("sr", value);
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={12} md={8}>
          Versatz unten
        </Cell>
        <Cell sm={12} md={16}>
          <SizePicker
            size="small"
            number={value.sd}
            min={settings.sd.min}
            max={settings.sd.max}
            step={1}
            onChange={(value) => {
              onEdit("sd", value);
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={12} md={8}>
          Farbe
        </Cell>
        <Cell sm={12} md={16}>
          <ColorPicker
            size="small"
            color={value.c}
            onChange={(color) => {
              onEdit("c", color);
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={12} md={12}>
          <select
            value={value.show}
            onChange={(e) => onEdit("show", e.target.value)}
          >
            <option value="always">Immer anzeigen</option>
            <option value="hover">Nur bei Mouse Hover anzeigen</option>
          </select>
        </Cell>
      </Row>
    </Grid>
  );
}

export default BoxShadowPicker;
