import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import Avatar from "../../user/Avatar";
import { Icon } from "../../_foundation/_buttons";
import qs from "query-string";
import { isUndefined } from "util";
import { cloneDeep } from "lodash";

class AdminMembers extends Component {
  state = {
    status: "INIT",
    loadingError: false,
    members: undefined,
    search: {
      show: 100,
      page: 1,
      search: "",
      course: null,
    },
    pages: 0,
    page: 0,
    total: 0,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.location.search !== prevProps.location.search) {
      const queryParams = qs.parse(this.props.location.search);
      const { search } = this.state;

      if (Object.keys(queryParams).length > 0) {
        Object.keys(search).map((key) => {
          if (!isUndefined(queryParams[key])) {
            search[key] = queryParams[key];
          }
          return null;
        });

        this.setState({ search }, () => {
          this.init();
        });
      } else {
        this.init();
      }
    }
  }

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();

    const queryParams = qs.parse(this.props.location.search);
    const { search } = this.state;

    if (Object.keys(queryParams).length > 0) {
      Object.keys(search).map((key) => {
        if (!isUndefined(queryParams[key])) {
          search[key] = queryParams[key];
        }
        return null;
      });

      this.setState({ search }, () => {
        this.init();
      });
    } else {
      this.init();
    }

    _c.setPageTitle("pageTitles.admin.members", true);
  }

  render() {
    return (
      <AdminLayout
        nav="members"
        breadcrumbs={["admin_dashboard", "admin_members"]}
      >
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showSearch()}
        {this.view__showPerPage()}
        {this.view__showList()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    const _c = this.props.context;

    return <h1>{_c.translate("pageTitles.admin.members")}</h1>;
  }

  view__showPageActions() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={_c.config("urls.admin.member").replace(":id", "new")}
            className="button"
            data-tip="Neues Mitglied anlegen"
          >
            <Icon icon="plus" left /> {_c.translate("admin.members.addMember")}
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showSearch() {
    const { search } = this.state;
    const _c = this.props.context;

    return (
      <div className="admin-member-search">
        <Box>
          <Grid type="full">
            <Row>
              <Cell sm={24} md={20}>
                <input
                  type="text"
                  value={search.search}
                  placeholder="Suche nach E-Mail/Name"
                  onChange={(e) => {
                    search.search = e.target.value;
                    this.setState({ search });
                  }}
                  onKeyUp={(e) => {
                    if (e.keyCode === 13) {
                      this.search();
                    }
                  }}
                />
              </Cell>
              <Cell sm={24} md={4}>
                <button
                  className="primary button"
                  style={{ width: "100%" }}
                  onClick={this.search}
                >
                  <Icon icon="search" left /> {_c.translate("buttons.search")}
                </button>
              </Cell>
            </Row>
          </Grid>
        </Box>
      </div>
    );
  }

  view__showPerPage() {
    const { search } = this.state;
    const _c = this.props.context;

    const selectValues = {
      10: 10,
      25: 25,
      50: 50,
      100: 100,
      200: 200,
      9999999: _c.translate("admin.pagination.all"),
    };

    if (!selectValues[search.show]) {
      selectValues[search.show] = search.show;
    }

    return (
      <div className="admin-pagination-per-page">
        <select
          value={search.show}
          onChange={(e) => {
            search.show = e.target.value;
            this.props.history.push(this.generateUrl(search));
          }}
        >
          {Object.keys(selectValues).map((key, index) => {
            return (
              <option key={index} value={key}>
                {selectValues[key]}
              </option>
            );
          })}
        </select>{" "}
        {_c.translate("admin.pagination.perPage")}
      </div>
    );
  }

  view__showPagination(where) {
    const { search, pages, page } = this.state;

    let pagesObject = [];
    for (let i = 1; i <= pages; i++) {
      let classes = `pagination-page`;
      if (parseInt(i) === parseInt(page)) {
        classes = `current ${classes}`;
      }

      let pageUrlInfo = cloneDeep(search);
      pageUrlInfo.page = i;
      let pageUrl = this.generateUrl(pageUrlInfo);

      pagesObject.push(
        <Link to={pageUrl} className={classes}>
          {i}
        </Link>
      );
    }

    return (
      <div className={`${where} admin-pagination`}>
        {pagesObject.map((paginationPage, index) => {
          return <React.Fragment key={index}>{paginationPage}</React.Fragment>;
        })}
      </div>
    );
  }

  view__showList() {
    const { members, loadingError, total } = this.state;
    const _c = this.props.context;

    if (!members) {
      return _c.loading(loadingError);
    }

    if (members && isArray(members) && members.length === 0) {
      return (
        <p className="text-center">
          {_c.translate("admin.members.noMembersFound")}
        </p>
      );
    }

    return (
      <React.Fragment>
        <p className="text-center">
          {_c
            .translate("admin.members.membersFound")
            .replace("{total}", total)
            .replace(
              "{member}",
              total === 1
                ? _c.translate("admin.members.member")
                : _c.translate("admin.members.members")
            )}
        </p>
        {this.view__showPagination("top")}
        <Box size="nopadding" margin={false}>
          {this.view__showListTitle()}
          {this.view__showListDesktop(members)}
          {this.view__showListMobile(members)}
        </Box>
        {this.view__showPagination("bottom")}
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={2} className="text-right">
              <div className="padding">
                {_c.translate("admin.members.cellTitles.id")}
              </div>
            </Cell>
            <Cell md={2}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={12}>
              <div className="padding">
                {_c.translate("admin.members.cellTitles.name")}
              </div>
            </Cell>
            <Cell md={4} className="text-center">
              <div className="padding">
                {_c.translate("admin.members.cellTitles.regdate")}
              </div>
            </Cell>
            <Cell md={4} className="text-center">
              <div className="padding">
                {_c.translate("admin.members.cellTitles.lastlogin")}
              </div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(members) {
    const _c = this.props.context;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {members.map((member, index) => {
            let v__admin;
            if (member.admin && member.admin.fulladmin === true) {
              v__admin = (
                <span className="primary label member-admin-label">
                  <Icon icon="check" left /> ADMIN
                </span>
              );
            }

            let classes = "admin-list-item";
            if (!member.active) {
              classes = `inactive ${classes}`;
            }

            return (
              <li className={classes} key={index}>
                <Link
                  to={_c.config("urls.admin.member").replace(":id", member.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={2} className="text-right">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">
                              <span className="monospace">{member.id}</span>
                            </div>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="padding">
                          <Avatar size="admin-user-list" user={member} />
                        </div>
                      </Cell>
                      <Cell md={12}>
                        <div className="padding">
                          <div className="admin-list-item-title">
                            {_c.getFullName(member)} {v__admin}
                          </div>
                          <div className="admin-list-item-subtitle">
                            {member.email}
                          </div>
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">
                              {member.registration_date}
                            </div>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            <div className="padding">
                              {member.last_visit_date}
                            </div>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile(members) {
    const _c = this.props.context;

    return (
      <div className="show-for-small-only">
        <ul className="striped admin-list">
          {members.map((member, index) => {
            let v__admin;
            if (member.admin && member.admin.fulladmin === true) {
              v__admin = (
                <span className="primary label member-admin-label">
                  <Icon icon="check" left /> ADMIN
                </span>
              );
            }

            let classes = "admin-list-item";
            if (!member.active) {
              classes = `inactive ${classes}`;
            }

            return (
              <li className={classes} key={index}>
                <Link
                  to={_c.config("urls.admin.member").replace(":id", member.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell sm={4} className="text-center">
                        <div className="padding">
                          <Avatar size="admin-user-list" user={member} />
                        </div>
                      </Cell>
                      <Cell sm={20}>
                        <div className="padding">
                          <div className="admin-list-item-title">
                            {_c.getFullName(member)} {v__admin}
                          </div>
                          <div className="admin-list-item-subtitle">
                            {member.email}
                          </div>
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            {_c.translate("admin.members.regdate")}{" "}
                            {member.registration_date}
                          </div>
                        </div>
                      </Cell>
                      <Cell md={4} className="text-center">
                        <div className="wrapper">
                          <div className="center-vertically">
                            {_c.translate("admin.members.lastlogin")}{" "}
                            {member.last_visit_date}
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  search = () => {
    const { search } = this.state;

    search.page = 1;
    //this.setState({ search });

    let updatedUrl = this.generateUrl(search);

    this.props.history.push(updatedUrl);
    this.init();
  };

  generateUrl = (search) => {
    let url = window.location.pathname;
    Object.keys(search).map((key, index) => {
      if (search[key]) {
        url = `${url}${index === 0 ? `?` : `&`}${key}=${search[key]}`;
      }
      return null;
    });

    return url;
  };

  init() {
    const { search } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("admin.members");

    Object.keys(search).map((key, index) => {
      if (search[key]) {
        apiUrl = `${apiUrl}${index === 0 ? `?` : `&`}${key}=${search[key]}`;
      }
      return null;
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { members, pages, page, total } = apiResponseData;
            this.setState(
              {
                members,
                pages,
                page,
                total,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:members:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:members:init");
      });
  }
}

export default withContext(AdminMembers);
