import React, { Component } from "react";
import withContext from "../../../../../context/contextHOC";
import { Grid, Row, Cell } from "../../../../_foundation/_grid";
import Avatar from "../../../../user/Avatar";
import ReplyForm from "../../parts/ReplyForm";
import ReactTooltip from "react-tooltip";
import { Icon } from "../../../../_foundation/_buttons";
import EditPopup from "../../parts/EditPopup";
import cloneDeep from "lodash/cloneDeep";
import { Link } from "react-router-dom";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

class ClassicForumLayout extends Component {
  state = {
    thread: this.props.thread,
    replyForm: undefined,
    editPost: null,
    showLightbox: null,
    photoIndex: 0,
    images: [],
  };

  /*componentDidMount() {
    window.scrollTo(0, document.getElementById("post_200292").offsetTop);
  }*/

  componentDidUpdate(prevProps, prevState) {
    if (this.props.thread !== prevProps.thread) {
      this.setState({ thread: this.props.thread });
    }
  }

  render() {
    const { thread, editPost } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <div className="community-thread-posts">{this.view__showPosts()}</div>
        {this.view__showReplyForm()}
        <EditPopup
          thread={thread}
          post={editPost}
          onUpdate={(thread) => {
            this.setState({ thread });
            this.props.onUpdate(thread);
          }}
        />
      </React.Fragment>
    );
  }

  view__showPosts() {
    const { thread, photoIndex, showLightbox } = this.state;
    let { images } = this.state;
    const _c = this.props.context;

    if (!thread || !thread.posts) {
      return null;
    }

    return (
      <React.Fragment>
        {thread.posts.map((post, index) => {
          if (post.deleted === true) {
            return (
              <div
                className="deleted community-post"
                id={`post_${post.slug}`}
                key={index}
              >
                {_c
                  .translate("community.post.isDeleted")
                  .replace("{number}", index + 1)}
              </div>
            );
          }

          let v__lastEdit;
          if (post.last_edit) {
            let v__lastEditBy;

            if (post.last_edit.type === "admin") {
              v__lastEditBy = _c.translate(
                "community.post.lastEdit.message.byAdmin"
              );
            } else if (post.last_edit.type === "own") {
              v__lastEditBy = _c
                .translate("community.post.lastEdit.message.byUser")
                .replace("{name}", post.author.displayname);
            }

            v__lastEdit = (
              <span className="post-last-edit">
                {_c
                  .translate("community.post.lastEdit.message.text")
                  .replace("{date}", post.last_edit.date_f)
                  .replace("{time}", post.last_edit.time_f)}{" "}
                {v__lastEditBy}
              </span>
            );
          }

          let v__postInfo = (
            <Grid type="full">
              <Row>
                <Cell sm={18} md={18}>
                  {post.created_at_f}
                  {v__lastEdit}
                </Cell>
                <Cell sm={6} md={6} className="text-right">
                  #{index + 1}
                </Cell>
              </Row>
            </Grid>
          );

          let v__likeButton = (
            <span
              data-tip={_c.translate("community.post.likeButton.likeTooltip")}
            >
              <Icon icon="thumbs-o-up" left />{" "}
              {_c.translate("community.post.likeButton.like")}
            </span>
          );

          if (post.liked_by_me === true) {
            v__likeButton = (
              <strong
                data-tip={_c.translate(
                  "community.post.likeButton.likedTooltip"
                )}
              >
                <Icon icon="thumbs-o-up" left />{" "}
                {_c.translate("community.post.likeButton.liked")}
              </strong>
            );
          }

          if (post.like_loading === true) {
            v__likeButton = (
              <span>
                <Icon icon="spinner fa-spin" />
              </span>
            );
          }

          let v__likedBy;
          if (post.liked_by && post.liked_by.length > 0) {
            v__likedBy = (
              <div className="post-liked-by">
                <Icon icon="thumbs-o-up" left />{" "}
                {_c.translate("community.post.likedby")}{" "}
                {post.liked_by.map((likedByUser, index) => {
                  let result = <span>{likedByUser.displayname}</span>;

                  if (index !== post.liked_by.length - 1) {
                    if (post.liked_by.length === 2) {
                      result = (
                        <React.Fragment>
                          {result} {_c.translate("general.and")}{" "}
                        </React.Fragment>
                      );
                    } else {
                      result = (
                        <React.Fragment>{result},&nbsp; </React.Fragment>
                      );
                    }
                  }
                  return <React.Fragment key={index}>{result}</React.Fragment>;
                })}
              </div>
            );
          }

          v__likeButton = (
            <span className="post-like-button">
              <button
                onClick={() => {
                  this.props.onPostLike(post, "thumbsup");
                }}
              >
                {v__likeButton}
              </button>
            </span>
          );

          const settingAllowLikes = _c.setting("community_allow_likes");
          if (!settingAllowLikes) {
            v__likeButton = null;
            v__likedBy = null;
          }

          let v__editButton, v__deleteButton;
          const user = _c.getUser();
          if (post.author_id === user.id) {
            v__editButton = (
              <span className="post-edit-button">
                <button
                  data-tip={_c.translate("community.post.editButton.tooltip")}
                  onClick={() => {
                    this.setState({ editPost: cloneDeep(post) });
                  }}
                >
                  <Icon icon="edit" left />{" "}
                  {_c.translate("community.post.editButton.label")}
                </button>
              </span>
            );

            if (!post.is_first_post) {
              v__deleteButton = (
                <span className="post-delete-button">
                  <button
                    data-tip={_c.translate(
                      "community.post.deleteButton.tooltip"
                    )}
                    onClick={() => {
                      this.props.onDeletePost(post.id);
                    }}
                  >
                    <Icon icon="trash" left />{" "}
                    {_c.translate("community.post.deleteButton.label")}
                  </button>
                </span>
              );
            }
          } else if (_c.isCommunityModerator()) {
            v__deleteButton = (
              <span className="post-delete-button">
                <button
                  data-tip={_c.translate("community.post.deleteButton.tooltip")}
                  onClick={() => {
                    this.props.onDeletePost(post.id);
                  }}
                >
                  <Icon icon="trash" left />{" "}
                  {_c.translate("community.post.deleteButton.label")}
                </button>
              </span>
            );
          }

          let v__postActions = (
            <React.Fragment>
              {v__deleteButton}
              {v__editButton}

              {v__likeButton}
              <span className="post-quote-button">
                <button
                  onClick={() => {
                    this.setState(
                      {
                        replyForm: this.props.onCreateBlockQuote(post),
                      },
                      () => {
                        if (document.getElementById(`post_${post.slug}`)) {
                          const postElement = document.getElementById(
                            `communityThreadReplyForm`
                          );
                          const rect = postElement.getBoundingClientRect();
                          if (rect.top) {
                            window.scrollTo(
                              0,
                              parseInt(rect.top + window.scrollY)
                            );
                          }
                        }
                      }
                    );
                  }}
                  data-tip={_c.translate("community.post.quoteButton.tooltip")}
                >
                  <Icon icon="quote-right" />{" "}
                  {_c.translate("community.post.quoteButton.label")}
                </button>
              </span>
            </React.Fragment>
          );

          let v__lightbox;

          if (showLightbox === true && images[photoIndex]) {
            v__lightbox = (
              <Lightbox
                mainSrc={images[photoIndex]}
                nextSrc={images[(photoIndex + 1) % images.length]}
                prevSrc={
                  images[(photoIndex + images.length - 1) % images.length]
                }
                onCloseRequest={() =>
                  this.setState({
                    showLightbox: false,
                    photoIndex: 0,
                    images: [],
                  })
                }
                onMovePrevRequest={() =>
                  this.setState({
                    photoIndex:
                      (photoIndex + images.length - 1) % images.length,
                  })
                }
                onMoveNextRequest={() =>
                  this.setState({
                    photoIndex: (photoIndex + 1) % images.length,
                  })
                }
              />
            );
          }

          let v__postFiles = (
            <div className="post-files">
              <ul>
                {v__lightbox}
                {post.filesFiles.map((file, index) => {
                  const styles = {
                    backgroundImage: `url("${file.url}")`,
                  };

                  let dimensionsClass = "h";
                  if (file.width && file.height) {
                    if (file.height > file.width) {
                      dimensionsClass = "v";
                    } else if (file.width === file.height) {
                      dimensionsClass = "q";
                    }
                  }

                  return (
                    <li key={index}>
                      <div
                        className={`community-post-upload-file community-post-upload-file-success community-post-upload-dimensions-${dimensionsClass}`}
                        style={styles}
                        onClick={() => {
                          this.setState({
                            images: post.images,
                            photoIndex: index,
                            showLightbox: true,
                          });
                        }}
                      >
                        <div className="center-vertically">
                          <span style={{ visibility: "hidden" }}>
                            <Icon icon="check-circle" />
                          </span>
                          <div className="community-post-upload-file-name">
                            &nbsp;
                          </div>
                        </div>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          );

          return (
            <div
              className="community-post"
              id={`post_${post.slug}`}
              key={index}
            >
              <div className="show-for-small-only">
                {this.view__showPostAuthor(post, "mobile")}
              </div>
              <div className="hide-for-small-only">
                {this.view__showPostAuthor(post, "desktop")}
              </div>
              <div className="post-content">
                <div className="post-info">{v__postInfo}</div>
                <div className="post-text">
                  <div dangerouslySetInnerHTML={{ __html: post.text_html }} />
                </div>
                {v__postFiles}
                <div className="clearfix" />
                <div className="post-actions">
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={12}>
                        {v__likedBy}
                      </Cell>
                      <Cell sm={24} md={12} className="text-right">
                        {v__postActions}
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
              <div className="clearfix" />
            </div>
          );
        })}
      </React.Fragment>
    );
  }

  view__showPostAuthor = (post, type) => {
    const { thread } = this.state;
    const { author } = post;
    const _c = this.props.context;

    // AVATAR
    let show_avatar = _c.setting("community_post_show_avatar");
    if (show_avatar) {
      show_avatar = true;
    }

    let v__avatar;
    if (show_avatar === true && show_avatar !== false) {
      v__avatar = (
        <div className="post-author-avatar">
          <Link
            to={_c
              .config("urls.communityProfile")
              .replace(":username", author.username)}
          >
            <Avatar user={author} size="forum-post" />
          </Link>
        </div>
      );
    }

    // USER TITLE
    let show_usertitle = _c.setting("community_post_show_usertitle");
    if (show_usertitle && show_usertitle !== false) {
      show_usertitle = true;
    }

    let v__usertitle;
    if (show_usertitle === true && author.community_title) {
      v__usertitle = (
        <div className="post-author-usertitle">{author.community_title}</div>
      );
    }

    // REG DATE
    let show_regdate = _c.setting("community_post_show_regdate");
    if (show_regdate && show_regdate !== false) {
      show_regdate = true;
    }

    let v__regdate;
    if (show_regdate === true) {
      v__regdate = (
        <div className="post-author-regdate">
          {_c.translate("community.post.membersince")}{" "}
          {author.registration_date}
        </div>
      );
    }

    // POST COUNT
    let show_postcount = _c.setting("community_post_show_postcount");
    if (show_postcount && show_postcount !== false) {
      show_postcount = true;
    }

    let v__postcount;
    if (show_postcount === true) {
      v__postcount = (
        <div className="post-author-postcount">
          {_c.translate("community.post.postcount")}: {author.postcount}
        </div>
      );
    }

    // THREAD OPENER
    let v__threadopener;
    if (thread.author_id === post.author_id) {
      v__threadopener = (
        <div className="post-threadopener">
          <Icon icon="pencil" /> {_c.translate("community.post.threadOpener")}
        </div>
      );
    }

    if (type === "mobile") {
      let v__nameSubLine;

      if (v__regdate && v__postcount) {
        v__nameSubLine = (
          <div className="post-name-subline">
            {v__regdate} | {v__postcount}
          </div>
        );
      }

      return (
        <div className="post-author-line">
          <div className="float-left">{v__avatar}</div>
          <div className="post-author-name">
            <Link
              to={_c
                .config("urls.communityProfile")
                .replace(":username", author.username)}
            >
              {author.displayname} {v__usertitle}
              {v__nameSubLine}
            </Link>
            {v__threadopener}
          </div>
          <div className="clearfix"></div>
        </div>
      );
    } else {
      return (
        <div className="post-author-box">
          {v__avatar}
          <div className="post-author-name">
            <Link
              to={_c
                .config("urls.communityProfile")
                .replace(":username", author.username)}
            >
              {author.displayname}
            </Link>
          </div>
          {v__usertitle}
          <hr />
          {v__regdate}
          {v__postcount}
          {v__threadopener}
        </div>
      );
    }
  };

  view__showReplyForm() {
    const { thread, replyForm } = this.state;
    const _c = this.props.context;

    if (thread.locked === true) {
      return (
        <div className="community-thread-locked">
          {_c.translate("community.reply.threadClosed")}
        </div>
      );
    }

    return (
      <div id="communityThreadReplyForm">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={16} mdo={4}>
              <div>&nbsp;</div>
              <h3>{_c.translate("community.reply.replyTitle")}</h3>
              <ReplyForm
                thread={thread}
                view="post"
                replyForm={replyForm}
                onSavePost={(thread) => {
                  this.setState({ thread });
                  this.props.onUpdate(thread);
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withContext(ClassicForumLayout);
