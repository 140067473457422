import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import LoginView from "./views/LoginView";

import qs from "query-string";
import { isUndefined } from "util";

class Login extends Component {
  state = {
    status: null,
    view: this.props.view,
    errors: {
      notfound: false,
      empty: false,
      forgottenempty: false,
      invalid: false,
      denied: false,
      exists: false,
      maintenance: false,
    },
    messages: {
      success_passwordforgotten: false,
    },
    input: {
      email: "",
      password: "",
    },
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.view !== prevProps.view) {
      this.setState({ view: this.props.view }, () => {
        this.pageTitle();
      });
    }
  }

  componentDidMount() {
    const _c = this.props.context;

    _c.init(true);
    this.pageTitle();
    this.init();

    const queryParams = qs.parse(this.props.location.search);

    if (!isUndefined(queryParams.status) && queryParams.status === "exists") {
      const { errors } = this.state;
      errors.exists = true;
      this.setState({ errors });
    }

    if (!isUndefined(queryParams.email)) {
      const { input } = this.state;
      input.email = queryParams.email;
      this.setState({ input });
    }
  }

  render() {
    const { status, view, errors, messages, input } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <LoginView
          status={status}
          view={view}
          errors={errors}
          messages={messages}
          input={input}
          onUpdateInputChange={this.onUpdateInputChange}
          onUpdateStatus={this.onUpdateStatus}
          onUpdateView={this.onUpdateView}
          onAttemptLogin={this.attemptLogin}
          onAttemptReset={this.attemptReset}
        />
        {_c.loadStylesheets()}
      </React.Fragment>
    );
  }

  onUpdateInputChange = (property, newValue) => {
    const { input } = this.state;

    input[property] = newValue;
    this.setState({ input });
  };

  onUpdateStatus = (newStatus) => {
    this.setState({ status: newStatus });
  };

  onUpdateView = (newView) => {
    this.setState({ view: newView });
  };

  setError = (errorKey, value) => {
    const { errors } = this.state;
    errors[errorKey] = value;
    this.setState({ errors });
  };

  setMessage = (messageKey, value) => {
    const { messages } = this.state;
    messages[messageKey] = value;
    this.setState({ messages });
  };

  resetErrors = () => {
    const { errors, messages } = this.state;
    Object.keys(errors).map((errorKey) => {
      errors[errorKey] = false;
      return null;
    });

    Object.keys(messages).map((messageKey) => {
      messages[messageKey] = false;
      return null;
    });
  };

  attemptLogin = () => {
    this.setState({ status: "loading" });
    this.resetErrors();

    const { input } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("login");

    if (!input.email || !input.password) {
      this.setError("empty", true);
      return null;
    }

    axios
      .post(apiUrl, {
        email: input.email,
        password: input.password,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status, data } = response.data;

        if (status === "NOTFOUND") {
          this.setError("notfound", true);
        } else if (status === "INVALID") {
          this.setError("invalid", true);
        } else if (status === "DENIED") {
          this.setError("denied", true);
        } else if (status === "MAINTENANCE") {
          this.setError("maintenance", true);
        } else if (status === "SUCCESS") {
          if (data.access_token) {
            _c.loginSuccessful(data.access_token);
          }
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  };

  attemptReset = () => {
    this.setState({ status: "LOADING" });
    this.resetErrors();

    const { input } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("password.forgotten");

    if (!input.email) {
      this.setError("forgottenempty", true);
      return null;
    }

    axios
      .post(apiUrl, {
        email: input.email,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;

        if (status === "NOTFOUND") {
          this.setError("notfound", true);
        } else if (status === "DENIED") {
          this.setError("denied", true);
        } else if (status === "SUCCESS") {
          this.setMessage("success_passwordforgotten", true);
          this.setState({ status: "SUCCESS" });
        }
      })
      .catch((error) => {
        if (error.response) {
          const statusCode = error.response.status;
          if (statusCode === 401 || statusCode === 400) {
            this.setState({ hasErrorWrong: true, isLoading: false });
          }
        }
      });
  };

  pageTitle = () => {
    const { view } = this.state;
    const _c = this.props.context;

    let pageTitle = "?";
    if (view === "forgotten") {
      pageTitle = _c.translate("pageTitles.forgotten");
    } else if (view === "login") {
      //pageTitle = _c.translate("pageTitles.login");
      pageTitle = "Login";
    }

    _c.setPageTitle(pageTitle, true);
  };

  init() {
    const _c = this.props.context;
    _c.initFinished();
  }
}

export default withContext(Login);
