import React from "react";
import { Icon } from "../../_foundation/_buttons";
import PageViewHelper from "../PageViewHelper";
import Audio from "../../modules/courses/parts/Audio";
import ApiHelper from "../../../helpers/ApiHelper";
import UrlHelper from "../../../helpers/UrlHelper";

function ElementLesson({ element }) {
  if (!element.options.view) element.options.view = "embed";

  return (
    <>
      <div style={PageViewHelper.applyOptions(element)}>
        {element.options.view === "embed" && <ViewEmbed element={element} />}
        {element.options.view === "link" && <ViewLink element={element} />}
      </div>
    </>
  );
}

const ViewLink = ({ element }) => {
  if (!element.extra || !element.extra.lesson)
    return <div className="text-center">Lektion nicht gefunden.</div>;

  const { course, lesson } = element.extra;

  let url = UrlHelper.url("courseLesson")
    .replace(":courseSlug", course.slug)
    .replace(":lessonSlug", lesson.slug);

  if (lesson.access && lesson.access.hasAccess === false && lesson.access.showBefore === true) {
    return (
      <div className="grid-item">
        <div>
          <img src={lesson.image} alt="" />
        </div>
        <div className="grid-thumbnail-text">
          <h3>
            <Icon icon="lock" /> {lesson.name}
          </h3>
          {/*<div
            dangerouslySetInnerHTML={{
              __html: lesson.description_html,
            }}
          />*/}
        </div>
      </div>
    );
  } else {
    return (
      <a href={url}>
        <div>
          <img src={lesson.image} alt="" />
        </div>
        <div className="grid-thumbnail-text">
          <h3>{lesson.name}</h3>
          {/*<div
            dangerouslySetInnerHTML={{
              __html: lesson.description_html,
            }}
          />*/}
        </div>
      </a>
    );
  }
};

const ViewEmbed = ({ element }) => {
  if (!element.extra || !element.extra.lesson)
    return <div className="text-center">Lektion nicht gefunden.</div>;

  const { lesson } = element.extra;

  if (lesson.type === "audio") {
    return (
      <div>
        <div className="course-lesson-audio">
          <Audio lesson={lesson} onListen={(currentTime) => {}} />
        </div>
        {lesson.id === 536 ? null : (
          <div className="text-right">
            <a
              href={ApiHelper.url("downloads.audio", { id: lesson.id })}
              className="small primary hollow button"
            >
              <Icon icon="download" left /> DOWNLOAD
            </a>
          </div>
        )}
      </div>
    );
  } else if (lesson.type === "vimeo") {
    let embedUrl = `https://player.vimeo.com/video/${lesson.content}`;
    return (
      <div className="responsive-embed widescreen">
        <iframe
          src={embedUrl}
          frameBorder="0"
          allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
          title="vimeoPlayer"
        ></iframe>
      </div>
    );
  } else if (lesson.type === "youtube") {
    let embedUrl = `https://www.youtube.com/embed/${lesson.content}`;
    return (
      <div className="responsive-embed widescreen">
        <div id="youtubePlayer">
          <iframe
            src={embedUrl}
            frameBorder="0"
            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            title="YoutubePlayer"
          ></iframe>
        </div>
      </div>
    );
  } else if (lesson.type === "embed") {
    return (
      <div className="responsive-embed widescreen">
        <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
      </div>
    );
  }

  return null;
};

export default ElementLesson;
