import React, { useState } from "react";
import PageViewHelper from "../PageViewHelper";

function ElementImage({ element }) {
  const [mouseHoverStatus, setMouseHoverStatus] = useState(false);

  return (
    <div
      className="page-element-image"
      style={PageViewHelper.applyOptions(element, null, null, mouseHoverStatus)}
      onMouseOver={() => setMouseHoverStatus(true)}
      onMouseOut={() => setMouseHoverStatus(false)}
    >
      <img src={element.content} alt="" />
    </div>
  );
}

export default ElementImage;
