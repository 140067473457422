import React, { Component } from "react";
import withContext from "../../../../../context/contextHOC";
import KeyboardEventHandler from "react-keyboard-event-handler";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

class SizePicker extends Component {
  state = {
    _updatableProps: ["number", "size", "reset", "min", "max", "step"],
    number: this.props.number,
    min: this.props.min,
    max: this.props.max,
    step: this.props.step,
    size: this.props.size || "small",
    unit: "",
    showSizePicker: false,
  };

  componentDidMount() {
    this.checkNumber(this.state.number);
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        if (propKey === "number") {
          this.checkNumber(this.props[propKey]);
        } else {
          this.setState({ [propKey]: this.props[propKey] });
        }
      }
      return null;
    });
  }

  render() {
    return (
      <React.Fragment>
        <KeyboardEventHandler
          handleKeys={["all"]}
          onKeyEvent={(key, event) => {
            //console.log(key);
            if (key === "esc") {
              if (this.state.showSizePicker === true) {
                this.setState({ showSizePicker: false });
              }
            }
          }}
          handleFocusableElements={true}
        />
        {this.view__showButton()}
        {this.view__showSizePicker()}
      </React.Fragment>
    );
  }

  view__showSizePicker() {
    const { showSizePicker, number, min, max, step } = this.state;
    const _c = this.props.context;

    const styles = {
      position: "absolute",
      background: "white",
      zIndex: "99999",
      display: showSizePicker === true ? "block" : "none",
      padding: "0px 10px 0px 10px",
      boxSizing: "initial",
      borderRadius: "4px",
      boxShadow:
        "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
    };

    return (
      <div style={styles} className="sizepicker-popup">
        <div className="sizepicker-slider">
          <Slider
            min={min}
            max={max}
            step={step}
            value={number}
            onChange={this.selectNumber}
            onAfterChange={this.saveNumber}
          />
        </div>
        <button
          className="sizepicker-esc"
          onClick={() => {
            this.setState({ showSizePicker: false });
          }}
        >
          {_c.translate("admin.design.esc.text")}
        </button>
      </div>
    );
  }

  view__showButton() {
    const { number } = this.state;

    let wrapperStyles = {
      border: "1px solid #cccccc",
      borderRadius: "3px",
    };

    wrapperStyles = this.size(wrapperStyles);

    const contentStyles = {
      border: "2px solid rgb(250, 250, 250)",
      width: "100%",
      height: "100%",
      cursor: "pointer",
      fontSize: "0.8rem",
    };

    return (
      <div style={wrapperStyles} onClick={this.toggleSizepicker}>
        <input
          type="text"
          value={number}
          style={contentStyles}
          onChange={(e) => this.saveNumber(e.target.value)}
        />
      </div>
    );
  }

  size = (styles) => {
    const { size } = this.state;

    if (size === "small") {
      styles.width = "45px";
      styles.height = "27px";
    } else if (size === "fullwidth") {
      styles.width = "100%";
      styles.height = "35px";
    }

    return styles;
  };

  toggleSizepicker = () => {
    let { showSizePicker } = this.state;
    showSizePicker = showSizePicker === true ? false : true;
    this.setState({ showSizePicker });
  };

  selectNumber = (value) => {
    this.setState({ number: value });
  };

  saveNumber = (value) => {
    let numberValue = this.getNumber(value);
    this.setState({ showSizePicker: false });

    if (this.props.onChange) {
      this.props.onChange(numberValue);
    }
  };

  getNumber = (value) => {
    const { unit } = this.state;
    return `${value}${unit}`;
  };

  checkNumber = (numberText) => {
    let unit = "";
    let number = 0;

    const units = ["px", "%", "em", "rem"];

    units.map((unitKey) => {
      if (numberText.includes(unitKey)) {
        unit = unitKey;
        number = numberText.replace(unitKey, "");
      }
      return null;
    });
    this.setState({ number, unit });
  };
}

export default withContext(SizePicker);
