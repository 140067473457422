import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import "react-image-crop/dist/ReactCrop.css";
import VisionboardGrid from "../../modules/visionboard/VisionboardGrid";

class AdminVisionboard extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_visionboard"],
    visionboard: null,
    selectedImage: null,
    newImage: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("visionboard");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    return (
      <AdminLayout nav="visionboard" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <h1>{_c.translate("admin.visionboard.title")}</h1>
              <p className="lead">{_c.translate("admin.visionboard.text")}</p>
            </Cell>
          </Row>
        </Grid>
        {this.view__showImages()}
      </AdminLayout>
    );
  }

  view__showImages() {
    const { visionboard, newImage } = this.state;
    const _c = this.props.context;

    if (!visionboard || !visionboard.images) {
      return _c.loading();
    }

    return (
      <div className="admin-visionboard">
        <VisionboardGrid
          type="admin"
          visionboard={visionboard}
          newImage={newImage}
          onUpdate={(visionboard) => {
            this.setState({ visionboard });
          }}
        />
      </div>
    );

    /*return (
      <div className="admin-visionboard">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <Grid type="full">
          <Row margin="xy">
            {visionboardImages.map((image, index) => {
              let layoutWidth, layoutClass;
              schemeIndex++;
              const { scheme, classes } = layout;
              if (schemeIndex > scheme.length - 1) {
                schemeIndex = 0;
              }

              layoutWidth = scheme[schemeIndex];
              layoutClass = classes[layoutWidth];

              if (image === "new") {
                return (
                  <Cell key={index} sm={24} md={layoutWidth}>
                    <ReactTooltip
                      place={_c.getToolTipSetting("position")}
                      type={_c.getToolTipSetting("type")}
                      effect={_c.getToolTipSetting("effect")}
                    />
                    <button
                      data-tip={_c.translate("visionboard.add.tooltip")}
                      className={`${layoutClass} visionboard-image visionboard-image-add`}
                      onClick={() => {
                        this.setState({ selectedImage: newImage });
                        this.showPopupImageUpload();
                        //console.log(newImage);
                      }}
                    >
                      <div className="wrapper">
                        <div className="center-vertically">
                          <Icon icon="plus" />
                        </div>
                      </div>
                    </button>
                  </Cell>
                );
              } else {
                return (
                  <Cell sm={24} md={layoutWidth} key={index}>
                    <div
                      className={`${layoutClass} visionboard-image`}
                      style={{ backgroundImage: `url(${image.url})` }}
                      onClick={() => {
                        this.setState({
                          showUploadPopup: false,
                          showCropPopup: false,
                        });
                      }}
                    ></div>
                  </Cell>
                );
              }
            })}
          </Row>
        </Grid>
      </div>
    );*/
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.visionboard");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { visionboard, newImage } = response.data.data;
            visionboard.images.push("new");
            this.setState({ visionboard, newImage });
          }
          _c.setPageTitle("pageTitles.admin.visionboard", true);
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:visionboard:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:visionboard:init");
      });
  }
}

export default withContext(AdminVisionboard);
