import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { Icon } from "../_foundation/_buttons";

class AdminEditor extends Component {
  state = {
    _updatableProps: ["value", "html", "mode", "allowVisual", "allowHtml"],
    key: this.props.key || "",
    value: this.props.value,
    html: this.props.html || "",
    mode: this.props.mode || "visual",
    allowVisual: this.props.allowVisual,
    allowHtml: this.props.allowHtml,
    editorState: null,
  };

  componentDidMount() {
    const { value } = this.state;
    this.initEditorState(value);
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.props.key !== prevProps.key) {
      this.setState({ key: this.props.key });

      const { _updatableProps } = this.state;

      _updatableProps.map((propKey) => {
        if (this.props[propKey] !== prevProps[propKey]) {
          this.setState({ [propKey]: this.props[propKey] });

          if (propKey === "value") {
            this.initEditorState(this.props[propKey]);
          }
        }
        return null;
      });
    }
  }

  render() {
    return <div className="admin-editor">{this.view__showEditor()}</div>;
  }

  view__showEditor() {
    let { html, mode, editorState, allowHtml, allowVisual } = this.state;
    const _c = this.props.context;

    if (!allowHtml && allowHtml !== false) {
      allowHtml = true;
    }

    if (!allowVisual && allowVisual !== false) {
      allowVisual = true;
    }

    let classesVisual = "tiny primary button";
    let classesHtml = "tiny primary button";

    let v__editorVisual;
    let v__editorHtml;

    let v__buttonVisual;
    let v__buttonHtml;

    if (mode === "visual") {
      classesHtml = `hollow ${classesHtml}`;
    } else if (mode === "html") {
      classesVisual = `hollow ${classesVisual}`;
    }

    if (allowHtml === true) {
      v__buttonHtml = (
        <button
          className={classesHtml}
          onClick={() => {
            this.switchMode("html");
          }}
        >
          <Icon icon="code" left /> {_c.translate("admin.editor.html.name")}
        </button>
      );
      v__editorHtml = (
        <div style={{ display: mode === "html" ? "block" : "none" }}>
          <textarea
            value={html}
            rows={10}
            onChange={(e) => {
              this.onChangeHtml(e.target.value);
            }}
          />
        </div>
      );
    }

    if (allowVisual === true) {
      v__buttonVisual = (
        <button
          className={classesVisual}
          onClick={() => {
            this.switchMode("visual");
          }}
        >
          <Icon icon="align-left" left />{" "}
          {_c.translate("admin.editor.visual.name")}
        </button>
      );
      v__editorVisual = (
        <div style={{ display: mode === "visual" ? "block" : "none" }}>
          <Editor
            editorState={editorState}
            wrapperClassName="admin-editor-wrapper"
            editorClassName="admin-editor-editor"
            onEditorStateChange={(editorState) => {
              this.onEditorStateChange(editorState);
            }}
            localization={{
              locale: "de",
            }}
            toolbar={{
              options: _c.config("adminEditor.settings.replyFormOptions"),
              inline: _c.config("adminEditor.settings.replyInlineOptions"),
              blockType: _c.config(
                "adminEditor.settings.replyBlockTypeOptions"
              ),
            }}
          />
        </div>
      );
    }

    return (
      <React.Fragment>
        {v__editorVisual}
        {v__editorHtml}
        <div className="editor-mode-buttons">
          {v__buttonVisual}
          {v__buttonHtml}
        </div>
      </React.Fragment>
    );
  }

  onEditorStateChange = (newEditorState) => {
    const newHtml = draftToHtml(
      convertToRaw(newEditorState.getCurrentContent())
    );

    this.setState({ editorState: newEditorState, html: newHtml }, () => {
      this.onChange();
    });
  };

  onChangeHtml = (newHtml) => {
    const blocksFromHtml = convertFromHTML(newHtml);

    const newEditorState = EditorState.createWithContent(
      ContentState.createFromBlockArray(
        blocksFromHtml.contentBlocks,
        blocksFromHtml.entityMap
      )
    );

    this.setState({ html: newHtml, editorState: newEditorState }, () => {
      this.onChange();
    });
  };

  switchMode = (newMode) => {
    this.setState({ mode: newMode });
  };

  onChange = () => {
    const { editorState, html, mode } = this.state;
    const value = convertToRaw(editorState.getCurrentContent());

    if (this.props.onChange) {
      this.props.onChange({ value, html, mode });
    }
  };

  initEditorState = (value) => {
    let { allowHtml, allowVisual } = this.state;

    if (!allowHtml && allowHtml !== false) {
      allowHtml = true;
    }

    if (!allowVisual && allowVisual !== false) {
      allowVisual = true;
    }

    let editorState;
    if (allowVisual === true) {
      if (value) {
        editorState = EditorState.createWithContent(convertFromRaw(value));
      } else {
        editorState = EditorState.createEmpty();
      }
    }

    this.setState({ editorState });
  };
}

export default withContext(AdminEditor);
