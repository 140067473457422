import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import TruePowerKurs5 from "./coursepages/TruePowerKurs5";
import axios from "axios";
import CourseLayout from "./CourseLayout";
import Free from "./coursepages/Free";
import TruePowerFlow from "./coursepages/TruePowerFlow";
import DefaultCoursePage from "./coursepages/DefaultCoursePage";
import MeditateWithLoa from "./coursepages/MeditateWithLoa";
import RhythmOfTruth from "./coursepages/RhythmOfTruth";
import PageView from "../../pagebuilder/PageView";
import DefaultLayout from "../../layout/DefaultLayout";
import CoursesGrid from "./CoursesGrid";

class CoursePage extends Component {
  state = {
    courseSlug: this.props.match.params.courseSlug,
    courses: null,
    course: null,
    lessons: null,
    downloads: null,
    nextLivecall: null,
    homeItemPersonalMessage: null,
    page: null,
    status: "INIT",
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { courseSlug } = this.state;

    if (this.props.match.params.courseSlug !== courseSlug) {
      this.setState({ courseSlug: this.props.match.params.courseSlug }, () => {
        this.init();
      });
    }
  }

  render() {
    const {
      courseSlug,
      course,
      courses,
      lessons,
      downloads,
      status,
      homeItemPersonalMessage,
      nextLivecall,
      page,
    } = this.state;

    const _c = this.props.context;

    let output;
    let full = false;

    if (page && page.identifier) {
      return (
        <div
          className={`pageview-page ${
            page.banner ? `page-pagebuilder-with-banner` : `page-pagebuilder-no-banner`
          }`}
        >
          <DefaultLayout>
            <PageView page={page} />
            {page.identifier === "86458264" ? (
              <div style={{ position: "relative", top: "-30px" }}>
                <CoursesGrid
                  purchased={courses.purchased}
                  available={courses.available}
                  _c={_c}
                  full={false}
                />
              </div>
            ) : null}
          </DefaultLayout>
        </div>
      );
    }

    if (
      courseSlug === "truepowerkurs5" ||
      courseSlug === "truepowerkurs" ||
      courseSlug === "377188"
    ) {
      output = (
        <TruePowerKurs5
          course={course}
          lessons={lessons}
          downloads={downloads}
          homeItemPersonalMessage={homeItemPersonalMessage}
          nextLivecall={nextLivecall}
        />
      );
    } else if (courseSlug === "truepowerflow" || courseSlug === "loauniverse") {
      full = true;
      output = (
        <TruePowerFlow courses={courses} course={course} lessons={lessons} downloads={downloads} />
      );
    } else if (courseSlug === "449154") {
      full = true;
      output = (
        <RhythmOfTruth courses={courses} course={course} lessons={lessons} downloads={downloads} />
      );
    } else if (courseSlug === "free") {
      output = (
        <Free
          course={course}
          lessons={lessons}
          downloads={downloads}
          homeItemPersonalMessage={homeItemPersonalMessage}
        />
      );
    } else if (courseSlug === "102581") {
      output = (
        <MeditateWithLoa
          course={course}
          lessons={lessons}
          downloads={downloads}
          homeItemPersonalMessage={homeItemPersonalMessage}
        />
      );
    } else {
      output = (
        <DefaultCoursePage
          course={course}
          lessons={lessons}
          downloads={downloads}
          homeItemPersonalMessage={homeItemPersonalMessage}
        />
      );
    }

    return (
      <div className="course-course">
        <CourseLayout
          full={full}
          course={course}
          lessons={lessons}
          downloads={downloads}
          status={status}
        >
          {output}
        </CourseLayout>
      </div>
    );
  }

  init() {
    const { courseSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.coursePage", {
      courseSlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const {
              course,
              courses,
              lessons,
              downloads,
              homeItemPersonalMessage,
              nextLivecall,
              page,
            } = response.data.data;

            this.setState(
              {
                course,
                courses,
                lessons,
                downloads,
                homeItemPersonalMessage,
                nextLivecall,
                page,
                status: "LOADED",
              },
              () => {
                if (page && page.identifier === "86458264") {
                  document.title = _c.setting("pagetitle");
                } else _c.setPageTitle(`${course.name}`, true);
              }
            );
          } else if (status === "COURSE_NOTFOUND") {
            this.setState({ status }, () => {
              _c.setPageTitle(`${_c.translate("courses.notfound.title")}`, true);
            });
          } else if (status === "LESSON_NOTFOUND") {
            const { course } = response.data.data;
            this.setState({ course, status }, () => {
              _c.setPageTitle(
                `${_c.translate("courses.lesson.notfound.title")} [${course.name}]`,
                true
              );
            });
          } else if (status === "ERROR") {
            return _c.handleError(response.data, "course:lesson:init");
          }
        } catch {
          return _c.handleError({ status: "AXIOS_RESPONSE_ERROR" }, "course:page:init:response");
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "course:page:init");
        }
      });
  }
}

export default withContext(CoursePage);
