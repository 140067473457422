import React, { useContext, useState } from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import SizePicker from "./SizePicker";
import { cloneDeep } from "lodash";
import ReactTooltip from "react-tooltip";
import { PtContext } from "../../../../../context/ptProvider";

function TRBLPicker({ value, min, max, step, onUpdate }) {
  const _c = useContext(PtContext);

  const [sidesLocked, setSidesLocked] = useState(false);

  const toggleSidesLocked = () => {
    setSidesLocked(!sidesLocked);
  };

  const onEdit = (property, newValue) => {
    if (sidesLocked) {
      value.t = newValue;
      value.r = newValue;
      value.b = newValue;
      value.l = newValue;
    } else {
      value[property] = newValue;
    }
    onUpdate(cloneDeep(value));
  };

  return (
    <div className="trbl-picker">
      <ReactTooltip
        place={_c.getToolTipSetting("position")}
        type={_c.getToolTipSetting("type")}
        effect={_c.getToolTipSetting("effect")}
      />

      <Grid type="full">
        <Row>
          <Cell sm={8} mdo={8}>
            <InputField
              property="t"
              value={value.t}
              min={min}
              max={max}
              step={step}
              onEdit={onEdit}
            />
          </Cell>
        </Row>
        <Row>
          <Cell sm={8}>
            <InputField
              property="l"
              value={value.l}
              min={min}
              max={max}
              step={step}
              onEdit={onEdit}
            />
          </Cell>
          <Cell sm={8}>
            <button
              className="trbl-sides-lock"
              onClick={toggleSidesLocked}
              data-tip="Wenn aktiviert, werden alle vier Seiten gleichzeitig bearbeitet"
            >
              <span className="mi">
                {sidesLocked ? `border_outer` : `border_clear`}
              </span>
            </button>
          </Cell>
          <Cell sm={8}>
            <InputField
              property="r"
              value={value.r}
              min={min}
              max={max}
              step={step}
              onEdit={onEdit}
            />
          </Cell>
        </Row>
        <Row>
          <Cell sm={8} mdo={8}>
            <InputField
              property="b"
              value={value.b}
              min={min}
              max={max}
              step={step}
              onEdit={onEdit}
            />
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

const InputField = ({ property, value, min, max, step, onEdit }) => (
  <SizePicker
    size="fullwidth"
    number={value}
    min={min}
    max={max}
    step={step}
    onChange={(value) => {
      onEdit(property, value);
    }}
  />
);

export default TRBLPicker;
