import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import Box from "../../../../layout/Box";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import AdminDataField from "../../../AdminDataField";
import ControlAlignment from "../../controls/ControlAlignment";
import ControlBackgroundColor from "../../controls/ControlBackgroundColor";
import ControlBorder from "../../controls/ControlBorder";
import ControlBoxShadow from "../../controls/ControlBoxShadow";
import ControlFontColor from "../../controls/ControlFontColor";
import ControlFontSize from "../../controls/ControlFontSize";
import ControlFontWeight from "../../controls/ControlFontWeight";
import ControlMargin from "../../controls/ControlMargin";
import ControlPadding from "../../controls/ControlPadding";
import SettingsSection from "./SettingsSection";

function ElementDesign(props) {
  const [item, setItem] = useState(cloneDeep(props.item));

  const { itemType } = props;
  let { unavailableOptions } = props;

  useEffect(() => {
    console.log("ElementDesign props.items.options", props.item.options);
    setItem(cloneDeep(props.item));
  }, [props.item]);

  if (!unavailableOptions) unavailableOptions = [];

  const toggleFullwidth = (size) => {
    props.actions.onEdit("fullwidth", !item.fullwidth);
  };

  let fullwidthLabel = "Volle Breite";
  if (itemType === "area")
    fullwidthLabel = "Dieser Bereich nimmt die gesamte Bildschirmbreite ein.";
  if (itemType === "row")
    fullwidthLabel =
      "Diese Zeile nimmt die gesamte Breite des Elternbereichs ein.";

  return (
    <div className="pagebuilder-element-settings">
      <Grid type="full">
        {!unavailableOptions.includes("fullwidth") &&
        ["area", "row"].includes(itemType) ? (
          <Row margin="xy">
            <Cell sm={24}>
              <SettingsSection title="Volle Breite">
                <Box size="small">
                  <AdminDataField
                    value={item.fullwidth}
                    editValue={item.fullwidth}
                    editType="singleCheckbox"
                    label={fullwidthLabel}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) =>
                      props.actions.onEdit("fullwidth", newValue)
                    }
                  />
                </Box>
              </SettingsSection>
            </Cell>
          </Row>
        ) : null}
        {["element"].includes(itemType) && ["button"].includes(item.type) && (
          <Row margin="xy">
            <Cell sm={24}>
              <SettingsSection title="Ausrichtung">
                <Box size="small">
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={12} md={8}>
                        <div className="pagebuilder-control-label">
                          Ausrichtung:
                        </div>
                      </Cell>
                      <Cell sm={12} md={16}>
                        <ControlAlignment
                          item={item}
                          onUpdate={props.actions.onEdit}
                        />
                      </Cell>
                    </Row>
                  </Grid>
                </Box>
              </SettingsSection>
            </Cell>
          </Row>
        )}
        {["element"].includes(itemType) &&
          !["text", "image", "countdown"].includes(item.type) && (
            <Row margin="xy">
              <Cell sm={24}>
                <SettingsSection title="Schrift">
                  <Box size="small">
                    <Grid type="full">
                      <Row margin="xy">
                        <Cell sm={12} md={8}>
                          <div className="pagebuilder-control-label">
                            Schriftgröße:
                          </div>
                        </Cell>
                        <Cell sm={12} md={16}>
                          <ControlFontSize
                            item={item}
                            onUpdate={props.actions.onEdit}
                          />
                        </Cell>
                      </Row>
                      <Row margin="xy">
                        <Cell sm={12} md={8}>
                          <div className="pagebuilder-control-label">
                            Schriftstärke:
                          </div>
                        </Cell>
                        <Cell sm={12} md={16}>
                          <ControlFontWeight
                            item={item}
                            onUpdate={props.actions.onEdit}
                          />
                        </Cell>
                      </Row>
                      <Row margin="xy">
                        <Cell sm={12} md={8}>
                          <div className="pagebuilder-control-label">
                            Schriftfarbe:
                          </div>
                        </Cell>
                        <Cell sm={12} md={16}>
                          <ControlFontColor
                            item={item}
                            onUpdate={props.actions.onEdit}
                          />
                        </Cell>
                      </Row>
                    </Grid>
                  </Box>
                </SettingsSection>
              </Cell>
            </Row>
          )}
        {["element", "column", "row", "area"].includes(itemType) && (
          <Row margin="xy">
            <Cell sm={24}>
              <SettingsSection title="Hintergrund">
                <Box size="small">
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={12} md={8}>
                        <div className="pagebuilder-control-label">
                          Hintergrundfarbe:
                        </div>
                      </Cell>
                      <Cell sm={12} md={16}>
                        <ControlBackgroundColor
                          item={item}
                          onUpdate={props.actions.onEdit}
                        />
                      </Cell>
                    </Row>
                  </Grid>
                </Box>
              </SettingsSection>
            </Cell>
          </Row>
        )}

        {["element", "column", "row", "area"].includes(itemType) && (
          <Row margin="xy">
            <Cell sm={24}>
              <SettingsSection title="Abstände">
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24} md={12}>
                      <Box size="small">
                        <p>Außenabstand (margin)</p>
                        <ControlMargin
                          item={item}
                          onUpdate={props.actions.onEdit}
                        />
                      </Box>
                    </Cell>
                    <Cell sm={24} md={12}>
                      <Box size="small">
                        <p>Innenabstand (padding)</p>
                        <ControlPadding
                          item={item}
                          onUpdate={props.actions.onEdit}
                        />
                      </Box>
                    </Cell>
                  </Row>
                </Grid>
              </SettingsSection>
            </Cell>
          </Row>
        )}

        {["element", "column", "row", "area"].includes(itemType) && (
          <Row margin="xy">
            <Cell sm={24}>
              <SettingsSection title="Rahmen">
                <ControlBorder item={item} onUpdate={props.actions.onEdit} />
              </SettingsSection>
            </Cell>
          </Row>
        )}
        {["element"].includes(itemType) &&
          ["button", "image"].includes(item.type) && (
            <Row margin="xy">
              <Cell sm={24}>
                <SettingsSection title="Schatten">
                  <ControlBoxShadow
                    item={item}
                    onUpdate={props.actions.onEdit}
                  />
                </SettingsSection>
              </Cell>
            </Row>
          )}
      </Grid>
    </div>
  );
}

export default ElementDesign;
