import React from "react";
import PagebuilderHelper from "../../PagebuilderHelper";

function PreviewButton(props) {
  const element = props.element;

  return (
    <div
      className={`pagebuilder-element-preview pagebuilder-element-preview-button`}
    >
      {PagebuilderHelper.getPreview(element)}
    </div>
  );
}

export default PreviewButton;
