import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import AdminDataField from "../admin/AdminDataField";
import MMPopup from "../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../_foundation/_buttons";
import { Cell, Grid, Row } from "../_foundation/_grid";
import axios from "axios";

class QAPopup extends Component {
  state = {
    showQAPopup: this.props.show,
    questionText: "",
    questionName: "",
    questionAnonymous: false,
    changesWereMade: false,
    view: "question",
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show !== prevProps.show) {
      this.setState({ showQAPopup: this.props.show });
    }
  }

  render() {
    const { view, showQAPopup } = this.state;

    if (!showQAPopup) {
      return null;
    }

    let v__output;

    if (view === "thankyou") {
      v__output = this.view__showThankYou();
    } else {
      v__output = this.view__showQuestion();
    }

    return (
      <MMPopup
        show={showQAPopup}
        size="small"
        handleClose={this.handle__cancel}
      >
        {v__output}
      </MMPopup>
    );
  }

  view__showQuestion() {
    const {
      showQAPopup,
      questionText,
      questionName,
      questionAnonymous,
    } = this.state;
    const _c = this.props.context;

    const user = _c.getUser();
    let userName = "";

    if (!showQAPopup) {
      return null;
    }

    return (
      <React.Fragment>
        <h2>Deine Q&A-Frage</h2>

        <p className="lead">
          This is your sacred space, love. Auch wenn wir TRUE POWER sisters uns
          gegenseitig ausnahmslos supporten, gibt es manche Themen, die du
          vielleicht nicht öffentlich besprechen möchtest. We respect your
          privacy. Deshalb haben wir einen privaten Frage Button eingerichtet,
          mit dem du deine ganz persönlichen Fragen einschicken kannst.
        </p>

        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <AdminDataField
                value={questionName || userName}
                editValue={questionName || userName}
                editType="text"
                label={"Dein Name (optional)"}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.setState({
                    questionName: newValue,
                    changesWereMade: true,
                  });
                }}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <h3>Deine Frage</h3>
              <textarea
                rows={8}
                value={questionText}
                placeholder="Gib hier Deine Frage ein."
                onChange={(e) => {
                  const newValue = e.target.value;
                  this.setState({
                    questionText: newValue,
                    changesWereMade: true,
                  });
                }}
              />
            </Cell>
          </Row>
          {/*<Row margin="xy">
            <Cell sm={24} md={24}>
              <div className="qa-popup-anonymous">
                <AdminDataField
                  value={questionAnonymous || false}
                  editValue={questionAnonymous || false}
                  editType="singleCheckbox"
                  label={"Anonym senden"}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.setState({
                      questionAnonymous: newValue,
                      changesWereMade: true,
                    });
                  }}
                />
                <p>
                  <small>
                    Wenn Du Deine Frage anonym sendest, sieht niemand (auch
                    nicht das Team LOA), dass diese Frage von Dir stammt.
                  </small>
                </p>
              </div>
            </Cell>
                    </Row>*/}
        </Grid>
        <div className="popup-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button className="small primary button" onClick={this.handle__save}>
            <Icon icon="check" left /> Frage senden
          </button>
        </div>
      </React.Fragment>
    );
  }

  view__showThankYou() {
    return (
      <React.Fragment>
        <h2>Deine Q&A-Frage</h2>
        <div className="success callout">
          <div>Herzlichen Dank, Deine Frage wurde erfolgreich gespeichert.</div>
        </div>
      </React.Fragment>
    );
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let reallyCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(
          "Möchtest Du wirklich abbrechen? Deine Eingaben gehen dann verloren."
        )
      ) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.setState(
        {
          changesWereMade: false,
          questionAnonymous: false,
          questionName: "",
          questionText: "",
          view: "question",
        },
        () => {
          _c.showQAPopup(false);
        }
      );
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { questionText, questionAnonymous, questionName } = this.state;

    if (!questionText) {
      window.alert("Bitte gib Deine Frage ein.");
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("question");

      axios
        .post(
          apiUrl,
          {
            question: questionText,
            anonymous: questionAnonymous,
            name: questionName,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;

              _c.setIsSaving(false);

              this.setState({
                changesWereMade: false,
                questionText: "",
                questionName: "",
                questionAnonymous: false,
                view: "thankyou",
              });
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:community:area:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:community:area:save");
        });
    }, 200);
  };
}

export default withContext(QAPopup);
