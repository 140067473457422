import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import PersonalMessage from "../PersonalMessage";
import CourseRecommendations from "./CourseRecommendations";

class Free extends Component {
  state = {
    course: this.props.course,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    homeItemPersonalMessage: this.props.homeItemPersonalMessage,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (
      prevProps.homeItemPersonalMessage !== this.props.homeItemPersonalMessage
    ) {
      this.setState({
        homeItemPersonalMessage: this.props.homeItemPersonalMessage,
      });
    }
  }

  render() {
    const { lessons } = this.state;
    if (!lessons) {
      return null;
    }

    let contentCategory, recommendationsCategory;
    lessons.map((category) => {
      if (category.internal_key === "content") {
        contentCategory = category;
      } else if (category.internal_key === "recommendations") {
        recommendationsCategory = category;
      }
    });

    return (
      <div className={`course-page-truepowerkurs5`}>
        {this.view__showHeader(contentCategory)}
        <div>&nbsp;</div>
        {this.view__showPersonalMessage(contentCategory)}
        <div>&nbsp;</div>
        {this.view__showGridHeader(contentCategory)}
        {this.view__showGrid(contentCategory)}
        <div>&nbsp;</div>
        {this.view__showRecommendations(recommendationsCategory)}
      </div>
    );
  }

  view__showHeader(contentCategory) {
    let v__categoryHeader;

    if (contentCategory.banner) {
      v__categoryHeader = (
        <React.Fragment>
          <div className="category-banner">
            <img src={contentCategory.banner} alt={contentCategory.name} />
          </div>
        </React.Fragment>
      );
    }

    return v__categoryHeader;
  }

  view__showPersonalMessage() {
    const { course, lessons, homeItemPersonalMessage } = this.state;

    let personalmessageLesson;
    lessons.map((category) => {
      category.lessons.map((lesson) => {
        if (lesson.internal_key === "personalmessage") {
          personalmessageLesson = lesson;
        }
      });
    });

    if (personalmessageLesson) {
      return (
        <Grid>
          <Row>
            <Cell sm={24} md={20} mdo={2}>
              <PersonalMessage
                course={course}
                lesson={personalmessageLesson}
                homeItemPersonalMessage={homeItemPersonalMessage}
              />
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  view__showGridHeader(contentCategory) {
    const { course } = this.state;

    let output;
    if (contentCategory.description_html) {
      output = (
        <div className="category-description">
          <div
            dangerouslySetInnerHTML={{
              __html: contentCategory.description_html,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <h1>{contentCategory.name}</h1>
        {output}
      </div>
    );
  }

  view__showGrid(contentCategory) {
    const { course } = this.state;
    const _c = this.props.context;

    return (
      <div className="truepowerkurs5-grid">
        <Grid>
          <Row>
            {contentCategory.lessons.map((lesson) => {
              let url = _c
                .config("urls.courseLesson")
                .replace(":courseSlug", course.slug)
                .replace(":lessonSlug", lesson.slug);
              let v__thumbnail;

              if (
                lesson.access &&
                lesson.access.hasAccess === false &&
                lesson.access.showBefore === true
              ) {
                v__thumbnail = (
                  <div className="grid-item">
                    <div>
                      <img src={lesson.image} alt="" />
                    </div>
                    <div className="grid-thumbnail-text">
                      <h3>
                        <Icon icon="lock" /> {lesson.name}
                      </h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />
                    </div>
                  </div>
                );
              } else {
                v__thumbnail = (
                  <a href={url}>
                    <div>
                      <img src={lesson.image} alt="" />
                    </div>
                    <div className="grid-thumbnail-text">
                      <h3>{lesson.name}</h3>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />
                    </div>
                  </a>
                );
              }

              return (
                <Cell sm={24} md={8} key={lesson.id}>
                  {v__thumbnail}
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }

  view__showRecommendations(recommendationsCategory) {
    let v__description;

    if (recommendationsCategory.description_html) {
      v__description = (
        <div className="category-description">
          <div
            dangerouslySetInnerHTML={{
              __html: recommendationsCategory.description_html,
            }}
          />
        </div>
      );
    }

    return (
      <div>
        <h1>{recommendationsCategory.name}</h1>
        {v__description}
        <CourseRecommendations showCourses={["truepowerkurs5", "ausbildung"]} />
      </div>
    );
  }
}

export default withContext(Free);
