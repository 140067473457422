import React, { Component } from "react";
import { Icon } from "../components/_foundation/foundation";
import axios from "axios";
import ReactNotification from "react-notifications-component";
import { store } from "react-notifications-component";
import "react-notifications-component/dist/theme.css";

import { hasToBeArray } from "../components/_utils/_utils";

import ReactGA from "react-ga";
import ReactPixel from "react-facebook-pixel";

/*
 * Language and Config Files for local development only
 * On production they will be loaded dynamically from API Storage
 */
import translations_de_DU from "../translations/de_DU.json";
import translations_en_EN from "../translations/en_EN.json";
import membermateConfig from "../config/membermate.config.json";
//import customConfigFile from "../config/custom.config.json";

export const PtContext = React.createContext();

export default class PtProvider extends Component {
  constructor() {
    super();

    let apiBaseUrl;
    let apiUrlKey = "dev";
    let env = "dev";
    let debug = true;

    // Check if host is local
    if (window.location.hostname === "localhost" || window.location.hostname === "127.0.0.1") {
      // Local Delopment Environment
      apiUrlKey = "dev";
      env = "dev";
      debug = true;
      apiBaseUrl = membermateConfig.apiBaseUrl.dev;
      //apiBaseUrl = "https://membermate_v1.ptwd";
    } else if (window.location.hostname.substr(0, 11) === "devuniverse") {
      // Development Environment on Production Server / Staging
      apiUrlKey = "prod";
      env = "dev";
      debug = true;
      apiBaseUrl = membermateConfig.apiBaseUrl.staging;
      //apiBaseUrl = `https://mmapi.${window.location.host}`;
    } else {
      // Production Environment
      apiUrlKey = "prod";
      env = "prod";
      debug = false;
      apiBaseUrl = membermateConfig.apiBaseUrl.prod;
      //apiBaseUrl = `https://mmapi.${window.location.host}`;
    }

    // Init State
    this.state = {
      version: "1.10",
      lang: "en_EN",
      _config: {},
      _custom: {},
      _translations: {
        de_DU: {},
        de_SIE: {},
        en_EN: {},
      },
      debug: debug,
      env: env,
      apiBaseUrl: apiBaseUrl,
      apiUrlKey: apiUrlKey,
      headers: {
        Accept: "application/json",
        Authorization: "Bearer " + localStorage.getItem("access_token"),
      },
      app: {},
      user: {},
      initFinished: false,
      errors: [],
      pageTitle: "",
      search: null,
      adminMenuItems: [
        {
          title: "Dashboard",
          name: "dashboard",
          url: "/admin",
          icon: "th-large",
          permission: "ADMIN",
        },
        {
          title: "Live Calls",
          name: "livecalls",
          url: "/admin/livecalls",
          icon: "youtube-play",
          permission: "LIVECALLS",
          module: "live",
        },
        {
          title: "Q&A Fragen",
          name: "questions",
          url: "/admin/questions",
          icon: "comments-o",
          permission: "LIVECALLS",
          module: "live",
        },
        {
          title: "Community",
          name: "community",
          url: "/admin/community",
          icon: "comments-o",
          permission: "COMMUNITY",
          module: "community",
        },
        {
          title: "Produkte / Inhalte",
          name: "courses",
          url: "/admin/courses",
          icon: "graduation-cap",
          permission: "KURSE",
          module: "courses",
        },
        {
          title: "PageBuilder",
          name: "pagebuilder",
          url: "/admin/pagebuilder",
          icon: "th-large",
          permission: "KURSE",
          module: "courses",
        },
        {
          title: "Home-Seite",
          name: "home",
          url: "/admin/home",
          icon: "house",
          permission: "FULLADMIN",
        },
        {
          title: "Visionboard",
          name: "visionboard",
          url: "/admin/visionboard",
          icon: "picture-o",
          permission: "SETTINGS",
          module: "visionboard",
        },
        {
          title: "Mitglieder",
          name: "members",
          url: "/admin/members",
          icon: "users",
          permission: "MEMBERS",
        },
        {
          title: "Administratoren",
          name: "admins",
          url: "/admin/admins",
          icon: "start",
          permission: "FULLADMIN",
        },
        /*{
          title: "Design (beta)",
          name: "design",
          url: "/admin/design",
          icon: "brush",
          permission: "FULLADMIN",
        },*/
        {
          title: "Einstellungen",
          name: "settings",
          url: "/admin/settings",
          icon: "cogs",
          permission: "SETTINGS",
        },
        /*{
          title: "Tools",
          name: "tools",
          url: "/admin/tools",
          icon: "wrench",
          permission: "SETTINGS",
        },*/
        /*{
          title: "membermate",
          name: "license",
          url: "/admin/license",
          icon: "key",
          permission: "FULLADMIN",
        },*/
        /*{
          title: "Support",
          name: "support",
          url: "/admin/support",
          icon: "question-circle",
          permission: "FULLADMIN",
        },*/
      ],
      editLock: {
        timeout: 50, // Seconds
        result: { status: "UNKNOWN", name: "", intervalId: 0 },
      },
      loadedModule: "",
      months: {
        1: "Januar",
        2: "Februar",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "August",
        9: "September",
        10: "Oktober",
        11: "November",
        12: "Dezember",
      },
      monthsShort: {
        1: "Jan",
        2: "Feb",
        3: "März",
        4: "April",
        5: "Mai",
        6: "Juni",
        7: "Juli",
        8: "Aug",
        9: "Sep",
        10: "Okt",
        11: "Nov",
        12: "Dez",
      },
      weekdays: {
        1: "Montag",
        2: "Dienstag",
        3: "Mittwoch",
        4: "Donnerstag",
        5: "Freitag",
        6: "Samstag",
        7: "Sonntag",
      },
      weekdaysShort: {
        1: "Mo",
        2: "Di",
        3: "Mi",
        4: "Do",
        5: "Fr",
        6: "Sa",
        7: "So",
      },
      genders: {
        notselected: "",
        f: "weiblich",
        m: "männlich",
        d: "divers",
      },
      videoTypes: {
        vimeo: "Vimeo",
        youtube: "Youtube",
        embed: "Embed Code",
        audio: "Audiodatei",
        upload: "Hochladen",
      },
      lockableModules: ["community", "visionboard", "routines"],
      showQAPopup: false,
    };
  }

  render() {
    return (
      <PtContext.Provider
        value={{
          ...this.state,
          getApp: this.getApp,
          getAppSettings: this.getAppSettings,
          setting: this.setting,
          settingHtml: this.settingHtml,
          userSetting: this.userSetting,
          setUserSetting: this.setUserSetting,
          design: this.design,
          translate: this.translate,
          config: this.config,
          apiUrl: this.apiUrl,
          custom: this.custom,

          init: this.init,
          initTracking: this.initTracking,
          getVersion: this.getVersion,
          isDebug: this.isDebug,
          getHeaders: this.getHeaders,
          loginSuccessful: this.loginSuccessful,
          accessDenied: this.accessDenied,
          permissionDenied: this.permissionDenied,
          setAccessToken: this.setAccessToken,
          hasAccessToken: this.hasAccessToken,
          initAuth: this.init_auth,
          initFinished: this.initFinished,
          handleApiResponse: this.handleApiResponse,
          handleError: this.handleError,
          resetErrors: this.resetErrors,
          hasErrors: this.hasErrors,
          getErrors: this.getErrors,
          updateUser: this.handle__updateUser,
          handleLogout: this.handle__logout,
          hideLoadingScreen: this.hideLoadingScreen,
          showLoadingScreen: this.showLoadingScreen,
          redirectAfterLogin: this.redirectAfterLogin,
          redirectAfterLogout: this.redirectAfterLogout,
          redirectIfNotLoggedIn: this.redirectIfNotLoggedIn,
          loading: this.loading,
          notfound: this.notfound,
          setPageTitle: this.setPageTitle,
          isSaving: this.isSaving,
          setIsSaving: this.setIsSaving,
          setSavingType: this.setSavingType,
          getSavingType: this.getSavingType,
          setSavingProgress: this.setSavingProgress,
          getSavingProgress: this.getSavingProgress,
          finishSaving: this.finishSaving,
          getFullName: this.getFullName,
          isUserLoggedIn: this.isUserLoggedIn,
          getUser: this.getUser,
          isAdmin: this.isAdmin,
          isCommunityModerator: this.isCommunityModerator,
          isChatModerator: this.isChatModerator,
          getToolTipSetting: this.getToolTipSetting,
          getEditLockTimeout: this.getEditLockTimeout,
          editLock: this.editLock,
          cancelEditLock: this.cancelEditLock,
          showMsgEditLocked: this.showMsgEditLocked,
          createNotifictation: this.createNotifictation,
          formatDateForSaving: this.formatDateForSaving,
          formatDatetimeForSaving: this.formatDatetimeForSaving,
          getGenders: this.getGenders,
          getGender: this.getGender,
          getVideoTypes: this.getVideoTypes,
          getVideoType: this.getVideoType,
          getLivecallTypes: this.getLivecallTypes,
          getLivecallType: this.getLivecallType,
          getDownloadTypes: this.getDownloadTypes,
          getDownloadType: this.getDownloadType,
          getPurchaseTypes: this.getPurchaseTypes,
          getPurchaseType: this.getPurchaseType,
          getLessonPeriodUnits: this.getLessonPeriodUnits,
          getLessonPeriodUnit: this.getLessonPeriodUnit,

          createEmptyPost: this.createEmptyPost,
          createEmptyThread: this.createEmptyThread,
          setLoadedModule: this.setLoadedModule,
          hasModule: this.hasModule,
          hasModuleAccess: this.hasModuleAccess,
          getAdminMenuItems: this.getAdminMenuItems,

          loadStylesheets: this.loadStylesheets,

          search: this.search,
          resetSearch: this.resetSearch,
          getSearchResult: this.getSearchResult,

          showQAPopup: this.showQAPopup,
        }}
      >
        <ReactNotification />
        <div>{this.props.children}</div>
      </PtContext.Provider>
    );
  }

  getApp = () => {
    const { app } = this.state;
    return app;
  };

  getAppSettings = () => {
    const { app } = this.state;
    if (app && app.settings && app.modules) {
      return app.settings;
    }
    return null;
  };

  setting = (appSettingKey, defaultValue) => {
    const { app } = this.state;
    if (app && app.settings) {
      const appSettings = app.settings;
      if (appSettings[appSettingKey]) {
        return appSettings[appSettingKey].value;
      }
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return null;
    }
  };

  settingHtml = (appSettingKey, defaultValue) => {
    const { app } = this.state;
    if (app && app.settings) {
      const appSettings = app.settings;
      if (appSettings[appSettingKey]) {
        return appSettings[appSettingKey].value_html;
      }
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return null;
    }
  };

  userSetting = (userSettingKey, defaultValue) => {
    const { user } = this.state;
    if (user && user.settings) {
      const userSettings = user.settings;
      if (userSettings[userSettingKey]) {
        return userSettings[userSettingKey].value;
      }
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return null;
    }
  };

  setUserSetting = (userSettingKey, value, callback) => {
    const apiUrl = this.apiUrl("user.settings");

    axios
      .patch(
        apiUrl,
        {
          key: userSettingKey,
          value: value,
        },
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (this.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          //const { status } = response.data
          this.handleApiResponse(response.data, true);

          const { user } = response.data.data;

          this.handle__updateUser(user);

          if (callback) {
            callback();
          }
        } catch {}
      })
      .catch((error) => {});
  };

  design = (appStyleKey, defaultValue) => {
    const { app } = this.state;
    if (app && app.design) {
      const appStyles = app.design;
      if (appStyles[appStyleKey]) {
        return appStyles[appStyleKey];
      }
    }

    if (defaultValue) {
      return defaultValue;
    } else {
      return null;
    }
  };

  translate = (path, parameters, language) => {
    if (!language) {
      language = this.state.lang;
    }

    const { app } = this.state;

    /*if (!app || !app.languages) {
      return path;
    }*/

    let result = path;
    let languageFile;

    if (app && app.languages) {
      languageFile = this.state.app.languages[language];

      result = languageFile;

      try {
        let pathSteps = path.split(".");

        pathSteps.map((step) => {
          result = result[step];
          return null;
        });
      } catch (exception) {
        result = path;
      }
    }

    if (!result || result === path || result === languageFile) {
      if (language === "de_DU") {
        languageFile = translations_de_DU;
      } else if (language === "en_EN") {
        languageFile = translations_en_EN;
      }

      result = languageFile;

      try {
        let pathSteps = path.split(".");

        pathSteps.map((step) => {
          result = result[step];
          return null;
        });
      } catch (exception) {
        result = path;
      }
    }

    if (!result || result === path || result === languageFile) {
      result = path;
      if (this.isDebug()) {
        console.log(`Missing translation: [${path}]`);
      }
    } else {
      if (parameters) {
        Object.keys(parameters).map((parameterKey) => {
          result = result.replace(`{${parameterKey}}`, parameters[parameterKey]);
          return null;
        });
      }
    }

    return result;
  };

  config = (path) => {
    let file = membermateConfig;
    //let result = this.state._config;

    let result = file;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    //console.log("config", result);

    return result;
  };

  custom = (path) => {
    let customConfig = this.state.app.custom;
    let result = customConfig;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    //console.log("custom", result);

    return result;
  };

  apiUrl = (type, params) => {
    const { apiBaseUrl } = this.state;

    const endpointPath = `apiEndpoints.${type}`;

    let url = `${apiBaseUrl}${this.config("apiVersion").replace(
      "{hostname}",
      window.location.hostname
    )}${this.config(endpointPath)}`;

    if (params) {
      Object.keys(params).map((key) => {
        url = url.replace(`{${key}}`, params[key]);
        return null;
      });
    }

    return url;
  };

  init = (initApi, title, callback) => {
    if (initApi === true) {
      let apiUrl = this.apiUrl("init");

      axios
        .get(apiUrl, { headers: this.getHeaders() })
        .then((response) => {
          if (this.isDebug()) {
            console.log("API RESPONSE [INIT]", response.data);
          }

          this.handleApiResponse(response.data, true);

          const { app } = response.data;
          this.setState({ app }, () => {
            if (callback) {
              callback();
            } else {
              if (this.state.initFinished === true) {
                window.setTimeout(this.hideLoadingScreen, 500);
              }
            }
          });
        })
        .catch((error) => {
          //
        });
    }

    if (title) {
      this.setPageTitle(title, true);
    }
  };

  initTracking = () => {
    const { env } = this.state;

    const googleAnalyticsId = this.setting("tracking_ga");
    const facebookPixelId = this.setting("tracking_fbpixel");

    //console.log("ga", googleAnalyticsId);
    //console.log("fb", facebookPixelId);

    if (env === "prod") {
      if (googleAnalyticsId) {
        ReactGA.initialize(googleAnalyticsId, {
          debug: false,
          gaOptions: {
            siteSpeedSampleRate: 100,
            anonymizeIp: true,
          },
        });
        ReactGA.pageview(window.location.pathname + window.location.search);
      }

      if (facebookPixelId) {
        const advancedMatching = {};
        const options = {
          autoConfig: true, // set pixel's autoConfig
          debug: false, // enable logs
        };
        ReactPixel.init(facebookPixelId, advancedMatching, options);

        ReactPixel.pageView();
      }
    }
  };

  getVersion = () => {
    const version = this.state.version;
    return version;
  };

  isDebug = () => {
    const { debug } = this.state;
    if (debug === true) {
      return true;
    }
    return false;
  };

  getHeaders = () => {
    const { headers } = this.state;
    return headers;
  };

  handleApiResponse = (apiResponse, finishInit, callback) => {
    const { user, app } = apiResponse;

    if (user) {
      if (user.active === false) {
        localStorage.removeItem("access_token");
        window.location = this.state.redirectIfInactive;
      } else {
        this.setState({ user });
      }
    }

    if (app) {
      this.setState({ app }, () => {
        // Check if loaded Module is in license
        const { loadedModule } = this.state;
        if (loadedModule) {
          //console.log("loadedModule", loadedModule);
          //console.log("modules", app.modules);
          if (!this.hasModuleAccess(loadedModule)) {
            finishInit = false;
            window.location.href = this.config("redirect.permissionDenied");
          }
        }

        this.updatePageTitle();

        // Tracking
        this.initTracking();

        if (finishInit === true) {
          this.initFinished();
        }
      });
    }

    if (callback) {
      callback();
    }
  };

  handleError = (errorObject, action) => {
    let errorResponse, errorStatusCode, errorMessage;

    if (errorObject.response) {
      // If errorObject is axios ErrorObject
      errorResponse = errorObject.response;
      if (errorResponse.status) {
        errorStatusCode = errorResponse.status;
      }
    } else if (errorObject.status && errorObject.message) {
      // Else if errorObject is ApiResponse from Laravel API
      errorStatusCode = errorObject.status;
      errorMessage = errorObject.message;
    }

    if (!action) {
      action = "[unknown]";
    }

    const now = this.getErrorTimestamp(new Date());

    this.resetErrors();

    // UNAUTHENTICATED
    if (errorStatusCode === 401) {
      this.accessDenied();
    } else if (errorStatusCode === 403) {
      this.permissionDenied();
    } else if (errorStatusCode === 500) {
      // INTERNAL SERVER ERROR (SOMETHING WITH THE API)

      if (!errorMessage) {
        errorMessage = "API Server Error. Bitte Support informieren. Herzlichen Dank!";
      }

      const error = {
        instance: this.custom("instance"),
        status: "500 INTERNAL SERVER ERROR",
        timestamp: now,
        action: action,
        message: errorMessage,
      };
      this.addError(error);
    } else if (errorStatusCode === 404) {
      // 404 URL ON API SERVER NOT FOUND

      if (!errorMessage) {
        errorMessage = "Server Connection Error. Der Server ist aktuell nicht erreichbar.";
      }

      const error = {
        instance: this.custom("instance"),
        status: "404 SERVER NOT FOUND",
        timestamp: now,
        action: action,
        message: errorMessage,
      };
      this.addError(error);
    } else if (errorStatusCode === "AXIOS_RESPONSE_ERROR") {
      const error = {
        instance: this.custom("instance"),
        status: "DATABASE CONNECTION ERROR",
        timestamp: now,
        action: action,
        message: "Es ist ein Fehler bei der Datenbankverbindung aufgetreten.",
      };
      this.addError(error);
    } else {
      const error = {
        instance: this.custom("instance"),
        status: "UNEXPECTED ERROR",
        timestamp: now,
        action: action,
        message: "Ein unerwarteter Fehler ist aufgetreten.",
      };
      this.addError(error);
    }
  };

  getErrorTimestamp(dateObject) {
    let dateString = `${dateObject.getFullYear()}`;

    if (dateObject.getMonth() < 9) {
      dateString = `${dateString}-0${dateObject.getMonth()}`;
    } else {
      dateString = `${dateString}-${dateObject.getMonth()}`;
    }

    if (dateObject.getDate() < 10) {
      dateString = `${dateString}-0${dateObject.getDate()}`;
    } else {
      dateString = `${dateString}-${dateObject.getDate()}`;
    }

    dateString = `${dateString}@`;

    if (dateObject.getHours() < 10) {
      dateString = `${dateString}0${dateObject.getHours()}`;
    } else {
      dateString = `${dateString}${dateObject.getHours()}`;
    }

    if (dateObject.getMinutes() < 10) {
      dateString = `${dateString}:0${dateObject.getMinutes()}`;
    } else {
      dateString = `${dateString}:${dateObject.getMinutes()}`;
    }

    if (dateObject.getSeconds() < 10) {
      dateString = `${dateString}:0${dateObject.getSeconds()}`;
    } else {
      dateString = `${dateString}:${dateObject.getSeconds()}`;
    }

    dateString = `${dateString}.${dateObject.getMilliseconds()}`;

    return dateString;
  }

  addError = (error) => {
    let { errors } = this.state;
    errors.push(error);
    this.setState({ errors });
  };

  resetErrors = () => {
    this.setState({ errors: [] });
  };

  hasErrors = () => {
    let { errors } = this.state;

    errors = hasToBeArray(errors);

    if (errors.length > 0) {
      return true;
    } else {
      return false;
    }
  };

  getErrors = () => {
    let { errors } = this.state;

    if (this.hasErrors) {
      return errors;
    } else {
      return null;
    }
  };

  loginSuccessful = (accessToken) => {
    this.setAccessToken(accessToken);
    const recdirect = this.config("redirect.afterLogin");
    window.location.href = recdirect;
  };

  accessDenied = () => {
    const redirect = this.config("redirect.accessDenied");
    window.location.href = redirect;
  };

  permissionDenied = () => {
    const redirect = this.config("redirect.permissionDenied");
    window.location.href = redirect;
  };

  setAccessToken = (accessToken) => {
    localStorage.setItem("access_token", accessToken);
    return accessToken;
  };

  hasAccessToken = () => {
    if (localStorage.getItem("access_token")) {
      return true;
    }
    return false;
  };

  handle__updateUser = (user, callback) => {
    if (user) {
      if (user.active === false) {
        localStorage.removeItem("access_token");
        window.location = this.state.redirectIfInactive;
      } else {
        this.setState({ user });
        if (callback) {
          callback();
        }
      }
    }
  };

  handle__logout = () => {
    localStorage.removeItem("access_token");
    window.location = this.state.redirectAfterLogout;
  };

  handle__updateAccessToken = () => {
    const { headers } = this.state;
    headers.Authorization = "Bearer " + localStorage.getItem("access_token");
    this.setState({ headers: headers });
  };

  initFinished = () => {
    if (this.getAppSettings()) {
      this.setState({ initFinished: true });
      this.hideLoadingScreen();
    } else {
      this.setState({ initFinished: true });
    }
  };

  hideLoadingScreen = () => {
    setTimeout(this.doHideLoadingScreen, 500);
  };

  doHideLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "none";
  };

  showLoadingScreen = () => {
    document.getElementById("loadingScreen").style.display = "block";
  };

  redirectAfterLogin = () => {
    window.location = this.state.redirectAfterLogin;
  };

  redirectAfterLogout = () => {
    window.location = this.state.redirectAfterLogout;
  };

  redirectIfNotLoggedIn = () => {
    window.location = this.state.redirectIfNotLoggedIn;
  };

  loading = (isError) => {
    if (isError === true) {
      return (
        <div className="loading">
          <p>
            <Icon icon="exclamation-triangle" />
          </p>
          <div>
            <strong>Fehler beim Laden.</strong>
            <br />
            Bitte später erneut versuchen!
          </div>
        </div>
      );
    } else {
      return (
        <div className="loading">
          <p>
            <Icon icon="spin fa-circle-o-notch" />
          </p>
        </div>
      );
    }
  };

  notfound = (message) => {
    let text = "Eintrag nicht gefunden.";
    if (message) {
      text = message;
    }

    return (
      <div className="loading">
        <p>
          <Icon icon="question-circle-o" />
        </p>
        <div>
          <strong>{text}</strong>
          <br />
          Bitte gehe zurück und wähle einen anderen Eintrag.
        </div>
      </div>
    );
  };

  setPageTitle = (title, doUpdate) => {
    this.setState({ pageTitle: title }, () => {
      if (doUpdate === true) {
        this.updatePageTitle();
      }
    });
  };

  // Set Page Title after loading app.settings
  updatePageTitle = () => {
    const { pageTitle } = this.state;
    const memberareaTitle = this.setting("pagetitle");
    //console.log("pagetitle: ", pageTitle);
    //console.log("memberareatitle", memberareaTitle);
    document.title = `${this.translate(pageTitle)} | ${memberareaTitle}`;
  };

  /* SAVING MODAL */
  isSaving = () => {
    const { isSaving } = this.state;
    return isSaving;
  };

  setIsSaving = (value, time) => {
    if (value === true || value === false) {
      this.setState({ isSaving: value }, () => {
        if (parseInt(time) > 0) {
          this.startSavingTimer(time);
        }
      });
    }
  };

  startSavingTimer(time) {
    time = parseInt(time);
    const timeFactor = 0.5;
    const percentPerStep = 100 / (time / timeFactor); // (100% / seconds)
    //console.log(percentPerStep);
    let percent = 5;
    this.setSavingProgress(percent);

    let savingTimer = window.setInterval(() => {
      if (this.getSavingProgress() < 100) {
        percent += percentPerStep;
        //console.log(percent);
        this.setSavingProgress(percent);
      }

      if (percent + percentPerStep >= 100) {
        clearInterval(savingTimer);
      }
    }, 1000 * timeFactor);
  }

  finishSaving = () => {
    this.setSavingProgress(100);
  };

  setSavingType = (type) => {
    this.setState({ savingType: type });
  };

  getSavingType = () => {
    const { savingType } = this.state;
    return savingType;
  };

  setSavingProgress = (percent) => {
    this.setState({ savingProgress: percent });
  };

  getSavingProgress = () => {
    const { savingProgress } = this.state;
    return savingProgress;
  };

  isUserLoggedIn = () => {
    const { user } = this.state;
    let isLoggedIn = false;

    if (user && user.id && user.email) {
      isLoggedIn = true;
    }

    return isLoggedIn;
  };

  getUser = () => {
    const { user } = this.state;
    if (this.isUserLoggedIn() === true) {
      return user;
    } else {
      return null;
    }
  };

  getFullName = (givenUser) => {
    let { user } = this.state;
    let userName = "unbekannt";

    if ((!user || !user.id) && !givenUser) {
      return "";
    } else if (givenUser) {
      user = givenUser;
    }

    if (user.displayname) {
      return user.displayname;
    }

    if (user.firstname && user.lastname) {
      userName = `${user.firstname} ${user.lastname}`;
    } else if (user.firstname && !user.lastname) {
      userName = user.firstname;
    } else if (user.lastname && !user.firstname) {
      if (user.gender) {
        if (user.gender === "m") {
          userName = `Herr ${user.lastname}`;
        } else if (user.gender === "f") {
          userName = `Frau ${user.lastname}`;
        }
      } else {
        userName = `Frau/Herr ${user.lastname}`;
      }
    }

    return userName;
  };

  isAdmin = () => {
    const { user } = this.state;

    if (user && user.admin && user.admin.is_admin && user.admin.is_admin === true) {
      return true;
    }

    return false;
  };

  isCommunityModerator = () => {
    const { user } = this.state;

    if (
      user &&
      user.admin &&
      user.admin.is_admin &&
      user.admin.is_admin === true &&
      user.admin.is_community_moderator &&
      user.admin.is_community_moderator === true
    ) {
      return true;
    }

    return false;
  };

  isChatModerator = () => {
    const { user } = this.state;

    if (
      user &&
      user.admin &&
      user.admin.is_admin &&
      user.admin.is_admin === true &&
      user.admin.is_community_moderator &&
      user.admin.is_community_moderator === true
    ) {
      return true;
    }

    return false;
  };

  getToolTipSetting = (type) => {
    if (type === "position") {
      return this.config("tooltips.position");
    } else if (type === "type") {
      return this.config("tooltips.type");
    } else if (type === "effect") {
      return this.config("tooltips.effect");
    }
  };

  getEditLockTimeout = () => {
    const { editLock } = this.state;
    return editLock.timeout;
  };

  editLock = async (type, entry_id) => {
    let axiosResult = await axios
      .post(
        this.apiUrl("admin.editLock") + "/" + type + "/" + entry_id,
        {},
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;

          editLock.result.type = type;
          editLock.result.entry_id = entry_id;

          if (response.data.status === "SUCCESS") {
            editLock.result.status = "SUCCESS";
          } else if (response.data.status === "LOCKED") {
            editLock.result.status = "LOCKED";
            editLock.result.name = response.data.name;
          }
          this.setState({ editLock }, () => {
            if (response.data.status === "SUCCESS") {
              let intervalId = window.setInterval(
                this.setEditLockTimer,
                this.getEditLockTimeout() * 1000
              );
              editLock.result.intervalId = intervalId;
              this.setState({ editLock });
            }
          });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
          return editLock.result;
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert("Admin Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren.");
          return editLock.result;
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });

    //console.log("axiosresult", axiosResult);

    return axiosResult;
  };

  setEditLockTimer = () => {
    const { editLock } = this.state;

    this.updateEditLock(editLock.result.type, editLock.result.entry_id);
  };

  updateEditLock = (type, entry_id) => {
    axios
      .post(
        this.apiUrl("admin.editLock") + "/" + type + "/" + entry_id,
        {},
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;

          if (response.data.status === "SUCCESS") {
            editLock.result.status = "SUCCESS";
            editLock.result.name = response.data.name;
          } else if (response.data.status === "LOCKED") {
            editLock.result.status = "LOCKED";
            editLock.result.name = response.data.name;
          }
          this.setState({ editLock });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert("Admin Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren.");
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  cancelEditLock = () => {
    const { editLock } = this.state;

    window.clearInterval(editLock.result.intervalId);

    axios
      .delete(
        this.apiUrl("admin.editLock") + "/" + editLock.result.type + "/" + editLock.result.entry_id,
        {
          headers: this.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { editLock } = this.state;
          editLock.result.status = "UNKWOWN";
          editLock.result.name = undefined;

          this.setState({ editLock });

          if (this.isDebug()) {
            console.log("RESPONSE DATA", response.data);
          }
        } catch {
          const { editLock } = this.state;
          editLock.result.status = "ERROR";
          this.setState({ editLock });
          alert(
            "Admin Cancel Edit Lock - Verbindung zum Server fehlgeschlagen. Bitte erneut probieren."
          );
        }
      })
      .catch((error) => {
        if (this.isDebug()) {
          console.log("Error", error);
        }
      });
  };

  getEditLockResult = () => {
    const { editLock } = this.state;
    return editLock.result;
  };

  showMsgEditLocked = (message) => {
    let text = "Dieser Eintrag wird gerade bearbeitet.";
    if (message) {
      text = message;
    }

    return (
      <div className="warning callout text-center">
        <strong>
          <Icon icon="exclamation-triangle" left />
          {text}
        </strong>
      </div>
    );
  };

  createNotifictation = (title, message, type, position, show) => {
    if (!title) {
      title = null;
    }

    if (!message) {
      message = null;
    }

    if (!type) {
      type = "info";
    }

    if (!position) {
      position = "top-right";
    }

    if (!show) {
      show = 5;
    }

    store.addNotification({
      title: title,
      message: message,
      type: type,
      insert: "top",
      container: position,
      animationIn: ["animated", "fadeIn"],
      animationOut: ["animated", "fadeOut"],
      dismiss: {
        duration: show * 1000,
        onScreen: false,
      },
    });
  };

  formatDateForSaving = (dateObj) => {
    if (dateObj) {
      try {
        return (
          ("0" + dateObj.getDate()).slice(-2) +
          "." +
          ("0" + (dateObj.getMonth() + 1)).slice(-2) +
          "." +
          dateObj.getFullYear()
        );
      } catch {
        try {
          dateObj = new Date(dateObj);
          return (
            ("0" + dateObj.getDate()).slice(-2) +
            "." +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "." +
            dateObj.getFullYear()
          );
        } catch {
          return dateObj;
        }
      }
    }
  };

  formatDatetimeForSaving = (dateObj) => {
    if (dateObj) {
      try {
        return (
          ("0" + dateObj.getDate()).slice(-2) +
          "." +
          ("0" + (dateObj.getMonth() + 1)).slice(-2) +
          "." +
          dateObj.getFullYear() +
          " - " +
          ("0" + dateObj.getHours()).slice(-2) +
          ":" +
          ("0" + dateObj.getMinutes()).slice(-2)
        );
      } catch {
        try {
          dateObj = new Date(dateObj);
          return (
            ("0" + dateObj.getDate()).slice(-2) +
            "." +
            ("0" + (dateObj.getMonth() + 1)).slice(-2) +
            "." +
            dateObj.getFullYear() +
            " - " +
            ("0" + dateObj.getHours()).slice(-2) +
            ":" +
            ("0" + dateObj.getMinutes()).slice(-2)
          );
        } catch {
          return dateObj;
        }
      }
    }
  };

  getGenders = () => {
    const { genders } = this.state;
    return genders;
  };

  getGender = (gender) => {
    const { genders } = this.state;

    if (genders[gender]) {
      return genders[gender];
    } else {
      return gender;
    }
  };

  getVideoTypes = () => {
    const videoTypes = this.state.videoTypes;

    if (!this.hasModule("videostorage")) {
      delete videoTypes.upload;
    }

    return videoTypes;
  };

  getVideoType = (videoType) => {
    const { videoTypes } = this.state;

    if (videoTypes[videoType]) {
      if (videoType === "upload" && !this.hasModule("videostorage")) {
        return videoType;
      } else {
        return videoTypes[videoType];
      }
    } else {
      return videoType;
    }
  };

  getLivecallTypes = () => {
    const livecallTypes = this.config("livecallTypes");
    return livecallTypes;
  };

  getLivecallType = (type) => {
    const livecallTypes = this.config("livecallTypes");
    if (livecallTypes[type]) {
      return (
        <span>
          <Icon icon={livecallTypes[type].icon} left /> {livecallTypes[type].title}
        </span>
      );
    } else {
      return type;
    }
  };

  getDownloadTypes = (type) => {
    const tmpDownloadTypes = this.config("downloadTypes");
    let downloadTypes = {
      null: "",
    };

    // For Select Values
    if (type === "select") {
      Object.keys(tmpDownloadTypes).map((downloadTypeKey) => {
        downloadTypes[downloadTypeKey] = tmpDownloadTypes[downloadTypeKey].title;
        return null;
      });
    } else {
      downloadTypes = tmpDownloadTypes;
    }

    return downloadTypes;
  };

  getDownloadType = (type) => {
    const downloadTypes = this.config("downloadTypes");
    if (downloadTypes[type]) {
      return (
        <span className="small primary label download-icon">
          <Icon icon={downloadTypes[type].icon} left /> {downloadTypes[type].title}
        </span>
      );
    } else {
      if (type === null || type === "null") {
        return null;
      } else {
        return type;
      }
    }
  };

  getPurchaseTypes = () => {
    const purchaseTypes = this.config("purchaseTypes");
    return purchaseTypes;
  };

  getPurchaseType = (type) => {
    const purchaseTypes = this.config("purchaseTypes");
    if (purchaseTypes[type]) {
      return purchaseTypes[type].title;
    } else {
      return type;
    }
  };

  getLessonPeriodUnits = () => {
    const lessonPeriodUnits = this.config("lessonPeriodUnits");
    return lessonPeriodUnits;
  };

  getLessonPeriodUnit = (type) => {
    const lessonPeriodUnits = this.config("lessonPeriodUnits");
    if (lessonPeriodUnits[type]) {
      return lessonPeriodUnits[type].title;
    } else {
      return type;
    }
  };

  createEmptyPost = (thread) => {
    let threadId;
    if (thread && thread.id) {
      threadId = thread.id;
    } else {
      threadId = "new";
    }

    const post = {
      id: "new",
      thread_id: threadId,
      author_id: this.getUser().id,
      text: "",
      text_html: "",
      files: [],
      filesFiles: [],
    };

    return post;
  };

  createEmptyThread = (board) => {
    const thread = {
      id: "new",
      board_id: board.id,
      author_id: this.getUser().id,
      name: "",
    };

    return thread;
  };

  setLoadedModule = (module) => {
    this.setState({ loadedModule: module });
  };

  hasModule = (module) => {
    const { app } = this.state;

    let hasModule = false;

    if (app.modules && app.modules.includes(module)) {
      hasModule = true;
    }

    return hasModule;
  };

  userHasModuleAccess = (module) => {
    const { user, lockableModules } = this.state;

    let hasModule = false;

    if (!lockableModules.includes(module)) {
      hasModule = true;
    } else if (
      lockableModules.includes(module) &&
      user.moduleaccess &&
      user.moduleaccess.includes(module)
    ) {
      hasModule = true;
    }

    return hasModule;
  };

  hasModuleAccess = (module) => {
    const hasModule = this.hasModule(module);
    const hasModuleAccess = this.userHasModuleAccess(module);

    if (hasModule === true && hasModuleAccess === true) {
      return true;
    } else {
      return false;
    }
  };

  getAdminMenuItems = () => {
    const tmpAdminMenuItems = this.state.adminMenuItems;

    let adminMenuItems = [];

    tmpAdminMenuItems.map((menuItem) => {
      let showItem = true;

      if (menuItem.module) {
        if (!this.hasModuleAccess(menuItem.module)) {
          showItem = false;
        }
      }

      if (showItem === true) {
        adminMenuItems.push(menuItem);
      }

      return null;
    });

    return adminMenuItems;
  };

  loadStylesheets = (preview) => {
    /*if (this.state.env !== "prod") {
      //console.log("DEV: External stylesheet ignored.");
      return;
    }
    */

    // If embedded in an iframe
    if (window.location !== window.parent.location) {
      preview = true;
      window.top.postMessage(
        JSON.stringify({
          url: `${window.location.href}`,
        }),
        "*"
      );
    }

    let mainStylesheet = this.config("stylesheets.main");
    if (preview === true) {
      mainStylesheet = this.config("stylesheets.preview");
    }

    mainStylesheet = mainStylesheet
      .replace("{host}", this.state.apiBaseUrl)
      .replace("{hostname}", window.location.hostname);

    return (
      <React.Fragment>
        <link rel="stylesheet" href={mainStylesheet} />
      </React.Fragment>
    );
  };

  search = (s, options) => {
    if (!options) {
      options = [];
    }

    let apiUrl = this.apiUrl("search") + "?s=" + s + "&options=" + JSON.stringify(options);

    axios
      .get(apiUrl, {
        headers: this.getHeaders(),
      })
      .then((response) => {
        try {
          if (this.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          this.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { search } = response.data.data;
            this.setState({ search });
          }
        } catch {
          return this.handleError({ status: "AXIOS RESPONSE ERROR" }, "search:response");
        }
      })
      .catch((error) => {
        if (error.response) {
          return this.handleError(error, "search");
        }
      });
  };

  resetSearch = () => {
    this.setState({ search: null });
  };

  getSearchResult = () => {
    return this.state.search;
  };

  showQAPopup = (value) => {
    if (value === true || value === false) {
      this.setState({ showQAPopup: value });
    }

    const { showQAPopup } = this.state;
    return showQAPopup;
  };
}
