import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Icon } from "../../../_foundation/foundation";
import { Link } from "react-router-dom";

class PasswordForgottenForm extends Component {
  state = {
    _updatableProps: ["status", "show", "input"],
    status: this.props.status,
    show: this.props.show,
    input: this.props.input,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { show, input } = this.state;
    const { email } = input;
    const _c = this.props.context;

    if (!show) {
      return null;
    }

    return (
      <React.Fragment>
        <label>
          {_c.translate("auth.login.form.fields.email.label")}
          <input
            type="email"
            placeholder={_c.translate(
              "auth.login.form.fields.email.placeholder"
            )}
            value={email}
            onChange={(e) => {
              this.props.onUpdateInputChange("email", e.target.value);
            }}
            onKeyUp={(e) => {
              if (e.keyCode === 13) {
                this.props.onAttemptReset();
              }
            }}
          />
        </label>

        <div className="login-buttons">
          <Link to="/login" className="login-forgotten-backlink">
            <Icon icon="angle-left" left />
            {_c.translate("auth.login.form.buttons.backToLogin.label")}{" "}
          </Link>
          <button
            className="primary button"
            onClick={this.props.onAttemptReset}
          >
            {_c.translate("auth.login.form.buttons.forgotten.label")}{" "}
            <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withContext(PasswordForgottenForm);
