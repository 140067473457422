import React, { useContext, useEffect, useState } from "react";
import PageBuilderContext from "../../../../context/PageBuilderContext";
import { Grid, Row } from "../../../_foundation/_grid";
import PageColumn from "./PageColumn";
import PageItemName from "./PageItemName";

function PageRow(props) {
  const _p = useContext(PageBuilderContext);
  const [row, setRow] = useState(props.row);

  useEffect(() => {
    setRow(props.row);
  }, [props.row]);

  return (
    <div className="pagebuilder-item pagebuilder-row">
      <PageItemName
        type="row"
        item={row}
        onEditButton={() => props.actions.onSelectRow(row)}
        onColumnsButton={() => {
          props.actions.onSelectRowColumnSchema(row);
        }}
        onDuplicateButton={() => props.actions.onDuplicateItem("row", row)}
        onDeleteButton={() => props.actions.onDelete("row", row)}
        onMoveButton={(direction) =>
          props.actions.onMoveItem(row.identifier, direction)
        }
        listeners={props.listeners}
        attributes={props.attributes}
      />
      <div className="row-content">
        {/*[ROW {row.identifier}] [{row.position}]*/}
        {_p.isDragging && _p.draggingType === "row" ? null : (
          <div className="pagebuilder-columns">
            <Grid type="full">
              <Row margin="xy">
                {row.columns.map((column) => {
                  return (
                    <PageColumn
                      column={column}
                      key={column.identifier}
                      structure={props.structure}
                      actions={props.actions}
                    />
                  );
                })}
              </Row>
            </Grid>
          </div>
        )}
      </div>
    </div>
  );
}

export default PageRow;
