import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";
import Video from "../parts/Video";
import LessonList from "../parts/LessonList";
import LessonNotes from "../parts/LessonNotes";
import Description from "../parts/Description";

class Layout2 extends Component {
  state = {
    _updatableProps: [
      "course",
      "lesson",
      "lessons",
      "downloads",
      "status",
      "showNotePopup",
      "selectedNote",
      "player",
      "showLessonListOnMobile",
    ],
    course: this.props.course,
    lesson: this.props.lesson,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    status: this.props.status,
    showNotePopup: this.props.showNotePopup,
    selectedNote: this.props.selectedNote,
    player: this.props.player,
    showLessonListOnMobile: this.props.showLessonListOnMobile,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const {
      course,
      lesson,
      lessons,
      downloads,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
    } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>{this.view__showTitle()}</Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={7}>
              <LessonList
                course={course}
                lesson={lesson}
                lessons={lessons}
                downloads={downloads}
                status={status}
                showLessonListOnMobile={showLessonListOnMobile}
                closeLessonListOnMobile={() => {
                  this.setState({ showLessonListOnMobile: false }, () => {
                    this.onUpdateLayout();
                  });
                }}
              />
            </Cell>
            <Cell sm={24} md={17}>
              <Video
                course={course}
                lesson={lesson}
                status={status}
                player={player}
                onUpdate={(lessonStatus) => {
                  const { lesson } = this.state;
                  lesson.lessonStatus = lessonStatus;
                  this.setState({ lesson }, () => {
                    this.onUpdateLayout();
                  });
                }}
                onUpdatePlayer={(player) => {
                  this.setState({ player }, () => {
                    this.onUpdateLayout();
                  });
                }}
              />
              <Description lesson={lesson} />
              <div className="course-lesson-below-video-buttons">
                <button
                  className="primary hollow button show-for-small-only"
                  onClick={() => {
                    this.setState({ showLessonListOnMobile: true }, () => {
                      this.onUpdateLayout();
                    });
                  }}
                >
                  <Icon icon="th-list" left />{" "}
                  {_c.translate("courses.lesson.lessons")}
                </button>
                <button
                  className="primary button"
                  onClick={() => {
                    this.setState(
                      {
                        showNotePopup: true,
                        selectedNote: "new",
                      },
                      () => {
                        this.onUpdateLayout();
                      }
                    );
                  }}
                >
                  <Icon icon="sticky-note" left />{" "}
                  {_c.translate("courses.lesson.notes.addNote")}
                </button>
              </div>
              <LessonNotes
                lesson={lesson}
                showNotePopup={showNotePopup}
                selectedNote={selectedNote}
                onNoteSave={(lesson) => {
                  this.setState({ lesson }, () => {
                    this.onUpdateLayout();
                  });
                }}
                onGoToSecond={(seconds) => {
                  const { player } = this.state;
                  player.setCurrentTime(seconds);
                  this.setState({ player }, () => {
                    this.onUpdateLayout();
                  });
                }}
                onPopupClose={(showNotePopup, selectedNote) => {
                  this.setState({ showNotePopup, selectedNote }, () => {
                    this.onUpdateLayout();
                  });
                }}
              />
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showTitle() {
    const { course, lesson } = this.state;

    if (!course || !lesson) {
      return;
    }

    let pretitle, title;

    if (course.name) {
      pretitle = <div className="course-lesson-pretitle">{course.name}</div>;
    }

    if (lesson.name) {
      title = <h1>{lesson.name}</h1>;
    }

    return (
      <header>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={18} mdo={6}>
              <div className="padding">
                {pretitle}
                {title}
              </div>
            </Cell>
          </Row>
        </Grid>
      </header>
    );
  }

  onUpdateLayout = () => {
    const {
      course,
      lesson,
      lessons,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
    } = this.state;

    this.props.onUpdateLayout(
      course,
      lesson,
      lessons,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile
    );
  };
}

export default withContext(Layout2);
