import React from "react";
import PageViewHelper from "../PageViewHelper";

function ElementText({ element }) {
  return (
    <>
      <div style={PageViewHelper.applyOptions(element)}>
        <div dangerouslySetInnerHTML={{ __html: element.content_html }} />
      </div>
    </>
  );
}

export default ElementText;
