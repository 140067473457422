import React, { useEffect, useState } from "react";
import { Icon } from "../../../../_foundation/_buttons";

function SettingsSection(props) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  const toggleSection = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="pagebuilder-settings-section">
      <div className="settings-section-title">
        <button onClick={toggleSection}>
          <h3>
            {props.title}{" "}
            <span className="caret">
              <Icon icon={isOpen ? `caret-down` : `caret-right`} />
            </span>
          </h3>
        </button>
      </div>
      <div
        className="section-section-content"
        style={{ display: isOpen ? `block` : `none` }}
      >
        {props.children}
      </div>
    </div>
  );
}

export default SettingsSection;
