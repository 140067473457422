import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import { Link } from "react-router-dom";

class AdminQuestion extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_questions"],
    questions: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="questions" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              <h1>Q&A Fragen</h1>
            </Cell>
            <Cell
              sm={24}
              md={12}
              className="text-center medium-text-right"
            ></Cell>
          </Row>
        </Grid>
        {this.view__showListTitle()}
        {this.view__showQuestions()}
      </AdminLayout>
    );
  }

  view__showListTitle() {
    return (
      <div className="admin-list-title hide-for-small-only">
        <Grid type="full">
          <Row>
            <Cell md={6}>
              <div className="padding">Datum</div>
            </Cell>
            <Cell md={6}>
              <div className="padding">Verfasser</div>
            </Cell>
            <Cell md={12}>
              <div className="padding">Frage</div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showQuestions() {
    const { questions } = this.state;
    const _c = this.props.context;

    if (!questions) {
      return null;
    }

    return (
      <div className="admin-questions">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <ul className="striped admin-list">
                {questions.map((question, index) => {
                  let v__author = <em>anonym</em>;
                  if (
                    question.author &&
                    question.author.displayname &&
                    question.author.id
                  ) {
                    v__author = (
                      <Link
                        className="link"
                        style={{ textDecoration: "underline" }}
                        to={_c
                          .config("urls.admin.member")
                          .replace(":id", question.author.id)}
                      >
                        {question.name}
                      </Link>
                    );
                  }

                  return (
                    <li className="admin-list-item" key={index}>
                      <Grid type="full">
                        <Row>
                          <Cell md={6}>
                            <div className="padding">
                              {question.created_at_f}
                            </div>
                          </Cell>
                          <Cell md={6}>
                            <div className="padding">{v__author}</div>
                          </Cell>
                          <Cell md={12}>
                            <div className="padding">{question.question_f}</div>
                          </Cell>
                        </Row>
                      </Grid>
                    </li>
                  );
                })}
              </ul>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.questions");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { questions } = apiResponseData;
            this.setState({
              questions,
            });

            _c.setPageTitle("Q&A Fragen", true);
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:questions:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:questions:init");
      });
  }
}

export default withContext(AdminQuestion);
