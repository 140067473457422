import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import ReactTooltip from "react-tooltip";
import { PtContext } from "../../../context/ptProvider";
import ConfigHelper from "../../../helpers/ConfigHelper";
import UrlHelper from "../../../helpers/UrlHelper";
import { Icon } from "../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import Loading from "../../_reusables/Loading";
import AdminLayout from "../AdminLayout";
import Builder from "./Builder";
import PageBuilderAPI from "./PageBuilderAPI";
import PageSettings from "./PageSettings";

function Page(props) {
  const pageIdentifier = props.match.params.identifier;

  const _c = useContext(PtContext);

  const [pageTitle, setPageTitle] = useState("");
  const [page, setPage] = useState(null);
  const [extra, setExtra] = useState(null);

  const [showPageSettings, setShowPageSettings] = useState(false);
  const [unsavedChanges, setUnsavedChanges] = useState(false);

  useEffect(() => {
    if (!page) {
      PageBuilderAPI.getPage(pageIdentifier, _c, ({ page, extra }) => {
        setExtra(extra);
        updatePageSettings(page);
        if (page.identifier === "new") setShowPageSettings(true);
      });
    }
  }, []);

  const updatePageSettings = (updatedPage) => {
    setPage(cloneDeep(updatedPage));
    setPageTitle(updatedPage.name);
    _c.setPageTitle(updatedPage.name, true);
  };

  const onStructureUpdate = (structure) => {
    page.structure = cloneDeep(structure);
    setPage(cloneDeep(page));
  };

  const onCancel = () => {
    if (
      !unsavedChanges ||
      (unsavedChanges &&
        window.confirm(
          "Möchtest Du wirklich abbrechen? Sämtliche Änderungen gehen dann unwiderruflich verloren."
        ))
    )
      window.location.href = UrlHelper.url("admin.pagebuilder.pages");
  };

  const onSave = () => {
    PageBuilderAPI.savePage(page.identifier, page, _c, ({ page }) => {
      if (page && page.identifier && page.identifier !== "new")
        window.location.href = UrlHelper.url("admin.pagebuilder.page", {
          identifier: page.identifier,
        });
    });
  };

  const onDelete = () => {
    if (
      window.confirm(
        "Möchtest Du diese Seite wirklich unwiderruflich löschen?\r\n\r\nDIESE AKTION KANN NICHT RÜCKGÄNGIG GEMACHT WERDEN."
      )
    ) {
      PageBuilderAPI.deletePage(page.identifier, _c, () => {
        window.location.href = UrlHelper.url("admin.pagebuilder.pages");
      });
    }
  };

  const onClone = () => {
    if (window.confirm("Möchtest Du diese Seite wirklich duplizieren?")) {
      PageBuilderAPI.clonePage(page.identifier, _c, (clonedPageIdentifier) => {
        window.location.href = UrlHelper.url("admin.pagebuilder.page", {
          identifier: clonedPageIdentifier,
        });
      });
    }
  };

  if (!page)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="admin-pagebuilder hide-nav">
      <AdminLayout nav="pagebuilder">
        <ReactTooltip
          place={ConfigHelper.get("tooltips.position")}
          type={ConfigHelper.get("tooltips.type")}
          effect={ConfigHelper.get("tooltips.effect")}
        />
        <Buttons
          page={page}
          onCancel={onCancel}
          onSave={onSave}
          onDelete={onDelete}
          onClone={onClone}
          setShowPageSettings={setShowPageSettings}
        />
        <h1>
          {pageTitle}{" "}
          {/*pageIdentifier !== "new" ? (
            <button
              onClick={() => setShowPageSettings(true)}
              className="pagebuilder-pagesettings-icon"
              data-tip="Seiteneinstellungen"
            >
              <Icon icon="cog" />
            </button>
          ) : null*/}
        </h1>
        <hr />

        {showPageSettings === true ? (
          <PageSettings
            page={page}
            extra={extra}
            unsavedChanges={unsavedChanges}
            setUnsavedChanges={setUnsavedChanges}
            onUpdate={(updatedPage) => {
              updatePageSettings(updatedPage);
              setUnsavedChanges(true);
            }}
            onClose={() => setShowPageSettings(false)}
          />
        ) : null}
        <Builder
          page={page}
          extra={extra}
          unsavedChanges={unsavedChanges}
          setUnsavedChanges={setUnsavedChanges}
          onStructureUpdate={onStructureUpdate}
        />
        <Buttons
          page={page}
          onCancel={onCancel}
          onSave={onSave}
          setShowPageSettings={setShowPageSettings}
        />
      </AdminLayout>
    </div>
  );
}

const Buttons = ({ page, onCancel, onSave, onDelete, onClone, setShowPageSettings }) => {
  return (
    <div style={{ marginBottom: "20px" }}>
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={14} className="text-center medium-text-left">
            <button className="primary hollow button" onClick={onCancel}>
              <Icon icon="arrow-left" left /> Zurück
            </button>{" "}
            <button
              onClick={() => setShowPageSettings(true)}
              className="primary button"
              data-tip="Seiteneinstellungen"
            >
              <Icon icon="cog" left /> Seiteneinstellungen
            </button>{" "}
            {page.identifier !== "new" && (
              <>
                <button className="primary hollow button" onClick={onClone}>
                  <Icon icon="clone" left /> Duplizieren
                </button>{" "}
              </>
            )}
            {page.identifier !== "new" && (
              <>
                <button className="alert hollow button" onClick={onDelete}>
                  <Icon icon="trash" left /> Löschen
                </button>{" "}
              </>
            )}
          </Cell>
          <Cell sm={24} md={10} className="text-center medium-text-right">
            {page.identifier !== "new" && (
              <a
                href={UrlHelper.url("pagebuilderPage", { slug: page.slug })}
                target={`page_${page.identifier}`}
                rel="noopener noreferrer"
                className="primary hollow button"
              >
                <Icon icon="external-link-square" left />
                Seite aufrufen
              </a>
            )}{" "}
            <button className="primary button" onClick={onSave}>
              {page.identifier === "new" ? (
                <>
                  Speichern <Icon icon="arrow-right" right />
                </>
              ) : (
                <>
                  <Icon icon="globe" left /> Speichern &amp; veröffentlichen
                </>
              )}
            </button>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default Page;
