import React, { Component } from "react";
import withContext from "../../../../../context/contextHOC";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import HomeVideo from "../../../../home/HomeVideo";

class MoreWithLoa extends Component {
  state = { category: this.props.category, course: this.props.course };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.category !== prevProps.category) {
      this.setState({ category: this.props.category });
    }
    if (this.props.course !== prevProps.course) {
      this.setState({ course: this.props.course });
    }
  }

  render() {
    const { category, course } = this.state;
    const _c = this.props.context;

    if (!category || !course) {
      return null;
    }

    return (
      <React.Fragment>
        <section className="course-category-subcategory">
          <Grid type="full">
            <Row margin="xy">
              {category.lessons.map((subCategory, index) => {
                let v__title = <h2>{category.name}</h2>;
                if (
                  subCategory &&
                  subCategory.id &&
                  subCategory.id !== "pseudo"
                ) {
                  /*if (subCategory.image) {
                      v__title = (
                        <img src={subCategory.image} alt={subCategory.name} />
                      );
                    } else {
                      v__title = <h2>{subCategory.name}</h2>;
                    }*/
                  v__title = <h2>{subCategory.name}</h2>;
                }

                if (subCategory.image) {
                  v__title = (
                    <img
                      src={subCategory.image}
                      alt={subCategory.name}
                      style={{ marginBottom: "20px", marginTop: "20px" }}
                    />
                  );
                }

                if (
                  (!subCategory.lessons ||
                    (subCategory.lessons &&
                      subCategory.lessons.length === 0)) &&
                  (!subCategory.downloads ||
                    (subCategory.downloads &&
                      subCategory.downloads.length === 0))
                ) {
                  return null;
                }

                let md_lvl1 = 12;
                let md_lvl2 = 24;
                let classes;
                if (subCategory.id === 10) {
                  md_lvl1 = 24;
                  md_lvl2 = 12;
                  classes = "big-download";
                  if (parseInt(category.id) === 4) {
                    classes = "small-download";
                  }
                } else if (subCategory.id === 11) {
                  md_lvl1 = 24;
                  md_lvl2 = 24;
                  classes = "big-download";
                  v__title = null;
                }

                return (
                  <Cell sm={24} md={md_lvl1} key={index}>
                    {v__title}
                    <Grid type="full">
                      <Row margin="xy">
                        {subCategory.lessons.map((lesson, lessonIndex) => {
                          let v__image;

                          if (lesson.image) {
                            v__image = (
                              <a
                                href={_c
                                  .config("urls.courseLesson")
                                  .replace(":courseSlug", course.slug)
                                  .replace(":lessonSlug", lesson.slug)}
                                className="home-categories-category"
                              >
                                <img src={lesson.image} alt="" />
                              </a>
                            );
                          } else {
                            v__image = (
                              <React.Fragment>
                                <div className="category-lesson-image">
                                  <HomeVideo video={lesson} />
                                </div>
                              </React.Fragment>
                            );
                          }

                          return (
                            <Cell sm={24} md={md_lvl2} key={lessonIndex}>
                              {v__image}
                              <a
                                href={_c
                                  .config("urls.courseLesson")
                                  .replace(":courseSlug", course.slug)
                                  .replace(":lessonSlug", lesson.slug)}
                                className="home-categories-category"
                              >
                                <div className="category-lesson-content">
                                  <h3>{lesson.name}</h3>
                                  <div className="category-lesson-description">
                                    {lesson.short_description}
                                  </div>
                                </div>
                              </a>
                            </Cell>
                          );
                        })}
                        {subCategory.downloads.map((download, index) => {
                          let v__downloadContent, v__text, v__downloadName;
                          const styles = {};
                          if (download.text) {
                            v__text = (
                              <div className="download-text">
                                {download.text}
                              </div>
                            );
                          }

                          if (download.image) {
                            styles.backgroundImage = `url('${download.image}')`;
                            v__downloadName = (
                              <h2 style={{ marginBottom: "8px" }}>
                                {download.name}
                              </h2>
                            );
                          } else {
                            v__downloadContent = (
                              <div className="center-vertically category-download-content">
                                {download.name}
                              </div>
                            );
                          }

                          return (
                            <Cell
                              sm={24}
                              md={md_lvl2}
                              mdo={
                                md_lvl2 === 12 &&
                                subCategory.lessons.length === 0 &&
                                subCategory.downloads.length === 1
                                  ? 6
                                  : null
                              }
                              key={index}
                            >
                              {v__downloadName}
                              <a
                                href={download.url}
                                target="_blank"
                                rel="noopener noreferrer"
                                className={`category-download ${classes}`}
                                style={styles}
                              >
                                {v__downloadContent}
                              </a>
                              {v__text}
                            </Cell>
                          );
                        })}
                      </Row>
                    </Grid>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </section>
      </React.Fragment>
    );
  }
}

export default withContext(MoreWithLoa);
