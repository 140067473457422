import React, { Component } from "react";
import DefaultLayout from "../layout/DefaultLayout";
import axios from "axios";
import withContext from "../../context/contextHOC";
import { Grid, Row, Cell } from "../_foundation/_grid";
import Box from "../layout/Box";
//import AdminDataField from "../admin/AdminDataField";
import { Icon } from "../_foundation/_buttons";
import cloneDeep from "lodash/cloneDeep";
import MMTabs from "../mmcomponents/mmtabs/MMTabs";
import Switch from "react-switch";

class Privacy extends Component {
  state = {
    tabs: {},
    selectedTab: "privacy",
    editMode: true,
    changesWereMade: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();

    this.setState({
      tabs: {
        privacy: {
          name: _c.translate("privacy.tabs.privacy"),
          icon: "user-secret",
        },
        /*dataprotection: {
          name: _c.translate("privacy.tabs.dataprotection"),
          icon: "database",
        },*/
      },
    });

    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <DefaultLayout
        breadcrumbs={["account", "privacy"]}
        page="privacy"
        background="account"
        help="privacy"
      >
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h1>{_c.translate("pageTitles.privacy")}</h1>
              {this.view__showPageButtons()}
            </Cell>
          </Row>
          {this.view__showTabs()}
          {this.view__showContent()}
        </Grid>
        {this.view__showPageButtons()}
      </DefaultLayout>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="page-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showPageButtons() {
    const { editMode } = this.state;
    const _c = this.props.context;

    if (editMode === true) {
      return (
        <div className="page-buttons">
          <button
            className="small primary hollow button"
            onClick={this.handle__cancel}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
          <button className="small primary button" onClick={this.handle__save}>
            <Icon icon="check" left /> {_c.translate("buttons.save")}
          </button>
        </div>
      );
    }
  }

  view__showContent() {
    const { account, selectedTab } = this.state;
    const _c = this.props.context;

    if (!account) {
      return _c.loading();
    }

    if (selectedTab === "privacy") {
      return (
        <Row margin="xy">
          <Cell sm={24} md={12}>
            {this.view__showBoxCommunityProfile()}
            {this.view__showBoxChat()}
          </Cell>
          <Cell sm={24} md={12}></Cell>
        </Row>
      );
    } else if (selectedTab === "dataprotection") {
      return (
        <Row margin="xy">
          <Cell sm={24} md={12}></Cell>
          <Cell sm={24} md={12}></Cell>
        </Row>
      );
    }
  }

  view__showBoxCommunityProfile() {
    const { account } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <h3>{_c.translate("privacy.community.profile.title")}</h3>
        <p>{_c.translate("privacy.community.profile.intro")}</p>

        <div className="privacy-switch">
          <Grid type="full">
            <Row margin="y">
              <Cell sm={12} md={8} className="text-right">
                <Switch
                  height={20}
                  width={40}
                  onChange={() => {
                    this.handle__toggleUserSetting("profile_show_profile");
                  }}
                  checked={account.settings.profile_show_profile.value}
                />
              </Cell>
              <Cell sm={12} md={16}>
                <label
                  onClick={() => {
                    this.handle__toggleUserSetting("profile_show_profile");
                  }}
                >
                  {_c.translate("privacy.community.profile.showProfile")}
                </label>
              </Cell>
            </Row>
          </Grid>
        </div>

        <div
          style={{
            display: account.settings.profile_show_profile.value
              ? "block"
              : "none",
          }}
        >
          <hr />

          <div className="privacy-switch">
            <Grid type="full">
              <Row margin="y">
                <Cell sm={12} md={8} className="text-right">
                  <Switch
                    height={20}
                    width={40}
                    onChange={() => {
                      this.handle__toggleUserSetting("profile_show_gender");
                    }}
                    checked={account.settings.profile_show_gender.value}
                  />
                </Cell>
                <Cell sm={12} md={16}>
                  <label
                    onClick={() => {
                      this.handle__toggleUserSetting("profile_show_gender");
                    }}
                  >
                    {_c.translate("privacy.community.profile.showGender")}
                  </label>
                </Cell>
              </Row>
            </Grid>
          </div>

          <Grid type="full">
            <Row margin="y">
              <Cell sm={12} md={12}>
                <label>
                  {_c.translate("privacy.community.profile.showBirthday")}:
                </label>
              </Cell>
              <Cell sm={12} md={12} className="text-right">
                <select
                  value={account.settings.profile_show_birthday.value}
                  onChange={(event) => {
                    this.handle__setUserSetting(
                      "profile_show_birthday",
                      event.target.value
                    );
                  }}
                >
                  <option value="birthday">
                    {_c.translate("privacy.community.profile.birthdayAge")}
                  </option>
                  <option value="age">
                    {_c.translate("privacy.community.profile.ageOnly")}
                  </option>
                  <option value="none">
                    {_c.translate("privacy.community.profile.noBirthday")}
                  </option>
                </select>
              </Cell>
            </Row>
          </Grid>
        </div>

        <div style={{ marginTop: "30px", lineHeight: "110%" }}>
          <small>
            {_c.translate("privacy.community.profile.showProfileHint")}
          </small>
        </div>
      </Box>
    );
  }

  view__showBoxChat() {
    const { account } = this.state;
    const _c = this.props.context;

    return (
      <Box>
        <h3>{_c.translate("privacy.chat.title")}</h3>

        <div>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={12} md={12}>
                <label>{_c.translate("privacy.chat.showName.label")}:</label>
              </Cell>
              <Cell sm={12} md={12} className="text-right">
                <select
                  value={account.settings.chat_name.value}
                  onChange={(event) => {
                    this.handle__setUserSetting(
                      "chat_name",
                      event.target.value
                    );
                  }}
                >
                  <option value="fullname">
                    {_c.translate("privacy.chat.showName.options.fullname")}
                  </option>
                  <option value="firstname">
                    {_c.translate("privacy.chat.showName.options.firstname")}
                  </option>
                  <option value="lastname">
                    {_c.translate("privacy.chat.showName.options.lastname")}
                  </option>
                </select>
              </Cell>
            </Row>
          </Grid>
        </div>
      </Box>
    );
  }

  handle__toggleUserSetting(property) {
    const { account } = this.state;
    const { settings } = account;

    if (settings[property]) {
      if (settings[property].value === true) {
        settings[property].value = false;
      } else {
        settings[property].value = true;
      }

      this.setState({ account, changesWereMade: true });
    }
  }

  handle__setUserSetting(property, value) {
    const { account } = this.state;
    const { settings } = account;

    if (settings[property]) {
      settings[property].value = value;

      this.setState({ account, changesWereMade: true });
    }
  }

  handle__cancel = () => {
    const { account, changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("privacy.cancel.confirm"))) {
        this.props.history.push(
          _c
            .config("urls.communityProfile")
            .replace(":username", account.username)
        );
      } else {
        return false;
      }
    } else {
      this.props.history.push(
        _c
          .config("urls.communityProfile")
          .replace(":username", account.username)
      );
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { account } = this.state;

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      let apiUrl = _c.apiUrl("user.privacy");
      axios
        .patch(
          apiUrl,
          {
            account: account,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          if (_c.isDebug()) {
            console.log("DEBUG", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            _c.createNotifictation(
              _c.translate("privacy.messages.saved.title"),
              _c.translate("privacy.messages.saved.message"),
              "success"
            );
            this.props.history.push(
              _c
                .config("urls.communityProfile")
                .replace(":username", account.username)
            );

            _c.setIsSaving(false);
          }
        })
        .catch((error) => {
          console.log("Error", error);
        });
    }, 200);
  };

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("user.load");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const data = response.data.data;
            const { account } = data;
            const original = cloneDeep(account);

            this.setState(
              {
                account,
                original,
                status: "LOADED",
              },
              () => {
                _c.initFinished();
              }
            );
          }
          _c.setPageTitle("pageTitles.privacy", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "user:privacy:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "user:privacy:init");
      });
  }
}

export default withContext(Privacy);
