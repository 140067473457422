import React, { Component } from "react";

class New extends Component {
  state = {
    _updatableProps: ["label", "expire"],
    label: this.props.label,
    expire: this.props.expire, // Format: YYYY-MM-DD
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    let { label, expire } = this.state;

    if (!label) {
      label = "NEU";
    }

    const now = new Date();
    const expireDate = new Date();

    try {
      const dateElements = expire.split("-");
      const year = parseInt(dateElements[0]);
      const month = parseInt(dateElements[1]);
      const day = parseInt(dateElements[2]);

      //console.log(dateElements);
      //console.log(year, month, day);

      expireDate.setFullYear(year);
      expireDate.setMonth(month - 1);
      expireDate.setDate(day);
      expireDate.setHours(23);
      expireDate.setMinutes(59);
      expireDate.setSeconds(59);

      //console.log(expireDate);
    } catch (error) {}

    if (expireDate > now) {
      return <span className="new-badge">{label}</span>;
    } else {
      return null;
    }
  }
}

export default New;
