import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import MMTabs from "../../../../mmcomponents/mmtabs/MMTabs";
import ElementSettings from "./ElementSettings";
import ElementDesign from "./ElementDesign";
import Loading from "../../../../_reusables/Loading";
import Box from "../../../../layout/Box";
import AdminDataField from "../../../AdminDataField";
import { PtContext } from "../../../../../context/ptProvider";

const tabs = {
  content: {
    name: "Inhalt",
    icon: "pencil-square-o",
  },
  design: {
    name: "Design",
    icon: "paint-brush",
  },
  settings: {
    name: "Einstellungen",
    icon: "cog",
  },
};

function PopupLessonGrid(props) {
  const _c = useContext(PtContext);

  const [element, setElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState("content");

  useEffect(() => {
    setElement(cloneDeep(props.element));
  }, [props.element]);

  useEffect(() => {}, [element]);

  const onEdit = (property, value) => {
    if (props.actions.onEdit) props.actions.onEdit(property, value);
  };
  const actions = {
    onEdit,
  };

  if (!element)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="element-popup-text">
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
        />
      </div>
      {selectedTab === "content" && (
        <TabContent
          element={element}
          extra={props.extra}
          _c={_c}
          actions={actions}
        />
      )}
      {selectedTab === "design" && (
        <ElementDesign item={element} itemType="element" actions={actions} />
      )}
      {selectedTab === "settings" && (
        <ElementSettings item={element} actions={actions} />
      )}
    </div>
  );
}

const TabContent = ({ element, extra, _c, actions }) => {
  const selectValuesCourses = {
    none: "--- Kein Kurs ausgewählt ---",
  };
  extra.courses.map((course) => {
    selectValuesCourses[`course_${course.id}`] = course.name;
    return null;
  });

  const selectValuesCategories = {
    none: "--- Keine Kategorie ausgewählt",
  };

  if (element.options.course && element.options.course !== "none") {
    extra.courses.map((course) => {
      if (parseInt(course.id) === parseInt(element.options.course)) {
        course.categories.map((category) => {
          selectValuesCategories[
            `category_${category.id}`
          ] = `${category.name}`;
          return null;
        });
      }
      return null;
    });
  }

  const selectValuesViews = _c.config("pagebuilder.options.lesson.views");

  const selectValuesPerRow = {
    24: "1",
    12: "2",
    8: "3",
    6: "4",
    4: "6",
    3: "8",
  };

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <Box size="small">
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24}>
                  <AdminDataField
                    value={`course_${element.options.course}`}
                    editValue={`course_${element.options.course}`}
                    editType="select"
                    selectValues={selectValuesCourses}
                    label={"Kurs"}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) =>
                      actions.onEdit(
                        `options.course`,
                        newValue.replace("course_", "")
                      )
                    }
                  />
                </Cell>
              </Row>
              {element.options.course && element.options.course !== "none" && (
                <Row margin="xy">
                  <Cell sm={24}>
                    <AdminDataField
                      value={`category_${element.options.category}`}
                      editValue={`category_${element.options.category}`}
                      editType="select"
                      selectValues={selectValuesCategories}
                      label={"Angezeigte Kategorie"}
                      editable={true}
                      edit={true}
                      onUpdate={(newValue) =>
                        actions.onEdit(
                          `options.category`,
                          newValue.replace("category_", "")
                        )
                      }
                    />
                  </Cell>
                </Row>
              )}
              {element.options.category && element.options.category !== "none" && (
                <>
                  <Row margin="xy">
                    <Cell sm={24}>
                      <AdminDataField
                        value={element.options.view}
                        editValue={element.options.view}
                        editType="select"
                        selectValues={selectValuesViews}
                        label={"Art der Darstellung"}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) =>
                          actions.onEdit(`options.view`, newValue)
                        }
                      />
                    </Cell>
                  </Row>
                  <Row margin="xy">
                    <Cell sm={24}>
                      <AdminDataField
                        value={element.options.perrow_md || 8}
                        editValue={element.options.perrow_md || 8}
                        editType="select"
                        selectValues={selectValuesPerRow}
                        label={"Lektionen pro Zeile"}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) =>
                          actions.onEdit(`options.perrow_md`, newValue)
                        }
                      />
                    </Cell>
                  </Row>
                </>
              )}
            </Grid>
          </Box>
        </Cell>
      </Row>
    </Grid>
  );
};

export default PopupLessonGrid;
