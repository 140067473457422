import React, { useContext } from "react";
import { PtContext } from "../../../context/ptProvider";
import UrlHelper from "../../../helpers/UrlHelper";

function LinkWrapper(props) {
  const _c = useContext(PtContext);
  const { element } = props;

  if (!element.options || !element.options.linkType) return props.children;

  if (element.options.linkType === "qapopup") {
    return (
      <a
        onClick={(e) => {
          e.preventDefault();
          _c.showQAPopup(true);
        }}
      >
        {props.children}
      </a>
    );
  }

  if (element.options.linkType === "url" && element.options.url) {
    if (element.options.newtab)
      return (
        <a href={element.options.url} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      );
    else return <a href={element.options.url}>{props.children}</a>;
  }

  if (
    element.options.linkType === "page" &&
    element.options.linkpage &&
    element.extra &&
    element.extra.pageSlug
  ) {
    if (element.options.newtab)
      return (
        <a
          href={UrlHelper.url("pagebuilderPage", {
            slug: element.extra.pageSlug,
          })}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      );
    else
      return (
        <a
          href={UrlHelper.url("pagebuilderPage", {
            slug: element.extra.pageSlug,
          })}
        >
          {props.children}
        </a>
      );
  }

  if (
    element.options.linkType === "course" &&
    element.options.linkcourse &&
    element.extra &&
    element.extra.courseSlug
  ) {
    let url;

    if (element.extra.lessonSlug)
      url = UrlHelper.url("courseLesson", {
        courseSlug: element.extra.courseSlug,
        lessonSlug: element.extra.lessonSlug,
      });
    else if (element.extra.categorySlug)
      url = UrlHelper.url("courseCategory", {
        courseSlug: element.extra.courseSlug,
        categorySlug: element.extra.categorySlug,
      });
    else
      url = UrlHelper.url("course", {
        courseSlug: element.extra.courseSlug,
      });

    if (element.options.newtab)
      return (
        <a href={url} target="_blank" rel="noopener noreferrer">
          {props.children}
        </a>
      );
    else return <a href={url}>{props.children}</a>;
  }

  if (
    element.options.linkType === "download" &&
    element.options.linkdownload &&
    element.extra &&
    element.extra.downloadUrl
  ) {
    if (element.options.newtab)
      return (
        <a
          href={element.extra.downloadUrl}
          target="_blank"
          rel="noopener noreferrer"
        >
          {props.children}
        </a>
      );
    else return <a href={element.extra.downloadUrl}>{props.children}</a>;
  }

  return props.children;
}

export default LinkWrapper;
