import React from "react";
import Countdown from "../../_reusables/Countdown";
import PageViewHelper from "../PageViewHelper";

function ElementCountdown({ element }) {
  if (!element.content) return null;
  return (
    <>
      <div style={PageViewHelper.applyOptions(element)}>
        <Countdown
          date={new Date(element.content)}
          preAction={10 * 60}
          onPreAction={() => {}}
          onZero={() => {
            /*console.log("COUNTDOWN ZERO");
                document.getElementById("countdownButton").style.display =
                  "block";*/
          }}
          type="home-countdown"
        />
      </div>
    </>
  );
}

export default ElementCountdown;
