import React from "react";
import ColorPicker from "./components/ColorPicker";

const optionsKey = "color";

const defaultColor = "black";

function ControlFontColor({ item, onUpdate }) {
  const getValue = () => {
    let value = defaultColor;
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onEdit = (value) => {
    onUpdate(`options.${optionsKey}`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <ColorPicker
        size="small"
        color={getValue()}
        onChange={(color) => {
          onEdit(color);
        }}
      />
    </div>
  );
}

export default ControlFontColor;
