import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import { cloneDeep } from "lodash";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import AdminDataField from "../AdminDataField";
import axios from "axios";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";
import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

class AdminCourseDownloads extends Component {
  state = {
    _updatableProps: [
      "course",
      "downloads",
      "newDownload",
      "lesson",
      "category",
      "newDownload",
    ],
    course: this.props.course,
    downloads: this.props.downloads,
    newDownload: this.props.newDownload,
    lesson: this.props.lesson,
    category: this.props.category,
    selectedDownload: null,
    selectedCategory: null,
    changesWereMade: false,
    showDialogDelete: false,
  };

  componentDidMount() {
    this.setState({ selectedCategory: this.state.category });
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] }, () => {
          if (propKey === "category") {
            this.setState({ selectedCategory: this.state.category });
          }
        });
      }
      return null;
    });
  }

  render() {
    const { downloads, course, lesson } = this.state;
    const _c = this.props.context;

    if (!downloads) {
      return null;
    }

    if (!course.id || course.id === "new") {
      return (
        <p>
          <em>{_c.translate("admin.courses.downloads.noNew")}</em>
        </p>
      );
    } else if (course.id && lesson && lesson.id === "new") {
      return (
        <p>
          <em>{_c.translate("admin.courses.downloads.saveLessonFirst")}</em>
        </p>
      );
    }

    return (
      <React.Fragment>
        {this.view__showContent()}
        {this.view__showPopups()}
        {this.view__showDialogs()}
        <ul id="adminSortDownloads" className="admin-course-downloads" />
      </React.Fragment>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, selectedDownload } = this.state;
    const _c = this.props.context;

    if (!selectedDownload) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <p
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.courses.downloads.delete.confirm"),
              }}
            />
          }
          button_1={{
            title: _c.translate("admin.courses.downloads.delete.cancel"),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate("admin.courses.downloads.delete.yes"),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showPopups() {
    const {
      selectedDownload,
      showDialogDelete,
      course,
      selectedCategory,
    } = this.state;
    const _c = this.props.context;

    if (!course || !course.categories) {
      return null;
    }

    let output;

    if (selectedDownload && !showDialogDelete) {
      const downloadTypes = _c.getDownloadTypes("select");

      const categoryValues = {
        0: _c.translate(
          "admin.courses.downloads.download.selectSubCategory.noSubCategory"
        ),
      };
      const categoriesById = {};

      course.categories.map((category) => {
        categoryValues[`course_${category.id}`] = category.name;
        categoriesById[category.id] = category;

        return null;
      });

      let selectedCategoryId = "course_0";
      let v__subCategory;

      if (selectedCategory && selectedCategory.id) {
        selectedCategoryId = `course_${selectedCategory.id}`;

        let selectedSubCategoryId = 0;

        const subCategoryValues = {
          0: "-",
        };

        if (selectedCategory.subcategories) {
          selectedCategory.subcategories.map((subcategory) => {
            subCategoryValues[`subcategory_${subcategory.id}`] =
              subcategory.name;
            return null;
          });
        }

        if (selectedDownload.subcategory) {
          selectedSubCategoryId = `subcategory_${selectedDownload.subcategory}`;
        } else {
          selectedCategoryId = 0;
        }

        v__subCategory = (
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={subCategoryValues[selectedSubCategoryId]}
                editValue={selectedSubCategoryId}
                editType="select"
                selectValues={subCategoryValues}
                label={_c.translate(
                  "admin.courses.downloads.download.selectSubCategory.subCategory"
                )}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit(
                    "subcategory",
                    newValue.replace("subcategory_", "")
                  );
                }}
              />
            </Cell>
          </Row>
        );
      }

      let v__upload, v__url;
      if (selectedDownload.type === "link") {
        v__upload = (
          <Row>
            <Cell sm={24} md={24}>
              <hr />
              <AdminDataField
                value={selectedDownload.url}
                editValue={selectedDownload.url}
                editType="text"
                label="URL des Links"
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("url", newValue);
                }}
              />
            </Cell>
          </Row>
        );
      } else {
        v__upload = (
          <Row>
            <Cell sm={24} md={24}>
              <hr />
              <p>
                <strong>
                  {_c.translate(
                    "admin.courses.downloads.download.upload.title"
                  )}
                </strong>
              </p>
              <input
                style={{ margin: "0", height: "auto" }}
                type="file"
                onChange={(e) => {
                  this.handle__edit("file", e.target.files[0]);
                }}
              />
            </Cell>
          </Row>
        );

        v__url = (
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <small style={{ wordWrap: "break-word" }}>
                URL:{" "}
                <a
                  href={selectedDownload.url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {selectedDownload.url}
                </a>
              </small>
            </Cell>
          </Row>
        );
      }

      output = (
        <MMPopup show={true} size="small" handleClose={this.handle__cancel}>
          <h3>
            {selectedDownload.name ||
              _c.translate("admin.courses.downloads.download.download")}
          </h3>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.name}
                  editValue={selectedDownload.name}
                  editType="text"
                  label={_c.translate(
                    "admin.courses.downloads.download.name.title"
                  )}
                  placeholder={_c.translate(
                    "admin.courses.downloads.download.name.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("name", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.type}
                  editValue={selectedDownload.type}
                  editType="select"
                  selectValues={downloadTypes}
                  label={_c.translate("admin.courses.downloads.download.type")}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("type", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24} className="text-center">
                <AdminDataField
                  value={selectedDownload.published}
                  editValue={selectedDownload.published}
                  editType="singleCheckbox"
                  label={_c.translate(
                    "admin.courses.downloads.download.published"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("published", newValue);
                  }}
                />
              </Cell>
            </Row>
            {/*<Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.url}
                  editValue={selectedDownload.url}
                  editType="text"
                  label={_c.translate(
                    "admin.courses.downloads.download.url.title"
                  )}
                  placeholder={_c.translate(
                    "admin.courses.downloads.download.url.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("url", newValue);
                  }}
                />
              </Cell>
                </Row>*/}
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedDownload.text}
                  editValue={selectedDownload.text}
                  editType="textarea"
                  label={_c.translate("admin.courses.downloads.download.text")}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__edit("text", newValue);
                  }}
                />
              </Cell>
            </Row>

            {v__url}

            {v__upload}

            <Row margin="y">
              <Cell sm={24}>
                <hr />
                <p>
                  <strong>
                    {_c.translate(
                      "admin.courses.downloads.download.selectSubCategory.title"
                    )}
                  </strong>
                  <br />
                  {_c.translate(
                    "admin.courses.downloads.download.selectSubCategory.text"
                  )}
                </p>
              </Cell>
            </Row>
            <Row>
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={categoryValues[selectedCategoryId]}
                  editValue={selectedCategoryId}
                  editType="select"
                  selectValues={categoryValues}
                  label={_c.translate(
                    "admin.courses.downloads.download.selectSubCategory.category"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(categoryId) => {
                    let newSelectedCategory =
                      categoriesById[categoryId.replace("course_", "")];

                    this.handle__edit("category", newSelectedCategory.id);
                    this.handle__edit("subcategory", null);

                    this.setState({
                      selectedCategory: newSelectedCategory,
                    });
                  }}
                />
              </Cell>
            </Row>
            {v__subCategory}
            <Row margin="y">
              <Cell sm={24}>
                <div style={{ margin: "1rem 0" }}>
                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={8}>
                        <button
                          className="small primary hollow button"
                          onClick={() => {
                            this.setState({ showDialogDelete: true });
                          }}
                        >
                          <Icon icon="trash" left />{" "}
                          {_c.translate(
                            "admin.courses.downloads.delete.button"
                          )}
                        </button>
                      </Cell>
                      <Cell sm={24} md={16} className="text-right">
                        <button
                          className="small primary hollow button"
                          onClick={this.handle__cancel}
                        >
                          <Icon icon="times" left />{" "}
                          {_c.translate("buttons.cancel")}
                        </button>
                        <button
                          className="small primary button"
                          onClick={this.handle__save}
                        >
                          <Icon icon="check" left />{" "}
                          {_c.translate("buttons.save")}
                        </button>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      );
    }

    return <React.Fragment>{output}</React.Fragment>;
  }

  view__showContent() {
    const { lesson } = this.state;
    const _c = this.props.context;

    if (lesson) {
      return (
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <div>{_c.translate("admin.courses.downloads.texts.lesson")}</div>
              <div>&nbsp;</div>
              {this.view__showDownloads("lesson")}
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <h3>{_c.translate("admin.courses.downloads.titles.general")}</h3>
              <div>{_c.translate("admin.courses.downloads.texts.general")}</div>
              <div>&nbsp;</div>
              {this.view__showDownloads("general")}
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  view__showDownloads(type) {
    const { downloads, newDownload, lesson, course } = this.state;
    const _c = this.props.context;

    if (!downloads || !course || !course.categories) {
      return null;
    }

    let showDownloads = [];
    if (type === "general") {
      showDownloads = downloads.general;
    } else if (type === "lesson" && lesson && downloads.lesson[lesson.id]) {
      showDownloads = downloads.lesson[lesson.id];
    }

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-course-downloads">{children}</ul>;
    });

    const SortableItem = sortableElement(({ download }) => {
      let v__type = _c.getDownloadType(download.type);

      let v__published;
      if (download.published === true) {
        v__published = (
          <span className="course-download-published">
            <Icon icon="check" left />{" "}
            {_c.translate("admin.courses.downloads.download.isPublished")}
          </span>
        );
      } else {
        v__published = (
          <span className="course-download-notpublished">
            <Icon icon="minus" left />{" "}
            {_c.translate("admin.courses.downloads.download.isNotPublished")}
          </span>
        );
      }

      let v__category, v__subCategory;
      let categoryName = download.category;
      let subcategoryName = download.subcategory;

      if (download.category) {
        course.categories.map((category) => {
          if (parseInt(category.id) === parseInt(download.category)) {
            categoryName = category.name;

            if (category.subcategories) {
              category.subcategories.map((subcategory) => {
                if (
                  parseInt(subcategory.id) === parseInt(download.subcategory)
                ) {
                  subcategoryName = subcategory.name;
                }
              });
            }
          }
          return null;
        });

        v__category = <div>Kategorie: {categoryName}</div>;
      }
      if (download.subcategory) {
        v__subCategory = <div>--- Unterkategorie: {subcategoryName}</div>;
      }

      return (
        <li className="admin-course-download">
          <Grid type="full">
            <Row>
              <Cell sm={2} md={2}>
                <div className="course-categories-drag-handle-wrapper">
                  <DragHandle />
                </div>
              </Cell>
              <Cell sm={22} md={22}>
                <Box
                  size="small"
                  onClick={() => {
                    const selectedDownload = cloneDeep(download);
                    if (selectedDownload && selectedDownload.category) {
                      course.categories.map((category) => {
                        if (
                          parseInt(category.id) ==
                          parseInt(selectedDownload.category)
                        ) {
                          this.setState({
                            selectedCategory: cloneDeep(category),
                          });
                        }
                        return null;
                      });
                    }
                    this.setState({ selectedDownload });
                  }}
                >
                  <h3>
                    {v__type} {download.name}
                  </h3>
                  {v__category}
                  {v__subCategory}
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={24} md={12} className="text-center">
                        {v__published}
                      </Cell>
                      <Cell sm={24} md={12} className="text-right">
                        {_c.translate(
                          "admin.courses.downloads.download.clicks"
                        )}
                        : {download.clicks}
                      </Cell>
                    </Row>
                    {/*<Row margin="xy">
                      <Cell sm={24} md={24}>
                        URL: {download.url}
                      </Cell>
                        </Row>*/}
                  </Grid>
                </Box>
              </Cell>
            </Row>
          </Grid>
        </li>
      );
    });

    return (
      <React.Fragment>
        <SortableContainer
          onSortEnd={this.onDownloadsSortEnd}
          useDragHandle
          helperContainer={document.getElementById("adminSortDownloads")}
        >
          {showDownloads.map((download, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                download={download}
              />
            );
          })}
        </SortableContainer>
        <div className="admin-courses-add-download">
          <Box
            size="small"
            onClick={() => {
              if (this.state.lesson) {
                newDownload.lesson = this.state.lesson.id;
              }
              this.setState({ selectedDownload: cloneDeep(newDownload) });
            }}
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.courses.downloads.download.add")}
          </Box>
        </div>
      </React.Fragment>
    );
  }

  onDownloadsSortEnd = ({ oldIndex, newIndex }) => {
    const _c = this.props.context;

    let { downloads, course } = this.state;
    downloads = arrayMove(downloads, oldIndex, newIndex);

    const newOrder = [];
    downloads.map((download) => {
      return newOrder.push(download.id);
    });

    this.setState({ downloads }, () => {
      const apiUrl = _c.apiUrl("admin.courseSortDownloads", {
        courseId: course.id,
      });

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);

              if (status === "SUCCESS") {
                const apiResponseData = response.data.data;
                const { course } = apiResponseData;

                _c.setIsSaving(false);

                this.setState(
                  {
                    course,
                  },
                  () => {
                    _c.cancelEditLock();
                    _c.createNotifictation(
                      _c.translate(
                        "admin.courses.downloads.messages.sorted.title"
                      ),
                      _c.translate(
                        "admin.courses.downloads.messages.sorted.text"
                      ),
                      "success"
                    );
                  }
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:course:downloads:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:course:downloads:sort");
          });
      }, 200);
    });
  };

  handle__edit(property, value) {
    const { selectedDownload } = this.state;
    selectedDownload[property] = value;
    this.setState({ selectedDownload, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let confirmCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(_c.translate("admin.courses.downloads.cancel.confirm"))
      ) {
        confirmCancel = true;
      }
    } else {
      confirmCancel = true;
    }

    if (confirmCancel === true) {
      this.setState({
        selectedDownload: null,
        changesWereMade: false,
      });
    }

    return confirmCancel;
  };

  handle__save = () => {
    const _c = this.props.context;
    const { course, selectedDownload } = this.state;

    if (!selectedDownload.name) {
      window.alert(_c.translate("admin.courses.downloads.errors.noName"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.courseDownload", {
        courseId: course.id,
        downloadId: selectedDownload.id,
      });

      const formData = new FormData();

      if (selectedDownload.file) {
        formData.append("file", selectedDownload.file);
        formData.append("name", selectedDownload.file.name);
      }

      formData.append("download", JSON.stringify(selectedDownload));

      this.setState({ selectedDownload: null });

      axios
        .post(apiUrl, formData, {
          headers: _c.headers,
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { course } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedDownload: null,
                  showDialogDelete: false,
                  course,
                  downloads: course.downloads,
                },
                () => {
                  if (this.props.onUpdate) {
                    this.props.onUpdate(course);
                  }
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate(
                      "admin.courses.downloads.messages.saved.title"
                    ),
                    _c.translate("admin.courses.downloads.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:course:download:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:course:download:save");
        });
    }, 200);
  };

  handle__delete = () => {
    const { course, selectedDownload } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.courseDownload", {
      courseId: course.id,
      downloadId: selectedDownload.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { course } = apiResponseData;

            _c.setIsSaving(false);

            this.setState(
              {
                changesWereMade: false,
                selectedDownload: null,
                showDialogDelete: false,
                course,
                downloads: course.downloads,
              },
              () => {
                if (this.props.onUpdate) {
                  this.props.onUpdate(course);
                }
                _c.cancelEditLock();
                _c.createNotifictation(
                  _c.translate(
                    "admin.courses.downloads.messages.deleted.title"
                  ),
                  _c.translate("admin.courses.downloads.messages.deleted.text"),
                  "success"
                );
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:course:download:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:course:download:delete");
      });
  };
}

export default withContext(AdminCourseDownloads);
