import React, { Component } from "react";
import { isUndefined } from "util";
import withContext from "../../../context/contextHOC";
import New from "../../admin/New";
import { Icon } from "../../_foundation/_buttons";

class MMTabs extends Component {
  state = {
    tabs: this.props.tabs,
    selectedTab: this.props.selected,
    isNew: this.props.isNew,
  };

  componentDidUpdate(prevProps) {
    if (prevProps.tabs !== this.props.tabs) {
      this.setState({ tabs: this.props.tabs });
    }

    if (prevProps.selected !== this.props.selected) {
      this.setState({ selectedTab: this.props.selected });
    }

    if (prevProps.isNew !== this.props.isNew) {
      this.setState({ isNew: this.props.isNew });
    }
  }

  render() {
    const { tabs, isNew } = this.state;
    return (
      <div className="mmtabs">
        <div className="mmtabs-inner">
          {Object.keys(tabs).map((keyName, index) => {
            if (isNew === true && tabs[keyName].showNew === false) {
              return null;
            }

            return (
              <button
                className={this.isSelectedClasses(keyName)}
                key={index}
                onClick={() => {
                  this.select(keyName);
                }}
              >
                {this.icon(tabs[keyName])}
                {tabs[keyName].name}
                {this.view__showNewLabel(tabs[keyName])}
              </button>
            );
          })}
        </div>
      </div>
    );
  }

  view__showNewLabel(tab) {
    const newLabel = tab.new;
    const _c = this.props.context;

    if (newLabel) {
      return (
        <span style={{ paddingLeft: "5px" }}>
          <New expire={newLabel} label={_c.translate("general.new")} />
        </span>
      );
    }
  }

  isSelectedClasses(tabKey) {
    const { selectedTab } = this.state;

    if (selectedTab === tabKey) {
      return "mmtab selected";
    } else {
      return "mmtab";
    }
  }

  icon(tab) {
    if (!isUndefined(tab.icon) && tab.icon) {
      return (
        <span>
          <Icon icon={tab.icon} left />
          &nbsp;
        </span>
      );
    } else {
      return null;
    }
  }

  select = (selectedKeyName) => {
    this.props.onUpdate(selectedKeyName);
  };
}

export default withContext(MMTabs);
