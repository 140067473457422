import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import ReactTooltip from "react-tooltip";
import { Link } from "react-router-dom";
import Countdown from "../../_reusables/Countdown";
import Avatar from "../../user/Avatar";
import DashboardStatus from "../status/DashboardStatus";

class AdminDashboard extends Component {
  state = {
    dashboard: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const _c = this.props.context;

    return (
      <AdminLayout nav="dashboard" breadcrumbs={["admin_dashboard"]}>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24} md={24}>
              <h1>{_c.translate("pageTitles.admin.dashboard")}</h1>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24} md={12}>
              {this.view__showUserStats()}
              {this.view__showNewestMembers()}
              {this.view__showCommunityPoster()}
            </Cell>
            <Cell sm={24} md={12}>
              {this.view__showStatus()}
              {this.view__showNextLivecall()}
            </Cell>
          </Row>
        </Grid>
      </AdminLayout>
    );
  }

  view__showStatus() {
    const { dashboard } = this.state;
    if (!dashboard) {
      return null;
    }

    const { status } = dashboard;

    return <DashboardStatus status={status} />;
  }

  view__showNewestMembers() {
    const { dashboard } = this.state;
    if (!dashboard) {
      return null;
    }

    const _c = this.props.context;

    const { userStats } = dashboard;

    return (
      <div className="admin-dashboard-newestmembers">
        <Box>
          <h3>
            {_c.translate("admin.dashboard.userStats.newestMembers.title")}
          </h3>
          <ul className="striped admin-list">
            {userStats.newestMembers.map((member, index) => {
              return (
                <li key={index} className="admin-list-item">
                  <Link
                    className="padding clickable"
                    to={_c
                      .config("urls.admin.member")
                      .replace(":id", member.id)}
                  >
                    <Grid type="full">
                      <Row>
                        <Cell sm={24} md={16}>
                          <span style={{ marginRight: "8px" }}>
                            <Avatar
                              size="admin-dashboard-user-list"
                              user={member}
                            />
                          </span>{" "}
                          {_c.getFullName(member)}
                        </Cell>
                        <Cell sm={24} md={8} className="text-right">
                          {member.registration_datetime}
                        </Cell>
                      </Row>
                    </Grid>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Box>
      </div>
    );
  }

  view__showUserStats() {
    const { dashboard } = this.state;
    if (!dashboard) {
      return null;
    }

    const _c = this.props.context;

    const { userStats } = dashboard;

    return (
      <Box>
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              {this.bigNumber(
                userStats.total_users,
                _c.translate("admin.dashboard.userStats.totalUsers.label"),
                _c.translate("admin.dashboard.userStats.totalUsers.tooltip")
              )}
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={12}>
              {this.smallNumber(
                userStats.active_users,
                _c.translate("admin.dashboard.userStats.activeUsers.label"),
                _c.translate("admin.dashboard.userStats.activeUsers.tooltip")
              )}
            </Cell>
            <Cell sm={24} md={12}>
              {this.smallNumber(
                userStats.buyers,
                _c.translate("admin.dashboard.userStats.buyers.label"),
                _c.translate("admin.dashboard.userStats.buyers.tooltip")
              )}
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showCommunityPoster() {
    const { dashboard } = this.state;
    const _c = this.props.context;

    if (!_c.hasModuleAccess("community")) {
      return null;
    }

    if (!dashboard) {
      return null;
    }

    const { userStats } = dashboard;

    return (
      <div className="admin-dashboard-communityposters">
        <Box>
          <h3>
            {_c.translate("admin.dashboard.userStats.communityPosters.title")}
          </h3>
          <ul className="striped admin-list">
            {userStats.communityPosters.map((entry, index) => {
              return (
                <li key={index} className="admin-list-item">
                  <Link
                    className="padding clickable"
                    to={_c
                      .config("urls.admin.member")
                      .replace(":id", entry.user.id)}
                  >
                    <Grid type="full">
                      <Row>
                        <Cell sm={20} md={20}>
                          <span style={{ marginRight: "8px" }}>
                            <Avatar
                              size="admin-dashboard-user-list"
                              user={entry.user}
                            />
                          </span>{" "}
                          {_c.getFullName(entry.user)}
                        </Cell>
                        <Cell sm={4} md={4} className="text-right">
                          {entry.postcount}
                        </Cell>
                      </Row>
                    </Grid>
                  </Link>
                </li>
              );
            })}
          </ul>
        </Box>
      </div>
    );
  }

  view__showNextLivecall() {
    const { dashboard } = this.state;
    const _c = this.props.context;

    if (!_c.hasModuleAccess("live")) {
      return null;
    }

    if (!dashboard) {
      return null;
    }

    const { live } = dashboard;

    let v__content = (
      <div className="text-center">
        {_c.translate("admin.dashboard.live.nextLivecall.nolivecall")}
      </div>
    );

    if (live.nextLivecall && live.nextLivecall.id && live.nextLivecall.start) {
      const { nextLivecall } = live;

      let v__image, v__description;

      if (nextLivecall.image) {
        v__image = (
          <div className="admin-dashboard-nextlivecall-image">
            <img src={nextLivecall.image} alt="[Preview]" />
          </div>
        );
      }

      if (nextLivecall.description) {
        v__description = (
          <div className="admin-dashboard-nextlivecall-description">
            {nextLivecall.description}
          </div>
        );
      }

      v__content = (
        <div>
          <Link
            className="clickable"
            to={_c
              .config("urls.admin.livecall")
              .replace(":id", nextLivecall.id)}
          >
            {v__image}
            <div className="admin-dashboard-nextlivecall-start">
              {nextLivecall.start_date}
            </div>
            <div className="admin-dashboard-nextlivecall-title">
              {nextLivecall.title}
            </div>
            {v__description}
            <div className="admin-dashboard-nextlivecall-countdown">
              <Countdown
                date={new Date(nextLivecall.start)}
                onZero={() => {
                  nextLivecall.status = "ONAIR";
                }}
                type="home"
              />
            </div>
          </Link>
        </div>
      );
    }

    return (
      <div className="admin-dashboard-nextlivecall">
        <Box>
          <h3>{_c.translate("admin.dashboard.live.nextLivecall.title")}</h3>
          {v__content}
        </Box>
      </div>
    );
  }

  bigNumber(value, label, tooltip) {
    let classes = "big dashboard-number";
    if (tooltip) {
      classes = `has-tooltip ${classes}`;
    }

    return (
      <div className={classes} data-tip={tooltip}>
        <div className="dashboard-number-value">{value}</div>
        <div className="dashboard-number-label">{label}</div>
      </div>
    );
  }

  smallNumber(value, label, tooltip) {
    let classes = "small dashboard-number";
    if (tooltip) {
      classes = `has-tooltip ${classes}`;
    }

    return (
      <div className={classes} data-tip={tooltip}>
        <div className="dashboard-number-value">{value}</div>
        <div className="dashboard-number-label">{label}</div>
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.dashboard");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { dashboard } = apiResponseData;

            this.setState({ dashboard });
          }
          _c.setPageTitle("pageTitles.admin.dashboard", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:dashboard:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:dashboard:init");
      });
  }
}

export default withContext(AdminDashboard);
