import React, { Component } from "react";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import Progress from "../parts/Progress";
import axios from "axios";
import withContext from "../../../../context/contextHOC";

class CourseRecommendations extends Component {
  state = {
    showCourses: this.props.showCourses,
    courses: null,
  };

  componentDidMount() {
    this.init();
  }

  render() {
    const { courses, showCourses } = this.state;

    if (!courses || !showCourses) {
      return null;
    }

    return <div>{this.view__showCourses()}</div>;
  }

  view__showCourses() {
    const { courses, showCourses } = this.state;
    const _c = this.props.context;

    const baseUrl = _c.config("urls.course");

    const selectedCourses = [];

    courses.map((course) => {
      if (showCourses.includes(course.slug)) {
        selectedCourses.push(course);
      }

      return null;
    });

    let view = "horizontal";

    let v__content = (
      <Grid type="full">
        <Row margin="xy">
          {selectedCourses.map((course, index) => {
            let v__progress;

            let v__image = (
              <div className="course-no-image">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate("courses.overview.noimage"),
                  }}
                />
              </div>
            );
            if (course.image) {
              v__image = <img src={course.image} alt={course.name} />;
            }

            let v__description;
            if (course.description) {
              v__description = (
                <div className="course-description">{course.description}</div>
              );
            }

            let v__button;
            if (course.purchased === true) {
              let courseUrl = baseUrl.replace(":courseSlug", course.slug);

              if (course.has_page) {
                courseUrl = _c
                  .config("urls.coursePage")
                  .replace(":courseSlug", course.slug);
              }

              if (course.slug === "ausbildung") {
                courseUrl = _c.config("urls.home.truepowerausbildung");
              }

              v__button = (
                <div className="course-button">
                  <a href={courseUrl} className="primary button">
                    <span
                      dangerouslySetInnerHTML={{
                        __html: _c.translate(
                          "courses.overview.purchased.button.label"
                        ),
                      }}
                    />{" "}
                    <Icon icon="angle-double-right" right />
                  </a>
                </div>
              );

              v__progress = <Progress course={course} where="overview" />;

              v__image = <a href={courseUrl}>{v__image}</a>;
            } else if (course.purchase_url) {
              v__button = (
                <div className="course-button">
                  <a
                    href={course.purchase_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primary button"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: _c.translate(
                          "courses.overview.available.button.label"
                        ),
                      }}
                    />{" "}
                    <Icon icon="angle-double-right" right />
                  </a>
                </div>
              );

              v__image = (
                <a
                  href={course.purchase_url}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {v__image}
                </a>
              );
            }

            let cellMd = 6;
            if (view === "vertical") {
              cellMd = 8;
            } else if (view === "horizontal") {
              cellMd = 8;
            }

            return (
              <Cell sm={24} md={cellMd} key={index}>
                <div className="course-overview-course">
                  <div className="course-image">{v__image}</div>
                  {v__progress}
                  <div className="course-content">
                    <h3>{course.name}</h3>
                    {v__description}
                  </div>
                  {v__button}
                </div>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );

    return <div>{v__content}</div>;
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.overview");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, userStatus } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const coursesData = response.data.data.courses;
            const { all, purchased, available } = coursesData;
            this.setState({
              courses: all,
              purchasedCourses: purchased,
              availableCourses: available,
              userStatus,
              status: "LOADED",
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:overview:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "course:overview:init");
      });
  }
}

export default withContext(CourseRecommendations);
