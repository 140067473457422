import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { isArray } from "util";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Link } from "react-router-dom";
import { Icon } from "../../_foundation/_buttons";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";

class AdminLiveCalls extends Component {
  state = {
    baseUrl: "/admin/livecalls",
    status: "INIT",
    loadingError: false,
    livecalls: undefined,
    courses: [],
    breadcrumbs: ["admin_dashboard", "admin_livecalls"],
    tabs: [],
    selectedTab: "active",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("live");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    return (
      <AdminLayout nav="livecalls" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell sm={24} md={12} className="text-center medium-text-right">
              {this.view__showPageActions()}
            </Cell>
          </Row>
        </Grid>
        {this.view__showTabs()}
        {this.view__showContent()}
      </AdminLayout>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab } = this.state;

    return (
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => {
            this.setState({ selectedTab: selectedKeyName });
          }}
        />
      </div>
    );
  }

  view__showContent() {
    const { selectedTab } = this.state;

    if (selectedTab === "active") {
      return <React.Fragment>{this.view__showList("active")}</React.Fragment>;
    } else if (selectedTab === "inactive") {
      return <React.Fragment>{this.view__showList("inactive")}</React.Fragment>;
    }
  }

  view__showHeadline() {
    const _c = this.props.context;
    return <h1>{_c.translate("pageTitles.admin.livecalls")}</h1>;
  }

  view__showPageActions() {
    const { baseUrl } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="small primary button-group hollow no-gaps">
          <a
            href={`${baseUrl}/new`}
            className="button"
            data-tip={_c.translate("admin.livecalls.addLivecall")}
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.livecalls.addLivecall")}
          </a>
        </div>
      </React.Fragment>
    );
  }

  view__showList(what) {
    const { livecalls, loadingError } = this.state;
    const _c = this.props.context;

    if (!livecalls) {
      return _c.loading(loadingError);
    }

    let showLivecalls = [];
    livecalls.map((livecall) => {
      if (what === "active" && livecall.status !== "FINISHED") {
        showLivecalls.push(livecall);
      } else if (what === "inactive" && livecall.status === "FINISHED") {
        showLivecalls.push(livecall);
      }
      return null;
    });

    if (showLivecalls && isArray(showLivecalls) && showLivecalls.length === 0) {
      if (what === "active") {
        return (
          <p className="text-center">
            {_c.translate("admin.livecalls.noActiveLivecallsFound")}
          </p>
        );
      } else if (what === "inactive") {
        return (
          <p className="text-center">
            {_c.translate("admin.livecalls.noInactiveLivecallsFound")}
          </p>
        );
      }
    }

    return (
      <React.Fragment>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop(showLivecalls)}
          {this.view__showListMobile(showLivecalls)}
        </Box>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={3}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={8}>
              <div className="padding">
                {_c.translate("admin.livecalls.cellTitles.name")}
              </div>
            </Cell>
            <Cell md={7}>
              <div className="padding">
                {_c.translate("admin.livecalls.cellTitles.access")}
              </div>
            </Cell>
            <Cell md={6} className="text-center">
              <div className="padding">
                {_c.translate("admin.livecalls.cellTitles.type")}
              </div>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop(livecalls) {
    const _c = this.props.context;

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {livecalls.map((livecall, index) => {
            let livecallImage;
            if (livecall.image) {
              livecallImage = (
                <img
                  src={livecall.image}
                  alt="Vorschau"
                  className="admin-livecall-image"
                />
              );
            }

            let v__status;
            if (livecall.status) {
              if (livecall.status === "ONAIR") {
                v__status = (
                  <span className="admin-live-onair">
                    {_c.translate("live.onair")}
                  </span>
                );
              }
            }

            let v__access;
            if (livecall.access_granted === "all") {
              v__access = _c.translate(`admin.access.info.all`);
            } else if (livecall.access_granted === "courses") {
              v__access = `${_c.translate(
                `admin.access.info.courses`
              )} ${this.view__showAccessCourseNames(
                livecall.access_granted_for
              )}`;
            }

            return (
              <li className="admin-list-item" key={index}>
                <Link
                  to={_c
                    .config("urls.admin.livecall")
                    .replace(":id", livecall.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={3} className="text-center">
                        <div className="padding">{livecallImage}</div>
                      </Cell>
                      <Cell md={8}>
                        <div className="padding">
                          <div>
                            {livecall.start_date} {v__status}
                          </div>
                          <div>
                            <strong>{livecall.title}</strong>
                          </div>
                        </div>
                      </Cell>
                      <Cell md={7}>
                        <div className="padding">{v__access}</div>
                      </Cell>
                      <Cell md={5} className="text-center">
                        <div className="padding">
                          <div>{livecall.type}</div>
                          <div>{livecall.external_id}</div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile(livecalls) {
    const _c = this.props.context;

    return (
      <div className="show-for-small-only">
        <ul className="striped admin-list">
          {livecalls.map((livecall, index) => {
            let livecallImage;
            if (livecall.image) {
              livecallImage = (
                <img
                  src={livecall.image}
                  alt="Vorschau"
                  className="admin-livecall-image"
                />
              );
            }

            let v__status;
            if (livecall.status) {
              if (livecall.status === "ONAIR") {
                v__status = (
                  <span className="admin-live-onair">
                    {_c.translate("live.onair")}
                  </span>
                );
              }
            }

            let v__access;
            if (livecall.access_granted === "all") {
              v__access = _c.translate(`admin.access.info.all`);
            } else if (livecall.access_granted === "courses") {
              v__access = `${_c.translate(
                `admin.access.info.courses`
              )} ${this.view__showAccessCourseNames(
                livecall.access_granted_for
              )}`;
            }

            v__access = (
              <span>
                <Icon icon="unlock" left /> {v__access}
              </span>
            );

            return (
              <li className="admin-list-item" key={index}>
                <Link
                  to={_c
                    .config("urls.admin.livecall")
                    .replace(":id", livecall.id)}
                  className="clickable"
                >
                  <Grid type="full">
                    <Row>
                      <Cell sm={6} className="text-center">
                        <div className="padding">{livecallImage}</div>
                      </Cell>
                      <Cell sm={18}>
                        <div className="padding">
                          <div>
                            {livecall.start_date} {v__status}
                          </div>
                          <div>
                            <strong>{livecall.title}</strong>
                          </div>
                          <div>
                            <small>{v__access}</small>
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showAccessCourseNames(courseIds) {
    const { courses } = this.state;
    const _c = this.props.context;

    let courseNames = [];
    courses.map((course) => {
      if (courseIds.includes(course.id)) {
        courseNames.push(course.name);
      }
      return null;
    });

    if (courseNames.length === 0) {
      return _c.translate("admin.community.access.info.none");
    } else {
      return courseNames.join(", ");
    }
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.livecalls");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { livecalls, courses } = apiResponseData;
            this.setState({
              livecalls,
              courses,
              status: "LOADED",
            });

            _c.setPageTitle("pageTitles.admin.livecalls", true);
          }

          this.setState({
            tabs: {
              active: {
                name: _c.translate("admin.livecalls.tabs.active"),
                icon: "youtube-play",
              },
              inactive: {
                name: _c.translate("admin.livecalls.tabs.inactive"),
                icon: "check-circle",
              },
            },
          });
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:livecalls:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:livecalls:init");
      });
  }
}

export default withContext(AdminLiveCalls);
