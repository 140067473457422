import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import AdminLayout from "../AdminLayout";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Box from "../../layout/Box";
import { Icon } from "../../_foundation/_buttons";
import AdminDataField from "../AdminDataField";

import AdminEditor from "../../_reusables/AdminEditor";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";

import Dropzone from "react-dropzone";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import AdminAccessSelect from "../AdminAccessSelect";
import ReactTooltip from "react-tooltip";
import { cloneDeep } from "lodash";

class AdminSettings extends Component {
  state = {
    status: "INIT",
    loadingError: false,
    livecalls: undefined,
    breadcrumbs: ["admin_dashboard", "admin_settings"],
    selectedPage: "general",
    textSettings: null,
    editorMode: "visual",
    uploadImage: null,
    uploadImageStatus: null,
    moduleAccesses: null,
    cropUrl: "",
    cropImage: "",
    crop: {
      unit: "%",
      x: 0,
      y: 0,
      height: 100,
      width: 100,
    },
    selectedImage: null,
    selectedBackgroundImage: null,
    designBackgroundKeys: [
      "design_bg_home",
      "design_bg_signup",
      "design_bg_login",
      "design_bg_courses",
      "design_bg_course",
      "design_bg_community_areas",
      "design_bg_community_board",
      "design_bg_community_thread",
      "design_bg_community_profile",
      "design_bg_live",
      "design_bg_routines",
      "design_bg_visionboard",
      "design_bg_account",
    ],
    domain: "",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="settings" breadcrumbs={breadcrumbs}>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={12}>
              {this.view__showHeadline()}
            </Cell>
            <Cell
              sm={24}
              md={12}
              className="text-center medium-text-right"
            ></Cell>
          </Row>
        </Grid>
        {this.view__showSettings()}
        {this.view__showPopups()}
      </AdminLayout>
    );
  }

  view__showHeadline() {
    const _c = this.props.context;
    return <h1>{_c.translate("pageTitles.admin.settings")}</h1>;
  }

  view__showSettings() {
    return (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={6}>
            {this.view__showSettingsNav()}
          </Cell>
          <Cell sm={24} md={18}>
            {this.view__showSettingsPage()}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showSettingsNav() {
    const { selectedPage } = this.state;
    const _c = this.props.context;
    let v__output;

    let tmpList = [
      "general",
      "domain",
      "logo",
      "tracking",
      "login",
      "signup",
      "access",
      "home",
      "courses",
      "community",
      "email_templates",
      "backgrounds",
      "legalinfo",
      "dataprivacy",
    ];

    let list = [];

    tmpList.map((listItem) => {
      let showItem = true;

      if (listItem === "courses" && !_c.hasModuleAccess("courses")) {
        showItem = false;
      } else if (listItem === "community" && !_c.hasModuleAccess("community")) {
        showItem = false;
      } else if (listItem === "live" && !_c.hasModuleAccess("live")) {
        showItem = false;
      } else if (
        listItem === "visionboard" &&
        !_c.hasModuleAccess("visionboard")
      ) {
        showItem = false;
      }

      if (showItem === true) {
        list.push(listItem);
      }

      return null;
    });

    v__output = (
      <div className="admin-settings-nav">
        <ul>
          {list.map((listItem, index) => {
            let classes;
            if (selectedPage === listItem) {
              classes = `${classes} selected`;
            }

            return (
              <li key={index} className={classes}>
                <button
                  onClick={() => {
                    this.setState({ selectedPage: listItem });
                  }}
                >
                  {_c.translate(`admin.settings.nav.${listItem}`)}
                </button>
              </li>
            );
          })}
        </ul>
      </div>
    );

    return <React.Fragment>{v__output}</React.Fragment>;
  }

  view__showSettingsPage() {
    const { selectedPage } = this.state;

    if (selectedPage === "general") {
      return this.view__showPageGeneral();
    } else if (selectedPage === "logo") {
      return this.view__showPageLogo();
    } else if (selectedPage === "login") {
      return this.view__showPageLogin();
    } else if (selectedPage === "signup") {
      return this.view__showPageSignup();
    } else if (selectedPage === "access") {
      return this.view__showPageAccesses();
    } else if (selectedPage === "home") {
      return this.view__showPageHome();
    } else if (selectedPage === "courses") {
      return this.view__showPageCourses();
    } else if (selectedPage === "community") {
      return this.view__showPageCommunity();
    } else if (selectedPage === "email_templates") {
      return this.view__showPageEmailTemplates();
    } else if (selectedPage === "legalinfo") {
      return this.view__showPageLegalinfo();
    } else if (selectedPage === "dataprivacy") {
      return this.view__showPageDataprivacy();
    } else if (selectedPage === "backgrounds") {
      return this.view__showPageBackgrounds();
    } else if (selectedPage === "tracking") {
      return this.view__showPageTracking();
    } else if (selectedPage === "domain") {
      return this.view__showPageDomain();
    }
  }

  view__showLoading() {
    return (
      <div className="admin-settings-loading">
        <Icon icon="circle-o-notch fa-spin" />
      </div>
    );
  }

  view__showPageGeneral() {
    const { status, textSettings } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    const { supportemail, mail_from_name, pagetitle } = textSettings;

    let maintenanceValues = {
      yes: _c.translate("admin.settings.general.maintenance.options.active"),
      no: _c.translate("admin.settings.general.maintenance.options.inactive"),
    };

    let forcedCallout;
    let maintenanceEditable = true;
    if (_c.setting("maintenance_forced") === true) {
      maintenanceEditable = false;
      forcedCallout = (
        <div className="small warning callout" style={{ marginTop: "10px" }}>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("admin.settings.general.maintenance.forced"),
            }}
          />
        </div>
      );
    }

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Box>
            <Grid type="full">
              <Row margin="xy">
                <Cell sm={24} md={20}>
                  <AdminDataField
                    value={supportemail.value}
                    editValue={supportemail.value}
                    editType="text"
                    label={_c.translate(
                      "admin.settings.general.supportemail.label"
                    )}
                    placeholder={_c.translate(
                      "admin.settings.general.supportemail.placeholder"
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      supportemail.value = newValue;
                      textSettings.supportemail = supportemail;
                      this.setState({ textSettings });
                    }}
                  />
                </Cell>
                <Cell sm={24} md={2}>
                  <div
                    className="admin-settings-buttons"
                    style={{
                      display:
                        supportemail.value === supportemail.original
                          ? "none"
                          : "block",
                    }}
                  >
                    <button
                      className="tiny hollow primary button"
                      onClick={() => {
                        supportemail.value = supportemail.original;
                        textSettings.supportemail = supportemail;
                        this.setState({ textSettings });
                      }}
                    >
                      <Icon icon="times" left />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      className="tiny primary button"
                      onClick={() => {
                        this.handle__updateSetting(
                          "supportemail",
                          supportemail.value
                        );
                      }}
                    >
                      <Icon icon="check" left />
                    </button>
                  </div>
                </Cell>
              </Row>

              <Row margin="xy">
                <Cell sm={24} md={20}>
                  <AdminDataField
                    value={mail_from_name.value}
                    editValue={mail_from_name.value}
                    editType="text"
                    label={_c.translate(
                      "admin.settings.general.mail_from_name.label"
                    )}
                    placeholder={_c.translate(
                      "admin.settings.general.mail_from_name.placeholder"
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      mail_from_name.value = newValue;
                      textSettings.mail_from_name = mail_from_name;
                      this.setState({ textSettings });
                    }}
                  />
                </Cell>
                <Cell sm={24} md={2}>
                  <div
                    className="admin-settings-buttons"
                    style={{
                      display:
                        mail_from_name.value === mail_from_name.original
                          ? "none"
                          : "block",
                    }}
                  >
                    <button
                      className="tiny hollow primary button"
                      onClick={() => {
                        mail_from_name.value = mail_from_name.original;
                        textSettings.mail_from_name = mail_from_name;
                        this.setState({ textSettings });
                      }}
                    >
                      <Icon icon="times" left />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      className="tiny primary button"
                      onClick={() => {
                        this.handle__updateSetting(
                          "mail_from_name",
                          mail_from_name.value
                        );
                      }}
                    >
                      <Icon icon="check" left />
                    </button>
                  </div>
                </Cell>
              </Row>

              <Row margin="xy">
                <Cell sm={24} md={20}>
                  <AdminDataField
                    value={pagetitle.value}
                    editValue={pagetitle.value}
                    editType="text"
                    label={_c.translate(
                      "admin.settings.general.pagetitle.label"
                    )}
                    placeholder={_c.translate(
                      "admin.settings.general.pagetitle.placeholder"
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      pagetitle.value = newValue;
                      textSettings.pagetitle = pagetitle;
                      this.setState({ textSettings });
                    }}
                  />
                </Cell>
                <Cell sm={24} md={2}>
                  <div
                    className="admin-settings-buttons"
                    style={{
                      display:
                        pagetitle.value === pagetitle.original
                          ? "none"
                          : "block",
                    }}
                  >
                    <button
                      className="tiny hollow primary button"
                      onClick={() => {
                        pagetitle.value = pagetitle.original;
                        textSettings.pagetitle = pagetitle;
                        this.setState({ textSettings });
                      }}
                    >
                      <Icon icon="times" left />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      className="tiny primary button"
                      onClick={() => {
                        this.handle__updateSetting(
                          "pagetitle",
                          pagetitle.value
                        );
                      }}
                    >
                      <Icon icon="check" left />
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Box>
          <Box>
            <h3>{_c.translate("admin.settings.general.maintenance.title")}</h3>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("admin.settings.general.maintenance.text"),
              }}
            />
            <Grid type="full">
              <Row margin="y">
                <Cell sm={24} md={24}>
                  <AdminDataField
                    value={
                      _c.setting("maintenance")
                        ? _c.translate(
                            "admin.settings.general.maintenance.options.active"
                          )
                        : _c.translate(
                            "admin.settings.general.maintenance.options.inactive"
                          )
                    }
                    editValue={_c.setting("maintenance") ? "yes" : "no"}
                    editType="select"
                    label={_c.translate(
                      "admin.settings.general.maintenance.label"
                    )}
                    selectValues={maintenanceValues}
                    editable={maintenanceEditable}
                    edit={true}
                    onUpdate={(newValue) => {
                      this.handle__updateSetting("maintenance", newValue);
                    }}
                  />
                </Cell>
              </Row>
            </Grid>
            {forcedCallout}
          </Box>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.general")}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showPageDomain() {
    const { status, textSettings, domain } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={`https://${domain}`}
                  editValue={`https://${domain}`}
                  editType="text"
                  label={_c.translate("admin.settings.domain.domain.label")}
                  editable={false}
                  edit={false}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <h4>{_c.translate("admin.settings.domain.title")}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate("admin.settings.domain.text"),
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.domain")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageLogo() {
    const { status, textSettings } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    const { logo_default, logo_login } = textSettings;

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          <Box>
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <strong>
                    {_c.translate(`admin.settings.logo.logo_default.label`)}
                  </strong>
                </Cell>
              </Row>
              <Row>
                <Cell sm={24} md={18}>
                  <AdminDataField
                    value={logo_default.value}
                    editValue={logo_default.value}
                    editType="text"
                    label={_c.translate(
                      `admin.settings.logo.logo_default.label`
                    )}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      logo_default.value = newValue;
                      textSettings.logo_default = logo_default;
                      this.setState({ textSettings });
                    }}
                  />
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      data-tip={_c.translate(
                        "admin.settings.logo.uploadButton.tooltip"
                      )}
                      className="tiny primary button"
                      onClick={() => {
                        this.setState({
                          selectedImage: "logo_default",
                        });
                        this.showPopupImageUpload();
                      }}
                    >
                      <Icon icon="upload" />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div
                    className="admin-settings-buttons"
                    style={{
                      display:
                        logo_default.value === logo_default.original
                          ? "none"
                          : "block",
                    }}
                  >
                    <button
                      className="tiny hollow primary button"
                      onClick={() => {
                        logo_default.value = logo_default.original;
                        textSettings.logo_default = logo_default;
                        this.setState({ textSettings });
                      }}
                    >
                      <Icon icon="times" left />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      className="tiny primary button"
                      onClick={() => {
                        this.handle__updateSetting(
                          "logo_default",
                          logo_default.value
                        );
                      }}
                    >
                      <Icon icon="check" left />
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Box>
          <Box>
            <Grid type="full">
              <Row>
                <Cell sm={24}>
                  <strong>
                    {_c.translate(`admin.settings.logo.logo_login.label`)}
                  </strong>
                </Cell>
              </Row>
              <Row>
                <Cell sm={24} md={18}>
                  <AdminDataField
                    value={logo_login.value}
                    editValue={logo_login.value}
                    editType="text"
                    label={_c.translate(`admin.settings.logo.logo_login.label`)}
                    editable={true}
                    edit={true}
                    onUpdate={(newValue) => {
                      logo_login.value = newValue;
                      textSettings.logo_login = logo_login;
                      this.setState({ textSettings });
                    }}
                  />
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      data-tip={_c.translate(
                        "admin.settings.logo.uploadButton.tooltip"
                      )}
                      className="tiny primary button"
                      onClick={() => {
                        this.setState({
                          selectedImage: "logo_login",
                        });
                        this.showPopupImageUpload();
                      }}
                    >
                      <Icon icon="upload" />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div
                    className="admin-settings-buttons"
                    style={{
                      display:
                        logo_login.value === logo_login.original
                          ? "none"
                          : "block",
                    }}
                  >
                    <button
                      className="tiny hollow primary button"
                      onClick={() => {
                        logo_login.value = logo_login.original;
                        textSettings.logo_login = logo_login;
                        this.setState({ textSettings });
                      }}
                    >
                      <Icon icon="times" left />
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={2}>
                  <div className="admin-settings-buttons">
                    <button
                      className="tiny primary button"
                      onClick={() => {
                        this.handle__updateSetting(
                          "logo_login",
                          logo_login.value
                        );
                      }}
                    >
                      <Icon icon="check" left />
                    </button>
                  </div>
                </Cell>
              </Row>
            </Grid>
          </Box>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.logo")}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showPageTracking() {
    const { status, textSettings } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    const { tracking_ga, tracking_fbpixel } = textSettings;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={20}>
                <AdminDataField
                  value={tracking_ga.value}
                  editValue={tracking_ga.value}
                  editType="text"
                  label={_c.translate(
                    "admin.settings.tracking.tracking_ga.label"
                  )}
                  placeholder={_c.translate(
                    "admin.settings.tracking.tracking_ga.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    tracking_ga.value = newValue;
                    textSettings.tracking_ga = tracking_ga;
                    this.setState({ textSettings });
                  }}
                />
              </Cell>
              <Cell sm={24} md={2}>
                <div
                  className="admin-settings-buttons"
                  style={{
                    display:
                      tracking_ga.value === tracking_ga.original
                        ? "none"
                        : "block",
                  }}
                >
                  <button
                    className="tiny hollow primary button"
                    onClick={() => {
                      tracking_ga.value = tracking_ga.original;
                      textSettings.tracking_ga = tracking_ga;
                      this.setState({ textSettings });
                    }}
                  >
                    <Icon icon="times" left />
                  </button>
                </div>
              </Cell>
              <Cell sm={24} md={2}>
                <div className="admin-settings-buttons">
                  <button
                    className="tiny primary button"
                    onClick={() => {
                      this.handle__updateSetting(
                        "tracking_ga",
                        tracking_ga.value
                      );
                    }}
                  >
                    <Icon icon="check" left />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={20}>
                <AdminDataField
                  value={tracking_fbpixel.value}
                  editValue={tracking_fbpixel.value}
                  editType="text"
                  label={_c.translate(
                    "admin.settings.tracking.tracking_fbpixel.label"
                  )}
                  placeholder={_c.translate(
                    "admin.settings.tracking.tracking_fbpixel.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    tracking_fbpixel.value = newValue;
                    textSettings.tracking_fbpixel = tracking_fbpixel;
                    this.setState({ textSettings });
                  }}
                />
              </Cell>
              <Cell sm={24} md={2}>
                <div
                  className="admin-settings-buttons"
                  style={{
                    display:
                      tracking_fbpixel.value === tracking_fbpixel.original
                        ? "none"
                        : "block",
                  }}
                >
                  <button
                    className="tiny hollow primary button"
                    onClick={() => {
                      tracking_fbpixel.value = tracking_fbpixel.original;
                      textSettings.tracking_fbpixel = tracking_fbpixel;
                      this.setState({ textSettings });
                    }}
                  >
                    <Icon icon="times" left />
                  </button>
                </div>
              </Cell>
              <Cell sm={24} md={2}>
                <div className="admin-settings-buttons">
                  <button
                    className="tiny primary button"
                    onClick={() => {
                      this.handle__updateSetting(
                        "tracking_fbpixel",
                        tracking_fbpixel.value
                      );
                    }}
                  >
                    <Icon icon="check" left />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.tracking")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageEmailTemplates() {
    let { status, textSettings, editorMode, emailTemplates } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    editorMode = "html";

    let v__output;

    let emailTemplateKeys = ["signup", "new_purchase", "password_reset"];

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          {emailTemplateKeys.map((emailTemplateKey, index) => {
            return (
              <Box key={index}>
                <h3>
                  {_c.translate(
                    `admin.settings.email_templates.types.${emailTemplateKey}.title`
                  )}
                </h3>
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate(
                      `admin.settings.email_templates.types.${emailTemplateKey}.text`
                    ),
                  }}
                />
                <Grid type="full">
                  <Row margin="y">
                    <Cell sm={24} md={24}>
                      <AdminDataField
                        value={emailTemplates[emailTemplateKey].subject}
                        editValue={emailTemplates[emailTemplateKey].subject}
                        editType="text"
                        label={_c.translate(
                          "admin.settings.email_templates.subject.label"
                        )}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) => {
                          emailTemplates[emailTemplateKey].subject = newValue;
                          this.setState({ emailTemplates });
                        }}
                      />
                    </Cell>
                  </Row>
                  <Row margin="y">
                    <Cell sm={24} md={24}>
                      <AdminEditor
                        key={emailTemplateKey}
                        value={emailTemplates[emailTemplateKey].text}
                        html={emailTemplates[emailTemplateKey].text_html}
                        allowVisual={false}
                        mode={editorMode}
                        onChange={({ value, html, mode }) => {
                          emailTemplates[emailTemplateKey].text = value;
                          emailTemplates[emailTemplateKey].text_html = html;
                          this.setState({ emailTemplates, editorMode: mode });
                        }}
                      />
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            );
          })}
          <div className="page-buttons">
            {/*<button
              className="small hollow primary button"
              onClick={this.cancelEmailTemplates}
            >
              <Icon icon="times" left /> {_c.translate("buttons.cancel")}
            </button>*/}
            <button
              className="small primary button"
              onClick={this.handle__saveEmailTemplates}
            >
              <Icon icon="check" left />{" "}
              {_c.translate("admin.settings.email_templates.buttons.save")}
            </button>
          </div>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.email_templates")}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showPageLegalinfo() {
    const { status, textSettings, editorMode } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    const { legalinfo, legalinfo_html } = textSettings;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={20}>
                <AdminEditor
                  key="legalinfo"
                  value={legalinfo.value}
                  html={legalinfo_html.value}
                  mode={editorMode}
                  onChange={({ value, html, mode }) => {
                    legalinfo.value = value;
                    legalinfo_html.value = html;
                    textSettings.legalinfo = legalinfo;
                    this.setState({ textSettings, editorMode: mode });
                  }}
                />
              </Cell>
              <Cell sm={24} md={2}>
                <div
                  className="admin-settings-buttons"
                  style={{
                    display:
                      legalinfo.value === legalinfo.original ? "none" : "block",
                  }}
                >
                  <button
                    className="tiny hollow primary button"
                    onClick={() => {
                      legalinfo.value = legalinfo.original;
                      textSettings.legalinfo = legalinfo;
                      this.setState({ textSettings });
                    }}
                  >
                    <Icon icon="times" left />
                  </button>
                </div>
              </Cell>
              <Cell sm={24} md={2}>
                <div className="admin-settings-buttons">
                  <button
                    className="tiny primary button"
                    onClick={() => {
                      this.handle__updateSetting("legalinfo", legalinfo.value);
                      this.handle__updateSetting(
                        "legalinfo_html",
                        legalinfo_html.value
                      );
                    }}
                  >
                    <Icon icon="check" left />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.legalinfo")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageDataprivacy() {
    const { status, textSettings, editorMode } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    const { dataprivacy, dataprivacy_html } = textSettings;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={20}>
                <AdminEditor
                  key="dataprivacy"
                  value={dataprivacy.value}
                  html={dataprivacy_html.value}
                  mode={editorMode}
                  onChange={({ value, html, mode }) => {
                    dataprivacy.value = value;
                    dataprivacy_html.value = html;
                    textSettings.dataprivacy = dataprivacy;
                    this.setState({ textSettings, editorMode: mode });
                  }}
                />
              </Cell>
              <Cell sm={24} md={2}>
                <div
                  className="admin-settings-buttons"
                  style={{
                    display:
                      dataprivacy.value === dataprivacy.original
                        ? "none"
                        : "block",
                  }}
                >
                  <button
                    className="tiny hollow primary button"
                    onClick={() => {
                      dataprivacy.value = dataprivacy.original;
                      textSettings.dataprivacy = dataprivacy;
                      this.setState({ textSettings });
                    }}
                  >
                    <Icon icon="times" left />
                  </button>
                </div>
              </Cell>
              <Cell sm={24} md={2}>
                <div className="admin-settings-buttons">
                  <button
                    className="tiny primary button"
                    onClick={() => {
                      this.handle__updateSetting(
                        "dataprivacy",
                        dataprivacy.value
                      );
                      this.handle__updateSetting(
                        "dataprivacy_html",
                        dataprivacy_html.value
                      );
                    }}
                  >
                    <Icon icon="check" left />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.dataprivacy")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageLogin() {
    const { status } = this.state;
    const _c = this.props.context;

    let loginLayoutValues = {
      vertical: _c.translate(
        "admin.settings.login.loginLayout.options.vertical"
      ),
      horizontal: _c.translate(
        "admin.settings.login.loginLayout.options.horizontal"
      ),
    };

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <AdminDataField
            value={_c.setting("login_layout")}
            editValue={_c.setting("login_layout")}
            editType="select"
            label={_c.translate("admin.settings.login.loginLayout.label")}
            selectValues={loginLayoutValues}
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              this.handle__updateSetting("login_layout", newValue);
            }}
          />
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.login")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageSignup() {
    const { status } = this.state;
    const _c = this.props.context;

    let signupEnabledValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let signupLastnameValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24}>
                <AdminDataField
                  value={_c.setting("signup_enabled")}
                  editValue={_c.setting("signup_enabled") ? "yes" : "no"}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.signup.signupEnabled.label"
                  )}
                  selectValues={signupEnabledValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting("signup_enabled", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24}>
                <AdminDataField
                  value={_c.setting("signup_lastname")}
                  editValue={_c.setting("signup_lastname") ? "yes" : "no"}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.signup.signupLastname.label"
                  )}
                  selectValues={signupLastnameValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting("signup_lastname", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.signup")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageAccesses() {
    const { status, moduleAccesses, courses, changesWereMade } = this.state;
    const _c = this.props.context;

    if (!moduleAccesses) {
      return null;
    }

    const items = ["community", "visionboard", "routines"];

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                {items.map((moduleKey, index) => {
                  const moduleAccess = moduleAccesses[moduleKey];
                  if (!_c.hasModule(moduleKey)) {
                    return null;
                  }
                  return (
                    <React.Fragment key={index}>
                      <Box>
                        <p>
                          <strong>
                            {_c.translate(
                              `admin.settings.access.${moduleKey}.title`
                            )}
                          </strong>
                          <br />
                          {_c.translate(
                            `admin.settings.access.${moduleKey}.text`
                          )}
                        </p>
                        <Grid type="full">
                          <Row margin="y">
                            <Cell sm={24} md={24}>
                              <AdminAccessSelect
                                accessSettingKey={moduleKey}
                                courses={courses}
                                access_granted={moduleAccess.access_granted}
                                access_granted_for={
                                  moduleAccess.access_granted_for
                                }
                                editMode={true}
                                changesWereMade={changesWereMade}
                                onUpdate={({
                                  access_granted,
                                  access_granted_for,
                                }) => {
                                  moduleAccesses[
                                    moduleKey
                                  ].access_granted = access_granted;
                                  moduleAccesses[
                                    moduleKey
                                  ].access_granted_for = access_granted_for;
                                  this.setState({ moduleAccesses });
                                }}
                              />
                              <div className="admin-settings-buttons text-right">
                                <button
                                  className="tiny primary button"
                                  style={{ width: "auto" }}
                                  onClick={() => {
                                    this.handle__saveModuleAccess(
                                      moduleAccesses[moduleKey]
                                    );
                                  }}
                                >
                                  <Icon icon="check" left />{" "}
                                  {_c.translate("buttons.save")}
                                </button>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </Box>
                    </React.Fragment>
                  );
                })}
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.access")}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showPageHome() {
    const { status } = this.state;
    const _c = this.props.context;

    let homeAllowCoursesValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let homeAllowVisionboardValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("home_allow_courses")}
                  editValue={_c.setting("home_allow_courses") ? "yes" : "no"}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.home.homeAllowCourses.label"
                  )}
                  selectValues={homeAllowCoursesValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting("home_allow_courses", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("home_allow_visionboard")}
                  editValue={
                    _c.setting("home_allow_visionboard") ? "yes" : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.home.homeAllowVisionboard.label"
                  )}
                  selectValues={homeAllowVisionboardValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "home_allow_visionboard",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.home")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageCourses() {
    const { status, textSettings } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    const { course_lesson_finish_button } = textSettings;

    let courseLessonLayoutValues = {
      layout1: _c.translate(
        "admin.settings.courses.courseLessonLayout.options.layout1"
      ),
      layout2: _c.translate(
        "admin.settings.courses.courseLessonLayout.options.layout2"
      ),
      layout3: _c.translate(
        "admin.settings.courses.courseLessonLayout.options.layout3"
      ),
    };

    let courseOverviewLayoutValues = {
      vertical: _c.translate(
        "admin.settings.login.loginLayout.options.vertical"
      ),
      horizontal: _c.translate(
        "admin.settings.login.loginLayout.options.horizontal"
      ),
    };

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("course_overview_layout")}
                  editValue={_c.setting("course_overview_layout")}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.courses.courseOverviewLayout.label"
                  )}
                  selectValues={courseOverviewLayoutValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "course_overview_layout",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("course_lesson_layout")}
                  editValue={_c.setting("course_lesson_layout")}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.courses.courseLessonLayout.label"
                  )}
                  selectValues={courseLessonLayoutValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "course_lesson_layout",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>
            <Row margin="y">
              <Cell sm={24} md={20}>
                <AdminDataField
                  value={course_lesson_finish_button.value}
                  editValue={course_lesson_finish_button.value}
                  editType="number"
                  min={0}
                  max={100}
                  label={_c.translate(
                    "admin.settings.courses.courseLessonFinishButton.label"
                  )}
                  placeholder={_c.translate(
                    "admin.settings.courses.courseLessonFinishButton.placeholder"
                  )}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    course_lesson_finish_button.value = newValue;
                    textSettings.course_lesson_finish_button = course_lesson_finish_button;
                    this.setState({ textSettings });
                  }}
                />
              </Cell>
              <Cell sm={24} md={2}>
                <div
                  className="admin-settings-buttons"
                  style={{
                    display:
                      course_lesson_finish_button.value ===
                      course_lesson_finish_button.original
                        ? "none"
                        : "block",
                  }}
                >
                  <button
                    className="tiny hollow primary button"
                    onClick={() => {
                      course_lesson_finish_button.value =
                        course_lesson_finish_button.original;
                      textSettings.course_lesson_finish_button = course_lesson_finish_button;
                      this.setState({ textSettings });
                    }}
                  >
                    <Icon icon="times" left />
                  </button>
                </div>
              </Cell>
              <Cell sm={24} md={2}>
                <div className="admin-settings-buttons">
                  <button
                    className="tiny primary button"
                    onClick={() => {
                      this.handle__updateSetting(
                        "course_lesson_finish_button",
                        course_lesson_finish_button.value
                      );
                    }}
                  >
                    <Icon icon="check" left />
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.courses")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageCommunity() {
    const { status } = this.state;
    const _c = this.props.context;

    /*let communityPostShowAvatarValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };*/

    let communityPostShowRegdateValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let communityPostShowPostcountValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let communityProfileAllowLinksValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let communityAllowLikesValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let communityAllowUploadsValues = {
      yes: _c.translate("admin.settings.boolean.true"),
      no: _c.translate("admin.settings.boolean.false"),
    };

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <Grid type="full">
            {/*<Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_post_show_avatar")}
                  editValue={
                    _c.setting("community_post_show_avatar") ? "yes" : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityPostShowAvatar.label"
                  )}
                  selectValues={communityPostShowAvatarValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_post_show_avatar",
                      newValue
                    );
                  }}
                />
              </Cell>
                </Row>*/}

            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_post_show_regdate")}
                  editValue={
                    _c.setting("community_post_show_regdate") ? "yes" : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityPostShowRegdate.label"
                  )}
                  selectValues={communityPostShowRegdateValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_post_show_regdate",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>

            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_post_show_postcount")}
                  editValue={
                    _c.setting("community_post_show_postcount") ? "yes" : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityPostShowPostcount.label"
                  )}
                  selectValues={communityPostShowPostcountValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_post_show_postcount",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>

            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_profile_allow_sociallinks")}
                  editValue={
                    _c.setting("community_profile_allow_sociallinks")
                      ? "yes"
                      : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityProfileAllowLinks.label"
                  )}
                  selectValues={communityProfileAllowLinksValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_profile_allow_sociallinks",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>

            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_allow_likes")}
                  editValue={_c.setting("community_allow_likes") ? "yes" : "no"}
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityAllowLikes.label"
                  )}
                  selectValues={communityAllowLikesValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_allow_likes",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>

            <Row margin="y">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={_c.setting("community_allow_uploads")}
                  editValue={
                    _c.setting("community_allow_uploads") ? "yes" : "no"
                  }
                  editType="select"
                  label={_c.translate(
                    "admin.settings.community.communityAllowUploads.label"
                  )}
                  selectValues={communityAllowUploadsValues}
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => {
                    this.handle__updateSetting(
                      "community_allow_uploads",
                      newValue
                    );
                  }}
                />
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.community")}</h3>
        <Box>{v__output}</Box>
      </React.Fragment>
    );
  }

  view__showPageBackgrounds() {
    const { status, textSettings, designBackgroundKeys } = this.state;
    const _c = this.props.context;

    if (!textSettings) {
      return null;
    }

    let v__output;

    if (status === "LOADING") {
      v__output = this.view__showLoading();
    } else {
      v__output = (
        <React.Fragment>
          <ReactTooltip
            place={_c.getToolTipSetting("position")}
            type={_c.getToolTipSetting("type")}
            effect={_c.getToolTipSetting("effect")}
          />
          {designBackgroundKeys.map((key, index) => {
            const backgroundSetting = textSettings[key];

            return (
              <Box key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={24}>
                      <strong>
                        {_c.translate(
                          `admin.settings.backgrounds.${key}.label`
                        )}
                      </strong>
                    </Cell>
                  </Row>
                  <Row>
                    <Cell sm={24} md={18}>
                      <AdminDataField
                        value={backgroundSetting.value}
                        editValue={backgroundSetting.value}
                        editType="text"
                        label={_c.translate(
                          `admin.settings.backgrounds.${key}.label`
                        )}
                        editable={true}
                        edit={true}
                        onUpdate={(newValue) => {
                          backgroundSetting.value = newValue;
                          textSettings.backgroundSetting = backgroundSetting;
                          this.setState({ textSettings });
                        }}
                      />
                    </Cell>
                    <Cell sm={24} md={2}>
                      <div className="admin-settings-buttons">
                        <button
                          data-tip={_c.translate(
                            "admin.settings.backgrounds.uploadButton.tooltip"
                          )}
                          className="tiny primary button"
                          onClick={() => {
                            this.setState({ selectedBackgroundImage: key });
                            this.showPopupImageUpload();
                          }}
                        >
                          <Icon icon="upload" />
                        </button>
                      </div>
                    </Cell>
                    <Cell sm={24} md={2}>
                      <div
                        className="admin-settings-buttons"
                        style={{
                          display:
                            backgroundSetting.value ===
                            backgroundSetting.original
                              ? "none"
                              : "block",
                        }}
                      >
                        <button
                          className="tiny hollow primary button"
                          onClick={() => {
                            backgroundSetting.value =
                              backgroundSetting.original;
                            textSettings.backgroundSetting = backgroundSetting;
                            this.setState({ textSettings });
                          }}
                        >
                          <Icon icon="times" left />
                        </button>
                      </div>
                    </Cell>
                    <Cell sm={24} md={2}>
                      <div className="admin-settings-buttons">
                        <button
                          className="tiny primary button"
                          onClick={() => {
                            this.handle__updateSetting(
                              key,
                              backgroundSetting.value
                            );
                          }}
                        >
                          <Icon icon="check" left />
                        </button>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            );
          })}
        </React.Fragment>
      );
    }

    return (
      <React.Fragment>
        <h3>{_c.translate("admin.settings.nav.backgrounds")}</h3>
        {v__output}
      </React.Fragment>
    );
  }

  view__showPopups() {
    const { uploadImageStatus, cropUrl, crop } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        <MMPopup
          show={uploadImageStatus === "upload"}
          size="medium"
          nopadding
          handleClose={this.hidePopupImageUpload}
        >
          <Dropzone
            onDrop={(acceptedFiles) => {
              const picture = acceptedFiles[0];

              this.setState({ cropImage: picture });

              const reader = new FileReader();

              reader.addEventListener(
                "load",
                function () {
                  this.setState({ cropUrl: reader.result });
                  this.hidePopupImageUpload();
                  this.showPopupImageCrop();
                }.bind(this),
                false
              );

              if (picture) {
                reader.readAsDataURL(picture);
              }
            }}
          >
            {({ getRootProps, getInputProps }) => (
              <section>
                <div {...getRootProps()}>
                  <input {...getInputProps()} />
                  <div id="avatarDropZone">
                    <h3>
                      {_c.translate(
                        "admin.settings.backgrounds.uploadPopup.title"
                      )}
                    </h3>
                    <div id="avatarDropZoneText">
                      <p>
                        <Icon icon="upload" />
                      </p>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate(
                            "admin.settings.backgrounds.uploadPopup.text"
                          ),
                        }}
                      />
                    </div>
                  </div>
                </div>
              </section>
            )}
          </Dropzone>
        </MMPopup>

        <MMPopup
          show={uploadImageStatus === "crop"}
          size="small"
          nopadding
          handleClose={() => {
            this.hidePopupImageCrop();
          }}
        >
          <div className="avatar-crop-heading">
            <h3>
              {_c.translate("admin.settings.backgrounds.cropPopup.title")}
            </h3>
          </div>

          <ReactCrop src={cropUrl} crop={crop} onChange={this.onCrop} />

          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="avatar-crop-buttons">
                  <button
                    className="hollow primary button"
                    onClick={() => {
                      this.hidePopupImageCrop();
                    }}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="primary button"
                    disabled={this.isCropSaveButtonDisabled()}
                    onClick={this.uploadImage}
                  >
                    <Icon icon="check" left />{" "}
                    {_c.translate(
                      "admin.settings.backgrounds.cropPopup.buttons.save"
                    )}
                  </button>
                </div>
              </Cell>
            </Row>
          </Grid>
        </MMPopup>
      </React.Fragment>
    );
  }

  isCropSaveButtonDisabled() {
    if ((this.state.crop.height === 0) | (this.state.crop.width === 0)) {
      return true;
    } else {
      return false;
    }
  }

  onCrop = (crop, percentCrop) => {
    this.setState({ crop: percentCrop });
  };

  showPopupImageUpload = () => {
    this.setState({ uploadImageStatus: "upload" });
  };

  hidePopupImageUpload = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageCrop = () => {
    this.setState({ uploadImageStatus: "crop" });
  };

  hidePopupImageCrop = () => {
    this.setState({ uploadImageStatus: null });
  };

  showPopupImageDelete = () => {
    this.setState({ uploadImageStatus: "delete" });
  };

  hidePopupImageDelete = () => {
    this.setState({ uploadImageStatus: null });
  };

  uploadImage = () => {
    const {
      cropImage,
      crop,
      selectedBackgroundImage,
      selectedImage,
    } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    let apiUrl = null;
    let successType = null;

    if (selectedBackgroundImage) {
      formData.append("file", cropImage);
      formData.append("name", cropImage.name);
      formData.append("crop", JSON.stringify(crop));
      formData.append("background_key", selectedBackgroundImage);

      apiUrl = _c.apiUrl("admin.settingsBackground");
      successType = selectedBackgroundImage;
    } else if (selectedImage) {
      formData.append("file", cropImage);
      formData.append("name", cropImage.name);
      formData.append("crop", JSON.stringify(crop));
      formData.append("setting_key", selectedImage);

      apiUrl = _c.apiUrl("admin.settingsImage");
      successType = selectedImage;
    }

    _c.setIsSaving(true, 5);
    this.hidePopupImageCrop();

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { status } = response.data;
        _c.handleApiResponse(response.data, true);

        if (status === "SUCCESS") {
          const { accesses, courses } = response.data.data;
          this.setState({ moduleAccesses: accesses, courses });
          this.hidePopupImageCrop();
          this.initTextSettings();

          _c.setIsSaving(false);

          _c.cancelEditLock();
          _c.createNotifictation(
            _c.translate("admin.settings.messages.saved.title"),
            _c
              .translate("admin.settings.messages.saved.text")
              .replace("{settingName}", `${successType}`),
            "success"
          );

          _c.initFinished();
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  cancelEmailTemplates = () => {
    const _c = this.props.context;

    if (
      window.confirm(
        _c.translate("admin.settings.email_templates.cancel.confirm")
      )
    ) {
      this.setState({
        emailTemplates: cloneDeep(this.state.originalEmailTemplates),
      });
    }
  };

  handle__updateSetting(key, value) {
    const _c = this.props.context;

    this.setState({ status: "LOADING" });

    if (value === null) {
      value = "";
    }

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.settings");

      axios
        .post(
          apiUrl,
          {
            key,
            value,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            _c.handleApiResponse(response.data, true);

            this.setState({ status: "LOADED" });

            this.initTextSettings();

            _c.cancelEditLock();
            _c.createNotifictation(
              _c.translate("admin.settings.messages.saved.title"),
              _c
                .translate("admin.settings.messages.saved.text")
                .replace("{settingName}", key),
              "success"
            );

            /*if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { livecall } = apiResponseData;

              if (requestedId === "new" && livecall && livecall.id) {
                this.props.history.push(
                  _c.config("urls.admin.livecall").replace(":id", livecall.id)
                );
                return;
              }

              const original = JSON.parse(JSON.stringify(livecall));
              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  editMode: false,
                  livecall,
                  original,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    "Eintrag gespeichert",
                    "Die Änderungen am Live Call wurden erfolgreich gespeichert.",
                    "success"
                  );
                }
              );
            }*/
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:settings:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:settings:save");
        });
    }, 200);
  }

  handle__saveEmailTemplates = () => {
    const { emailTemplates } = this.state;
    const _c = this.props.context;

    this.setState({ status: "LOADING" });

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.settingsEmailTemplates");

      axios
        .post(
          apiUrl,
          {
            email_templates: emailTemplates,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            _c.handleApiResponse(response.data, true);

            this.setState({ status: "LOADED" });

            this.initTextSettings();

            _c.cancelEditLock();
            _c.createNotifictation(
              _c.translate(
                "admin.settings.email_templates.messages.saved.title"
              ),
              _c.translate(
                "admin.settings.email_templates.messages.saved.text"
              ),
              "success"
            );
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:settings:save:emailtemplates:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:settings:emailtemplates:save");
        });
    }, 200);
  };

  handle__saveModuleAccess(moduleAccess) {
    const _c = this.props.context;

    this.setState({ status: "LOADING" });

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.moduleAccess");

      axios
        .post(
          apiUrl,
          {
            module: moduleAccess.module,
            access_granted: moduleAccess.access_granted,
            access_granted_for: moduleAccess.access_granted_for,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            _c.handleApiResponse(response.data, true);

            this.setState({ status: "LOADED" });

            this.initTextSettings();

            _c.cancelEditLock();
            _c.createNotifictation(
              _c.translate("admin.settings.messages.saved.title"),
              _c
                .translate("admin.settings.messages.saved.text")
                .replace("{settingName}", `${moduleAccess.module}_access`),
              "success"
            );
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:settings:save:moduleaccess:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:settings:moduleaccess:save");
        });
    }, 200);
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.settings");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const {
              accesses,
              courses,
              emailTemplates,
              domain,
            } = response.data.data;
            this.setState({
              moduleAccesses: accesses,
              courses,
              emailTemplates,
              originalEmailTemplates: cloneDeep(emailTemplates),
              domain,
            });
            this.initTextSettings();
          }
          _c.setPageTitle("pageTitles.admin.settings", true);
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:settings:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:settings:init");
      });
  }

  initTextSettings() {
    const _c = this.props.context;
    const { designBackgroundKeys } = this.state;

    let legalinfo = _c.setting("legalinfo");

    let dataprivacy = _c.setting("dataprivacy");

    const textSettings = {
      supportemail: {
        value: _c.setting("supportemail"),
        original: _c.setting("supportemail"),
      },
      mail_from_name: {
        value: _c.setting("mail_from_name"),
        original: _c.setting("mail_from_name"),
      },
      pagetitle: {
        value: _c.setting("pagetitle"),
        original: _c.setting("pagetitle"),
      },
      logo_default: {
        value: _c.setting("logo_default"),
        original: _c.setting("logo_default"),
      },
      logo_login: {
        value: _c.setting("logo_login"),
        original: _c.setting("logo_login"),
      },
      course_lesson_finish_button: {
        value: _c.setting("course_lesson_finish_button"),
        original: _c.setting("course_lesson_finish_button"),
      },
      tracking_ga: {
        value: _c.setting("tracking_ga"),
        original: _c.setting("tracking_ga"),
      },
      tracking_fbpixel: {
        value: _c.setting("tracking_fbpixel"),
        original: _c.setting("tracking_fbpixel"),
      },
      legalinfo: {
        value: legalinfo,
        original: legalinfo,
      },
      legalinfo_html: {
        value: _c.setting("legalinfo_html"),
        original: _c.setting("legalinfo_html"),
      },
      dataprivacy: {
        value: dataprivacy,
        original: dataprivacy,
      },
      dataprivacy_html: {
        value: _c.setting("dataprivacy_html"),
        original: _c.setting("dataprivacy_html"),
      },
    };

    designBackgroundKeys.map((key) => {
      textSettings[key] = { value: _c.setting(key), original: _c.setting(key) };
      return null;
    });

    this.setState({ textSettings });
  }
}

export default withContext(AdminSettings);
