import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import HomeVideo from "../../../home/HomeVideo";
import Audio from "../parts/Audio";
import Countdown from "../../../_reusables/Countdown";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import { Icon } from "../../../_foundation/_buttons";
import CoursesGrid from "../CoursesGrid";

class TruePowerFlow extends Component {
  state = {
    course: this.props.course,
    courses: this.props.courses,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    showCountdownButton: false,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.courses !== this.props.courses) {
      this.setState({ courses: this.props.courses });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }
  }

  render() {
    const { lessons, courses } = this.state;
    if (!lessons) {
      return null;
    }

    const _c = this.props.context;

    return (
      <div className={`course-page-truepowerflow`}>
        <Grid>
          <Row>
            <Cell sm={24}>{this.view__showCategories()}</Cell>
          </Row>
        </Grid>
        <CoursesGrid
          purchased={courses.purchased}
          available={courses.available}
          _c={_c}
          full={false}
        />
      </div>
    );
  }

  view__showCategories() {
    const { lessons } = this.state;
    const _c = this.props.context;

    return (
      <div>
        {lessons.map((category) => {
          let v__banner;

          if (category.banner) {
            v__banner = <img src={category.banner} alt="" />;
          } else if (category.image) {
            v__banner = <img src={category.image} alt="" />;
          }

          if (v__banner) {
            if (category.short_description) {
              v__banner = (
                <a
                  href={category.short_description}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {v__banner}
                </a>
              );
            }

            v__banner = <div>{v__banner}</div>;
          }

          let v__description;
          if (category.description_html) {
            v__description = (
              <div
                dangerouslySetInnerHTML={{ __html: category.description_html }}
              />
            );
          }

          return (
            <div className="section" key={category.id}>
              {v__banner}
              {v__description}

              {category.lessons.map((lesson) => {
                let v__image;
                if (lesson.image) {
                  v__image = (
                    <div>
                      <img
                        src={lesson.image}
                        alt=""
                        style={{
                          width: "100%",
                          height: "auto",
                          margin: "auto",
                          display: "block",
                        }}
                      />
                    </div>
                  );
                }

                let v__media;
                if (lesson.type === "audio") {
                  v__media = (
                    <div>
                      <div className="course-lesson-audio">
                        <Audio
                          lesson={lesson}
                          onListen={(currentTime) => {
                            this.setState({ audioSeconds: currentTime });
                          }}
                        />
                      </div>
                      <div className="text-right">
                        <a
                          href={_c.apiUrl("downloads.audio", { id: lesson.id })}
                          className="small primary hollow button"
                        >
                          <Icon icon="download" left /> DOWNLOAD
                        </a>
                      </div>
                    </div>
                  );
                } else if (lesson.type === "text") {
                  if (lesson.description_html.includes("%countdown%")) {
                    v__media = this.countdown();
                  } else {
                    let description = lesson.description_html;

                    description = description.replace(
                      "%button%",
                      '<a class="large primary button" href="https://www.instagram.com/loa.helser/" target="_blank" rel="noopener noreferrer"><i class="fa fa-instagram"></i> &nbsp;LOA AUF INSTAGRAM</a>'
                    );

                    description = description.replace(
                      "%button2%",
                      '<a class="large primary button" style="transform: scale(1.3)" href="https://truepower.loahelser.com/online" target="_blank" rel="noopener noreferrer">I AM READY!</a>'
                    );

                    description = description.replace(
                      "%button_tpmeetsbw%",
                      '<a class="large primary button" style="transform: scale(1.3)" href="https://truepower.loahelser.com/breathwork" target="_blank" rel="noopener noreferrer">I want to join!</a>'
                    );

                    description = description.replace(
                      "%button_tp%",
                      '<a class="large primary button" style="transform: scale(1.3)" href="https://truepower.loahelser.com/online" target="_blank" rel="noopener noreferrer">Hier kommst du zur Anmeldung</a>'
                    );

                    v__media = (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: description,
                        }}
                      />
                    );
                  }
                } else {
                  v__media = <HomeVideo video={lesson} />;
                }

                return (
                  <div key={lesson.id}>
                    {v__image}
                    {v__media}
                  </div>
                );
              })}
            </div>
          );
        })}
      </div>
    );
  }

  countdown() {
    const countdownDate = new Date();
    countdownDate.setFullYear(2021);
    countdownDate.setMonth(4);
    countdownDate.setDate(13);
    countdownDate.setHours(7);
    countdownDate.setMinutes(0);
    countdownDate.setSeconds(0);

    /*countdownDate.setFullYear(2021);
    countdownDate.setMonth(4);
    countdownDate.setDate(12);
    countdownDate.setHours(22);
    countdownDate.setMinutes(23);
    countdownDate.setSeconds(0);*/

    return (
      <Grid type="full">
        <Row>
          <Cell sm={24} md={12} mdo={6}>
            <Countdown
              date={countdownDate}
              preAction={10 * 60}
              onPreAction={() => {}}
              onZero={() => {
                /*console.log("COUNTDOWN ZERO");
                document.getElementById("countdownButton").style.display =
                  "block";*/
              }}
              type="home-countdown"
            />
            <div
              id="countdownButton"
              className="text-center"
              style={{ marginTop: "40px", display: "none" }}
            >
              <a
                href="http://bit.ly/truepower5"
                target="_blank"
                rel="noopener noreferrer"
                className="large secondary button"
                style={{
                  background: "#333",
                  color: "white",
                  transform: "scale(1.2)",
                }}
              >
                Anmeldung & mehr Infos hier
                <Icon icon="heart" right />
              </a>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }
}

export default withContext(TruePowerFlow);
