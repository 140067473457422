import React from "react";
import { Icon } from "../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import Progress from "./parts/Progress";

const Courses = ({ courses, _c, baseUrl, type }) => {
  return (
    <Row margin="xy">
      {courses.map((course, index) => {
        let v__progress;

        let v__image = (
          <div className="course-no-image">
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate("courses.overview.noimage"),
              }}
            />
          </div>
        );
        if (course.image) {
          v__image = <img src={course.image} alt={course.name} />;
        }

        let v__description;
        if (course.description) {
          v__description = <div className="course-description">{course.description}</div>;
        }

        let v__button;
        let courseUrl;

        if (type === "purchased") {
          courseUrl = baseUrl.replace(":courseSlug", course.slug);

          if (course.has_page) {
            courseUrl = _c.config("urls.coursePage").replace(":courseSlug", course.slug);
          }

          if (course.slug === "ausbildung") {
            courseUrl = _c.config("urls.home.truepowerausbildung");
          }
        } else {
          courseUrl = course.purchase_url;
        }

        if (type === "purchased") {
          v__button = (
            <div className="course-button">
              <a href={courseUrl} className="primary button">
                {course.id === 3 ? "BACK TO LOA HOME" : "SHOW COURSE"}{" "}
                <Icon icon="angle-double-right" right />
              </a>
            </div>
          );

          v__image = <a href={courseUrl}>{v__image}</a>;
        } else {
          v__button = (
            <div className="course-button">
              <a
                href={courseUrl}
                className="primary button"
                target="_blank"
                rel="noopener noreferrer"
              >
                MEHR ERFAHREN
                <Icon icon="angle-double-right" right />
              </a>
            </div>
          );

          v__image = (
            <a href={courseUrl} target="_blank" rel="noopener noreferrer">
              {v__image}
            </a>
          );
        }

        v__progress = <Progress course={course} where="overview" />;

        return (
          <Cell sm={24} md={8} key={index}>
            <div className="course-overview-course">
              <div className="course-image">{v__image}</div>
              {v__progress}
              <div className="course-content text-center">
                <h3>{course.name}</h3>
                {v__description}
              </div>
              {v__button}
            </div>
          </Cell>
        );
      })}
    </Row>
  );
};

function CoursesGrid({ purchased, available, _c, full }) {
  const baseUrl = _c.config("urls.course");

  return (
    <div
      style={{
        width: "100%",
        padding: "60px 0",
        background: "rgb(240, 240, 240)",
        position: "relative",
        top: "30px",
      }}
    >
      <Grid type={full ? "full" : undefined}>
        <Row margin="xy">
          <Cell sm={24}>
            <h2>{_c.translate("pageTitles.courseOverview")}</h2>
            <Courses courses={purchased} type="purchased" _c={_c} baseUrl={baseUrl} />
            {available && available.length > 0 ? (
              <>
                <hr />
                <h2>Weitere Kurse</h2>
                <Courses courses={available} type="available" _c={_c} baseUrl={baseUrl} />
              </>
            ) : null}
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

export default CoursesGrid;
