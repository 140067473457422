import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";
import Video from "../parts/Video";
import Audio from "../parts/Audio";
import LessonList from "../parts/LessonList";
import LessonNotes from "../parts/LessonNotes";
import FinishButton from "../parts/FinishButton";
import Description from "../parts/Description";
import Box from "../../../layout/Box";
import CategoryList from "../parts/CategoryList";

class Layout1 extends Component {
  state = {
    _updatableProps: [
      "course",
      "lesson",
      "lessons",
      "downloads",
      "status",
      "showNotePopup",
      "selectedNote",
      "player",
      "showLessonListOnMobile",
    ],
    course: this.props.course,
    lesson: this.props.lesson,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    status: this.props.status,
    showNotePopup: this.props.showNotePopup,
    selectedNote: this.props.selectedNote,
    player: this.props.player,
    showLessonListOnMobile: this.props.showLessonListOnMobile,
    showFinishButton: false,
    audioSeconds: null,
  };

  componentDidMount() {
    const _c = this.props.context;

    let settingCourseLessonFinishButtonPercent = parseInt(
      _c.setting("course_lesson_finish_button")
    );

    if (
      this.state.lesson.type === "text" ||
      settingCourseLessonFinishButtonPercent === 0
    ) {
      this.setState({ showFinishButton: true });
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });

        if (propKey === "lesson") {
          if (this.props.lesson.type === "text") {
            this.setState({ showFinishButton: true });
          }
        }
      }
      return null;
    });
  }

  render() {
    const {
      course,
      lesson,
      lessons,
      downloads,
      status,
      showNotePopup,
      selectedNote,
      player,
      audioSeconds,
    } = this.state;
    const _c = this.props.context;

    let v__media, v__downloadButton, v__questionButton;
    if (lesson.type === "audio") {
      let v__image;

      if (lesson.image) {
        v__image = (
          <Box size="nopadding">
            <img src={lesson.image} alt="" />
          </Box>
        );
      }

      v__media = (
        <div>
          {v__image}
          <div className="course-lesson-audio">
            <Audio
              lesson={lesson}
              onListen={(currentTime) => {
                this.setState({ audioSeconds: currentTime });
              }}
            />
          </div>
        </div>
      );

      v__downloadButton = (
        <React.Fragment>
          &nbsp;
          <a
            href={_c.apiUrl("downloads.audio", { id: lesson.id })}
            target="_blank"
            rel="noopener noreferrer"
            className="primary button"
          >
            <Icon icon="download" left /> Download
          </a>
          &nbsp;
        </React.Fragment>
      );

      v__questionButton = (
        <React.Fragment>
          <button
            className="primary button"
            onClick={() => {
              _c.showQAPopup(true);
            }}
          >
            <Icon icon="question-circle" left /> STELLE DEINE FRAGE
          </button>
          &nbsp;
        </React.Fragment>
      );
    } else if (lesson.type === "text") {
      v__media = null;
    } else {
      v__media = (
        <Video
          course={course}
          lesson={lesson}
          status={status}
          player={player}
          onUpdate={(lessonStatus) => {
            const { lesson } = this.state;
            lesson.lessonStatus = lessonStatus;

            let showFinishButton = false;
            if (_c.isDebug()) {
              console.log(parseInt(lessonStatus.progress));
            }

            let settingCourseLessonFinishButtonPercent = parseInt(
              _c.setting("course_lesson_finish_button")
            );

            if (
              !settingCourseLessonFinishButtonPercent &&
              settingCourseLessonFinishButtonPercent !== 0
            ) {
              settingCourseLessonFinishButtonPercent = 0;
            }

            if (
              parseInt(lessonStatus.progress) >=
                parseInt(settingCourseLessonFinishButtonPercent) ||
              lesson.type === "text"
            ) {
              showFinishButton = true;
            }

            this.setState({ lesson, showFinishButton }, () => {
              this.onUpdateLayout();
            });
          }}
          onUpdatePlayer={(player) => {
            this.setState({ player }, () => {
              this.onUpdateLayout();
            });
          }}
        />
      );
    }

    let v__list = null;
    if (course.slug === "ausbildung") {
      v__list = (
        <CategoryList
          course={course}
          lessons={lessons}
          downloads={downloads}
          lesson={lesson}
        />
      );
    } else {
      v__list = (
        <LessonList
          course={course}
          lesson={lesson}
          lessons={lessons}
          downloads={downloads}
          status={status}
          showLessonListOnMobile={true}
          closeLessonListOnMobile={() => {
            this.setState({ showLessonListOnMobile: false }, () => {
              this.onUpdateLayout();
            });
          }}
        />
      );
    }

    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>{this.view__showTitle()}</Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={17}>
              {v__media}
              <Description lesson={lesson} />
              <div className="course-lesson-below-video-buttons">
                {/*<button
                  className="primary hollow button show-for-small-only"
                  onClick={() => {
                    this.setState({ showLessonListOnMobile: true }, () => {
                      this.onUpdateLayout();
                    });
                  }}
                >
                  <Icon icon="th-list" left />{" "}
                  {_c.translate("courses.lesson.lessons")}
                </button>*/}
                {v__downloadButton}
                {v__questionButton}
                <button
                  className="primary button"
                  onClick={() => {
                    this.setState(
                      {
                        showNotePopup: true,
                        selectedNote: "new",
                      },
                      () => {
                        this.onUpdateLayout();
                      }
                    );
                  }}
                >
                  <Icon icon="sticky-note" left />{" "}
                  {_c.translate("courses.lesson.notes.addNote")}
                </button>
              </div>
              <LessonNotes
                audioSeconds={audioSeconds}
                lesson={lesson}
                showNotePopup={showNotePopup}
                selectedNote={selectedNote}
                onNoteSave={(lesson) => {
                  this.setState({ lesson }, () => {
                    this.onUpdateLayout();
                  });
                }}
                onGoToSecond={(seconds) => {
                  const { player } = this.state;
                  player.setCurrentTime(seconds);
                  this.setState({ player }, () => {
                    this.onUpdateLayout();
                  });
                }}
                onPopupClose={(showNotePopup, selectedNote) => {
                  this.setState({ showNotePopup, selectedNote }, () => {
                    this.onUpdateLayout();
                  });
                }}
              />
            </Cell>
            <Cell sm={24} md={7}>
              {v__list}
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showTitle() {
    const { course, lesson } = this.state;

    if (!course || !lesson) {
      return;
    }

    let pretitle, title;

    if (course.name) {
      pretitle = <div className="course-lesson-pretitle">{course.name}</div>;
    }

    if (lesson.name) {
      title = <h1>{lesson.name}</h1>;
    }

    return (
      <header className="text-center medium-text-left">
        {pretitle}
        {title}
      </header>
    );
  }

  onUpdateLayout = () => {
    const {
      course,
      lesson,
      lessons,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile,
    } = this.state;

    this.props.onUpdateLayout(
      course,
      lesson,
      lessons,
      status,
      showNotePopup,
      selectedNote,
      player,
      showLessonListOnMobile
    );
  };
}

export default withContext(Layout1);
