import React from "react";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";

const optionsKey = "icon";

const icons = [
  "home",
  "globe",
  "heart",
  "heart-o",
  "star",
  "star-half",
  "star-half-o",
  "star-o",
  "thumbs-up",
  "thumbs-o-up",
  "thumbs-down",
  "thumbs-o-down",
  "search",
  "at",
  "ban",
  "bars",
  "bell",
  "bell-o",
  "bolt",
  "bomb",
  "rocket",
  "book",
  "bookmark",
  "bookmark-o",
  "bullhorn",
  "bullseye",
  "calendar",
  "calendar-check-o",
  "calendar-o",
  "calendar-plus-o",
  "camera",
  "camera-retro",
  "play",
  "play-circle",
  "youtube-play",
  "cart-plus",
  "check",
  "check-circle",
  "check-circle-o",
  "check-square",
  "check-square-o",
  "female",
  "male",
  "street-view",
  "child",
  "frown-o",
  "meh-o",
  "smile-o",
  "caret-square-o-up",
  "caret-square-o-right",
  "caret-square-o-down",
  "caret-square-o-left",
  "cart-arrow-down",
  "angle-up",
  "angle-right",
  "angle-down",
  "angle-left",
  "angle-double-up",
  "angle-double-right",
  "angle-double-down",
  "angle-double-left",
  "arrow-up",
  "arrow-right",
  "arrow-down",
  "arrow-left",
  "arrow-circle-up",
  "arrow-circle-right",
  "arrow-circle-down",
  "arrow-circle-left",
  "arrow-circle-o-up",
  "arrow-circle-o-right",
  "arrow-circle-o-down",
  "arrow-circle-o-left",
  "clock-o",
  "clone",
  "close",
  "cloud",
  "cloud-download",
  "cloud-upload",
  "coffee",
  "cog",
  "cogs",
  "comment",
  "comment-o",
  "commenting",
  "commenting-o",
  "comments",
  "comments-o",
  "compass",
  "money",
  "credit-card",
  "credit-card-alt",
  "cube",
  "cubes",
  "dashboard",
  "desktop",
  "laptop",
  "tablet",
  "mobile",
  "phone",
  "television",
  "dot-circle-o",
  "download",
  "envelope",
  "envelope-o",
  "envelope-open",
  "envelope-open-o",
  "exchange",
  "exclamation",
  "exclamation-circle",
  "exclamation-triangle",
  "info",
  "info-circle",
  "question",
  "question-circle",
  "question-circle-o",
  "external-link",
  "external-link-square",
  "eye",
  "fire",
  "flag",
  "flag-o",
  "glass",
  "graduation-cap",
  "user",
  "user-o",
  "user-circle",
  "user-circle-o",
  "group",
  "hand-peace-o",
  "handshake-o",
  "hashtag",
  "microphone",
  "podcast",
  "music",
  "headphones",
  "key",
  "diamond",
  "trophy",
  "tree",
  "leaf",
  "snowflake-o",
  "paw",
  "sun-o",
  "moon-o",
  "life-ring",
  "lightbulb-o",
  "lock",
  "magic",
  "map-marker",
  "map-pin",
  "pencil",
  "paint-brush",
  "paper-plane",
  "paper-plane-o",
  "plus",
  "plus-circle",
  "plus-square",
  "plus-square-o",
  "random",
  "instagram",
  "facebook",
  "facebook-official",
  "facebook-square",
  "linkedin",
  "linkedin-square",
  "xing",
  "xing-square",
  "twitter",
  "whatsapp",
  "telegram",
  "android",
  "apple",
  "chrome",
  "firefox",
  "dropbox",
  "linux",
  "paypal",
  "spotify",
  "soundcloud",
  "vimeo",
  "vimeo-square",
  "youtube",
  "youtube-square",
];

function ControlIconPicker({ item, onUpdate }) {
  const getValue = () => {
    let value;
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onSelect = (iconKey) => {
    if (getValue() === iconKey) onUpdate(`options.${optionsKey}`, null);
    else onUpdate(`options.${optionsKey}`, iconKey);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <Grid type="full">
        <Row>
          {icons.map((iconKey) => {
            return (
              <Cell sm={6} md={2} key={iconKey}>
                <button
                  onClick={() => onSelect(iconKey)}
                  className={`pagebuilder-control-icon-picker-button ${
                    getValue() === iconKey && `selected`
                  }`}
                >
                  <Icon icon={iconKey} />
                </button>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
}

export default ControlIconPicker;
