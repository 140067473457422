import React, { useState, useEffect } from "react";
import { Cell } from "../../../_foundation/_grid";
import AddButton from "./AddButton";
import PageElement from "./PageElement";
import PageItemName from "./PageItemName";

function PageColumn(props) {
  const [column, setColumn] = useState(props.column);

  useEffect(() => {
    setColumn(props.column);
  }, [props.column]);

  if (!column || !column.column_type) return null;

  let elementPosition = 1;

  return (
    <Cell
      sm={column.column_type.sm}
      md={column.column_type.md}
      lg={column.column_type.lg}
    >
      <div className="pagebuilder-item pagebuilder-column">
        <PageItemName
          type="column"
          item={column}
          label={`Spalte ${column.position}`}
          onMoveButton={(direction) =>
            props.actions.onMoveItem(column.identifier, direction)
          }
          onEditButton={() => props.actions.onSelectColumn(column)}
        />
        <div className="column-content">
          {/*[COLUMN {column.identifier}] [{column.position}]*/}
          <AddButton
            type="element"
            column={column.identifier}
            position={elementPosition}
            structure={props.structure}
            onUpdate={props.actions.onUpdate}
          />
          {column.elements.map((element) => {
            elementPosition++;
            return (
              <React.Fragment key={element.identifier}>
                <PageElement element={element} actions={props.actions} />

                <AddButton
                  type="element"
                  column={column.identifier}
                  position={elementPosition}
                  structure={props.structure}
                  onUpdate={props.actions.onUpdate}
                />
              </React.Fragment>
            );
          })}
        </div>
      </div>
    </Cell>
  );
}

export default PageColumn;
