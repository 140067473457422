import { cloneDeep } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import { PtContext } from "../../../context/ptProvider";
import ConfigHelper from "../../../helpers/ConfigHelper";
import UrlHelper from "../../../helpers/UrlHelper";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import { Icon } from "../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import Loading from "../../_reusables/Loading";
import AdminLayout from "../AdminLayout";
import PageBuilderAPI from "./PageBuilderAPI";

const initBreadcrumbs = ["admin_dashboard", "admin_pagebuilder"];
const initTabs = {
  coursePages: {
    name: "Kursseiten",
    icon: "youtube-play",
  },
  categoryPages: {
    name: "Kategorieseiten",
    icon: "list-alt",
  },
  misc: {
    name: "Weitere Seiten",
    icon: "file-o",
  },
};

function Pages(props) {
  const _c = useContext(PtContext);

  const [breadcrumbs, setBreadcrumbs] = useState(initBreadcrumbs);
  const [tabs, setTabs] = useState(initTabs);

  const [selectedTab, setSelectedTab] = useState("coursePages");
  const [pages, setPages] = useState(null);
  const [extra, setExtra] = useState(null);

  useEffect(() => {
    _c.setPageTitle("PageBuilder", true);
    if (!pages) {
      PageBuilderAPI.getPages(_c, ({ pages, extra }) => {
        setPages(pages);
        setExtra(extra);

        const updatedTabs = {};
        Object.keys(initTabs).map((tabKey) => {
          const tab = cloneDeep(initTabs[tabKey]);
          if (pages[tabKey]) tab.name = `${tab.name} (${pages[tabKey].length})`;
          updatedTabs[tabKey] = tab;
          return null;
        });
        setTabs(updatedTabs);
      });
    }
  }, []);

  const getCourseName = (courseId) => {
    let result = courseId;

    if (extra && extra.courses) {
      const course = extra.courses.find((c) => parseInt(c.id) === parseInt(courseId));
      if (course && course.name) return course.name;
    }

    return result;
  };

  const getCategoryName = (courseId, categoryId) => {
    let result = categoryId;

    if (extra && extra.courses) {
      const course = extra.courses.find((c) => parseInt(c.id) === parseInt(courseId));
      if (course) {
        const category = course.categories.find((c) => parseInt(c.id) === categoryId);
        if (category && category.name) return category.name;
      }
    }

    return result;
  };

  if (!pages)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="admin-pagebuilder">
      <AdminLayout nav="pagebuilder" breadcrumbs={breadcrumbs}>
        <h1>PageBuilder</h1>
        <div className="text-right">
          <AddButton />
        </div>
        <div className="admin-tabs">
          <MMTabs
            tabs={tabs}
            selected={selectedTab}
            onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
          />
        </div>
        <PagesList
          pages={pages}
          getCourseName={getCourseName}
          getCategoryName={getCategoryName}
          selectedTab={selectedTab}
        />
      </AdminLayout>
    </div>
  );
}

const AddButton = () => {
  return (
    <Link
      to={UrlHelper.url("admin.pagebuilder.page", { identifier: "new" })}
      className="small primary button"
    >
      <Icon icon="plus" left /> Neue Seite anlegen
    </Link>
  );
};

const PagesList = ({ pages, getCourseName, getCategoryName, selectedTab }) => {
  if (!pages || !pages[selectedTab]) return null;

  const pageUrl = "";

  return (
    <>
      <PagesListHeader />
      {pages[selectedTab].length === 0 ? (
        <div className="text-center" style={{ paddingTop: "10px" }}>
          <em>Keine Seiten in dieser Kategorie.</em>
        </div>
      ) : (
        <ul className="admin-list">
          {pages[selectedTab].map((page, index) => {
            const stats = pages.stats[page.identifier];
            return (
              <li key={index}>
                <Link
                  className="clickable"
                  to={UrlHelper.url("admin.pagebuilder.page", {
                    identifier: page.identifier,
                  })}
                >
                  <Grid type="full">
                    <Row>
                      <Cell md={10}>
                        <div className="padding">
                          <div className="pagebuilder-list-name">
                            <Icon icon="file-o" left /> {page.name}
                          </div>
                          <div className="pagebuilder-list-url">{pageUrl}</div>
                        </div>
                      </Cell>
                      <Cell md={6}>
                        <div className="padding">
                          {page.entrypages ? (
                            <>
                              {page.entrypages.map((entrypage, index) => {
                                if (entrypage[0])
                                  return <div key={index}>{getCourseName(entrypage[0])}</div>;
                                return null;
                              })}
                            </>
                          ) : null}
                        </div>
                      </Cell>
                      <Cell md={6} className="text-center">
                        <div className="padding">
                          {page.entrypage_category &&
                            getCategoryName(page.entrypage_course, page.entrypage_category)}
                        </div>
                      </Cell>
                      <Cell md={2} className="text-center">
                        <div className="padding">
                          <Icon icon={page.draft ? `check` : "minus"} />
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </Link>
              </li>
            );
          })}
        </ul>
      )}
    </>
  );
};

const PagesListHeader = () => {
  return (
    <div className="admin-list-title">
      <ReactTooltip
        place={ConfigHelper.get("tooltips.position")}
        type={ConfigHelper.get("tooltips.type")}
        effect={ConfigHelper.get("tooltips.effect")}
      />
      <Grid type="full">
        <Row>
          <Cell md={10}>
            <div className="padding">Seite</div>
          </Cell>
          <Cell md={6}>
            <div className="padding">Kurs</div>
          </Cell>
          <Cell md={6} className="text-center">
            <div className="padding">Kategorie</div>
          </Cell>
          <Cell md={2} className="text-center">
            <div className="padding">
              <span data-tip={"Entwurf?"}>
                <Icon icon="pencil-square-o" />
              </span>
            </div>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default Pages;
