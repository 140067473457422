import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Link } from "react-router-dom";

class Footer extends Component {
  render() {
    const _c = this.props.context;

    return (
      <footer>
        <div className="default-footer">
          <p className="text-center">
            v{_c.getVersion()} |{" "}
            <a
              href="https://loahelser.com/privacy-policy/"
              target="_blank"
              rel="noopener noreferrer"
            >
              PRIVACY POLICY
            </a>
          </p>
        </div>
      </footer>
    );
  }
}

export default withContext(Footer);
