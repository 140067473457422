import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Grid, Row, Cell, Icon } from "../_foundation/foundation";
import withContext from "../../context/contextHOC";
import { Line } from "rc-progress";
import Avatar from "../user/Avatar";
import KeyboardEventHandler from "react-keyboard-event-handler";

import imageCloseButton from "../../images/close-button.png";
import Search from "../search/Search";

import qs from "query-string";
//import { cloneDeep } from "lodash";
import HomeVideo from "../home/HomeVideo";
import QAPopup from "./QAPopup";
import Banner from "./Banner";

class MainTopbar extends Component {
  state = {
    banner: this.props.banner,
    type: this.props.type || "page",
    showMenu: false,
    showContentMenu: false,
    showSearch: false,
    preview: false,
    selectedContentCategory: null,
  };

  componentDidMount() {
    const queryParams = qs.parse(this.props.location.search);
    if (queryParams && queryParams.design && queryParams.design === "preview") {
      this.setState({ preview: true });
    }

    //window.addEventListener("scroll", this.handleScroll);
  }

  componentDidUpdate(prevProps, prevState) {
    //window.removeEventListener("scroll", this.handleScroll);
  }

  render() {
    const { type, showSearch, preview } = this.state;
    const _c = this.props.context;

    return (
      <div>
        <KeyboardEventHandler
          handleKeys={["all"]}
          onKeyEvent={(key, event) => {
            //console.log(key);
            if (key === "esc") {
              this.hideMenu();
              this.hideSearch();
            }
          }}
          handleFocusableElements={true}
        />
        <div id="maintenanceModeBar">
          <Icon icon="info-circle" left />{" "}
          <span
            dangerouslySetInnerHTML={{
              __html: _c.translate("general.maintenance.text"),
            }}
          />
        </div>
        <div id="mainTopbar" className={`topbar topbar-${type}`}>
          {this.view__showContent()}
        </div>
        {_c.loadStylesheets(preview)}
        {this.view__showMenu()}
        {this.view__showContentMenu()}
        {this.view__showQAPopup()}
        {this.view__showSavingModal()}
        {this.view__showErrorModal()}
        <Search show={showSearch} toggleSearch={this.toggleSearch} />
      </div>
    );
  }

  view__showContent() {
    const { type } = this.state;

    const v__loginButton = this.view__loginButton();

    const _c = this.props.context;
    const logoUrl = this.getLogoUrl();

    /*let v__logo;
    const logoDefault = _c.setting("logo_default");
    if (logoDefault) {
      v__logo = <img src={logoDefault} alt="Logo" className="top-logo" />;
    }*/

    let gridType;
    if (_c.config("general.showHelp") === true) {
      gridType = "full";
    }

    gridType = "full";

    let v__searchButton;
    if (_c.setting("search_show") === true) {
      v__searchButton = (
        <button className="top-search-button" onClick={this.toggleSearch}>
          <Icon icon="search" />
        </button>
      );
    }

    if (type === "index") {
      return (
        <div className="topbar-index">
          <Grid type="full">
            <Row padding="x">
              <Cell sm={24} md={5} className="hide-for-small-only">
                &nbsp;
              </Cell>
              <Cell sm={10} md={12} className="text-left medium-text-left">
                <div className="index-topbar-nav"></div>
              </Cell>
              <Cell sm={2} md={2} className="text-right">
                {v__searchButton}
              </Cell>
              <Cell sm={12} md={5} classes="text-right">
                <div>{v__loginButton}</div>
              </Cell>
            </Row>
          </Grid>
        </div>
      );
    } else {
      return (
        <div className={`topbar-page grid-type-${gridType}`}>
          {/*<div className="top-welcome">
            Hello, Beautiful! <Icon icon="heart" />
      </div>*/}
          <div className="top-default">
            <Grid type={gridType}>
              <Row>
                <Cell sm={2} md={6}>
                  <button
                    id="contentMenuToggle"
                    className="top-mainmenu-button"
                    onClick={this.toggleContentMenu}
                  >
                    <Icon icon="bars" />
                  </button>
                  <Link
                    to={_c.config("urls.courses")}
                    className="top-mainmenu-button top-mainmenu-courses hide-for-small-only"
                  >
                    MY PRODUCTS
                  </Link>
                </Cell>
                <Cell sm={20} md={12} className="text-center">
                  <Link onClick={this.hideMenu} to={logoUrl}>
                    <div className="top-message">
                      <img
                        src="https://static.loahelser.com/images/loa-home-w.png"
                        alt="loa home - a sacred space for your truth"
                      />
                    </div>
                  </Link>
                </Cell>
                <Cell sm={8} md={5} classes="text-right hide-for-small-only">
                  <a
                    href="mailto:support@loahelser.de?subject=LOA%HOME%Support"
                    className="topbar-supportbutton hide-for-small-only"
                  >
                    <Icon icon="envelope-o" left />
                    <span> SUPPORT</span>
                  </a>
                </Cell>
                <Cell sm={2} md={1} classes="text-right">
                  <div>{v__loginButton}</div>
                </Cell>
              </Row>
            </Grid>
          </div>
          <Banner banner={this.getBanner()} logoUrl={logoUrl} hideMenu={this.hideMenu} />
        </div>
      );
    }
  }

  view__showMenu = () => {
    const { showMenu } = this.state;
    const _c = this.props.context;

    if (!_c.isUserLoggedIn()) {
      return;
    }

    const user = _c.getUser();

    let menuItemCourses, menuItemCommunity, menuItemVisionboard, menuItemRoutines;
    let menuItemAdmin, menuItemOwnProfile;
    if (_c.isAdmin() === true) {
      menuItemAdmin = (
        <React.Fragment>
          <li className="separator"></li>
          <li>
            <Link onClick={this.hideMenu} to={_c.config("urls.admin.admin")}>
              {_c.translate("topbar.usermenu.adminarea")}
            </Link>
          </li>
        </React.Fragment>
      );
    }

    if (user.username && _c.hasModuleAccess("community")) {
      menuItemOwnProfile = (
        <Link
          onClick={this.hideMenu}
          to={_c.config("urls.communityProfile").replace(":username", _c.user.username)}
        >
          {_c.translate("topbar.usermenu.profile")}
        </Link>
      );
    }

    if (_c.hasModuleAccess("courses")) {
      menuItemCourses = (
        <li>
          <Link onClick={this.hideMenu} to={_c.config("urls.courses")}>
            {_c.translate("mainMenu.courses")}
          </Link>
        </li>
      );
    }

    if (_c.hasModuleAccess("community")) {
      menuItemCommunity = (
        <li>
          <Link onClick={this.hideMenu} to={_c.config("urls.communityAreas")}>
            {_c.translate("mainMenu.community")}
          </Link>
        </li>
      );
    }

    if (_c.hasModuleAccess("visionboard")) {
      menuItemVisionboard = (
        <li>
          <Link onClick={this.hideMenu} to={_c.config("urls.visionboard")}>
            {_c.translate("mainMenu.visionboard")}
          </Link>
        </li>
      );
    }

    if (_c.hasModuleAccess("routines")) {
      const now = new Date();
      const year = now.getFullYear();
      const month = now.getMonth() + 1;

      menuItemRoutines = (
        <li>
          <Link
            onClick={this.hideMenu}
            to={_c.config("urls.routinesMonth").replace(":year", year).replace(":month", month)}
          >
            {_c.translate("mainMenu.routines")}
          </Link>
        </li>
      );
    }

    if (showMenu === true) {
      return (
        <React.Fragment>
          <div className="menu-modal" onClick={this.toggleMenu} />
          <div className="main-menu-wrapper" id="mainMenu">
            <div className="main-menu">
              <Grid type="full">
                <Row>
                  <Cell
                    sm={24}
                    md={12}
                    className="text-center medium-text-left small-order-2 medium-order-1"
                  >
                    {/*<h1>{_c.translate("mainMenu.menu")}</h1>*/}
                  </Cell>
                  <Cell sm={24} md={12} className="text-right small-order-1 medium-order-2">
                    <img
                      className="menu-close-button"
                      src={imageCloseButton}
                      alt="X"
                      onClick={this.toggleMenu}
                    />
                  </Cell>
                </Row>
              </Grid>
              <Grid type="full">
                <Row margin="xy">
                  <Cell sm={24} md={24} className="text-center medium-text-right">
                    <ul className="main-menu-list">
                      <li>{menuItemOwnProfile}</li>
                      <li>
                        <Link onClick={this.hideMenu} to={_c.config("urls.account")}>
                          {_c.translate("topbar.usermenu.account")}
                        </Link>
                      </li>
                      {/*<li>
                      <Link
                        onClick={this.hideMenu}
                        to={_c.config("urls.privacy")}
                      >
                        {_c.translate("topbar.usermenu.privacy")}
                      </Link>
                    </li>*/}
                      <li>
                        <Link onClick={this.hideMenu} to="/password">
                          {_c.translate("topbar.usermenu.changePassword")}
                        </Link>
                      </li>
                      {menuItemAdmin}
                      <li className="separator"></li>
                      <li>
                        <button
                          className="button-logout"
                          onClick={() => {
                            _c.handleLogout();
                          }}
                        >
                          {_c.translate("topbar.usermenu.logout")}
                        </button>
                      </li>
                    </ul>
                  </Cell>
                </Row>
              </Grid>
            </div>
          </div>
        </React.Fragment>
      );
    }

    return null;
  };

  view__showContentMenu() {
    let { showContentMenu } = this.state;
    const _c = this.props.context;

    const app = _c.getApp();
    if (app && app.tpa) {
      const { tpa } = app;

      if (
        tpa.access &&
        tpa.access === true &&
        tpa.categories &&
        tpa.latestVideos &&
        window.location.href.includes("ausbildung")
      ) {
        if (showContentMenu === true) {
          let v__newVideos = (
            <Grid type="full">
              <Row margin="xy">
                {tpa.latestVideos.map((video, index) => {
                  let v__image;

                  if (video.image) {
                    v__image = (
                      <a
                        href={_c
                          .config("urls.courseLesson")
                          .replace(":courseSlug", tpa.course.slug)
                          .replace(":lessonSlug", video.slug)}
                        className="home-categories-category"
                      >
                        <img src={video.image} alt="" />
                      </a>
                    );
                  } else {
                    v__image = <HomeVideo video={video} />;
                  }

                  return (
                    <Cell sm={24} md={12} key={index}>
                      <div>{v__image}</div>
                      <div>
                        <h3>{video.name}</h3>
                        <div className="video-description">{video.supershort_description}</div>
                      </div>
                    </Cell>
                  );
                })}
              </Row>
            </Grid>
          );

          return (
            <React.Fragment>
              <div className="menu-modal" onClick={this.toggleContentMenu} />
              <div className="content-menu" id="contentMenu">
                <div className="content-menu-content">
                  {/*<div className="float-right">
                    <div className="error-popup-closebutton">
                      <button onClick={this.toggleContentMenu}>
                        <img
                          className="menu-close-button"
                          src={imageCloseButton}
                          alt="X"
                        />
                      </button>
                    </div>
                  </div>
          <h1>TRUE POWER Ausbildung</h1>*/}
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={24} md={8}>
                        <ul className="content-menu-menu">
                          <li>
                            <Link
                              to={_c.config("urls.home.truepowerausbildung")}
                              onClick={this.toggleContentMenu}
                            >
                              <span className="icon">
                                <Icon icon="angle-double-right" />
                              </span>{" "}
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to="/courses">MY PRODUCTS</Link>
                          </li>
                          {tpa.categories.map((category, index) => {
                            if (category.featured === true) {
                              return (
                                <li key={index}>
                                  <a
                                    href={_c
                                      .config("urls.courseCategory")
                                      .replace(":courseSlug", tpa.course.slug)
                                      .replace(":categorySlug", category.id)}
                                    className="home-categories-category"
                                  >
                                    <span className="icon">
                                      <Icon icon="angle-double-right" />
                                    </span>{" "}
                                    {category.name}
                                  </a>
                                </li>
                              );
                            }

                            return null;
                          })}
                          <li className="content-menu-fb-group">
                            <a
                              href="https://www.facebook.com/groups/1363539220660269"
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <Icon icon="facebook-square" left /> Zur Facebook-Gruppe
                            </a>
                          </li>
                          {/*<li className="content-menu-qa">
                            <button
                              onClick={() => {
                                _c.showQAPopup(true);
                                this.setState({ showContentMenu: false });
                              }}
                            >
                              <Icon icon="comments-o" left /> Stelle Deine
                              Frage!
                            </button>
                            </li>*/}
                        </ul>
                      </Cell>
                      <Cell sm={24} md={16}>
                        <h2>New Videos and Audios</h2>
                        {v__newVideos}
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </React.Fragment>
          );
        }
      } else {
        if (showContentMenu === true) {
          return (
            <React.Fragment>
              <div className="menu-modal" onClick={this.toggleContentMenu} />
              <div className="content-menu" id="contentMenu">
                <div className="content-menu-content">
                  <Grid type="full">
                    <Row margin="xy">
                      <Cell sm={24} md={8}>
                        <ul className="content-menu-menu">
                          <li>
                            <Link to={"/"} onClick={this.toggleContentMenu}>
                              <span className="icon">
                                <Icon icon="angle-double-right" />
                              </span>{" "}
                              Home
                            </Link>
                          </li>
                          <li>
                            <Link to="/courses">MY PRODUCTS</Link>
                          </li>
                        </ul>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </React.Fragment>
          );
        }
      }
    }
  }

  view__showQAPopup() {
    const _c = this.props.context;

    const showQAPopup = _c.showQAPopup();

    if (showQAPopup === true) {
      return <QAPopup show={showQAPopup} />;
    }
  }

  /*view__showMobileNav() {
    const { showMobileNav } = this.state;

    if (showMobileNav === true) {
      return (
        <div className="show-for-small-only" id="mobileNav">
          <ul className="mobile-menu">
            <li>
              <Link to="/courses">
                <Icon icon="youtube-play" left /> Kurse
              </Link>
            </li>
          </ul>
          <div>&nbsp;</div>
          <ul className="mobile-menu">{this.view__showUserNav()}</ul>
        </div>
      );
    }

    return null;
  }

  view__mobileNavButton() {
    return (
      <span
        id="mobileNavButton"
        onClick={() => {
          this.toggleNav();
        }}
      >
        <Icon icon="bars" />
      </span>
    );
  }*/

  view__loginButton() {
    const _c = this.props.context;
    const { user } = _c;

    if (_c.userIsLoaded === false) {
      return <Icon icon="circle-o-notch fa-spin" />;
    } else if (user && user.active && user.active === true) {
      /*let menuStyles;
      const { showUserMenu } = this.state;
      if (showUserMenu === true) {
        menuStyles = { display: "block" };
      } else {
        menuStyles = { display: "none" };
      }*/

      return (
        <div className="dropdown-menu-wrapper" id="dropdownMenuWrapper">
          <strong
            className="dropdown-menu-trigger topbar-username"
            //onMouseOver={this.showMenu}
            onClick={() => {
              if (this.state.showMenu === true) {
                this.hideMenu();
              } else if (this.state.showMenu === false) {
                this.showMenu();
              }
            }}
          >
            {/*<span className="hide-for-small-only">{user.firstname}</span>{" "}*/}
            <Avatar user={user} size="main-topbar" />
          </strong>
        </div>
      );
    } else {
      return <React.Fragment>{this.view__showLoginButton()}</React.Fragment>;
    }
  }

  /*view__showUserNav() {
    const _c = this.props.context;

    let menuItemAdmin = null;
    if (_c.isAdmin() === true) {
      menuItemAdmin = (
        <React.Fragment>
          <li className="separator">
            <hr />
          </li>
          <li>
            <Link to="/admin">{_c.translate("topbar.usermenu.adminarea")}</Link>
          </li>
        </React.Fragment>
      );
    }

    if (_c.isUserLoggedIn()) {
      return (
        <React.Fragment>
          <li>
            <Link
              to={_c
                .config("urls.communityProfile")
                .replace(":username", _c.user.username)}
            >
              {_c.translate("topbar.usermenu.profile")}
            </Link>
          </li>
          <li>
            <Link to={_c.config("urls.account")}>
              {_c.translate("topbar.usermenu.account")}
            </Link>
          </li>
          <li>
            <Link to={_c.config("urls.privacy")}>
              {_c.translate("topbar.usermenu.privacy")}
            </Link>
          </li>
          <li>
            <Link to="/password">
              {_c.translate("topbar.usermenu.changePassword")}
            </Link>
          </li>
          {menuItemAdmin}
          <li className="separator">
            <hr />
          </li>
          <li>
            <button
              onClick={() => {
                _c.handleLogout();
              }}
            >
              {_c.translate("topbar.usermenu.logout")}
            </button>
          </li>
        </React.Fragment>
      );
    } else {
      return <li>{this.view__showLoginButton()}</li>;
    }
  }*/

  view__showLoginButton() {
    return (
      <button
        onClick={() => {
          localStorage.setItem("page_after_login", window.location.href);
          window.location.href = "/login";
        }}
        className="topbar-login-button"
      >
        <Icon icon="user-circle-o " left /> &nbsp;Anmelden
      </button>
    );
  }

  view__showErrorModal() {
    const _c = this.props.context;

    if (_c.hasErrors()) {
      let errors = _c.getErrors();

      let v__title = _c.translate("errors.titles.oneError");
      if (errors.length > 1) {
        v__title = _c.translate("errors.titles.manyErrors");
      }

      return (
        <div className="error-modal">
          <div className="error-popup">
            <div className="error-popup-content">
              <Grid>
                <Row>
                  <Cell sm={24} md={14} mdo={5}>
                    <div className="error-popup-icon">
                      <Icon icon="exclamation-triangle" />
                    </div>
                    <h1>{v__title}</h1>
                    <div className="error-popup-errors">
                      {errors.map((error, index) => {
                        const { instance, timestamp, status, action, message } = error;

                        return (
                          <div className="error-popup-error" key={index}>
                            <Grid type="full">
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">
                                    {_c.translate("errors.instance")}:
                                  </span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text error-text-timestamp">
                                    {instance}
                                  </span>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">{_c.translate("errors.url")}:</span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text error-text-timestamp">
                                    {window.location.href}
                                  </span>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">
                                    {_c.translate("errors.timestamp")}:
                                  </span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text error-text-timestamp">
                                    {timestamp}
                                  </span>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">
                                    {_c.translate("errors.status")}:
                                  </span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text">{status}</span>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">
                                    {_c.translate("errors.action")}:
                                  </span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text">{action}</span>
                                </Cell>
                              </Row>
                              <Row>
                                <Cell sm={24} md={8}>
                                  <span className="error-label">
                                    {_c.translate("errors.message")}:
                                  </span>
                                </Cell>
                                <Cell sm={24} md={16}>
                                  <span className="error-text">{message}</span>
                                </Cell>
                              </Row>
                            </Grid>
                          </div>
                        );
                      })}
                    </div>
                    <div className="error-popup-closemessage">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: _c.translate("errors.closeMessage"),
                        }}
                      />
                      <div className="error-popup-closebutton">
                        <button className="small primary hollow button" onClick={_c.resetErrors}>
                          <Icon icon="times" left /> {_c.translate("errors.closeButton")}
                        </button>
                      </div>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </div>
          </div>
        </div>
      );
    }

    return;
  }

  view__showSavingModal() {
    const _c = this.props.context;

    if (_c.isSaving()) {
      const savingType = _c.getSavingType();
      let extraText;
      if (savingType === "pdfmail") {
        extraText = (
          <div className="saving-popup-extra-text">
            Die Erstellung von PDFs und der Versand von E-Mails können ggfs. bis zu 20 Sekunden
            dauern.
          </div>
        );
      } else if (savingType === "pdf") {
        extraText = (
          <div className="saving-popup-extra-text">
            Die Erstellung von PDFs kann ggfs. bis zu 20 Sekunden dauern.
          </div>
        );
      } else if (savingType === "mail") {
        extraText = (
          <div className="saving-popup-extra-text">
            Der Versand von E-Mails kann ggfs. bis zu 20 Sekunden dauern.
          </div>
        );
      } else if (savingType === "videoupload") {
        extraText = (
          <div className="saving-popup-extra-text">
            Der Video-Upload kann abhängig von der Größe mehrere Minuten dauern. Bitte schließe die
            Seite nicht.
          </div>
        );
      }

      let savingProgress = _c.getSavingProgress();

      let progressBar = (
        <div className="saving-popup-icon">
          <Icon icon="circle-o-notch fa-spin" />
        </div>
      );

      if (savingProgress !== null) {
        progressBar = (
          <div className="saving-popup-progressbar">
            <Line percent={savingProgress} strokeWidth="3" strokeColor="#d0a98f" />
          </div>
        );
      }

      return (
        <div className="saving-modal">
          <div className="saving-popup">
            <div className="saving-popup-content">
              {progressBar}
              <div className="saving-popup-text">Wird gespeichert ...</div>
              {extraText}
            </div>
          </div>
        </div>
      );
    }
  }

  toggleSearch = () => {
    const { showSearch } = this.state;
    if (showSearch === true) {
      this.setState({ showSearch: false, showMenu: false });
    } else {
      this.setState({ showSearch: true, showMenu: false });
    }
  };

  showSearch = () => {
    this.setState({ showSearch: true, showMenu: false });
  };

  hideSearch = () => {
    this.setState({ showSearch: false, showMenu: false });
  };

  toggleMenu = () => {
    const { showMenu } = this.state;
    if (showMenu === true) {
      this.setState({ showMenu: false, showSearch: false });
    } else {
      this.setState({ showMenu: true, showSearch: false });
    }
  };

  showMenu = () => {
    this.setState({ showMenu: true, showSearch: false });
  };

  hideMenu = () => {
    this.setState({ showMenu: false, showSearch: false });
  };

  toggleContentMenu = () => {
    const { showContentMenu } = this.state;
    if (showContentMenu === true) {
      this.setState({ showContentMenu: false, showSearch: false });
    } else {
      this.setState({ showContentMenu: true, showSearch: false });
    }
  };

  showContentMenu = () => {
    this.setState({ showContentMenu: true, showSearch: false });
  };

  hideContentMenu = () => {
    this.setState({ showContentMenu: false, showSearch: false });
  };

  menuPosition = () => {
    /*
    try {
      const userNameElement = document.getElementById("dropdownMenuWrapper");
      const mainMenuElement = document.getElementById("mainMenu");
      if (userNameElement && mainMenuElement) {
        let position = userNameElement.offsetLeft;
        position = position + userNameElement.offsetWidth;
        position = position - mainMenuElement.offsetWidth + 0;

        mainMenuElement.style.left = `${parseInt(position)}px`;

        //console.log("position", position);
      }
    } catch (error) {}

    try {
      const toggleElement = document.getElementById("contentMenuToggle");
      const contentMenuElement = document.getElementById("contentMenu");
      if (toggleElement && contentMenuElement) {
        let position = toggleElement.offsetLeft - 10;

        contentMenuElement.style.left = `${parseInt(position)}px`;

        //console.log("position", position);
      }
    } catch (error) {}
    */
  };

  getBanner() {
    let { banner } = this.state;
    if (!banner) {
      banner = "universe";
    }

    return banner;
  }

  getLogoUrl() {
    let { banner } = this.state;

    let logoUrl = "/";

    /*if (banner === "truepowerkurs5") {
      logoUrl = "/course/truepowerkurs5/page";
    } else if (banner === "ausbildung") {
      logoUrl = "/ausbildung";
    } else {
      logoUrl = "/";
    }*/

    return logoUrl;
  }
}

export default withRouter(withContext(MainTopbar));
