import React from "react";
import PageBuilderContext from "./PageBuilderContext";

const value = {
  isDragging: false,
  draggingType: null,
  draggingItem: null,
  draggingOver: null,
  startDragging: function (type, item) {
    this.draggingType = type;
    this.draggingItem = item;
    this.isDragging = true;
  },
  stopDragging: function () {
    this.draggingType = null;
    this.draggingItem = null;
    this.isDragging = false;
  },
  setDraggingOver: function (identifier) {
    this.draggingOver = identifier;
  },
};

function PageBuilderContextProvider(props) {
  return (
    <PageBuilderContext.Provider value={value}>
      {props.children}
    </PageBuilderContext.Provider>
  );
}

export default PageBuilderContextProvider;
