import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Icon } from "../_foundation/_buttons";
import { Cell, Grid, Row } from "../_foundation/_grid";

class AdminAccessSelect extends Component {
  state = {
    _updatableProps: [
      "accessSettingKey",
      "access_granted",
      "access_granted_for",
      "courses",
      "changesWereMade",
      "editMode",
    ],
    accessSettingKey: this.props.accessSettingKey,
    access_granted: this.props.access_granted,
    access_granted_for: this.props.access_granted_for,
    courses: this.props.courses,
    changesWereMade: this.props.changesWereMade || false,
    editMode: this.props.editMode,
    translation_root: this.props.translation_root || "admin.access",
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { editMode } = this.state;

    if (editMode === true) {
      return this.view__showEdit();
    } else {
      return this.view__showView();
    }
  }

  view__showView() {
    let { access_granted, access_granted_for, translation_root } = this.state;

    const _c = this.props.context;

    let v__access;
    if (access_granted === "all") {
      v__access = _c.translate(`${translation_root}.info.all`);
    } else if (access_granted === "courses") {
      v__access = `${_c.translate(
        `${translation_root}.info.courses`
      )} ${this.view__showAccessCourseNames(access_granted_for)}`;
    }

    v__access = (
      <div className="access-info">
        <Icon icon="unlock" left />
        {_c.translate(`${translation_root}.info.access`)}
        <br /> {v__access}
      </div>
    );

    return (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={24}>
            {v__access}
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showEdit() {
    let {
      access_granted,
      access_granted_for,
      translation_root,
      courses,
      accessSettingKey,
    } = this.state;

    const _c = this.props.context;

    return (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={12}>
            <label>
              <input
                type="radio"
                name={`access_${accessSettingKey}`}
                value="all"
                checked={access_granted === "all" ? true : false}
                onChange={(e) => {
                  access_granted = e.target.value;
                  this.setState(
                    { changesWereMade: true, access_granted },
                    this.update
                  );
                }}
              />{" "}
              {_c.translate("admin.community.access.options.all.label")}
            </label>
            <label>
              <input
                type="radio"
                name={`access_${accessSettingKey}`}
                value="courses"
                checked={access_granted === "courses" ? true : false}
                onChange={(e) => {
                  access_granted = e.target.value;
                  this.setState(
                    { changesWereMade: true, access_granted },
                    this.update
                  );
                }}
              />{" "}
              {_c.translate(`${translation_root}.options.courses.label`)}
            </label>
          </Cell>
          <Cell sm={24} md={12}>
            <div
              style={{
                display: access_granted === "all" ? "block" : "none",
              }}
              className="admin-community-access-for-all"
            >
              {_c.translate(`${translation_root}.options.all.text`)}
            </div>
            <div
              style={{
                display: access_granted === "courses" ? "block" : "none",
              }}
              className="admin-community-access-for-courses"
            >
              {_c.translate(`${translation_root}.options.courses.text`)}
              <div className="admin-community-access-list">
                <ul>
                  {courses.map((course, index) => {
                    return (
                      <li key={index}>
                        <label>
                          <input
                            type="checkbox"
                            value={course.id}
                            checked={
                              access_granted_for.includes(course.id)
                                ? true
                                : false
                            }
                            onChange={(e) => {
                              let selectedCourseId = e.target.value;
                              let found = false;
                              access_granted_for.map((courseId) => {
                                if (
                                  parseInt(courseId) ===
                                  parseInt(selectedCourseId)
                                ) {
                                  found = true;
                                }
                                return null;
                              });

                              if (found === true) {
                                let newAccessGrantedFor = [];
                                access_granted_for.map((courseId) => {
                                  if (
                                    parseInt(courseId) !==
                                    parseInt(selectedCourseId)
                                  ) {
                                    newAccessGrantedFor.push(
                                      parseInt(courseId)
                                    );
                                  }
                                  return null;
                                });

                                access_granted_for = newAccessGrantedFor;
                              } else {
                                access_granted_for.push(
                                  parseInt(selectedCourseId)
                                );
                              }

                              this.setState(
                                {
                                  changesWereMade: true,
                                  access_granted_for,
                                },
                                this.update
                              );
                            }}
                          />{" "}
                          <span>{course.name}</span>
                        </label>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }

  view__showAccessCourseNames(courseIds) {
    const { courses } = this.state;
    const _c = this.props.context;

    let courseNames = [];
    courses.map((course) => {
      if (courseIds.includes(course.id)) {
        courseNames.push(course.name);
      }
      return null;
    });

    if (courseNames.length === 0) {
      return _c.translate("admin.community.access.info.none");
    } else {
      return courseNames.join(", ");
    }
  }

  update = () => {
    const { access_granted, access_granted_for, changesWereMade } = this.state;

    if (this.props.onUpdate) {
      this.props.onUpdate({
        access_granted,
        access_granted_for,
        changesWereMade,
      });
    }
  };
}

export default withContext(AdminAccessSelect);
