import React, { useState } from "react";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import ColorPicker from "./components/ColorPicker";
import SizePicker from "./components/SizePicker";

const optionsKey = "border";

const defaultValue = {
  t: {
    width: "0px",
    color: "black",
    style: "none",
  },
  r: {
    width: "0px",
    color: "black",
    style: "none",
  },
  b: {
    width: "0px",
    color: "black",
    style: "none",
  },
  l: {
    width: "0px",
    color: "black",
    style: "none",
  },
};

const views = {
  all: {
    title: "Alle Rahmen",
    icon: "border_outer",
  },
  top: {
    title: "Oberer Rahmen",
    icon: "border_top",
  },
  right: {
    title: "Rechter Rahmen",
    icon: "border_right",
  },
  bottom: {
    title: "Unterer Rahmen",
    icon: "border_bottom",
  },
  left: {
    title: "Linker Rahmen",
    icon: "border_left",
  },
};

function ControlBorder({ item, onUpdate }) {
  const [view, setView] = useState("all");

  const getValueObject = () => {
    let valueObject = defaultValue;
    if (item.options && item.options[optionsKey])
      valueObject = item.options[optionsKey];
    return valueObject;
  };

  const getValue = (view, property) => {
    let values = defaultValue;
    if (item.options && item.options[optionsKey])
      values = item.options[optionsKey];

    let value = null;
    if (view === "top") value = values.t[property];
    else if (view === "right") value = values.r[property];
    else if (view === "bottom") value = values.b[property];
    else if (view === "left") value = values.l[property];
    else if (
      view === "all" &&
      values.t[property] === values.r[property] &&
      values.t[property] === values.b[property] &&
      values.t[property] === values.r[property]
    )
      value = values.t[property];
    else if (view === "all") {
      if (property === "width") value = "1px";
      if (property === "color") value = "black";
      if (property === "style") value = "solid";
    }

    return value;
  };

  const getPreviewStyles = () => {
    const styles = {};

    const valueObject = getValueObject();
    if (valueObject.t && valueObject.t.width)
      styles.borderTop = `${valueObject.t.width} ${valueObject.t.style} ${valueObject.t.color}`;
    if (valueObject.r && valueObject.r.width)
      styles.borderRight = `${valueObject.r.width} ${valueObject.r.style} ${valueObject.r.color}`;
    if (valueObject.b && valueObject.b.width)
      styles.borderBottom = `${valueObject.b.width} ${valueObject.b.style} ${valueObject.b.color}`;
    if (valueObject.l && valueObject.l.width)
      styles.borderLeft = `${valueObject.l.width} ${valueObject.l.style} ${valueObject.l.color}`;

    return styles;
  };

  const selectView = (view) => {
    setView(view);
  };

  const onEdit = (property, value) => {
    const newValue = getValueObject();

    if (view === "top" || view === "all") newValue.t[property] = value;
    if (view === "right" || view === "all") newValue.r[property] = value;
    if (view === "bottom" || view === "all") newValue.b[property] = value;
    if (view === "left" || view === "all") newValue.l[property] = value;

    onUpdate(`options.${optionsKey}`, newValue);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={16}>
            <div className="control-border-menu">
              <ul>
                {Object.keys(views).map((viewKey) => {
                  return (
                    <li key={viewKey}>
                      <button
                        onClick={() => selectView(viewKey)}
                        className={`${
                          view === viewKey && `selected `
                        }control-border-menu-view-button`}
                      >
                        <span className="mi">{views[viewKey].icon}</span>
                      </button>
                    </li>
                  );
                })}
              </ul>
            </div>
            <div className="control-border-view">
              <div className="control-border-view-content">
                <BorderView
                  view={view}
                  borderWidth={getValue(view, "width")}
                  borderColor={getValue(view, "color")}
                  borderStyle={getValue(view, "style")}
                  onEdit={onEdit}
                />
              </div>
            </div>
          </Cell>
          <Cell sm={24} md={8}>
            <div
              className="control-border-preview"
              style={getPreviewStyles()}
            />
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

const BorderView = ({
  view,
  borderWidth,
  borderColor,
  borderStyle,
  onEdit,
}) => {
  return (
    <div>
      <div className="control-border-view-title">
        <strong>{views[view].title}</strong>
      </div>
      {view === "all" && (
        <p>
          <small>
            Änderungen, die hier vorgenommen werden, überschreiben die Werte der
            Einzelseiten.
          </small>
        </p>
      )}

      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={8}>
            Rahmenstärke:
          </Cell>
          <Cell sm={24} md={16}>
            <SizePicker
              size="small"
              number={borderWidth}
              min={0}
              max={50}
              step={1}
              onChange={(value) => {
                onEdit("width", value);
              }}
            />
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24} md={8}>
            Rahmenfarbe:
          </Cell>
          <Cell sm={24} md={16}>
            <ColorPicker
              size="small"
              color={borderColor}
              onChange={(color) => {
                onEdit("color", color);
              }}
            />
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24} md={8}>
            Rahmenstil:
          </Cell>
          <Cell sm={24} md={16}>
            <select
              value={borderStyle}
              onChange={(e) => onEdit("style", e.target.value)}
            >
              <option value="none">Ausgeblendet</option>
              <option value="solid">Durchgehend (___________)</option>
              <option value="dashed">Gestrichelt (-----------)</option>
              <option value="dotted">Gepunktet (............)</option>
            </select>
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

export default ControlBorder;
