import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import Box from "../../../layout/Box";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";
import Loading from "../../../_reusables/Loading";

class PasswordResetView extends Component {
  state = {
    _updatableProps: ["status", "token", "errors", "messages", "input"],
    token: this.props.token,
    status: this.props.status,
    input: this.props.input,
    errors: this.props.errors,
    messages: this.props.messages,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { status } = this.state;
    const _c = this.props.context;

    let v__title = _c.translate("auth.passwordreset.views.title");
    let v__intro = _c.translate("auth.passwordreset.views.intro");

    return (
      <div className="page page-passwordreset">
        <main className="content">
          <Grid>
            <Row padding="x">
              <Cell sm={24} md={12} mdo={6}>
                <h2>{v__title}</h2>
                <p className="lead">{v__intro}</p>
                <Loading show={status === "LOADING"} />
                {this.view__showMessages()}
                {this.view__showErrors()}
                {this.view__showForm()}
              </Cell>
            </Row>
          </Grid>
        </main>
      </div>
    );
  }

  view__showMessages() {
    let { messages } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(messages).map((messageKey) => {
      if (messages[messageKey] === true) {
        let v__button;
        if (messageKey === "saved") {
          v__button = (
            <div className="text-center">
              <div>&nbsp;</div>
              <a href="/login" className="primary hollow button">
                Zum Login <Icon icon="angle-right" right />
              </a>
            </div>
          );
        }

        let v__error = (
          <div
            className="passwordreset-message success callout"
            key={messageKey}
          >
            <div>
              <strong>
                {_c.translate(
                  `auth.passwordreset.messages.${messageKey}.title`
                )}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate(
                  `auth.passwordreset.messages.${messageKey}.text`
                ),
              }}
            />
            {v__button}
          </div>
        );

        if (messageKey === "saved") {
          v__output.push(v__error);
        }
      }
      return null;
    });

    return <div className="passwordreset-messages">{v__output}</div>;
  }

  view__showErrors() {
    let { errors } = this.state;
    const _c = this.props.context;

    let v__output = [];

    Object.keys(errors).map((errorKey) => {
      if (errors[errorKey] === true) {
        let v__button;
        if (errorKey === "token_invalid") {
          v__button = (
            <div className="text-center">
              <div>&nbsp;</div>
              <a href="/login/password" className="primary hollow button">
                Neuen Reset-Link anfordern <Icon icon="angle-right" right />
              </a>
            </div>
          );
        }

        let v__error = (
          <div className="login-error alert callout" key={errorKey}>
            <div>
              <strong>
                {_c.translate(`auth.passwordreset.errors.${errorKey}.title`)}
              </strong>
            </div>
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate(
                  `auth.passwordreset.errors.${errorKey}.text`
                ),
              }}
            />
            {v__button}
          </div>
        );

        if (
          errorKey === "notfound" ||
          errorKey === "token_invalid" ||
          errorKey === "empty" ||
          errorKey === "notequal"
        ) {
          v__output.push(v__error);
        }
      }
      return null;
    });

    return <div className="login-errors">{v__output}</div>;
  }

  view__showForm() {
    const { status, input } = this.state;
    const { password1, password2 } = input;
    const _c = this.props.context;

    if (
      status === "SUCCESS" ||
      status === "LOADING" ||
      status === "TOKEN_INVALID"
    ) {
      return null;
    }

    return (
      <Grid>
        <Row padding="x">
          <Cell sm={24} md={24}>
            <Box>
              <Grid type="full">
                <Row margin="y">
                  <Cell sm={24}>
                    <label>
                      {_c.translate(
                        "auth.passwordreset.form.fields.password1.label"
                      )}
                      <input
                        type="password"
                        placeholder={_c.translate(
                          "auth.passwordreset.form.fields.password1.placeholder"
                        )}
                        value={password1}
                        onChange={(e) => {
                          this.props.onUpdateInputChange(
                            "password1",
                            e.target.value
                          );
                        }}
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.props.onSave();
                          }
                        }}
                      />
                    </label>
                  </Cell>
                </Row>
                <Row margin="y">
                  <Cell sm={24}>
                    <label>
                      {_c.translate(
                        "auth.passwordreset.form.fields.password2.label"
                      )}
                      <input
                        type="password"
                        placeholder={_c.translate(
                          "auth.passwordreset.form.fields.password2.placeholder"
                        )}
                        value={password2}
                        onChange={(e) => {
                          this.props.onUpdateInputChange(
                            "password2",
                            e.target.value
                          );
                        }}
                        onKeyUp={(e) => {
                          if (e.keyCode === 13) {
                            this.props.onSave();
                          }
                        }}
                      />
                    </label>
                  </Cell>
                </Row>
              </Grid>
            </Box>
          </Cell>
        </Row>
        <Row padding="x">
          <Cell sm={24} md={24}>
            <div className="page-buttons">
              <button className="primary button" onClick={this.props.onSave}>
                <Icon icon="check" left />{" "}
                {_c.translate("auth.passwordreset.form.buttons.save.label")}
              </button>
            </div>
          </Cell>
        </Row>
      </Grid>
    );
  }
}

export default withContext(PasswordResetView);
