import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import axios from "axios";
import DefaultLayout from "../layout/DefaultLayout";
import { Cell, Grid, Row } from "../_foundation/_grid";
import New from "../admin/New";
import HomeVideo from "./HomeVideo";
import HomeCountdown from "./HomeCountdown";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";

class TruePowerAusbildungHome extends Component {
  state = {
    access: this.props.access,
    homeInfo: {},
    homeItems: {},
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    return (
      <React.Fragment>
        <DefaultLayout
          page="home"
          help="home"
          breadcrumbs={["home_truepower"]}
          banner="ausbildung"
        >
          {/*}
          {this.view__showWelcomeVideo()}
    <Strich />*/}
          {this.view__showWordsFromLoa()}
          {this.view__showNews()}
          {this.view__showCountdown()}
          {this.view__showBelowCountdown()}
          {this.view__showCategories()}
          {this.view__showMap()}
          {this.view__showAgenda()}
          {this.view__showFooterBottom()}
        </DefaultLayout>
      </React.Fragment>
    );
  }

  view__showWelcomeVideo() {
    const { homeInfo } = this.state;

    if (!homeInfo) {
      return null;
    }

    let v__output;

    const { welcomeVideo } = homeInfo;
    if (welcomeVideo && welcomeVideo.content) {
      let embedUrl = `https://player.vimeo.com/video/${welcomeVideo.content}`;

      v__output = (
        <section className="home-welcome-video">
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24} md={12}>
                <div className="home-banner">
                  <img
                    src="https://universeapi.loahelser.com/storage/loa/banner_live2.jpg"
                    alt=""
                  />
                </div>
              </Cell>
              <Cell sm={24} md={12} className="text-right">
                <div className="responsive-embed widescreen">
                  <div id="vimeoPlayer">
                    <iframe
                      src={embedUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="vimeoPlayer"
                    ></iframe>
                  </div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </section>
      );
    }

    return v__output;
  }

  view__showWordsFromLoa() {
    const { homeItems } = this.state;

    if ((homeItems && homeItems.words) || homeItems.wordsaudio) {
      const homeItemWords = homeItems.words;

      const wordsFromLoa = [];

      homeItemWords.map((word) => {
        if (word && word.image) {
          wordsFromLoa.push(word.image);
        }
        return null;
      });

      let v__title;
      if (homeItems && homeItems.wordstitle && homeItems.wordstitle.image) {
        v__title = (
          <Grid>
            <Row>
              <Cell sm={24}>
                <div className="home-words-title">
                  <img src={homeItems.wordstitle.image} alt="Words from Loa" />
                </div>
              </Cell>
            </Row>
          </Grid>
        );
      }

      let v__audio;
      if (homeItems.wordsaudio) {
        const homeItemWordsAudio = homeItems.wordsaudio;
        if (homeItemWordsAudio && homeItemWordsAudio.content) {
          v__audio = (
            <div className="audio-player" style={{ marginTop: "10px" }}>
              <Grid>
                <Row>
                  <Cell sm={24}>
                    <AudioPlayer src={homeItemWordsAudio.content} />
                  </Cell>
                </Row>
              </Grid>
            </div>
          );
        }
      }

      if (wordsFromLoa[0]) {
        return (
          <section className="home-words-from-loa">
            {v__title}
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={24}>
                  <div className="home-words-slider">
                    <img src={wordsFromLoa[0]} alt="- Quote" />
                  </div>
                </Cell>
              </Row>
            </Grid>
            {v__audio}
          </section>
        );
      }
    }
  }

  view__showNews() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.news) {
      const homeItem = homeItems.news;

      if (homeItem.image) {
        const v__image = <img src={homeItem.image} alt="NEWS" />;

        let v__content;
        if (homeItems && homeItems.newslink && homeItems.newslink.content) {
          v__content = <a href={homeItems.newslink.content}>{v__image}</a>;
        } else {
          v__content = v__image;
        }

        return (
          <section className="home-news">
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  {v__content}
                </Cell>
              </Row>
            </Grid>
          </section>
        );
      }
    }
  }

  view__showCountdown() {
    const { homeInfo } = this.state;

    if (!homeInfo || !homeInfo.nextLivecall) {
      return null;
    }

    return (
      <section className="home-countdown">
        <HomeCountdown livecall={homeInfo.nextLivecall} />
      </section>
    );
  }

  view__showBelowCountdown() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.belowcountdown) {
      const homeItem = homeItems.belowcountdown;

      if (homeItem.image) {
        return (
          <section className="home-belowcountdown">
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  <img src={homeItem.image} alt="" />
                </Cell>
              </Row>
            </Grid>
          </section>
        );
      }
    }
  }

  view__showCategories() {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    let v__output;

    const { categories } = homeInfo;
    if (categories) {
      v__output = (
        <section className="home-categories">
          <Grid type="full">
            <Row margin="xy">
              {/*this.view__showLatestVideo(true)*/}
              {categories.map((category, index) => {
                return (
                  <Cell sm={24} md={8} key={index}>
                    <a
                      href={_c
                        .config("urls.courseCategory")
                        .replace(":courseSlug", homeInfo.course.slug)
                        .replace(":categorySlug", category.id)}
                      className="home-categories-category"
                    >
                      <div className="category-image">
                        <img src={category.image} alt={category.name} />
                      </div>
                      <div className="category-content">
                        <h3>{category.name}</h3>
                        <div className="category-description">
                          {category.short_description}
                        </div>
                      </div>
                    </a>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </section>
      );
    }

    return v__output;
  }

  view__showLatestVideo(withCell) {
    const { homeInfo } = this.state;
    const _c = this.props.context;

    let v__output;

    const { latestVideo, course } = homeInfo;
    if (latestVideo && latestVideo.content && course && course.slug) {
      v__output = (
        <div className="home-categories-category">
          <HomeVideo video={latestVideo} />

          <div className="category-content">
            <h3>
              <New expire="2100-12-31" label="NEU" /> {latestVideo.name}
            </h3>
            <div className="category-description">
              {latestVideo.short_description}
            </div>
          </div>
        </div>
      );
    }

    if (withCell === true) {
      return (
        <Cell sm={24} md={8}>
          {v__output}
        </Cell>
      );
    } else {
      return v__output;
    }
  }

  view__showMap() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.map) {
      const homeItem = homeItems.map;

      if (homeItem.image) {
        return (
          <section className="home-map">
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  <img src={homeItem.image} alt="Karte" />
                </Cell>
              </Row>
            </Grid>
          </section>
        );
      }
    }
  }

  view__showAgenda() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.agenda) {
      const homeItem = homeItems.agenda;

      if (homeItem.image) {
        return (
          <section className="home-agenda">
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  <img src={homeItem.image} alt="Ablauf" />
                </Cell>
              </Row>
            </Grid>
          </section>
        );
      }
    }
  }

  view__showFooterBottom() {
    const { homeItems } = this.state;

    if (homeItems && homeItems.footerbottom) {
      const homeItem = homeItems.footerbottom;

      if (homeItem.image) {
        return (
          <section className="home-footerbottom">
            <Grid>
              <Row>
                <Cell sm={24} md={24}>
                  <img src={homeItem.image} alt="" />
                </Cell>
              </Row>
            </Grid>
          </section>
        );
      }
    }
  }

  onUpdate = ({ homeInfo }) => {
    this.setState({ homeInfo });
  };

  init() {
    const _c = this.props.context;

    let apiUrl = `${_c.apiUrl("home.home")}/truepower`;

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === 200) {
            _c.setPageTitle("pageTitles.home_truepower", true);
            _c.handleApiResponse(response.data, true);
          } else if (status === 404) {
            window.location.href = _c.config("urls.courses");
          }

          const { homeInfo } = response.data.data;

          let homeItems = {
            words: [],
          };

          if (homeInfo && homeInfo.homeItems) {
            homeInfo.homeItems.map((homeItem) => {
              if (homeItem.type === "news") {
                homeItems.news = homeItem;
              } else if (homeItem.type === "newslink") {
                homeItems.newslink = homeItem;
              } else if (homeItem.type === "belowcountdown") {
                homeItems.belowcountdown = homeItem;
              } else if (homeItem.type === "map") {
                homeItems.map = homeItem;
              } else if (homeItem.type === "agenda") {
                homeItems.agenda = homeItem;
              } else if (homeItem.type === "words") {
                homeItems.words.push(homeItem);
              } else if (homeItem.type === "wordsaudio") {
                homeItems.wordsaudio = homeItem;
              } else if (homeItem.type === "footerbottom") {
                homeItems.footerbottom = homeItem;
              } else if (homeItem.type === "wordstitle") {
                homeItems.wordstitle = homeItem;
              }
              return null;
            });
          }

          this.setState({ homeInfo, homeItems });
        } catch (error) {
          console.log(error);
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "home:init:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "home:init");
        }
      });
  }
}

export default withContext(TruePowerAusbildungHome);
