import React from "react";
import AlignVPicker from "./components/AlignVPicker";

const optionsKey = "alignh";

const defaultValue = "flex-start";

function ControlAlignment({ item, onUpdate }) {
  const getValue = () => {
    let value = defaultValue;
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onEdit = (value) => {
    console.log("VAL", value);
    onUpdate(`options.${optionsKey}`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <AlignVPicker value={getValue()} onEdit={onEdit} />
    </div>
  );
}

export default ControlAlignment;
