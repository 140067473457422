import React, { Component } from "react";
import { Icon } from "../../../_foundation/_buttons";
import withContext from "../../../../context/contextHOC";
import axios from "axios";
import Box from "../../../layout/Box";

class DownloadList extends Component {
  state = {
    downloads: this.props.downloads,
    onlyLessonDownloads: this.props.onlyLessonDownloads,
    lesson: this.props.lesson,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson });
    }
  }

  render() {
    const { downloads, lesson, onlyLessonDownloads } = this.state;
    const _c = this.props.context;

    if (!downloads) {
      return null;
    }

    let showDownloads = [];

    if (!onlyLessonDownloads) {
      showDownloads = downloads.general;
    } else {
      downloads.general = [];
    }
    let v__lessonDownloads;

    if (lesson && downloads.lesson[lesson.id]) {
      let lessonShowDownloads = downloads.lesson[lesson.id];
      v__lessonDownloads = (
        <div className="course-downloads">
          <Box size="small">
            <div className="course-category-title">
              <Icon icon="download" left />
              {_c.translate("courses.downloads.lessonTitle")}
            </div>
            <ul>
              {lessonShowDownloads.map((download, index) => {
                let v__downloadIcon = _c.getDownloadType(download.type);

                return (
                  <li key={index}>
                    <a
                      href={download.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        this.handle__downloadClick(download.id);
                      }}
                    >
                      {v__downloadIcon} {download.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Box>
        </div>
      );
    }

    let v__downloads;
    if (downloads && downloads.general && downloads.general.length > 0) {
      v__downloads = (
        <div className="course-downloads">
          <Box size="small">
            <div className="course-category-title">
              <Icon icon="download" left />
              {_c.translate("courses.downloads.title")}
            </div>
            <ul>
              {showDownloads.map((download, index) => {
                let v__downloadIcon = _c.getDownloadType(download.type);

                return (
                  <li key={index}>
                    <a
                      href={download.url}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        this.handle__downloadClick(download.id);
                      }}
                    >
                      {v__downloadIcon} {download.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </Box>
        </div>
      );
    }

    return (
      <React.Fragment>
        {v__lessonDownloads}
        {v__downloads}
      </React.Fragment>
    );
  }

  handle__downloadClick(downloadId) {
    const _c = this.props.context;

    let url = _c.apiUrl("courses.downloadClick", {
      downloadId,
    });

    axios
      .patch(
        url,
        {},
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          //
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
        //_c.checkError(error);
      });
  }
}

export default withContext(DownloadList);
