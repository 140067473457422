import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import AdminDataField from "../../../AdminDataField";
import MMTabs from "../../../../mmcomponents/mmtabs/MMTabs";
import ElementSettings from "./ElementSettings";
import ElementDesign from "./ElementDesign";
import Loading from "../../../../_reusables/Loading";
import Box from "../../../../layout/Box";
import PagebuilderHelper from "../../PagebuilderHelper";

const tabs = {
  content: {
    name: "Inhalt",
    icon: "pencil-square-o",
  },
  design: {
    name: "Design",
    icon: "paint-brush",
  },
  settings: {
    name: "Einstellungen",
    icon: "cog",
  },
};

function PopupCountdown(props) {
  const [element, setElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState("content");

  useEffect(() => {
    const propsElement = cloneDeep(props.element);
    if (!propsElement.content)
      propsElement.content = PagebuilderHelper.getDateString(new Date());
    setElement(cloneDeep(propsElement));
  }, [props.element]);

  useEffect(() => {}, [element]);

  const onEdit = (property, value) => {
    if (props.actions.onEdit) props.actions.onEdit(property, value);
  };

  const actions = {
    onEdit,
  };

  if (!element)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="element-popup-heading">
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
        />
      </div>
      {selectedTab === "content" && (
        <TabContent element={element} actions={actions} />
      )}
      {selectedTab === "design" && (
        <ElementDesign item={element} itemType="element" actions={actions} />
      )}
      {selectedTab === "settings" && (
        <ElementSettings item={element} actions={actions} />
      )}
    </div>
  );
}

const TabContent = ({ element, actions }) => {
  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <Box size="small">
            <AdminDataField
              value={element.content}
              editValue={new Date(element.content)}
              editType="datetime"
              label={"Countdown bis"}
              editable={true}
              edit={true}
              onUpdate={(newValue) =>
                actions.onEdit(
                  "content",
                  PagebuilderHelper.getDateString(newValue)
                )
              }
            />
          </Box>
        </Cell>
      </Row>
    </Grid>
  );
};

export default PopupCountdown;
