import React, { Component } from "react";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import DefaultLayout from "../../layout/DefaultLayout";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import Progress from "./parts/Progress";

class UniverseCourses extends Component {
  state = {
    courses: undefined,
    purchasedCourses: undefined,
    availableCourses: undefined,
    userStatus: "UNAUTHENTICATED",
    status: "INIT",
    breadcrumbs: ["home", "courses"],
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("courses");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let buttons, menu;
    if (_c.isAdmin()) {
      buttons = [
        {
          class: "primary hollow",
          label: _c.translate("breadcrumbs.courses.edit"),
          href: _c.config("urls.admin.courses"),
        },
      ];
    }

    return (
      <div className={`page page-courses`}>
        <DefaultLayout
          breadcrumbs={breadcrumbs}
          page="courses"
          help="courses"
          buttons={buttons}
          menu={menu}
        >
          <div style={{ paddingTop: "40px" }} />
          <Grid>
            <Row>
              <Cell sm={24}>{this.view__showContent()}</Cell>
            </Row>
          </Grid>
        </DefaultLayout>
      </div>
    );
  }

  view__showContent() {
    const { courses, availableCourses, status, userStatus } = this.state;
    const _c = this.props.context;

    if (courses === undefined || status === "INIT") {
      return _c.loading();
    }

    if (status === "LOADED" && courses && courses.length === 0) {
      return (
        <p
          className="text-center"
          dangerouslySetInnerHTML={{
            __html: _c.translate("courses.overview.nocourses"),
          }}
        />
      );
    }

    let view = _c.setting("course_overview_layout");
    if (!view) {
      view = "vertical";
    }

    let v__content;
    if (userStatus === "AUTHENTICATED") {
      // Horizontal and vertical view
      if (view === "vertical") {
        if (availableCourses.length === 0) {
          v__content = (
            <React.Fragment>
              {this.view__showPurchasedCourses("intro", view)}
              <div>&nbsp;</div>
              {this.view__showPurchasedCourses("courses", view)}
              <div>&nbsp;</div>
            </React.Fragment>
          );
        } else {
          v__content = (
            <React.Fragment>
              {this.view__showPurchasedCourses("intro", view)}
              <div>&nbsp;</div>
              {this.view__showPurchasedCourses("courses", view)}
              <div>&nbsp;</div>
              <div>&nbsp;</div>
              {this.view__showAvailableCourses("intro", view)}
              <div>&nbsp;</div>
              {this.view__showAvailableCourses("courses", view)}
            </React.Fragment>
          );
        }
      } else if (view === "horizontal") {
        if (availableCourses.length === 0) {
          v__content = (
            <React.Fragment>
              <Grid type="full">
                <Row margin="x" padding="xy">
                  <Cell sm={24} md={24} className="course-overview-horizontal-separator">
                    {this.view__showPurchasedCourses("intro", view)}
                    <div>&nbsp;</div>
                  </Cell>
                </Row>
                <Row margin="x" padding="xy">
                  <Cell sm={24} md={24} className="course-overview-horizontal-separator">
                    {this.view__showPurchasedCourses("courses", view)}
                    <div>&nbsp;</div>
                  </Cell>
                </Row>
              </Grid>
            </React.Fragment>
          );
        } else {
          v__content = (
            <React.Fragment>
              <Grid type="full">
                <Row margin="x" padding="xy">
                  <Cell sm={24} md={12} className="course-overview-horizontal-separator">
                    {this.view__showPurchasedCourses("intro", view)}
                    <div>&nbsp;</div>
                  </Cell>
                  <Cell sm={24} md={12}>
                    {this.view__showAvailableCourses("intro", view)}
                  </Cell>
                </Row>
                <Row margin="x" padding="xy">
                  <Cell sm={24} md={12} className="course-overview-horizontal-separator">
                    {this.view__showPurchasedCourses("courses", view)}
                    <div>&nbsp;</div>
                  </Cell>
                  <Cell sm={24} md={12}>
                    {this.view__showAvailableCourses("courses", view)}
                  </Cell>
                </Row>
              </Grid>
            </React.Fragment>
          );
        }
      }
    } else {
      v__content = (
        <React.Fragment>
          {this.view__showAvailableCourses("intro", view)}
          <div>&nbsp;</div>
          {this.view__showAvailableCourses("courses", view)}
        </React.Fragment>
      );
    }

    return (
      <div className="course-overview">
        <h1>{_c.translate("pageTitles.courseOverview")}</h1>
        <div>&nbsp;</div>
        {v__content}
      </div>
    );
  }

  view__showPurchasedCourses = (contentType, view) => {
    const _c = this.props.context;

    let v__courses = this.view__showCourseList("purchased", view);
    let v__intro = (
      <div>
        <h2
          dangerouslySetInnerHTML={{
            __html: _c.translate("courses.overview.purchased.title"),
          }}
        />
        <p
          className="lead"
          dangerouslySetInnerHTML={{
            __html: _c.translate("courses.overview.purchased.intro"),
          }}
        />
      </div>
    );

    v__intro = "";

    if (contentType === "intro") {
      return v__intro;
    } else if (contentType === "courses") {
      return v__courses;
    }
  };

  view__showAvailableCourses = (contentType, view) => {
    const _c = this.props.context;

    let v__courses = this.view__showCourseList("available", view);
    let v__intro = (
      <div>
        <h2
          dangerouslySetInnerHTML={{
            __html: _c.translate("courses.overview.available.title"),
          }}
        />
        <p
          className="lead"
          dangerouslySetInnerHTML={{
            __html: _c.translate("courses.overview.available.intro"),
          }}
        />
      </div>
    );

    if (contentType === "intro") {
      return v__intro;
    } else if (contentType === "courses") {
      return v__courses;
    }
  };

  view__showCourseList(type, view) {
    const { availableCourses, purchasedCourses, userStatus } = this.state;
    const _c = this.props.context;

    const baseUrl = _c.config("urls.course");

    let selectedCourses;
    if (type === "purchased") {
      selectedCourses = purchasedCourses;
    } else {
      selectedCourses = availableCourses;
    }

    let v__content = (
      <Grid type="full">
        <Row margin="xy">
          {selectedCourses.map((course, index) => {
            if (type === "available" && !_c.isAdmin() && !course.visible) {
              return null;
            }

            let v__progress;

            let v__image = (
              <div className="course-no-image">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate("courses.overview.noimage"),
                  }}
                />
              </div>
            );
            if (course.image) {
              v__image = <img src={course.image} alt={course.name} />;
            }

            let v__description;
            if (course.description) {
              v__description = <div className="course-description">{course.description}</div>;
            }

            let v__button;
            if (type === "purchased") {
              let courseUrl = baseUrl.replace(":courseSlug", course.slug);

              if (course.has_page) {
                courseUrl = _c.config("urls.coursePage").replace(":courseSlug", course.slug);
              }

              if (course.slug === "ausbildung") {
                courseUrl = _c.config("urls.home.truepowerausbildung");
              }

              v__button = (
                <div className="course-button">
                  <a href={courseUrl} className="primary button">
                    {course.id === 3 ? "BACK TO LOA HOME" : "SHOW COURSE"}{" "}
                    <Icon icon="angle-double-right" right />
                  </a>
                </div>
              );

              v__progress = <Progress course={course} where="overview" />;

              v__image = <a href={courseUrl}>{v__image}</a>;
            } else if (course.purchase_url) {
              v__button = (
                <div className="course-button">
                  <a
                    href={course.purchase_url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="primary button"
                  >
                    <span
                      dangerouslySetInnerHTML={{
                        __html: _c.translate("courses.overview.available.button.label"),
                      }}
                    />{" "}
                    <Icon icon="angle-double-right" right />
                  </a>
                </div>
              );

              v__image = (
                <a href={course.purchase_url} target="_blank" rel="noopener noreferrer">
                  {v__image}
                </a>
              );
            }

            let cellMd = 6;
            if (view === "vertical") {
              cellMd = 8;
            } else if (view === "horizontal") {
              if (userStatus === "AUTHENTICATED" && availableCourses.length > 0) {
                cellMd = 12;
              } else {
                cellMd = 8;
              }
            }

            return (
              <Cell sm={24} md={cellMd} key={index}>
                <div className="course-overview-course">
                  <div className="course-image">{v__image}</div>
                  {v__progress}
                  <div className="course-content text-center">
                    <h3>{course.name}</h3>
                    {v__description}
                  </div>
                  {v__button}
                </div>
              </Cell>
            );
          })}
        </Row>
      </Grid>
    );

    if (selectedCourses.length === 0) {
      if (type === "purchased") {
        v__content = (
          <p
            className="text-center"
            dangerouslySetInnerHTML={{
              __html: _c.translate("courses.overview.nocoursespurchased"),
            }}
          />
        );
      } else if (type === "available") {
        if (userStatus === "AUTHENTICATED") {
          v__content = (
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: _c.translate("courses.overview.purchasedall"),
              }}
            />
          );
        } else {
          v__content = (
            <p
              className="text-center"
              dangerouslySetInnerHTML={{
                __html: _c.translate("courses.overview.nocourses"),
              }}
            />
          );
        }
      }
    }

    return <React.Fragment>{v__content}</React.Fragment>;
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.overview");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status, userStatus } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const coursesData = response.data.data.courses;
            const { all, purchased, available } = coursesData;
            this.setState({
              courses: all,
              purchasedCourses: purchased,
              availableCourses: available,
              userStatus,
              status: "LOADED",
            });
          }
          _c.setPageTitle("pageTitles.courseOverview", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:overview:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "course:overview:init");
      });
  }
}

export default withContext(UniverseCourses);
