import React, { Component } from "react";
import Box from "../../layout/Box";
import Audio from "./parts/Audio";
import HomeVideo from "../../home/HomeVideo";

class PersonalMessage extends Component {
  state = {
    course: this.props.course,
    lesson: this.props.lesson,
    downloads: this.props.downloads,
    homeItemPersonalMessage: this.props.homeItemPersonalMessage,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (
      prevProps.homeItemPersonalMessage !== this.props.homeItemPersonalMessage
    ) {
      this.setState({
        homeItemPersonalMessage: this.props.homeItemPersonalMessage,
      });
    }
  }

  render() {
    const { course, lesson, homeItemPersonalMessage } = this.state;
    const _c = this.props.context;

    if (!lesson || !lesson.content) {
      return null;
    }

    let v__media;
    if (lesson.type === "audio") {
      let v__image;

      if (lesson.image) {
        v__image = (
          <Box size="nopadding">
            <img src={lesson.image} alt="" />
          </Box>
        );
      }

      v__media = (
        <div>
          {v__image}
          <div className="course-lesson-audio">
            <Audio
              lesson={lesson}
              onListen={(currentTime) => {
                this.setState({ audioSeconds: currentTime });
              }}
            />
          </div>
        </div>
      );
    } else if (lesson.type === "text") {
      v__media = null;
    } else {
      v__media = <HomeVideo video={lesson} />;
    }

    let v__title;
    if (homeItemPersonalMessage && homeItemPersonalMessage.image) {
      v__title = (
        <img
          src={homeItemPersonalMessage.image}
          alt="Personal Message from Loa"
          style={{ height: "60px" }}
        />
      );
    }

    return (
      <div className="personalmessage">
        {v__title}
        {v__media}
      </div>
    );
  }
}

export default PersonalMessage;
