import React, { useState } from "react";
import { Icon } from "../../../_foundation/_buttons";
import DeletePopup from "./popups/DeletePopup";

function PageItemName({
  type,
  item,
  label,
  onMoveButton,
  onEditButton,
  onColumnsButton,
  onDuplicateButton,
  onDeleteButton,
  attributes,
  listeners,
}) {
  const [showDeletePopup, setShowDeletePopup] = useState(false);

  const getButtonStatus = (direction) => {
    const { itemPosition } = item;
    if (!itemPosition) return "HIDE";
    let buttonStatus = "SHOW";

    if (type === "column" && ["up", "down"].includes(direction)) return "HIDE";
    if (
      ["area", "row", "element"].includes(type) &&
      ["left", "right"].includes(direction)
    )
      return "HIDE";

    if (direction === "up") {
      if (itemPosition.firstOfAll) buttonStatus = "DISABLED";
    }

    if (direction === "down") {
      if (itemPosition.lastOfAll) buttonStatus = "DISABLED";
    }

    if (direction === "left") {
      if (itemPosition.firstInParent) buttonStatus = "DISABLED";
    }

    if (direction === "right") {
      if (itemPosition.lastInParent) buttonStatus = "DISABLED";
    }

    return buttonStatus;
  };

  const onCancel = () => {
    setShowDeletePopup(false);
  };

  const onDelete = () => {
    onDeleteButton();
    setShowDeletePopup(false);
  };

  const actions = {
    onCancel,
    onDelete,
  };

  return (
    <>
      <div className="item-name">
        {/*<button
          className="pagebuilder-draghandle"
          {...listeners}
          {...attributes}
        >
          <Icon icon="bars" />
  </button>*/}
        <div className="float-right">
          <MoveButton
            type="up"
            status={getButtonStatus("up")}
            onClick={() => onMoveButton("up")}
          />
          <MoveButton
            type="down"
            status={getButtonStatus("down")}
            onClick={() => onMoveButton("down")}
          />
          <MoveButton
            type="left"
            status={getButtonStatus("left")}
            onClick={() => onMoveButton("left")}
          />
          <MoveButton
            type="right"
            status={getButtonStatus("right")}
            onClick={() => onMoveButton("right")}
          />
        </div>

        <button onClick={onEditButton}>
          <Icon icon="cog" />
        </button>
        {type === "row" && (
          <button onClick={onColumnsButton}>
            <Icon icon="columns" />
          </button>
        )}
        {!["column"].includes(type) && (
          <>
            <button onClick={onDuplicateButton}>
              <Icon icon="clone" />
            </button>

            <button onClick={() => setShowDeletePopup(true)}>
              <Icon icon="trash" />
            </button>
          </>
        )}
        <button onClick={onEditButton}>
          {label ? label : item.name}{" "}
          {/*item.itemPosition && (
            <code>
              <small>
                [firstOfAll:{" "}
                <Icon icon={item.itemPosition.firstOfAll ? "check" : "minus"} />
                ] [firstInParent:{" "}
                <Icon
                  icon={item.itemPosition.firstInParent ? "check" : "minus"}
                />
                ] [lastOfAll:{" "}
                <Icon icon={item.itemPosition.lastOfAll ? "check" : "minus"} />]
                [lastInParent:{" "}
                <Icon
                  icon={item.itemPosition.lastInParent ? "check" : "minus"}
                />
                ]
              </small>
            </code>
              )*/}
        </button>
      </div>
      {showDeletePopup && (
        <DeletePopup item={item} itemType={type} actions={actions} />
      )}
    </>
  );
}

const MoveButton = ({ type, status, onClick }) => {
  const tooltips = {
    up: "Eine Position nach oben bewegen",
    down: "Eine Position nach unten bewegen",
    left: "Eine Position nach links bewegen",
    right: "Eine Position nach rechts bewegen",
  };

  if (status === "HIDE") return null;
  return (
    <button
      data-tip={status === "SHOW" ? tooltips[type] : null}
      className={`pagebuilder-item-move-button ${
        status === "DISABLED" ? `disabled-button` : ``
      }`}
      disabled={status === "DISABLED"}
      onClick={status === "SHOW" ? onClick : null}
    >
      <Icon icon={`chevron-${type}`} />
    </button>
  );
};

export default PageItemName;
