import { cloneDeep } from "lodash";
import React, { useState } from "react";
import MMPopup from "../../../../mmcomponents/mmpopup/MMPopup";
import MMTabs from "../../../../mmcomponents/mmtabs/MMTabs";
import { Icon } from "../../../../_foundation/_buttons";
import ElementDesign from "../../elements/popups/ElementDesign";
import ElementSettings from "../../elements/popups/ElementSettings";

const tabs = {
  design: {
    name: "Design",
    icon: "paint-brush",
  },
  settings: {
    name: "Einstellungen",
    icon: "cog",
  },
};

function ColumnPopup(props) {
  const [column, setColumn] = useState(cloneDeep(props.column));
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedTab, setSelectedTab] = useState("design");

  const onEdit = (property, value) => {
    const updatedColumn = cloneDeep(column);

    if (property.substring(0, 8) === "options.") {
      let { options } = updatedColumn;
      if (!options) options = {};
      options[property.replace("options.", "")] = value;
      updatedColumn.options = options;
    } else {
      updatedColumn[property] = value;
    }

    console.log("upd", updatedColumn);

    setColumn(updatedColumn);
    setUnsavedChanges(true);
  };

  const onUpdate = () => {
    if (props.actions.onUpdateColumn) props.actions.onUpdateColumn(column);
  };

  const actions = {
    onEdit,
    onUpdate,
  };

  const onCancel = () => {
    if (
      !unsavedChanges ||
      window.confirm(
        "Möchtest Du wirklich abbrechen? Änderungen gehen unwiderruflich verloren."
      )
    )
      props.onCancel();
  };

  return (
    <div className="pagebuilder-column-popup">
      <MMPopup size="medium" show={true} handleClose={onCancel}>
        <h3>Spalte bearbeiten</h3>
        <div className="admin-tabs">
          <MMTabs
            tabs={tabs}
            selected={selectedTab}
            onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
          />
        </div>
        {selectedTab === "design" && (
          <ElementDesign item={column} itemType="column" actions={actions} />
        )}
        {selectedTab === "settings" && (
          <ElementSettings item={column} itemType="column" actions={actions} />
        )}
        <div className="text-right">
          <button className="small primary hollow button" onClick={onCancel}>
            <Icon icon="times" left /> Abbrechen
          </button>
          <button className="small primary button" onClick={onUpdate}>
            <Icon icon="check" left /> Übernehmen
          </button>
        </div>
      </MMPopup>
    </div>
  );
}

export default ColumnPopup;
