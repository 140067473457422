import React, { Component } from "react";
import { Link } from "react-router-dom";
import withContext from "../../../../context/contextHOC";
import { Icon } from "../../../_foundation/_buttons";
import DownloadList from "./DownloadList";

class CategoryList extends Component {
  state = {
    course: this.props.course,
    lessons: this.props.lessons,
    lesson: this.props.lesson,
    downloads: this.props.downloads,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.course !== prevProps.course) {
      this.setState({ course: this.props.course });
    }

    if (this.props.lessons !== prevProps.lessons) {
      this.setState({ lessons: this.props.lessons });
    }
  }

  render() {
    const { course, lessons, downloads, lesson } = this.state;
    const _c = this.props.context;

    if (!course || !lessons) {
      return null;
    }

    let v__downloads;
    if (downloads) {
      v__downloads = (
        <DownloadList
          downloads={downloads}
          lesson={lesson}
          onlyLessonDownloads={true}
        />
      );
    }

    return (
      <div className="course-lesson-categorylist">
        {lessons.map((category, index) => {
          return (
            <Link
              to={_c
                .config("urls.courseCategory")
                .replace(":courseSlug", course.slug)
                .replace(":categorySlug", category.id)}
              className="box"
              key={index}
            >
              <div className="box-content">
                <Icon icon="angle-double-right" left /> {category.name}
              </div>
            </Link>
          );
        })}
        {v__downloads}
      </div>
    );
  }
}

export default withContext(CategoryList);
