import React, { Component } from "react";
import { Icon } from "../../_foundation/_buttons";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import AdminDataField from "../AdminDataField";
import { cloneDeep } from "lodash";
import axios from "axios";
import Switch from "react-switch";

class AdminMemberPurchases extends Component {
  state = {
    _updatableProps: ["member", "purchases", "courses", "newPurchase"],
    member: this.props.member,
    purchases: this.props.purchases,
    courses: this.props.courses,
    newPurchase: this.props.newPurchase,
    selectedPurchase: null,
    changesWereMade: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { member, purchases, newPurchase } = this.state;
    const _c = this.props.context;

    if (!member || !purchases) {
      return _c.loading();
    }

    return (
      <div className="admin-purchases">
        <div className="admin-purchases-header">
          <button
            className="small primary button"
            onClick={() => {
              this.setState({ selectedPurchase: cloneDeep(newPurchase) });
            }}
          >
            <Icon icon="plus" left />{" "}
            {_c.translate("admin.purchases.buttons.add.label")}
          </button>
        </div>
        <Box size="nopadding">
          {this.view__showListTitle()}
          {this.view__showListDesktop()}
          {this.view__showListMobile()}
          {this.view__showPopups()}
        </Box>
      </div>
    );
  }

  view__showPopups() {
    const { selectedPurchase } = this.state;

    if (selectedPurchase) {
      return (
        <MMPopup show={true} size="medium" handleClose={this.handle__cancel}>
          {this.view__showPopupEdit()}
        </MMPopup>
      );
    }
  }

  view__showPopupEdit() {
    const { selectedPurchase, courses } = this.state;
    const _c = this.props.context;

    let v__title;
    if (selectedPurchase.id === "new") {
      v__title = _c.translate("admin.purchases.popup.add.title");
    } else {
      v__title = _c.translate("admin.purchases.popup.edit.title");
    }

    let selectValuesCourses = {};
    let firstCourse;
    Object.keys(courses).map((courseId) => {
      if (!firstCourse) {
        firstCourse = courseId;
      }
      selectValuesCourses[courseId] = courses[courseId].name;
      return null;
    });

    if (!selectedPurchase.course) {
      selectedPurchase.course = firstCourse;
    }

    let selectValuesTypes = {};
    const purchaseTypes = _c.getPurchaseTypes();
    Object.keys(purchaseTypes).map((purchaseTypeKey) => {
      selectValuesTypes[purchaseTypeKey] = purchaseTypes[purchaseTypeKey].title;
      return null;
    });

    return (
      <React.Fragment>
        <h3>{v__title}</h3>
        <Grid type="full">
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.course}
                editValue={selectedPurchase.course}
                editType="select"
                selectValues={selectValuesCourses}
                label={_c.translate("admin.purchases.popup.course.title")}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("course", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.type}
                editValue={selectedPurchase.type}
                editType="select"
                selectValues={selectValuesTypes}
                label={_c.translate("admin.purchases.popup.type.title")}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("type", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.purchase_date_f}
                editValue={selectedPurchase.purchase_date || new Date()}
                editType="datetime"
                label={_c.translate("admin.purchases.popup.purchaseDate.title")}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("purchase_date", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <AdminDataField
                value={selectedPurchase.voucher_code}
                editValue={selectedPurchase.voucher_code}
                editType="text"
                label={_c.translate("admin.purchases.popup.voucherCode.title")}
                editable={true}
                edit={true}
                onUpdate={(newValue) => {
                  this.handle__edit("voucher_code", newValue);
                }}
              />
            </Cell>
          </Row>
          <Row>
            <Cell sm={24} md={24}>
              <div className="page-buttons">
                <button
                  className="small primary hollow button"
                  onClick={this.handle__cancel}
                >
                  <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                </button>
                <button
                  className="small primary button"
                  onClick={this.handle__save}
                >
                  <Icon icon="check" left /> {_c.translate("buttons.save")}
                </button>
              </div>
            </Cell>
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showListTitle() {
    const _c = this.props.context;

    return (
      <div className="admin-list-title">
        <Grid type="full">
          <Row>
            <Cell md={2}>
              <div className="padding">&nbsp;</div>
            </Cell>
            <Cell md={22}>
              <Grid type="full">
                <Row>
                  <Cell md={12}>
                    <div className="padding">
                      {_c.translate("admin.purchases.cellTitles.course")}
                    </div>
                  </Cell>
                  <Cell md={4} className="text-center">
                    <div className="padding">
                      {_c.translate("admin.purchases.cellTitles.type")}
                    </div>
                  </Cell>
                  <Cell md={4} className="text-center">
                    <div className="padding">
                      {_c.translate("admin.purchases.cellTitles.purchaseDate")}
                    </div>
                  </Cell>
                  <Cell md={4} className="text-center">
                    <div className="padding">
                      {_c.translate("admin.purchases.cellTitles.voucher")}
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showListDesktop() {
    const { purchases, courses } = this.state;
    const _c = this.props.context;

    if (purchases.length === 0) {
      return (
        <div className="text-center" style={{ padding: "10px" }}>
          {_c.translate("admin.purchases.nopurchases")}
        </div>
      );
    }

    return (
      <div className="hide-for-small-only">
        <ul className="striped admin-list">
          {purchases.map((purchase, index) => {
            let courseName = "?";
            if (
              courses &&
              courses[purchase.course] &&
              courses[purchase.course].name
            ) {
              courseName = courses[purchase.course].name;
            }

            let classActive = "purchase-inactive";
            if (purchase.active) {
              classActive = "purchase-active";
            }

            return (
              <li className="admin-list-item" key={index}>
                <Grid type="full">
                  <Row>
                    <Cell md={2} className="text-center">
                      <div className="wrapper">
                        <div className="center-vertically-">
                          <div className="padding">
                            <Switch
                              height={20}
                              width={40}
                              onChange={() => {
                                this.handle__toggleActive(purchase.id);
                              }}
                              checked={purchase.active}
                            />
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell md={22}>
                      <button
                        className={`clickable ${classActive}`}
                        onClick={() => {
                          this.setState({
                            selectedPurchase: cloneDeep(purchase),
                          });
                        }}
                      >
                        <Grid type="full">
                          <Row>
                            <Cell md={12} className="text-text-left">
                              <div className="wrapper">
                                <div className="center-vertically-">
                                  <div className="padding">{courseName}</div>
                                </div>
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="padding">
                                {_c.getPurchaseType(purchase.type)}
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="padding">
                                {purchase.purchase_date_f}
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="wrapper">
                                <div className="center-vertically-">
                                  <div className="padding">
                                    {purchase.voucher_code}
                                  </div>
                                </div>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  view__showListMobile() {
    const { purchases, courses } = this.state;
    const _c = this.props.context;

    return (
      <div className="show-for-small-only">
        <ul className="striped admin-list">
          {purchases.map((purchase, index) => {
            let courseName = "?";
            if (
              courses &&
              courses[purchase.course] &&
              courses[purchase.course].name
            ) {
              courseName = courses[purchase.course].name;
            }

            let classActive = "purchase-inactive";
            if (purchase.active) {
              classActive = "purchase-active";
            }

            return (
              <li className="admin-list-item" key={index}>
                <Grid type="full">
                  <Row>
                    <Cell sm={4} className="text-center">
                      <div className="wrapper">
                        <div className="center-vertically-">
                          <div className="padding">
                            <Switch
                              height={20}
                              width={40}
                              onChange={() => {
                                this.handle__toggleActive(purchase.id);
                              }}
                              checked={purchase.active}
                            />
                          </div>
                        </div>
                      </div>
                    </Cell>
                    <Cell sm={20}>
                      <button
                        className={`clickable ${classActive}`}
                        onClick={() => {
                          this.setState({
                            selectedPurchase: cloneDeep(purchase),
                          });
                        }}
                      >
                        <Grid type="full">
                          <Row>
                            <Cell md={12} className="text-text-left">
                              <div className="wrapper">
                                <div className="center-vertically-">
                                  <div className="padding">{courseName}</div>
                                </div>
                              </div>
                            </Cell>
                            <Cell md={4} className="text-center">
                              {_c.translate("admin.purchases.type")}{" "}
                              {_c.getPurchaseType(purchase.type)}
                            </Cell>
                            <Cell md={4} className="text-center">
                              {_c.translate("admin.purchases.purchaseDate")}{" "}
                              {purchase.purchase_date_f}
                            </Cell>
                            <Cell md={4} className="text-center">
                              <div className="wrapper">
                                <div className="center-vertically-">
                                  {_c.translate("admin.purchases.voucher")}{" "}
                                  {purchase.voucher_code}
                                </div>
                              </div>
                            </Cell>
                          </Row>
                        </Grid>
                      </button>
                    </Cell>
                  </Row>
                </Grid>
              </li>
            );
          })}
        </ul>
      </div>
    );
  }

  handle__toggleActive = (purchaseId) => {
    const _c = this.props.context;
    const { member } = this.state;

    const apiUrl = _c.apiUrl("admin.purchaseToggleActive", {
      memberId: member.id,
      purchaseId: purchaseId,
    });

    axios
      .patch(
        apiUrl,
        {},
        {
          headers: _c.headers,
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { purchases } = apiResponseData;

            this.setState({
              purchases,
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:purchase:toggle:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:purchase:toggle");
      });
  };

  handle__edit(property, value) {
    const { selectedPurchase } = this.state;
    selectedPurchase[property] = value;
    this.setState({ selectedPurchase, changesWereMade: true });
  }

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("admin.purchases.cancel.confirm"))) {
        this.setState({ changesWereMade: false, selectedPurchase: null });
        _c.cancelEditLock();
      } else {
        return false;
      }
    } else {
      this.setState({ changesWereMade: false, selectedPurchase: null });
      _c.cancelEditLock();
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { selectedPurchase, member } = this.state;

    if (!selectedPurchase.purchase_date) {
      window.alert(_c.translate("admin.purchases.errors.noPurchaseDate"));
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.purchase", {
        memberId: member.id,
        purchaseId: selectedPurchase.id,
      });

      // Prepare Purchase Date
      if (selectedPurchase.purchase_date) {
        selectedPurchase.purchase_date = _c.formatDatetimeForSaving(
          selectedPurchase.purchase_date
        );
      }

      axios
        .post(
          apiUrl,
          {
            purchase: selectedPurchase,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const apiResponseData = response.data.data;
              const { member, purchases } = apiResponseData;

              _c.setIsSaving(false);

              this.setState(
                {
                  changesWereMade: false,
                  selectedPurchase: null,
                  member,
                  purchases,
                },
                () => {
                  _c.cancelEditLock();
                  _c.createNotifictation(
                    _c.translate("admin.purchases.messages.saved.title"),
                    _c.translate("admin.purchases.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:purchase:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:purchase:save");
        });
    }, 200);
  };
}

export default withContext(AdminMemberPurchases);
