import React from "react";
import { Cell, Row, Grid } from "../_foundation/_grid";
import ElementButton from "./elements/ElementButton";
import ElementHeading from "./elements/ElementHeading";
import ElementImage from "./elements/ElementImage";
import ElementLesson from "./elements/ElementLesson";
import ElementText from "./elements/ElementText";
import ElementVideo from "./elements/ElementVideo";
import PageViewHelper from "./PageViewHelper";
import LinkWrapper from "./elements/LinkWrapper";
import ElementLessonGrid from "./elements/ElementLessonGrid";
import ElementCountdown from "./elements/ElementCountdown";

function PageView({ page }) {
  return (
    <div>
      {page.structure.areas.map((area) => {
        return <PageArea area={area} key={area.identifier} />;
      })}
    </div>
  );
}

const PageArea = ({ area }) => {
  return (
    <div
      className={`pageview-area ${PageViewHelper.getResponsiveClasses(area.responsive)}`}
      id={area.identifier}
      style={PageViewHelper.applyOptions(area)}
    >
      <Grid type={area.fullwidth ? "full" : null}>
        <Row>
          <Cell sm={24}>
            {area.rows.map((row) => {
              return <PageRow row={row} key={row.identifier} />;
            })}
          </Cell>
        </Row>
      </Grid>
    </div>
  );
};

const PageRow = ({ row }) => {
  return (
    <div
      className={`pageview-row ${PageViewHelper.getResponsiveClasses(row.responsive)}`}
      id={row.identifier}
      style={PageViewHelper.applyOptions(row)}
    >
      <Grid type={row.fullwidth ? "full" : null}>
        <Row>
          {row.columns.map((column) => {
            return (
              <Cell
                sm={column.column_type.sm}
                md={column.column_type.md}
                lg={column.column_type.lg}
                key={column.identifier}
              >
                <PageColumn column={column} />
              </Cell>
            );
          })}
        </Row>
      </Grid>
    </div>
  );
};

const PageColumn = ({ column }) => {
  return (
    <div
      className={`pageview-column ${PageViewHelper.getResponsiveClasses(column.responsive)}`}
      id={column.identifier}
      style={PageViewHelper.applyOptions(column)}
    >
      {column.elements.map((element) => {
        return <PageElement element={element} key={element.identifier} />;
      })}
    </div>
  );
};

const PageElement = ({ element }) => {
  if (element.type === "lesson") {
    if (
      !element?.extra?.hasCourseAccess &&
      element?.ifnotpurchased &&
      element?.ifnotpurchased !== "show"
    ) {
      const content = <img src={element?.extra?.course?.image} alt="" />;

      if (element?.extra?.course?.purchase_url)
        return (
          <a href={element?.extra?.course?.purchase_url} target="_blank" rel="noopener noreferrer">
            {content}
          </a>
        );
      else return content;
    }
  }

  return (
    <span
      className={`pageview-element ${PageViewHelper.getResponsiveClasses(element.responsive)}`}
      id={element.identifier}
    >
      {["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.type) && (
        <LinkWrapper element={element}>
          <ElementHeading element={element} />
        </LinkWrapper>
      )}
      {element.type === "text" && (
        <LinkWrapper element={element}>
          <ElementText element={element} />
        </LinkWrapper>
      )}
      {element.type === "image" && (
        <LinkWrapper element={element}>
          <ElementImage element={element} />
        </LinkWrapper>
      )}
      {["vimeo", "youtube"].includes(element.type) && (
        <LinkWrapper element={element}>
          <ElementVideo element={element} />
        </LinkWrapper>
      )}
      {element.type === "button" && (
        <LinkWrapper element={element}>
          <ElementButton element={element} />
        </LinkWrapper>
      )}
      {element.type === "lesson" && <ElementLesson element={element} />}
      {element.type === "lessongrid" && <ElementLessonGrid element={element} />}
      {element.type === "countdown" && <ElementCountdown element={element} />}
    </span>
  );
};

export default PageView;
