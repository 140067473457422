import React, { useContext, useEffect, useState } from "react";
import { PtContext } from "../../context/ptProvider";
import PageBuilderAPI from "../admin/pagebuilder/PageBuilderAPI";
import DefaultLayout from "../layout/DefaultLayout";
import Loading from "../_reusables/Loading";
import PageView from "./PageView";

function PageBySlug(props) {
  const _c = useContext(PtContext);

  const [page, setPage] = useState(null);

  const { slug } = props.match.params;

  useEffect(() => {
    if (!page) {
      PageBuilderAPI.getPageBySlug(slug, _c, ({ page }) => {
        if (page) setPage(page);
        _c.setPageTitle(page.pagetitle, true);
      });
    }
  }, []);

  if (!page)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div
      className={`pageview-page ${
        page.banner
          ? `page-pagebuilder-with-banner`
          : `page-pagebuilder-no-banner`
      }`}
    >
      <DefaultLayout>
        <PageView page={page} />
      </DefaultLayout>
    </div>
  );
}

export default PageBySlug;
