import { cloneDeep } from "lodash";
import React, { useState } from "react";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import MMTabs from "../../mmcomponents/mmtabs/MMTabs";
import { Icon } from "../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminDataField from "../AdminDataField";
import Box from "../../layout/Box";

const tabs = {
  info: {
    name: "Informationen",
    icon: "information-circle",
  },
  assignment: {
    name: "Zuordnung",
    icon: "youtube-play",
  },
};

function PageSettings(props) {
  const { onUpdate, onClose } = props;

  const [page, setPage] = useState(props.page);
  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [selectedTab, setSelectedTab] = useState("info");
  const [selectedAssignmentType, setSelectedAssignmentType] = useState("none");

  const updatePage = (property, value) => {
    const updatedPage = cloneDeep(page);

    updatedPage[property] = value;
    setPage(updatedPage);
    setUnsavedChanges(true);
  };

  const addEmptyEntryPage = () => {
    let { entrypages } = page;
    if (!entrypages) entrypages = [];

    entrypages.push([null, null]);
    updatePage("entrypages", entrypages);
  };

  const onCancel = () => {
    if (
      !unsavedChanges ||
      (unsavedChanges &&
        window.confirm(
          "Möchtest Du wirklich abbrechen? Sämtliche Änderungen gehen dann unwiderruflich verloren."
        ))
    )
      onClose();
  };

  const onSave = () => {
    onUpdate(page);
    onClose();
  };

  return (
    <div className="pagebuilder-pagesettings">
      <MMPopup show={true} size="medium" handleClose={onCancel}>
        <h3>Seiteneinstellungen</h3>
        <div className="admin-tabs">
          <MMTabs
            tabs={tabs}
            selected={selectedTab}
            onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
          />
        </div>
        {selectedTab === "info" && <TabContentInformation page={page} updatePage={updatePage} />}
        {selectedTab === "assignment" && (
          <>
            {page.entrypages &&
              page.entrypages.map((entrypage, index) => {
                return (
                  <Box key={index}>
                    <TabContentAssignment
                      entrypages={page.entrypages}
                      index={index}
                      updatePage={updatePage}
                      selectedAssignmentType={selectedAssignmentType}
                      setSelectedAssignmentType={setSelectedAssignmentType}
                      extra={props.extra}
                    />
                  </Box>
                );
              })}
            <div className="text-center" style={{ marginTop: "-10px" }}>
              <button onClick={addEmptyEntryPage} style={{ cursor: "pointer" }}>
                <Icon icon="plus-circle" />
              </button>
            </div>
          </>
        )}
        <div className="text-right" style={{ marginTop: "20px" }}>
          <button className="small primary hollow button" onClick={onCancel}>
            <Icon icon="times" left /> Abbrechen
          </button>
          <button className="small primary button" onClick={onSave}>
            <Icon icon="check" left /> Übernehmen
          </button>
        </div>
      </MMPopup>
    </div>
  );
}

const TabContentInformation = ({ page, updatePage }) => (
  <Grid type="full">
    <Row margin="xy">
      <Cell sm={24} md={12}>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={page.name}
                editValue={page.name}
                editType="text"
                label="Seitenname (sehen nur Admins)"
                placeholder="Gib der Seite einen Namen"
                editable={true}
                edit={true}
                onUpdate={(newValue) => updatePage("name", newValue)}
              />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <AdminDataField
                value={page.pagetitle}
                editValue={page.pagetitle}
                editType="text"
                label="Seitentitel (öffentlich)"
                placeholder="Gib einen Seitentitel ein"
                editable={true}
                edit={true}
                onUpdate={(newValue) => updatePage("pagetitle", newValue)}
              />
            </Cell>
          </Row>
          {page.identifier !== "new" && (
            <Row margin="xy">
              <Cell sm={24}>
                <AdminDataField
                  value={page.slug}
                  editValue={page.slug}
                  editType="urlSlug"
                  label="URL der Seite (öffentlich)"
                  placeholder="Gib eine URL ein"
                  editable={true}
                  edit={true}
                  onUpdate={(newValue) => updatePage("slug", newValue)}
                />
                <div
                  className="small warning callout"
                  style={{ padding: "5px 8px", marginTop: "5px" }}
                >
                  Achtung: Bitte beachte, dass Du für jede Seite eine eindeutige Url angibst,
                  ansonsten kann es zu Konflikten mit den anderen Seiten kommen.
                </div>
              </Cell>
            </Row>
          )}
        </Grid>
      </Cell>
      <Cell sm={24} md={12}>
        <AdminDataField
          value={page.css}
          editValue={page.css}
          editType="textarea"
          rows={10}
          label="Zusätzliches CSS"
          placeholder=""
          editable={true}
          edit={true}
          onUpdate={(newValue) => updatePage("css", newValue)}
        />
      </Cell>
    </Row>
  </Grid>
);

const TabContentAssignment = ({ entrypages, index, updatePage, extra }) => {
  const entrypage = entrypages[index];

  const selectValuesCourses = {
    none: "----- Keine Zuordnung -----",
  };
  extra.courses.map((course) => {
    selectValuesCourses[`course_${course.id}`] = `${course.name}`;
    return null;
  });

  const selectValuesCategories = {
    none: "------------------",
  };
  let selectedCourse;
  if (entrypage[0]) {
    selectedCourse = extra.courses.find((c) => parseInt(c.id) === parseInt(entrypage[0]));
    if (selectedCourse) {
      selectedCourse.categories.map((category) => {
        selectValuesCategories[`category_${category.id}`] = `${category.name}`;
        return null;
      });
    }
  }

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <AdminDataField
            value={`course_${entrypage[0]}`}
            editValue={`course_${entrypage[0]}`}
            editType="select"
            selectValues={selectValuesCourses}
            label={
              entrypage[1] && entrypage[1] !== "none"
                ? "Ausgewählter Kurs"
                : "Startseite für diesen Kurs"
            }
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              if (newValue === "none") {
                entrypages.splice(index, 1);
              } else {
                entrypages[index][0] = parseInt(newValue.replace("course_", ""));
                entrypages[index][1] = null;
              }

              updatePage("entrypages", entrypages);
            }}
          />
        </Cell>
      </Row>
      {entrypage[0] && entrypage[0] !== "none" && (
        <Row margin="xy">
          <Cell sm={24}>
            <AdminDataField
              value={`category_${entrypage[1]}`}
              editValue={`category_${entrypage[1]}`}
              editType="select"
              selectValues={selectValuesCategories}
              label={"Startseite für diese Kategorie"}
              editable={true}
              edit={true}
              onUpdate={(newValue) => {
                entrypages[index][1] = parseInt(newValue.replace("category_", ""));
                updatePage("entrypages", entrypages);
              }}
            />
          </Cell>
        </Row>
      )}
    </Grid>
  );
};

export default PageSettings;
