import React, { Component } from "react";

class AdminDotsMenu extends Component {
  state = {
    _updatableProps: ["show", "button"],
    show: this.props.show,
    button: this.props.button || "buttonDotsMenu",
    styles: {
      marginTop: 35,
    },
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    let { styles, show } = this.state;

    if (!show) {
      return null;
    }

    return (
      <div className="dots-menu" style={styles}>
        <div className="dots-menu-content">{this.props.children}</div>
      </div>
    );
  }
}

export default AdminDotsMenu;
