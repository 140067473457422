import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import axios from "axios";

import cloneDeep from "lodash/cloneDeep";
import { Grid, Row, Cell } from "../../../_foundation/_grid";
import { Icon } from "../../../_foundation/_buttons";
import Box from "../../../layout/Box";
import ReactTooltip from "react-tooltip";
import MMPopup from "../../../mmcomponents/mmpopup/MMPopup";

class LessonNotes extends Component {
  state = {
    lesson: this.props.lesson,
    showNotePopup: this.props.showNotePopup,
    selectedNote: this.props.selectedNote,
    audioSeconds: this.props.audioSeconds,
    newNote: {
      id: "new",
      course: 0,
      lesson: 0,
      seconds: 0,
      tmpHours: 0,
      tmpMinutes: 0,
      tmpSeconds: 0,
      text: "",
    },
    changesWereMade: false,
    secondsBuffer: 0, // On Click on new note -> how many seconds back?
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.lesson !== prevProps.lesson) {
      this.setState({ lesson: this.props.lesson });
    }

    if (this.props.showNotePopup !== prevProps.showNotePopup) {
      this.setState({ showNotePopup: this.props.showNotePopup });
    }

    if (this.props.audioSeconds !== prevProps.audioSeconds) {
      this.setState({ audioSeconds: this.props.audioSeconds });
    }

    if (this.props.selectedNote !== prevProps.selectedNote) {
      let selectedNote = this.props.selectedNote;

      if (selectedNote === "new") {
        const { newNote, lesson, secondsBuffer, audioSeconds } = this.state;
        selectedNote = cloneDeep(newNote);

        selectedNote.course = lesson.course_id;
        selectedNote.lesson = lesson.id;

        if (audioSeconds) {
          selectedNote.seconds = audioSeconds;
          if (selectedNote.seconds > secondsBuffer) {
            selectedNote.seconds -= secondsBuffer;
          }

          selectedNote = this.convertSeconds(selectedNote);
        } else if (
          lesson &&
          lesson.lessonStatus &&
          lesson.lessonStatus.seconds
        ) {
          selectedNote.seconds = lesson.lessonStatus.seconds;
          if (selectedNote.seconds > secondsBuffer) {
            selectedNote.seconds -= secondsBuffer;
          }

          selectedNote = this.convertSeconds(selectedNote);
        }
      }

      this.setState({ selectedNote });
    }
  }

  render() {
    const _c = this.props.context;

    return (
      <div className="course-lesson-notes">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        {this.view__showNotes()}
        {this.view__showNotePopup()}
      </div>
    );
  }

  view__showNotes() {
    const { lesson, audioSeconds } = this.state;
    const _c = this.props.context;

    if (!lesson || !lesson.notes) {
      return _c.loading();
    }

    return (
      <div className="course-lesson-notes">
        {lesson.notes.map((note, index) => {
          note = this.convertSeconds(note);

          let noteTime = `${
            note.tmpHours < 10 ? `0${note.tmpHours}` : note.tmpHours
          }:${note.tmpMinutes < 10 ? `0${note.tmpMinutes}` : note.tmpMinutes}:${
            note.tmpSeconds < 10 ? `0${note.tmpSeconds}` : note.tmpSeconds
          }`;

          let v__seconds;
          if (lesson.type === "audio") {
            v__seconds = <span>{noteTime}</span>;
          } else {
            v__seconds = (
              <button
                data-tip={_c.translate("courses.lesson.notes.goToSecond")}
                onClick={() => {
                  this.handle__goToSecond(note.seconds);
                }}
              >
                {noteTime}{" "}
              </button>
            );
          }

          return (
            <div className="course-lesson-note" key={index}>
              <Box>
                <Grid type="full">
                  <Row margin="y">
                    <Cell sm={22}>
                      <div className="note-time">{v__seconds}</div>
                      <div className="note-text">
                        <div
                          dangerouslySetInnerHTML={{ __html: note.text_f }}
                        />
                      </div>
                    </Cell>
                    <Cell sm={2} className="note-buttons">
                      <div>
                        <button
                          className="tiny only-icon primary hollow button"
                          data-tip={_c.translate(
                            "courses.lesson.notes.popup.titles.editNote"
                          )}
                          onClick={() => {
                            this.setState({
                              selectedNote: cloneDeep(note),
                              showNotePopup: true,
                            });
                          }}
                        >
                          <Icon icon="edit" />
                        </button>
                      </div>
                      <div>
                        <button
                          className="tiny only-icon primary hollow button"
                          data-tip={_c.translate(
                            "courses.lesson.notes.popup.titles.deleteNote"
                          )}
                          onClick={() => {
                            this.handle__deleteNote(note);
                          }}
                        >
                          <Icon icon="trash" />
                        </button>
                      </div>
                    </Cell>
                  </Row>
                </Grid>
              </Box>
            </div>
          );
        })}
      </div>
    );
  }

  view__showNotePopup() {
    const { showNotePopup, selectedNote } = this.state;
    const _c = this.props.context;

    if (!showNotePopup || !selectedNote || !selectedNote.id) {
      return;
    }

    let v__title = _c.translate("courses.lesson.notes.popup.titles.editNote");
    if (selectedNote.id === "new") {
      v__title = _c.translate("courses.lesson.notes.popup.titles.addNote");
    }

    return (
      <MMPopup
        show={showNotePopup}
        size="small"
        handleClose={this.handle__closeNotePopup}
      >
        <div className="course-lesson-notes-popup">
          <h2>{v__title}</h2>
          <div
            dangerouslySetInnerHTML={{
              __html: _c.translate("courses.lesson.notes.popup.intro"),
            }}
          />
          <h3>{_c.translate("courses.lesson.notes.popup.time")}</h3>
          <Grid type="full">
            <Row margin="x" className="course-lesson-notes-popup-time">
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpHours}
                  onChange={(e) => {
                    this.handle__editNote("tmpHours", e.target.value);
                  }}
                  placeholder="Std."
                />
                <label>{_c.translate("courses.lesson.notes.popup.hour")}</label>
              </Cell>
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpMinutes}
                  onChange={(e) => {
                    this.handle__editNote("tmpMinutes", e.target.value);
                  }}
                  placeholder="Min."
                />
                <label>
                  {_c.translate("courses.lesson.notes.popup.minute")}
                </label>
              </Cell>
              <Cell sm={8}>
                <input
                  type="number"
                  value={selectedNote.tmpSeconds}
                  onChange={(e) => {
                    this.handle__editNote("tmpSeconds", e.target.value);
                  }}
                  placeholder="Sek."
                />
                <label>
                  {_c.translate("courses.lesson.notes.popup.second")}
                </label>
              </Cell>
            </Row>
          </Grid>
          <h3>{_c.translate("courses.lesson.notes.popup.note")}</h3>
          <Grid type="full">
            <Row>
              <Cell sm={24}>
                <textarea
                  rows={8}
                  value={selectedNote.text}
                  onChange={(e) => {
                    this.handle__editNote("text", e.target.value);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
          <div className="popup-buttons">
            <button
              className="small primary hollow button"
              onClick={this.handle__closeNotePopup}
            >
              <Icon icon="times" left /> {_c.translate("buttons.cancel")}
            </button>
            <button
              className="small primary button"
              onClick={this.handle__saveNote}
            >
              <Icon icon="check" left /> {_c.translate("buttons.save")}
            </button>
          </div>
        </div>
      </MMPopup>
    );
  }

  handle__goToSecond(seconds) {
    this.props.onGoToSecond(seconds);
  }

  handle__editNote(property, value) {
    let { selectedNote } = this.state;

    if (
      value > 59 &&
      (property === "tmpSeconds" || property === "tmpMinutes")
    ) {
    } else {
      selectedNote[property] = value;
    }

    if (
      property === "tmpHours" ||
      property === "tmpMinutes" ||
      property === "tmpSeconds"
    ) {
      selectedNote = this.convertTime(selectedNote);
    }

    this.setState({ selectedNote, changesWereMade: true });
  }

  handle__deleteNote(note) {
    const _c = this.props.context;

    const previewLength = 100;
    let notePreview = note.text;
    if (notePreview.length > previewLength - 4) {
      notePreview = note.text.substr(0, previewLength) + "...";
    }

    if (
      window.confirm(
        _c
          .translate("courses.lesson.notes.delete.confirm")
          .replace("{notePreview}", notePreview)
      )
    ) {
      const _c = this.props.context;

      let apiUrl = _c.apiUrl("courses.lessonNote", {});
      apiUrl = apiUrl + "/" + note.id;

      axios
        .delete(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;

            if (status === "SUCCESS") {
              const { lesson } = response.data.data;
              this.setState(
                {
                  lesson,
                  changesWereMade: false,
                },
                () => {
                  this.props.onNoteSave(lesson);
                  this.handle__closeNotePopup();
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "course:lesson:notes:delete:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "course:lesson:notes:delete");
        });
    }
  }

  handle__closeNotePopup = () => {
    let { changesWereMade, showNotePopup, selectedNote } = this.state;
    const _c = this.props.context;

    if (changesWereMade === true) {
      if (window.confirm(_c.translate("courses.lesson.notes.cancel.confirm"))) {
        showNotePopup = false;
        selectedNote = null;
        changesWereMade = false;
      }
    } else {
      showNotePopup = false;
      selectedNote = null;
      changesWereMade = false;
    }

    this.setState({
      showNotePopup,
      selectedNote,
      changesWereMade,
    });

    this.props.onPopupClose(showNotePopup, selectedNote);
  };

  convertSeconds(note) {
    let tmpHours = 0;
    let tmpMinutes = 0;
    let tmpSeconds = 0;

    let { seconds } = note;

    tmpHours = Math.floor(seconds / 60 / 60);
    seconds -= tmpHours * 60 * 60;

    tmpMinutes = Math.floor(seconds / 60);
    seconds -= tmpMinutes * 60;

    tmpSeconds = Math.floor(seconds);

    note.tmpHours = tmpHours;
    note.tmpMinutes = tmpMinutes;
    note.tmpSeconds = tmpSeconds;

    return note;
  }

  convertTime(note) {
    let seconds = 0;

    seconds += note.tmpHours * 60 * 60;
    seconds += note.tmpMinutes * 60;
    seconds += note.tmpSeconds;

    note.seconds = seconds;

    return note;
  }

  handle__saveNote = () => {
    const { selectedNote } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.lessonNote");

    axios
      .post(
        apiUrl,
        {
          note: selectedNote,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const { lesson } = response.data.data;
            this.setState(
              {
                lesson,
                changesWereMade: false,
              },
              () => {
                this.props.onNoteSave(lesson);
                this.handle__closeNotePopup();
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:lesson:notes:save:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "course:lesson:notes:save");
      });
  };
}

export default withContext(LessonNotes);
