import { cloneDeep } from "lodash";
import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import { Icon } from "../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminDataField from "../AdminDataField";
import axios from "axios";
import ReactTooltip from "react-tooltip";
import MMDialog from "../../mmcomponents/mmdialog/MMDialog";

import {
  sortableContainer,
  sortableElement,
  sortableHandle,
} from "react-sortable-hoc";
import arrayMove from "array-move";

class AdminProfileProperties extends Component {
  state = {
    profileProperties: this.props.profileProperties,
    changesWereMade: false,
    selectedProperty: null,
    newProperty: {
      id: "new",
      label: "",
      type: "textarea",
      show: true,
    },
    showDialogDelete: false,
    selectedLocation: null,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.profileProperties !== prevProps.profileProperties) {
      this.setState({ profileProperties: this.props.profileProperties });
    }
  }

  render() {
    const _c = this.props.context;

    return (
      <div className="admin-profile-properties">
        <p className="lead">
          {_c.translate("admin.community.profileProperties.text")}
        </p>
        <div>&nbsp;</div>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={10}>
              <h3>
                {_c.translate(
                  "admin.community.profileProperties.locations.sidebar.label"
                )}
              </h3>
              <p>
                {_c.translate(
                  "admin.community.profileProperties.locations.sidebar.text"
                )}
              </p>
              {this.view__showProperties("sidebar")}
            </Cell>
            <Cell sm={24} md={14}>
              <h3>
                {_c.translate(
                  "admin.community.profileProperties.locations.main.label"
                )}
              </h3>
              <p>
                {_c.translate(
                  "admin.community.profileProperties.locations.main.text"
                )}
              </p>
              {this.view__showProperties("main")}
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <hr />
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24} md={12}>
              <h3>
                {_c.translate(
                  "admin.community.profileProperties.locations.admin.label"
                )}
              </h3>
              <p>
                {_c.translate(
                  "admin.community.profileProperties.locations.admin.text"
                )}
              </p>
              {this.view__showProperties("admin")}
            </Cell>
          </Row>
        </Grid>
        {this.view__showDialogs()}
        {this.view__showPopups()}
        <ul id="adminSortProperties" className="admin-profile-properties" />
      </div>
    );
  }

  view__showDialogs() {
    const { showDialogDelete, selectedProperty } = this.state;
    const _c = this.props.context;

    if (!selectedProperty) {
      return null;
    }

    return (
      <React.Fragment>
        <MMDialog
          show={showDialogDelete}
          message={
            <div
              dangerouslySetInnerHTML={{
                __html: _c.translate(
                  "admin.community.profileProperties.delete.confirm"
                ),
              }}
            />
          }
          button_1={{
            title: _c.translate(
              "admin.community.profileProperties.delete.buttons.cancel"
            ),
            icon: <Icon icon="times" left />,
            type: "primary hollow",
          }}
          button_2={{
            title: _c.translate(
              "admin.community.profileProperties.delete.buttons.yes"
            ),
            icon: <Icon icon="trash" left />,
            type: "primary",
          }}
          onButton_1={() => {
            this.setState({ showDialogDelete: false });
          }}
          onButton_2={this.handle__delete}
        />
      </React.Fragment>
    );
  }

  view__showPopups() {
    const { selectedProperty, showDialogDelete } = this.state;
    const _c = this.props.context;

    const editMode = true;

    if (selectedProperty && !showDialogDelete) {
      let type = "edit";
      if (selectedProperty.id === "new") {
        type = "new";
      }

      const editSelectValues = {
        text: _c.translate(
          `admin.community.profileProperties.popup.form.type.options.text`
        ),
        textarea: _c.translate(
          `admin.community.profileProperties.popup.form.type.options.textarea`
        ),
      };

      let v__editType;
      if (selectedProperty.location === "admin") {
        v__editType = (
          <AdminDataField
            value={_c.translate(
              `admin.community.profileProperties.popup.form.type.options.${selectedProperty.type}`
            )}
            editValue={selectedProperty.type}
            editType="select"
            selectValues={editSelectValues}
            label={_c.translate(
              "admin.community.profileProperties.popup.form.type.label"
            )}
            editable={true}
            edit={editMode}
            onUpdate={(newValue) => {
              this.handle__edit("type", newValue);
            }}
          />
        );
      } else {
        v__editType = (
          <AdminDataField
            value={_c.translate(
              `admin.community.profileProperties.popup.form.type.options.${selectedProperty.type}`
            )}
            editValue={selectedProperty.type}
            editType="text"
            label={_c.translate(
              "admin.community.profileProperties.popup.form.type.label"
            )}
            editable={false}
            edit={editMode}
            onUpdate={(newValue) => {
              this.handle__edit("type", newValue);
            }}
          />
        );
      }

      return (
        <MMPopup size="small" show={true} handleClose={this.handle__cancel}>
          <h3>
            {_c.translate(
              `admin.community.profileProperties.popup.${type}.title`
            )}
          </h3>
          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={24}>
                <AdminDataField
                  value={selectedProperty.label}
                  editValue={selectedProperty.label}
                  editType="text"
                  label={_c.translate(
                    "admin.community.profileProperties.popup.form.label.label"
                  )}
                  placeholder={_c.translate(
                    "admin.community.profileProperties.popup.form.label.placeholder"
                  )}
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("label", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={12}>
                {v__editType}
              </Cell>
              <Cell sm={24} md={12}>
                <AdminDataField
                  value={_c.translate(
                    `admin.community.profileProperties.locations.${selectedProperty.location}.label`
                  )}
                  editValue={selectedProperty.location}
                  editType="text"
                  label={_c.translate(
                    "admin.community.profileProperties.popup.form.location.label"
                  )}
                  editable={false}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("location", newValue);
                  }}
                />
              </Cell>
            </Row>
            <Row margin="xy">
              <Cell sm={24} md={24} className="text-center">
                <AdminDataField
                  value={selectedProperty.show}
                  editValue={selectedProperty.show}
                  editType="singleCheckbox"
                  label={_c.translate(
                    "admin.community.profileProperties.popup.form.show.label"
                  )}
                  editable={true}
                  edit={editMode}
                  onUpdate={(newValue) => {
                    this.handle__edit("show", newValue);
                  }}
                />
              </Cell>
            </Row>
          </Grid>
          <div className="popup-buttons" style={{ paddingBottom: "0" }}>
            <Grid type="full">
              <Row>
                <Cell sm={24} md={8} className="text-left">
                  <button
                    className="small primary hollow button"
                    onClick={() => {
                      this.setState({ showDialogDelete: true });
                    }}
                  >
                    {_c.translate(
                      "admin.community.profileProperties.delete.buttonLabel"
                    )}
                  </button>
                </Cell>
                <Cell sm={24} md={16}>
                  <button
                    className="small primary hollow button"
                    onClick={this.handle__cancel}
                  >
                    <Icon icon="times" left /> {_c.translate("buttons.cancel")}
                  </button>
                  <button
                    className="small primary button"
                    onClick={this.handle__save}
                  >
                    <Icon icon="check" left /> {_c.translate("buttons.save")}
                  </button>
                </Cell>
              </Row>
            </Grid>
          </div>
        </MMPopup>
      );
    }
  }

  view__showProperties(location) {
    const { profileProperties } = this.state;
    const _c = this.props.context;

    let showProperties = [];
    profileProperties.map((profileProperty) => {
      if (profileProperty.location === location) {
        showProperties.push(profileProperty);
      }
      return null;
    });

    const DragHandle = sortableHandle(() => (
      <span className="drag-handle">
        <Icon icon="bars" />
      </span>
    ));

    const SortableContainer = sortableContainer(({ children }) => {
      return <ul className="admin-profile-properties">{children}</ul>;
    });

    const SortableItem = sortableElement(({ property }) => {
      let v__type = _c.translate(
        `admin.community.profileProperties.popup.form.type.options.${property.type}`
      );

      let v__visibleIcon;
      if (property.show === true) {
        v__visibleIcon = (
          <div className="float-right">
            <span
              data-tip={_c.translate(
                `admin.community.profileProperties.visible.${
                  property.show === true ? "yes" : "no"
                }.tooltip`
              )}
            >
              <Icon icon="eye" />
            </span>
          </div>
        );
      } else {
        v__visibleIcon = (
          <div className="float-right admin-profile-property-visible-icon">
            <span
              data-tip={_c.translate(
                `admin.community.profileProperties.visible.${
                  property.show === true ? "yes" : "no"
                }.tooltip`
              )}
            >
              <Icon icon="eye-slash" />
            </span>
          </div>
        );
      }

      return (
        <li
          className={`admin-profile-property ${
            property.show === true ? "property-visible" : "property-hidden"
          }`}
        >
          <Box size="nopadding">
            <Grid type="full">
              <Row>
                <Cell sm={4} md={2}>
                  <div className="wrapper text-center">
                    <div className="center-vertically">
                      <DragHandle />
                    </div>
                  </div>
                </Cell>
                <Cell sm={20} md={22}>
                  <button
                    className="clickable"
                    onClick={() => {
                      this.setState({
                        selectedProperty: cloneDeep(property),
                      });
                    }}
                  >
                    <ReactTooltip
                      place={_c.getToolTipSetting("position")}
                      type={_c.getToolTipSetting("type")}
                      effect={_c.getToolTipSetting("effect")}
                    />
                    {v__visibleIcon}
                    <div className="admin-profile-properties-label">
                      {property.label}
                    </div>
                    <div className="admin-profile-properties-type">
                      {v__type}
                    </div>
                  </button>
                </Cell>
              </Row>
            </Grid>
          </Box>
        </li>
      );
    });

    return (
      <React.Fragment>
        <SortableContainer
          onSortEnd={({ oldIndex, newIndex }) => {
            this.onSortEnd({ oldIndex, newIndex }, location);
          }}
          useDragHandle
          helperContainer={document.getElementById("adminSortProperties")}
        >
          {showProperties.map((property, index) => {
            return (
              <SortableItem
                key={`item-${index}`}
                index={index}
                property={property}
              />
            );
          })}
        </SortableContainer>
        {this.view__showAddButton(location)}
      </React.Fragment>
    );
  }

  view__showAddButton(location) {
    const _c = this.props.context;

    return (
      <div className="admin-profile-properties-add-button">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <button
          data-tip={_c.translate(
            "admin.community.profileProperties.new.button.tooltip"
          )}
          onClick={() => {
            const { newProperty } = this.state;
            const property = cloneDeep(newProperty);
            property.location = location;

            if (property.location === "sidebar") {
              property.type = "text";
            } else {
              property.type = "textarea";
            }

            this.setState({
              selectedProperty: property,
            });
          }}
        >
          <Icon icon="plus-circle" />
        </button>
      </div>
    );
  }

  onSortEnd = ({ oldIndex, newIndex }, location) => {
    const _c = this.props.context;

    let { profileProperties } = this.state;
    let properties = [];
    profileProperties.map((profileProperty) => {
      if (profileProperty.location === location) {
        properties.push(profileProperty);
      }
      return null;
    });
    properties = arrayMove(properties, oldIndex, newIndex);

    const newOrder = [];
    properties.map((property) => {
      return newOrder.push(property.id);
    });

    this.setState({ profileProperties }, () => {
      const apiUrl = _c.apiUrl("admin.settingsProfilePropertySort");

      _c.setIsSaving(true, 2);
      _c.setSavingType();

      setTimeout(() => {
        axios
          .patch(
            apiUrl,
            { newOrder },
            {
              headers: this.props.context.headers,
            }
          )
          .then((response) => {
            try {
              const { status } = response.data;
              _c.handleApiResponse(response.data, true);
              const { profileProperties } = response.data.data;

              if (status === "SUCCESS") {
                _c.setIsSaving(false);
                this.setState({ selectedProperty: null, profileProperties });
                _c.createNotifictation(
                  _c.translate(
                    "admin.community.profileProperties.messages.sorted.title"
                  ),
                  _c.translate(
                    "admin.community.profileProperties.messages.sorted.text"
                  ),
                  "success"
                );
              }
            } catch {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "admin:profileproperties:sort:response"
              );
            }
          })
          .catch((error) => {
            return _c.handleError(error, "admin:profileproperties:sort");
          });
      }, 200);
    });

    this.setState({ selectedLocation: null });
  };

  handle__edit = (property, value) => {
    const { selectedProperty } = this.state;
    selectedProperty[property] = value;
    this.setState({ selectedProperty, changesWereMade: true });
  };

  handle__cancel = () => {
    const { changesWereMade } = this.state;
    const _c = this.props.context;

    let reallyCancel = false;

    if (changesWereMade === true) {
      if (
        window.confirm(
          _c.translate(
            "admin.community.profileProperties.popup.form.cancel.message"
          )
        )
      ) {
        reallyCancel = true;
      }
    } else {
      reallyCancel = true;
    }

    if (reallyCancel === true) {
      this.setState({ selectedProperty: null });
    }
  };

  handle__save = () => {
    const _c = this.props.context;
    const { selectedProperty } = this.state;

    if (!selectedProperty.label) {
      window.alert(
        `${_c.translate("admin.community.profileProperties.errors.noLabel")}`
      );
      _c.setIsSaving(false);
      return;
    }

    _c.setIsSaving(true, 2);
    _c.setSavingType();

    setTimeout(() => {
      const apiUrl = _c.apiUrl("admin.settingsProfileProperty", {
        id: selectedProperty.id,
      });

      axios
        .post(
          apiUrl,
          {
            property: selectedProperty,
          },
          {
            headers: _c.headers,
          }
        )
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);
            const { profileProperties } = response.data.data;

            if (status === "SUCCESS") {
              _c.setIsSaving(false);
              this.setState({ selectedProperty: null, profileProperties });
              _c.cancelEditLock();
              _c.createNotifictation(
                _c.translate(
                  "admin.community.profileProperties.messages.saved.title"
                ),
                _c.translate(
                  "admin.community.profileProperties.messages.saved.text"
                ),
                "success"
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS_RESPONSE_ERROR" },
              "admin:profileproperty:save:response"
            );
          }
        })
        .catch((error) => {
          return _c.handleError(error, "admin:profileproperty:save");
        });
    }, 200);
  };

  handle__delete = () => {
    const { selectedProperty } = this.state;
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.settingsProfileProperty", {
      id: selectedProperty.id,
    });

    axios
      .delete(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          const { status } = response.data;
          _c.handleApiResponse(response.data, true);
          const { profileProperties } = response.data.data;

          if (status === "SUCCESS") {
            _c.createNotifictation(
              _c.translate(
                "admin.community.profileProperties.messages.deleted.title"
              ),
              _c.translate(
                "admin.community.profileProperties.messages.deleted.text"
              ),
              "success"
            );
            this.setState({
              selectedProperty: null,
              changesWereMade: false,
              showDialogDelete: false,
              profileProperties,
            });
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:profileproperty:delete:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:profileproperty:delete");
      });
  };
}

export default withContext(AdminProfileProperties);
