import React, { Component } from "react";
import { Grid, Row, Cell } from "../_foundation/_grid";
import MainTopbar from "../topbars/MainTopbar";
import Breadcrumbs from "../topbars/Breadcrumbs";
import withContext from "../../context/contextHOC";
import Footer from "../layout/Footer";

class DefaultLayout extends Component {
  state = {
    _updatableProps: ["breadcrumbs", "buttons", "menu"],
    nav: this.props.nav, // Selected Navigation Item
    breadcrumbs: this.props.breadcrumbs,
    page: this.props.page,
    help: this.props.help,
    background: this.props.background,
    buttons: this.props.buttons,
    menu: this.props.menu,
    banner: this.props.banner,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    let {
      breadcrumbs,
      page,
      help,
      background,
      menu,
      buttons,
      banner,
    } = this.state;
    const _c = this.props.context;

    const showHelp = _c.config("general.showHelp");

    let v__breadcrumbs = null;
    let classesBreadcrumbs = "";
    breadcrumbs = null;
    if (breadcrumbs) {
      v__breadcrumbs = (
        <Breadcrumbs breadcrumbs={breadcrumbs} buttons={buttons} menu={menu} />
      );
      classesBreadcrumbs = "page-with-breadcrumbs";
    }

    if (!page) {
      page = "default";
    }

    let mainContentStyles = {};

    if (page) {
      let backgroundUrl;
      if (background) {
        backgroundUrl = _c.setting(`design_bg_${background}`);
      } else {
        backgroundUrl = _c.setting(`design_bg_${page}`);
      }
      if (backgroundUrl) {
        mainContentStyles.backgroundImage = `url("${backgroundUrl}")`;
      }
    }

    let maintenanceModeClass = "";
    const maintenanceMode = _c.setting("maintenance");
    if (maintenanceMode === true) {
      maintenanceModeClass = "maintenance-active";
    }

    if (showHelp === true && help) {
      return (
        <Grid type="full">
          <Row>
            <Cell sm={24} md={20}>
              <div
                className={`page page-${page} ${classesBreadcrumbs} ${maintenanceModeClass}`}
              >
                <MainTopbar type="page" banner={banner} />
                {v__breadcrumbs}
                <main className="content" style={mainContentStyles}>
                  <Grid>
                    <Row>
                      <Cell sm={24} md={24}>
                        {this.props.children}
                      </Cell>
                    </Row>
                  </Grid>
                </main>
                <Footer />
              </div>
            </Cell>
            <Cell md={4} className="hide-for-small-only global-help">
              <div className="global-help-content">
                <div
                  dangerouslySetInnerHTML={{
                    __html: _c.translate(`help.${help}.text`),
                  }}
                />
              </div>
            </Cell>
          </Row>
        </Grid>
      );
    } else {
      return (
        <div
          className={`page page-${page} ${classesBreadcrumbs} ${maintenanceModeClass}`}
        >
          <MainTopbar type="page" banner={banner} />
          {v__breadcrumbs}
          <main className="content" style={mainContentStyles}>
            <Grid type="full">
              <Row>
                <Cell sm={24} md={24}>
                  {this.props.children}
                </Cell>
              </Row>
            </Grid>
          </main>
          <Footer />
        </div>
      );
    }
  }
}

export default withContext(DefaultLayout);
