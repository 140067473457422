import React from "react";
import { cloneDeep } from "lodash";
import ConfigHelper from "../../../helpers/ConfigHelper";
import { Icon } from "../../_foundation/_buttons";
import { arrayMove } from "@dnd-kit/sortable";

const previewNoContent = (
  <div className="pagebuilder-element-no-content">Kein Inhalt</div>
);
const previewNoPreview = (
  <div className="pagebuilder-element-no-preview">Keine Vorschau möglich.</div>
);

const initItemPosition = {
  firstOfAll: false,
  lastOfAll: false,
  firstInParent: false,
  lastInParent: false,
};

const maxChars = {
  h1: 60,
  h2: 60,
  h3: 60,
  h4: 60,
  h5: 60,
  h6: 60,
  text: 200,
};

const items = {
  area: {
    name: "Bereich",
    fullWidth: true,
    options: {
      __options: true,
    },
    responsive: { sm: true, md: true, lg: true },
    position: null,
    rows: [],
  },
  row: {
    name: "Zeile",
    fullWidth: false,
    column_schema: "24",
    options: {
      __options: true,
    },
    responsive: { sm: true, md: true, lg: true },
    position: null,
    columns: [],
  },
  column: {
    name: "Spalte",
    column_type: { sm: 24, md: 24, lg: 24 },
    options: {
      __options: true,
    },
    responsive: { sm: true, md: true, lg: true },
    position: null,
    elements: [],
  },
  element: {
    type: null,
    name: "Element",
    options: {
      __options: true,
    },
    content: null,
    content_html: null,
    responsive: { sm: true, md: true, lg: true },
    position: null,
  },
};

const defaultElementOptions = {
  button: {
    __options: true,
    border: {
      t: {
        width: "2px",
        color: "black",
        style: "solid",
      },
      r: {
        width: "2px",
        color: "black",
        style: "solid",
      },
      b: {
        width: "2px",
        color: "black",
        style: "solid",
      },
      l: {
        width: "2px",
        color: "black",
        style: "solid",
      },
    },
    padding: {
      t: "8px",
      r: "15px",
      b: "8px",
      l: "15px",
    },
    fontWeight: 600,
    newtab: true,
    alignh: "center",
  },
  lesson: {
    course: "none",
    lesson: "none",
    view: "embed",
  },
};

const PagebuilderHelper = {
  getDateString: function (dateObject) {
    //1995-12-17T03:24:00

    let month = dateObject.getMonth() + 1;
    if (month < 10) month = `0${month}`;

    let day = dateObject.getDate();
    if (day < 10) day = `0${day}`;

    let hour = dateObject.getHours();
    if (hour < 10) hour = `0${hour}`;

    let minute = dateObject.getMinutes();
    if (minute < 10) minute = `0${minute}`;

    return `${dateObject.getFullYear()}-${month}-${day}T${hour}:${minute}:00`;
  },
  createDateObject: function (dateString) {
    // dateString Format: YYYY-MM-DD--HH-ii

    const [dateDate, dateTime] = dateString.split("--");
    const [year, month, day] = dateDate.split("-");
    const [hour, minute] = dateTime.split("-");

    const date = new Date();

    console.log(dateDate, dateTime);
  },
  addArea: function (position, structure, pageIdentifier) {
    console.log("addArea()", position);

    if (!structure || !structure.areas) return structure;

    const newItem = this.createNewItem("area", position, pageIdentifier);
    if (!newItem) return null;

    if (structure.areas.length === 0) {
      structure.areas = [newItem];
    } else {
      const areas = [];

      structure.areas.map((area) => {
        if (parseInt(area.position) === parseInt(position)) areas.push(newItem);
        if (parseInt(area.position) >= parseInt(position)) area.position++;

        areas.push(area);

        return null;
      });

      if (structure.areas.length < position) {
        areas.push(newItem);
      }

      structure.areas = cloneDeep(areas);
    }

    return { updatedStructure: structure, area: newItem };
  },
  addRow: function (
    position,
    columnSchema,
    structure,
    pageIdentifier,
    areaIdentifier
  ) {
    console.log("addRow()", position, columnSchema, areaIdentifier);

    if (!structure || !structure.areas) return structure;

    const newItem = this.createNewItem(
      "row",
      position,
      pageIdentifier,
      areaIdentifier
    );
    if (!newItem) return null;

    newItem.column_schema = columnSchema;
    newItem.rows = this.addColumns(newItem, pageIdentifier);

    const areas = [];

    structure.areas.map((area) => {
      if (area.identifier === areaIdentifier) {
        const rows = [];

        if (area.rows.length === 0) {
          rows.push(newItem);
        } else {
          area.rows.map((row) => {
            if (parseInt(row.position) === parseInt(position))
              rows.push(newItem);
            if (parseInt(row.position) >= parseInt(position)) row.position++;

            rows.push(row);

            return null;
          });
        }

        if (area.rows.length > 0 && area.rows.length < position)
          rows.push(newItem);

        area.rows = rows;
      }

      areas.push(area);

      return null;
    });

    structure.areas = cloneDeep(areas);
    return { updatedStructure: structure, row: newItem };
  },
  addColumns: function (row, pageIdentifier) {
    if (!row.columns) row.columns = [];

    const columnSizes = row.column_schema.split(":");
    columnSizes.map((columnSize, index) => {
      const newItem = cloneDeep(
        this.createNewItem(
          "column",
          index + 1,
          pageIdentifier,
          null,
          row.identifier
        )
      );

      newItem.identifier = `${newItem.identifier}_${index + 1}`;
      newItem.name = `${newItem.name} ${index + 1}`;

      newItem.column_type = {
        sm: 24,
        md: parseInt(columnSize),
        lg: parseInt(columnSize),
      };

      row.columns.push(newItem);

      return null;
    });

    return row.columns;
  },
  addElement: function (
    position,
    type,
    structure,
    pageIdentifier,
    columnIdentifier
  ) {
    console.log("addElement()", position, type, columnIdentifier);

    if (!structure || !structure.areas) return structure;

    const newItem = this.createNewItem(
      "element",
      position,
      pageIdentifier,
      null,
      null,
      columnIdentifier
    );
    if (!newItem) return null;

    newItem.type = type;

    if (defaultElementOptions[newItem.type])
      newItem.options = cloneDeep(defaultElementOptions[newItem.type]);

    const pagebuilderElementTypes = ConfigHelper.get("pagebuilder.elements");
    if (pagebuilderElementTypes[type])
      newItem.name = pagebuilderElementTypes[type].title;

    const areas = [];

    structure.areas.map((area) => {
      const rows = [];

      area.rows.map((row) => {
        const columns = [];

        row.columns.map((column) => {
          const elements = [];

          if (column.identifier === columnIdentifier) {
            if (column.elements.length === 0) {
              elements.push(newItem);
            } else {
              column.elements.map((element) => {
                if (parseInt(element.position) === parseInt(position))
                  elements.push(newItem);

                if (parseInt(element.position) >= parseInt(position))
                  element.position++;

                elements.push(element);

                return null;
              });
            }

            if (column.elements.length > 0 && column.elements.length < position)
              elements.push(newItem);

            column.elements = elements;

            columns.push(column);
          }

          return null;
        });

        rows.push(row);

        return null;
      });

      areas.push(area);

      return null;
    });

    structure.areas = cloneDeep(areas);
    return { updatedStructure: structure, element: newItem };
  },
  createNewItem: function (type, position, page, area, row, column) {
    if (!items[type]) return null;

    const newItem = cloneDeep(items[type]);
    newItem.identifier = this.generateIdentifier(type);
    newItem.position = position;
    newItem.page = page;

    if (type === "row") newItem.area = area;
    if (type === "column") newItem.row = row;
    if (type === "element") newItem.column = column;

    return newItem;
  },
  generateIdentifier: function (type) {
    const min = 100000;
    const max = 1000000;
    let identifier = `new_${type}_`;
    identifier += `${Math.floor(Math.random() * (max - min + 1)) + min}`;

    const now = new Date();
    identifier += `_${now.getFullYear()}${
      now.getMonth() + 1
    }${now.getDate()}${now.getHours()}${now.getMinutes()}${now.getSeconds()}${now.getMilliseconds()}`;
    return identifier;
  },
  logStructure: function (structure) {
    console.log(" ");
    console.log("---------------- STRUCTURE START ----------------");
    structure.areas.map((area) => {
      console.log("AREA: ", "Id: " + area.identifier, "Name: " + area.name);

      area.rows.map((row) => {
        console.log(
          "---- ROW [" + row.position + "]: ",
          "Id: " + row.identifier,
          "Name: " + row.name,
          "Schema: " + row.column_schema
        );

        row.columns.map((column) => {
          console.log(
            "------ COLUMN [" + column.position + "]: ",
            "Id: " + column.identifier,
            "Name: " + column.name,
            "Type: " + column.column_type.lg
          );

          column.elements.map((element) => {
            console.log(
              "-------- ELEMENT [" + element.position + "]: ",
              "Id: " + element.identifier,
              "Column: " + element.column,
              "Name: " + element.name,
              "Type: " + element.type,
              "Content: " + element.content
            );
            return null;
          });
          return null;
        });
        return null;
      });

      return null;
    });
    console.log("----------------- STRUCTURE END -----------------");
    console.log(" ");
  },
  getPreview: function (element) {
    let preview;

    if (["h1", "h2", "h3", "h4", "h5", "h6"].includes(element.type)) {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = element.content;
        if (preview.length > maxChars[element.type])
          preview = preview.substring(0, maxChars[element.type] - 3);
      }
    } else if (element.type === "text") {
      if (!element.content_html) {
        preview = previewNoContent;
      } else {
        preview = element.content_html.replace(/(<([^>]+)>)/gi, "");
        if (preview.length > maxChars[element.type])
          preview = preview.substring(0, maxChars[element.type] - 3);
      }
    } else if (element.type === "image") {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = <img src={element.content} alt="" />;
      }
    } else if (["vimeo", "youtube"].includes(element.type)) {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = `Video ID: ${element.content}`;
      }
    } else if (element.type === "lesson") {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = <img src={element.content} alt="" />;
      }
    } else if (element.type === "lessongrid") {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = <img src={element.content} alt="" />;
      }
    } else if (element.type === "button") {
      if (!element.content) {
        preview = previewNoContent;
      } else {
        preview = (
          <>
            [{element.content}]{" "}
            {element.options && element.options.url && (
              <>
                <Icon icon="long-arrow-right" /> [{element.options.url}
              </>
            )}
            ]
          </>
        );
      }
    } else if (element.type === "countdown") {
      if (!element.content) preview = previewNoContent;
      else preview = element.content;
    }

    return preview;
  },
  updateArea: function (area, structure, doDelete) {
    const areas = [];

    structure.areas.map((structureArea) => {
      if (area.identifier === structureArea.identifier) {
        if (doDelete !== true) areas.push(area);
      } else {
        areas.push(structureArea);
      }

      return null;
    });

    structure.areas = areas;

    this.logStructure(structure);

    return structure;
  },
  updateRow: function (row, structure, doDelete) {
    const areas = [];

    structure.areas.map((area) => {
      const rows = [];
      area.rows.map((structureRow) => {
        if (row.identifier === structureRow.identifier) {
          if (doDelete !== true) rows.push(row);
        } else {
          rows.push(structureRow);
        }

        return null;
      });

      area.rows = rows;

      areas.push(area);
      return null;
    });

    structure.areas = areas;

    this.logStructure(structure);

    return structure;
  },
  updateColumn: function (column, structure) {
    const areas = [];

    structure.areas.map((area) => {
      const rows = [];
      area.rows.map((row) => {
        const columns = [];
        row.columns.map((structureColumn) => {
          if (column.identifier === structureColumn.identifier) {
            columns.push(column);
          } else {
            columns.push(structureColumn);
          }

          return null;
        });
        row.columns = columns;
        rows.push(row);

        return null;
      });

      area.rows = rows;

      areas.push(area);
      return null;
    });

    structure.areas = areas;

    this.logStructure(structure);

    return structure;
  },
  updateRowColumnSchema: function (row, newColumnSchema) {
    const oldColumnSchema = row.column_schema;
    const oldColumnSizes = oldColumnSchema.split(":");
    const newColumnSizes = newColumnSchema.split(":");

    let columns = [];

    if (newColumnSizes.length > oldColumnSizes.length) {
      columns = row.columns;
      const newColumns = newColumnSizes.length - oldColumnSizes.length;
      let position = oldColumnSizes.length;

      for (let i = 1; i <= newColumns; i++) {
        position++;
        const newItem = this.createNewItem(
          "column",
          position,
          row.page,
          null,
          row.identifier,
          null
        );
        newItem.identifier = `${newItem.identifier}_${position}`;
        newItem.name = `${newItem.name} ${position}`;
        columns.push(newItem);
      }
    } else if (newColumnSizes.length === oldColumnSizes.length) {
      // Do nothing but adjust column size / column type (see below)
      columns = row.columns;
    } else if (newColumnSizes.length < oldColumnSizes.length) {
      columns = [];
      const leftOverElements = [];
      row.columns.map((column) => {
        if (column.position <= newColumnSizes.length) {
          columns.push(column);
        } else {
          column.elements.map((element) => {
            leftOverElements.push(element);
            return null;
          });
        }
        return null;
      });

      if (leftOverElements.length > 0) {
        columns.map((column, index) => {
          const elements = column.elements;
          if (column.position === columns.length) {
            let elementPosition = column.elements.length;
            leftOverElements.map((leftOverElement) => {
              leftOverElement.column = column.identifier;
              leftOverElement.position = elementPosition;
              elements.push(leftOverElement);
              elementPosition++;
              return null;
            });
          }
          column.elements = elements;

          return null;
        });
      }
    }

    columns.map((column, index) => {
      const columnSize = newColumnSizes[index];
      column.column_type = {
        sm: 24,
        md: parseInt(columnSize),
        lg: parseInt(columnSize),
      };
      return null;
    });

    /*console.log("UPDATED COLUMNS");
      columns.map((column) => {
        console.log(
          "COLUMN",
          "Identifier " + column.identifier,
          "Size: " + column.column_type.lg,
          "Position: " + column.position,
          "Elements: " + column.elements.length
          );
          return null;
        });*/

    row.columns = columns;
    row.column_schema = newColumnSchema;
    return row;
  },
  updateElement: function (element, structure, doDelete) {
    const areas = [];

    structure.areas.map((area) => {
      const rows = [];

      area.rows.map((row) => {
        const columns = [];

        row.columns.map((column) => {
          const elements = [];

          column.elements.map((structureElement) => {
            if (element.identifier === structureElement.identifier) {
              if (doDelete !== true) elements.push(element);
            } else elements.push(structureElement);
            return null;
          });

          column.elements = elements;
          columns.push(column);
          return null;
        });

        row.columns = columns;
        rows.push(row);
        return null;
      });

      area.rows = rows;
      areas.push(area);
      return null;
    });

    this.logStructure(structure);

    return structure;
  },
  /*getArrayForSort: function (type, identifier, structure) {
    const items = [];

    structure.areas.map((area) => {
      if (type === "areas") {
        items.push(area.identifier);
      } else {
        area.map((row) => {
          if (type === "rows" && area.identifier === identifier) {
            items.push(row.identifier);
          } else {
            row.columns.map((column) => {
              if (type === "columns" && row.identifier === identifier) {
                items.push(column.identifier);
              } else {
                column.elements.map((element) => {
                  if (type === "elements" && column.identifier === identifier)
                    items.push(element);
                  return null;
                });
              }
              return null;
            });
          }
          return null;
        });
      }
      return null;
    });

    return items;
  },
  sortItems: function (type, items, structure) {
    const areas = [];
    const areasByIdentifier = {};
    structure.areas.map((area) => {
      if (type === "areas") {
        areasByIdentifier[area.identifier] = cloneDeep(area);
      }
      return null;
    });

    if (type === "areas") {
      items.map((item, index) => {
        if (areasByIdentifier[item]) {
          areasByIdentifier[item].position = index + 1;
          areas.push(areasByIdentifier[item]);
        }
        return null;
      });
    }

    const updatedStructure = cloneDeep(structure);
    updatedStructure.areas = areas;
    return updatedStructure;
  },*/
  replaceItemsArrayAfterSort: function (type, items, identifier, structure) {
    items = this.updatePositionsAfterSort(items);
    let areas = [];
    if (type === "areas") areas = items;
    else {
      let rows = [];
      structure.areas.map((area) => {
        if (type === "rows" && area.identifier === identifier) rows = items;
        else {
          let columns = [];
          area.rows.map((row) => {
            if (type === "columns" && row.identifier === identifier)
              columns = items;
            else {
              let elements = [];
              row.columns.map((column) => {
                if (type === "elements" && column.identifier === identifier)
                  elements = items;
                else elements = column.elements;
                column.elements = elements;
                return null;
              });
              columns = row.columns;
            }
            row.columns = columns;
            return null;
          });
          rows = area.rows;
        }
        area.rows = rows;
        return null;
      });

      areas = structure.areas;
    }

    const updatedStructure = cloneDeep(structure);
    updatedStructure.areas = areas;

    return updatedStructure;
  },
  updatePositionsAfterSort: function (items) {
    items.map((item, index) => {
      item.position = index + 1;
      return null;
    });
    return items;
  },
  findItem: function (identifier, structure) {
    let foundItem;

    structure.areas.map((area) => {
      if (area.identifier === identifier) foundItem = area;
      else {
        area.rows.map((row) => {
          if (row.identifier === identifier) foundItem = row;
          else {
            row.columns.map((column) => {
              if (column.identifier === identifier) foundItem = column;
              else {
                column.elements.map((element) => {
                  if (element.identifier === identifier) foundItem = element;
                  return null;
                });
              }
              return null;
            });
          }
          return null;
        });
      }
      return null;
    });
    return cloneDeep(foundItem);
  },
  prepareStructure: function (structure) {
    //console.log("STRUCURE", structure);

    // Get Item Positions
    const areaCount = structure.areas.length;
    structure.areas.map((area, areaIndex) => {
      const itemPosition = cloneDeep(initItemPosition);

      if (areaIndex + 1 === 1) {
        itemPosition.firstInParent = true;
        itemPosition.firstOfAll = true;
      }

      if (areaIndex + 1 === areaCount) {
        itemPosition.lastInParent = true;
        itemPosition.lastOfAll = true;
      }

      area.itemPosition = itemPosition;

      const rowsCount = area.rows.length;
      area.rows.map((row, rowIndex) => {
        // Resize columns after moving columns
        const columnSizes = row.column_schema.split(":");
        for (let i = 0; i < columnSizes.length; i++) {
          if (row.columns[i])
            row.columns[i].column_type = {
              sm: 24,
              md: parseInt(columnSizes[i]),
              lg: parseInt(columnSizes[i]),
            };
        }

        const itemPosition = cloneDeep(initItemPosition);

        if (rowIndex + 1 === 1) {
          itemPosition.firstInParent = true;

          if (area.itemPosition.firstOfAll) itemPosition.firstOfAll = true;
        }

        if (rowIndex + 1 === rowsCount) {
          itemPosition.lastInParent = true;

          if (area.itemPosition.lastOfAll) itemPosition.lastOfAll = true;
        }

        row.itemPosition = itemPosition;

        const columnsCount = row.columns.length;
        row.columns.map((column, columnIndex) => {
          const itemPosition = cloneDeep(initItemPosition);

          if (columnIndex + 1 === 1) {
            itemPosition.firstInParent = true;

            if (row.itemPosition.firstOfAll) itemPosition.firstOfAll = true;
          }

          if (columnIndex + 1 === columnsCount) {
            itemPosition.lastInParent = true;

            if (row.itemPosition.lastOfAll) itemPosition.lastOfAll = true;
          }

          column.itemPosition = itemPosition;

          const elementsCount = column.elements.length;
          column.elements.map((element, elementIndex) => {
            const itemPosition = cloneDeep(initItemPosition);

            if (elementIndex + 1 === 1) {
              itemPosition.firstInParent = true;

              if (column.itemPosition.firstOfAll)
                itemPosition.firstOfAll = true;
            }

            if (elementIndex + 1 === elementsCount) {
              itemPosition.lastInParent = true;

              if (column.itemPosition.lastOfAll) itemPosition.lastOfAll = true;
            }

            element.itemPosition = itemPosition;

            return null;
          });

          return null;
        });

        return null;
      });

      return null;
    });

    return structure;
  },
  moveItem: function (identifier, direction, structure) {
    let previousArea = null;
    let previousRow = null;
    let previousColumn = null;
    let previousElement = null;

    let stopMoving = false;

    structure.areas.map((area, areaIndex) => {
      if (stopMoving) return null;

      if (area.identifier === identifier) {
        structure.areas = this._changePositionOfItemInParent(
          area,
          areaIndex,
          structure.areas,
          direction
        );
        stopMoving = true;
      } else {
        area.rows.map((row, rowIndex) => {
          if (stopMoving) return null;

          if (row.identifier === identifier) {
            if (direction === "down") {
              if (row.itemPosition.lastInParent) {
                if (structure.areas[areaIndex + 1]) {
                  structure.areas[areaIndex + 1].rows.unshift(row);
                  area.rows.splice(rowIndex, 1);
                }
              } else {
                area.rows = this._changePositionOfItemInParent(
                  row,
                  rowIndex,
                  area.rows,
                  direction
                );
              }
            } else if (direction === "up") {
              if (row.itemPosition.firstInParent) {
                if (structure.areas[areaIndex - 1]) {
                  structure.areas[areaIndex - 1].rows.push(row);
                  area.rows.splice(rowIndex, 1);
                }
              } else {
                area.rows = this._changePositionOfItemInParent(
                  row,
                  rowIndex,
                  area.rows,
                  direction
                );
              }
            }

            stopMoving = true;
            return null;
          } else {
            row.columns.map((column, columnIndex) => {
              if (stopMoving) return null;

              if (column.identifier === identifier) {
                row.columns = this._changePositionOfItemInParent(
                  column,
                  columnIndex,
                  row.columns,
                  direction
                );

                stopMoving = true;
                return null;
              } else {
                column.elements.map((element, elementIndex) => {
                  if (stopMoving) return null;

                  if (element.identifier === identifier) {
                    if (direction === "down") {
                      if (element.itemPosition.lastInParent) {
                        if (row.columns[columnIndex + 1]) {
                          // Move to next column
                          row.columns[columnIndex + 1].elements.unshift(
                            element
                          );
                          column.elements.splice(elementIndex, 1);
                        } else if (
                          area.rows[rowIndex + 1] &&
                          area.rows[rowIndex + 1].columns[0]
                        ) {
                          //Move to first column in next row
                          area.rows[rowIndex + 1].columns[0].elements.unshift(
                            element
                          );
                          column.elements.splice(elementIndex, 1);
                        } else if (
                          structure.areas[areaIndex + 1] &&
                          structure.areas[areaIndex + 1].rows[0] &&
                          structure.areas[areaIndex + 1].rows[0].columns[0]
                        ) {
                          //Move to first column in first row of next area
                          structure.areas[
                            areaIndex + 1
                          ].rows[0].columns[0].elements.unshift(element);
                          column.elements.splice(elementIndex, 1);
                        }
                      } else {
                        column.elements = this._changePositionOfItemInParent(
                          element,
                          elementIndex,
                          column.elements,
                          direction
                        );
                      }
                    } else if (direction === "up") {
                      if (element.itemPosition.firstInParent) {
                        if (row.columns[columnIndex - 1]) {
                          // Move to previous column
                          row.columns[columnIndex - 1].elements.push(element);
                          column.elements.splice(elementIndex, 1);
                        } else if (
                          area.rows[rowIndex - 1] &&
                          area.rows[rowIndex - 1].columns[
                            area.rows[rowIndex - 1].columns.length - 1
                          ]
                        ) {
                          // Move to last column in previous row
                          area.rows[rowIndex - 1].columns[
                            area.rows[rowIndex - 1].columns.length - 1
                          ].elements.unshift(element);
                          column.elements.splice(elementIndex, 1);
                        } else if (
                          structure.areas[areaIndex - 1].rows[
                            structure.areas[areaIndex - 1].rows.length - 1
                          ] &&
                          structure.areas[areaIndex - 1].rows[
                            structure.areas[areaIndex - 1].rows.length - 1
                          ].columns[
                            structure.areas[areaIndex - 1].rows[
                              structure.areas[areaIndex - 1].rows.length - 1
                            ].columns.length - 1
                          ]
                        ) {
                          // Move to last column in last row of previous area
                          structure.areas[areaIndex - 1].rows[
                            structure.areas[areaIndex - 1].rows.length - 1
                          ].columns[
                            structure.areas[areaIndex - 1].rows[
                              structure.areas[areaIndex - 1].rows.length - 1
                            ].columns.length - 1
                          ].elements.push(element);
                          column.elements.splice(elementIndex, 1);
                        }
                      } else {
                        column.elements = this._changePositionOfItemInParent(
                          element,
                          elementIndex,
                          column.elements,
                          direction
                        );
                      }
                    }

                    stopMoving = true;
                    return null;
                  }

                  return null;
                });
              }
            });
          }
        });

        return null;
      }
    });

    //this.logStructure(structure);
    return this.prepareStructure(structure);
  },
  _changePositionOfItemInParent: function (
    item,
    itemIndex,
    siblings,
    direction
  ) {
    let newIndex = itemIndex;
    if (["up", "left"].includes(direction) && !item.itemPosition.firstOfAll)
      newIndex--;
    else if (
      ["down", "right"].includes(direction) &&
      !item.itemPosition.lastOfAll
    )
      newIndex++;

    siblings = arrayMove(siblings, itemIndex, newIndex);
    siblings = this.updatePositionsAfterSort(siblings);

    return siblings;
  },
  duplicateItem: function (item, structure) {
    structure.areas.map((area, areaIndex) => {
      if (area.identifier === item.identifier) {
        structure.areas.splice(
          areaIndex + 1,
          0,
          this._duplicateItem("area", area)
        );
        structure.areas = this.updatePositionsAfterSort(structure.areas);
      } else {
        area.rows.map((row, rowIndex) => {
          if (row.identifier === item.identifier) {
            area.rows.splice(
              rowIndex + 1,
              0,
              this._duplicateItem("row", row, area.identifier)
            );
            area.rows = this.updatePositionsAfterSort(area.rows);
          } else {
            row.columns.map((column, columnIndex) => {
              if (column.identifier === item.identifier) {
                row.columns.splice(
                  columnIndex + 1,
                  0,
                  this._duplicateItem("column", column, row.identifier)
                );
                row.columns = this.updatePositionsAfterSort(row.columns);
              } else {
                column.elements.map((element, elementIndex) => {
                  if (element.identifier === item.identifier) {
                    column.elements.splice(
                      elementIndex + 1,
                      0,
                      this._duplicateItem("element", element, column.identifier)
                    );
                    column.elements = this.updatePositionsAfterSort(
                      column.elements
                    );
                  }
                  return null;
                });
              }
              return null;
            });
          }
          return null;
        });
      }
      return null;
    });

    //console.log(structure);

    this.logStructure(structure);
    return structure;
  },
  _duplicateItem: function (type, item, parentIdentifier) {
    const duplicatedItem = cloneDeep(item);
    duplicatedItem.identifier = this.generateIdentifier(type);

    if (type === "row") duplicatedItem.area = parentIdentifier;
    else if (type === "column") duplicatedItem.row = parentIdentifier;
    else if (type === "element") duplicatedItem.column = parentIdentifier;

    if (duplicatedItem.rows) {
      duplicatedItem.rows = duplicatedItem.rows.map((row) => {
        return this._duplicateItem("row", row, duplicatedItem.identifier);
      });
    }

    if (duplicatedItem.columns) {
      duplicatedItem.columns = duplicatedItem.columns.map((column) => {
        return this._duplicateItem("column", column, duplicatedItem.identifier);
      });
    }

    if (duplicatedItem.elements) {
      duplicatedItem.elements = duplicatedItem.elements.map((element) => {
        return this._duplicateItem(
          "element",
          element,
          duplicatedItem.identifier
        );
      });
    }

    return duplicatedItem;
  },
};

export default PagebuilderHelper;
