import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import CourseLayout from "./CourseLayout";
import axios from "axios";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import HomeVideo from "../../home/HomeVideo";
import MoreWithLoa from "./categories/ausbildung/MoreWithLoa";
import PageView from "../../pagebuilder/PageView";
import DefaultLayout from "../../layout/DefaultLayout";

class CourseCategory extends Component {
  state = {
    courseSlug: this.props.match.params.courseSlug,
    categorySlug: this.props.match.params.categorySlug,
    breadcrumbs: ["home", "courses"],
    course: null,
    category: null,
    downloads: null,
    page: null,
    status: "INIT",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("courses");
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { courseSlug, categorySlug } = this.state;

    if (this.props.match.params.courseSlug !== courseSlug) {
      this.setState({ courseSlug: this.props.match.params.courseSlug }, () => {
        this.init();
      });
    }

    if (this.props.match.params.categorySlug !== categorySlug) {
      this.setState({ categorySlug: this.props.match.params.categorySlug }, () => {
        this.init();
      });
    }
  }

  render() {
    const { status, course, category, downloads, breadcrumbs, page } = this.state;

    if (!course || !category) {
      return null;
    }

    if (page && page.identifier) {
      return (
        <div
          className={`pageview-page ${
            page.banner ? `page-pagebuilder-with-banner` : `page-pagebuilder-no-banner`
          }`}
        >
          <DefaultLayout>
            <PageView page={page} />
          </DefaultLayout>
        </div>
      );
    }

    return (
      <div className="course-category">
        <CourseLayout
          status={status}
          course={course}
          category={category}
          downloads={downloads}
          breadcrumbs={breadcrumbs}
          buttons={null}
          menu={null}
          help="category"
        >
          {this.view__showContent()}
        </CourseLayout>
      </div>
    );
  }

  view__showContent() {
    const { course, category } = this.state;
    const _c = this.props.context;

    let v__categoryHeader;

    if (category.banner) {
      v__categoryHeader = (
        <React.Fragment>
          <div className="category-banner">
            <img src={category.banner} alt={category.name} />
          </div>
          <div className="category-description">
            <div
              dangerouslySetInnerHTML={{
                __html: category.description_html,
              }}
            />
          </div>
        </React.Fragment>
      );
    } else {
      v__categoryHeader = (
        <React.Fragment>
          <h1 className="category-name">
            <div className="category-course-name">{course.name}</div>
            {category.name}
          </h1>

          <Grid type="full">
            <Row margin="xy">
              <Cell sm={24} md={12}>
                <div className="category-image">
                  <img src={category.image} alt={category.name} />
                </div>
              </Cell>
              <Cell sm={24} md={12}>
                <div className="category-description">
                  <div
                    dangerouslySetInnerHTML={{
                      __html: category.description_html,
                    }}
                  />
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }

    return (
      <div>
        <section>{v__categoryHeader}</section>

        {this.view__showQAButtons()}
        {this.view__showEmptyBanner()}

        {this.view__showLessons()}
      </div>
    );
  }

  view__showEmptyBanner() {
    const { category } = this.state;

    if (
      category.lessons &&
      category.lessons[0] &&
      category.lessons[0].lessons &&
      category.lessons[0].lessons.length > 0
    ) {
      return null;
    }

    if (category.id === 3) {
      return (
        <section className="course-category-empty-banner">
          <img src="https://universeapi.loahelser.com/storage/loa/Meditation_Banner2.jpg" alt="" />
        </section>
      );
    } else if (category.id === 5) {
      return (
        <section className="course-category-empty-banner">
          <img src="https://universeapi.loahelser.com/storage/loa/Q_A_Banner_2.jpg" alt="" />
        </section>
      );
    }
  }

  view__showQAButtons() {
    const { category } = this.state;
    const _c = this.props.context;

    const textQAButton1Html = _c.settingHtml("text_qa_button1");
    const textQAButton2Html = _c.settingHtml("text_qa_button2");
    const textQAButton3Html = _c.settingHtml("text_qa_button3");

    /*const textQAButton1 = _c.setting("text_qa_button1");
    const textQAButton2 = _c.setting("text_qa_button2");
    const textQAButton3 = _c.setting("text_qa_button3");*/

    if (category.id === 5) {
      return (
        <React.Fragment>
          <section className="course-category-qa">
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={8}>
                  <div className="course-category-qa-button ">
                    <a
                      className="primary button"
                      href="https://www.facebook.com/groups/1363539220660269"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      FACEBOOK-GRUPPE
                    </a>
                  </div>
                  <div className="course-category-qa-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton2Html,
                      }}
                    />
                  </div>
                </Cell>
                <Cell sm={24} md={8}>
                  <div className="course-category-qa-button ">
                    <button
                      className="primary button"
                      onClick={() => {
                        _c.showQAPopup(true);
                      }}
                    >
                      STELLE DEINE FRAGE HIER
                    </button>
                  </div>
                  <div className="course-category-qa-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton1Html,
                      }}
                    />
                  </div>
                </Cell>

                <Cell sm={24} md={8}>
                  <div className="course-category-qa-button ">
                    <a
                      href="mailto:support@loahelser.de?subject=LOA%20UNIVERSE%20Supportanfrage"
                      className="topbar-supportbutton primary button"
                    >
                      SUPPORT
                    </a>
                  </div>
                  <div className="course-category-qa-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton3Html,
                      }}
                    />
                  </div>
                </Cell>
              </Row>
            </Grid>
          </section>

          {/*<section className="course-category-qa">
            <Grid>
              <Row margin="xy">
                <Cell sm={24} md={7}>
                  <div
                    className="course-category-qa-button-hr "
                    style={{ paddingTop: "5px" }}
                  >
                    <button
                      className="primary button"
                      onClick={() => {
                        _c.showQAPopup(true);
                      }}
                    >
                      <Icon icon="question-circle" left /> STELLE JETZT DEINE
                      FRAGE
                    </button>
                  </div>
                </Cell>
                <Cell sm={24} md={17}>
                  <div className="course-category-qa-text-hr">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton1,
                      }}
                    />
                  </div>
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24} md={7}>
                  <div className="course-category-qa-button-hr center-vertically">
                    <a
                      className="primary button"
                      href="https://www.facebook.com/groups/1363539220660269"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="facebook-square" left /> ZUR FACEBOOK GRUPPE
                    </a>
                  </div>
                </Cell>
                <Cell sm={24} md={17}>
                  <div className="course-category-qa-text-hr">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton2,
                      }}
                    />
                  </div>
                </Cell>
              </Row>
              <Row margin="xy">
                <Cell sm={24} md={7}>
                  <div className="course-category-qa-button-hr center-vertically">
                    <a
                      href="mailto:support@loahelser.de?subject=LOA%20UNIVERSE%20Supportanfrage"
                      className="topbar-supportbutton primary button"
                    >
                      <Icon icon="envelope-o" left /> SUPPORT E-MAIL SCHREIBEN
                    </a>
                  </div>
                </Cell>
                <Cell sm={24} md={17}>
                  <div className="course-category-qa-text-hr center-vertically">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: textQAButton3,
                      }}
                    />
                  </div>
                </Cell>
              </Row>
            </Grid>
                    </section>*/}
        </React.Fragment>
      );
    }
  }

  view__showLessons() {
    const { category, course } = this.state;
    const _c = this.props.context;

    if (parseInt(category.id) === 7) {
      return <MoreWithLoa category={category} course={course} />;
    } else if (parseInt(category.id) === 4) {
      return (
        <React.Fragment>
          <section className="course-category-subcategory">
            <Grid type="full">
              <Row margin="xy">
                {category.lessons.map((subCategory, index) => {
                  let v__title = <h2>{category.name}</h2>;
                  if (subCategory && subCategory.id && subCategory.id !== "pseudo") {
                    /*if (subCategory.image) {
                    v__title = (
                      <img src={subCategory.image} alt={subCategory.name} />
                    );
                  } else {
                    v__title = <h2>{subCategory.name}</h2>;
                  }*/
                    v__title = <h2>{subCategory.name}</h2>;
                  }

                  if (subCategory.image) {
                    v__title = (
                      <img
                        src={subCategory.image}
                        alt={subCategory.name}
                        style={{ marginBottom: "20px", marginTop: "20px" }}
                      />
                    );
                  }

                  if (
                    (!subCategory.lessons ||
                      (subCategory.lessons && subCategory.lessons.length === 0)) &&
                    (!subCategory.downloads ||
                      (subCategory.downloads && subCategory.downloads.length === 0))
                  ) {
                    return null;
                  }

                  let md_lvl1 = 12;
                  let md_lvl2 = 24;
                  let classes;
                  if (subCategory.id === 7 || subCategory.id === 8 || subCategory.category === 7) {
                    md_lvl1 = 12;
                    md_lvl2 = 24;
                    classes = "big-download";
                    if (parseInt(category.id) === 4) {
                      classes = "small-download";
                    }
                  } else {
                    md_lvl1 = 24;
                    md_lvl2 = 6;
                    classes = "small-download";
                  }

                  return (
                    <Cell sm={24} md={md_lvl1} key={index}>
                      {v__title}
                      <Grid type="full">
                        <Row margin="xy">
                          {subCategory.lessons.map((lesson, lessonIndex) => {
                            let v__image;

                            if (lesson.image) {
                              v__image = (
                                <a
                                  href={_c
                                    .config("urls.courseLesson")
                                    .replace(":courseSlug", course.slug)
                                    .replace(":lessonSlug", lesson.slug)}
                                  className="home-categories-category"
                                >
                                  <img src={lesson.image} alt="" />
                                </a>
                              );
                            } else {
                              v__image = (
                                <React.Fragment>
                                  <div className="category-lesson-image">
                                    <HomeVideo video={lesson} />
                                  </div>
                                </React.Fragment>
                              );
                            }

                            return (
                              <Cell sm={24} md={md_lvl2} key={lessonIndex}>
                                {v__image}
                                <a
                                  href={_c
                                    .config("urls.courseLesson")
                                    .replace(":courseSlug", course.slug)
                                    .replace(":lessonSlug", lesson.slug)}
                                  className="home-categories-category"
                                >
                                  <div className="category-lesson-content">
                                    <h3>{lesson.name}</h3>
                                    <div className="category-lesson-description">
                                      {lesson.short_description}
                                    </div>
                                  </div>
                                </a>
                              </Cell>
                            );
                          })}
                          {subCategory.downloads.map((download, index) => {
                            let v__downloadContent, v__text;
                            const styles = {};
                            if (download.text) {
                              v__text = (
                                <div className="download-text">
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: download.text,
                                    }}
                                  />
                                </div>
                              );
                            }

                            if (download.image) {
                              styles.backgroundImage = `url('${download.image}')`;
                            } else {
                              v__downloadContent = (
                                <div className="center-vertically category-download-content">
                                  {download.name}
                                </div>
                              );
                            }

                            return (
                              <Cell sm={24} md={md_lvl2} key={index}>
                                <a
                                  href={download.url}
                                  target="_blank"
                                  rel="noopener noreferrer"
                                  className={`category-download ${classes}`}
                                  style={styles}
                                >
                                  {v__downloadContent}
                                </a>
                                {v__text}
                              </Cell>
                            );
                          })}
                        </Row>
                      </Grid>
                    </Cell>
                  );
                })}
              </Row>
            </Grid>
          </section>
        </React.Fragment>
      );
    } else if (parseInt(category.id) === 2) {
      return (
        <React.Fragment>
          {category.lessons.map((subCategory, index) => {
            if (
              (!subCategory.lessons || (subCategory.lessons && subCategory.lessons.length === 0)) &&
              (!subCategory.downloads ||
                (subCategory.downloads && subCategory.downloads.length === 0))
            ) {
              return null;
            }

            return (
              <section className="course-category-subcategory" key={index}>
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24} md={24}>
                      <Grid type="full">
                        <Row margin="xy">
                          {subCategory.lessons.map((lesson, lessonIndex) => {
                            let v__image;

                            if (lesson.image) {
                              v__image = (
                                <a
                                  href={_c
                                    .config("urls.courseLesson")
                                    .replace(":courseSlug", course.slug)
                                    .replace(":lessonSlug", lesson.slug)}
                                  className="home-categories-category"
                                >
                                  <img src={lesson.image} alt="" />
                                </a>
                              );
                            } else {
                              v__image = (
                                <React.Fragment>
                                  <div className="category-lesson-image">
                                    <HomeVideo video={lesson} />
                                  </div>
                                </React.Fragment>
                              );
                            }

                            if (lesson.type === "text") {
                              return (
                                <Cell sm={24} md={24} key={lessonIndex}>
                                  <div
                                    dangerouslySetInnerHTML={{
                                      __html: lesson.description_html,
                                    }}
                                  />
                                </Cell>
                              );
                            } else {
                              return (
                                <Cell sm={24} md={12} key={lessonIndex}>
                                  {v__image}
                                  <a
                                    href={_c
                                      .config("urls.courseLesson")
                                      .replace(":courseSlug", course.slug)
                                      .replace(":lessonSlug", lesson.slug)}
                                    className="home-categories-category"
                                  >
                                    <div className="category-lesson-content">
                                      <h3>{lesson.name}</h3>
                                      <div className="category-lesson-description">
                                        {lesson.short_description}
                                      </div>
                                    </div>
                                  </a>
                                </Cell>
                              );
                            }
                          })}
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                </Grid>
              </section>
            );
          })}
        </React.Fragment>
      );
    } else {
      return (
        <React.Fragment>
          {category.lessons.map((subCategory, index) => {
            let v__title = <h2>{category.name}</h2>;
            if (subCategory && subCategory.id && subCategory.id !== "pseudo") {
              /*if (subCategory.image) {
                    v__title = (
                      <img src={subCategory.image} alt={subCategory.name} />
                    );
                  } else {
                    v__title = <h2>{subCategory.name}</h2>;
                  }*/
              v__title = <h2>{subCategory.name}</h2>;
            }

            if (
              (!subCategory.lessons || (subCategory.lessons && subCategory.lessons.length === 0)) &&
              (!subCategory.downloads ||
                (subCategory.downloads && subCategory.downloads.length === 0))
            ) {
              return null;
            }

            return (
              <section className="course-category-subcategory" key={index}>
                <Grid type="full">
                  <Row margin="xy">
                    <Cell sm={24} md={24}>
                      {v__title}
                      <Grid type="full">
                        <Row margin="xy">
                          {subCategory.lessons.map((lesson, lessonIndex) => {
                            let v__image;

                            if (lesson.image) {
                              v__image = (
                                <a
                                  href={_c
                                    .config("urls.courseLesson")
                                    .replace(":courseSlug", course.slug)
                                    .replace(":lessonSlug", lesson.slug)}
                                  className="home-categories-category"
                                >
                                  <img src={lesson.image} alt="" />
                                </a>
                              );
                            } else {
                              v__image = (
                                <React.Fragment>
                                  <div className="category-lesson-image">
                                    <HomeVideo video={lesson} />
                                  </div>
                                </React.Fragment>
                              );
                            }

                            return (
                              <Cell sm={24} md={12} key={lessonIndex}>
                                {v__image}
                                <a
                                  href={_c
                                    .config("urls.courseLesson")
                                    .replace(":courseSlug", course.slug)
                                    .replace(":lessonSlug", lesson.slug)}
                                  className="home-categories-category"
                                >
                                  <div className="category-lesson-content">
                                    <h3>{lesson.name}</h3>
                                    <div className="category-lesson-description">
                                      {lesson.short_description}
                                    </div>
                                  </div>
                                </a>
                              </Cell>
                            );
                          })}
                          {category.id !== 1
                            ? subCategory.downloads.map((download, index) => {
                                return (
                                  <Cell sm={24} md={12} key={index}>
                                    <a
                                      href={download.url}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                      className="category-download"
                                    >
                                      <div className="center-vertically category-download-content">
                                        {download.name}
                                      </div>
                                    </a>
                                  </Cell>
                                );
                              })
                            : null}
                        </Row>
                      </Grid>
                    </Cell>
                  </Row>
                </Grid>
              </section>
            );
          })}
        </React.Fragment>
      );
    }
  }

  init() {
    const { courseSlug, categorySlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.category", {
      courseSlug,
      categorySlug,
    });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { course, category, downloads, page } = response.data.data;

            const bc1 = {
              id: "course",
              to: `${_c.config("urls.course").replace(":courseSlug", course.slug)}`,
              name: course.name,
            };

            const bc2 = {
              id: "course_category",
              to: `${_c
                .config("urls.courseCategory")
                .replace(":courseSlug", course.slug)
                .replace(":categorySlug", category.slug)}`,
              name: category.name,
            };

            const { breadcrumbs } = this.state;
            breadcrumbs.push(bc1);
            breadcrumbs.push(bc2);

            this.setState(
              {
                course,
                category,
                downloads,
                status: "LOADED",
                breadcrumbs,
                page,
              },
              () => {
                _c.setPageTitle(`${category.name} [${course.name}]`, true);
              }
            );
          } else if (status === "COURSE_NOTFOUND") {
            this.setState({ status }, () => {
              _c.setPageTitle(`${_c.translate("courses.notfound.title")}`, true);
            });
          } else if (status === "LESSON_NOTFOUND") {
            const { course } = response.data.data;
            this.setState({ course, status }, () => {
              _c.setPageTitle(
                `${_c.translate("courses.lesson.notfound.title")} [${course.name}]`,
                true
              );
            });
          } else if (status === "ERROR") {
            return _c.handleError(response.data, "course:category:init");
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:category:init:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "course:category:init");
        }
      });
  }
}

export default withContext(CourseCategory);
