import React, { useState } from "react";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import AdminDataField from "../../AdminDataField";

function ControlLink({ item, extra, onUpdate }) {
  console.log("EXTTTTT", extra);

  const onEdit = (property, value) => {
    if (onUpdate) onUpdate(property, value);
  };

  const actions = {
    onEdit,
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-url`}>
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24}>
            <select
              value={item.options.linkType}
              onChange={(e) =>
                actions.onEdit("options.linkType", e.target.value)
              }
            >
              <option value="none">Keine Verlinkung</option>
              <option value="url">Verlinkung an externe URL</option>
              <option value="page">
                Verlinkung an andere PageBuilder-Seite
              </option>
              <option value="course">
                Verlinkung an Kurs, Kategorie oder Lektion
              </option>
              <option value="download">Verlinkung an Kurs-Download</option>
              <option value="qapopup">Q&amp;A Popup anzeigen</option>
            </select>
          </Cell>
        </Row>
      </Grid>
      {item.options.linkType === "url" && (
        <LinkTypeUrl item={item} actions={actions} />
      )}
      {item.options.linkType === "page" && (
        <LinkTypePage item={item} extra={extra} actions={actions} />
      )}
      {item.options.linkType === "course" && (
        <LinkTypeCourse item={item} extra={extra} actions={actions} />
      )}
      {item.options.linkType === "download" && (
        <LinkTypeDownload item={item} extra={extra} actions={actions} />
      )}
    </div>
  );
}

const LinkTypeUrl = ({ item, actions }) => {
  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <AdminDataField
            value={item.options.url}
            editValue={item.options.url}
            editType="text"
            label={"URL (optional)"}
            placeholder={"Gib eine URL ein, auf die verlinkt soll"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => actions.onEdit("options.url", newValue)}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={24} md={24} className="text-center">
          <AdminDataField
            value={item.options.newtab}
            editValue={item.options.newtab}
            editType="singleCheckbox"
            label={"In neuem Tab/Fenster öffnen"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => actions.onEdit("options.newtab", newValue)}
          />
        </Cell>
      </Row>
    </Grid>
  );
};

const LinkTypePage = ({ item, extra, actions }) => {
  const selectValuesPages = {
    none: "----- Keine Seite ausgewählt -----",
  };

  selectValuesPages.category_coursepages = "----- Kursseiten -----";
  extra.pages.coursePages.map((page) => {
    selectValuesPages[
      `page_${page.identifier}`
    ] = `${page.name} [${page.pagetitle}]`;
    return null;
  });

  selectValuesPages.category_categorypages = "----- Kategorieseiten -----";
  extra.pages.categoryPages.map((page) => {
    selectValuesPages[
      `page_${page.identifier}`
    ] = `${page.name} [${page.pagetitle}]`;
    return null;
  });

  selectValuesPages.category_misc = "----- Weitere Seiten -----";
  extra.pages.misc.map((page) => {
    selectValuesPages[
      `page_${page.identifier}`
    ] = `${page.name} [${page.pagetitle}]`;
    return null;
  });

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <AdminDataField
            value={`page_${item.options.linkpage}`}
            editValue={`page_${item.options.linkpage}`}
            editType="select"
            selectValues={selectValuesPages}
            label={"Verlinkte Seite"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              if (newValue.substring(0, 9) === "category_") return;

              actions.onEdit("options.linkpage", newValue.replace("page_", ""));
            }}
          />
        </Cell>
      </Row>
      <Row margin="xy">
        <Cell sm={24} md={24} className="text-center">
          <AdminDataField
            value={item.options.newtab}
            editValue={item.options.newtab}
            editType="singleCheckbox"
            label={"In neuem Tab/Fenster öffnen"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => actions.onEdit("options.newtab", newValue)}
          />
        </Cell>
      </Row>
    </Grid>
  );
};

const LinkTypeCourse = ({ item, extra, actions }) => {
  const selectValuesCourses = {
    none: "----- Kein Kurs ausgewählt -----",
  };
  extra.courses.map((course) => {
    selectValuesCourses[`course_${course.id}`] = `${course.name}`;
    return null;
  });

  let selectedCourse;

  const selectValuesCategories = {
    none: "----- Direkt auf den Kurs verlinken -----",
  };
  if (item.options.linkcourse) {
    selectedCourse = extra.courses.find(
      (c) => parseInt(c.id) === parseInt(item.options.linkcourse)
    );
    if (selectedCourse) {
      selectedCourse.categories.map((category) => {
        selectValuesCategories[`category_${category.id}`] = `${category.name}`;
        return null;
      });
    }
  }

  let selectedCategory;

  const selectValuesLessons = {
    none: "----- Direkt auf die Kategorie verlinken -----",
  };
  if (item.options.linkcourse) {
    selectedCourse = extra.courses.find(
      (c) => parseInt(c.id) === parseInt(item.options.linkcourse)
    );
    if (selectedCourse) {
      selectedCategory = selectedCourse.categories.find(
        (c) => parseInt(c.id) === parseInt(item.options.linkcategory)
      );
      if (selectedCategory) {
        selectedCategory.lessons.map((lesson) => {
          selectValuesLessons[`lesson_${lesson.id}`] = `${lesson.name}`;
          return null;
        });
      }
    }
  }

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <AdminDataField
            value={`course_${item.options.linkcourse}`}
            editValue={`course_${item.options.linkcourse}`}
            editType="select"
            selectValues={selectValuesCourses}
            label={"Verlinkter Kurs"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              actions.onEdit(
                "options.linkcourse",
                newValue.replace("course_", "")
              );
            }}
          />
        </Cell>
      </Row>
      {selectedCourse && (
        <Row margin="xy">
          <Cell sm={24}>
            <AdminDataField
              value={`category_${item.options.linkcategory}`}
              editValue={`category_${item.options.linkcategory}`}
              editType="select"
              selectValues={selectValuesCategories}
              label={"Verlinkte Kategorie"}
              editable={true}
              edit={true}
              onUpdate={(newValue) => {
                actions.onEdit(
                  "options.linkcategory",
                  newValue.replace("category_", "")
                );
              }}
            />
          </Cell>
        </Row>
      )}
      {selectedCourse && selectedCategory && (
        <Row margin="xy">
          <Cell sm={24}>
            <AdminDataField
              value={`lesson_${item.options.linklesson}`}
              editValue={`lesson_${item.options.linklesson}`}
              editType="select"
              selectValues={selectValuesLessons}
              label={"Verlinkte Lektion"}
              editable={true}
              edit={true}
              onUpdate={(newValue) => {
                actions.onEdit(
                  "options.linklesson",
                  newValue.replace("lesson_", "")
                );
              }}
            />
          </Cell>
        </Row>
      )}
      <Row margin="xy">
        <Cell sm={24} md={24} className="text-center">
          <AdminDataField
            value={item.options.newtab}
            editValue={item.options.newtab}
            editType="singleCheckbox"
            label={"In neuem Tab/Fenster öffnen"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => actions.onEdit("options.newtab", newValue)}
          />
        </Cell>
      </Row>
    </Grid>
  );
};

const LinkTypeDownload = ({ item, extra, actions }) => {
  const selectValuesCourses = {
    none: "----- Kein Kurs ausgewählt -----",
  };
  extra.courses.map((course) => {
    selectValuesCourses[`course_${course.id}`] = `${course.name}`;
    return null;
  });

  let selectedCourse;

  const selectValuesDownloads = {
    none: "----- Keine Verlinkung ausgewählt -----",
  };
  if (item.options.linkcourse) {
    selectedCourse = extra.courses.find(
      (c) => parseInt(c.id) === parseInt(item.options.linkcourse)
    );
    if (selectedCourse) {
      selectedCourse.downloads.map((download) => {
        selectValuesDownloads[`download_${download.id}`] = `${
          download.name
        } [${download.type.toUpperCase()}]`;
        return null;
      });
    }
  }

  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <AdminDataField
            value={`course_${item.options.linkcourse}`}
            editValue={`course_${item.options.linkcourse}`}
            editType="select"
            selectValues={selectValuesCourses}
            label={"Verlinkter Kurs"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => {
              actions.onEdit(
                "options.linkcourse",
                newValue.replace("course_", "")
              );
            }}
          />
        </Cell>
      </Row>
      {selectedCourse && (
        <Row margin="xy">
          <Cell sm={24}>
            <AdminDataField
              value={`download_${item.options.linkdownload}`}
              editValue={`download_${item.options.linkdownload}`}
              editType="select"
              selectValues={selectValuesDownloads}
              label={"Verlinkter Download"}
              editable={true}
              edit={true}
              onUpdate={(newValue) => {
                actions.onEdit(
                  "options.linkdownload",
                  newValue.replace("download_", "")
                );
              }}
            />
          </Cell>
        </Row>
      )}

      <Row margin="xy">
        <Cell sm={24} md={24} className="text-center">
          <AdminDataField
            value={item.options.newtab}
            editValue={item.options.newtab}
            editType="singleCheckbox"
            label={"In neuem Tab/Fenster öffnen"}
            editable={true}
            edit={true}
            onUpdate={(newValue) => actions.onEdit("options.newtab", newValue)}
          />
        </Cell>
      </Row>
    </Grid>
  );
};

export default ControlLink;
