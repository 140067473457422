import React, { Component } from "react";
import { foundationClasses } from "./_helpers";

/**
 * type = full / fluid / NULL
 */
export class Grid extends Component {
  render() {
    if (this.props.type === "full") {
      return (
        <div
          className="full grid-container"
          id={this.props.id}
          style={this.props.style}
        >
          {this.props.children}
        </div>
      );
    } else if (this.props.type === "fluid") {
      return (
        <div
          className="fluid grid-container"
          id={this.props.id}
          style={this.props.style}
        >
          {this.props.children}
        </div>
      );
    } else {
      return (
        <div
          className="grid-container"
          id={this.props.id}
          style={this.props.style}
        >
          {this.props.children}
        </div>
      );
    }
  }
}

/**
 * margin = x / y / xy
 * padding = x / y / xy
 * align = left / center / right / justify / spaced / top / middle / bottom / stretch
 * classes = STRING
 */
export class Row extends Component {
  render() {
    return (
      <div
        className={this.getRowClasses()}
        style={this.props.style}
        id={this.props.id}
      >
        {this.props.children}
      </div>
    );
  }

  getRowClasses() {
    let classes = "grid-x";

    classes = foundationClasses(classes, this.props);

    // Margin
    if (this.props.margin === "x") {
      classes += " grid-margin-x";
    } else if (this.props.margin === "y") {
      classes += " grid-margin-y";
    } else if (this.props.margin === "xy") {
      classes += " grid-margin-x grid-margin-y";
    }

    // Padding
    if (this.props.padding === "x") {
      classes += " grid-padding-x";
    } else if (this.props.padding === "y") {
      classes += " grid-padding-y";
    } else if (this.props.padding === "xy") {
      classes += " grid-padding-x grid-padding-y";
    }

    // Align
    if (this.props.align) {
      classes += " align-" + this.props.align;
    }

    return classes;
  }
}

/**
 * sm = STRING
 * md = STRING
 * lg = STRING
 *
 * smo = STRING (small offset)
 * mdo = STRING (medium offset)
 * lgo = STRING (large offset)
 *
 * classes = STRING
 */
export class Cell extends Component {
  render() {
    return (
      <div
        className={this.getCellClasses()}
        style={this.props.style}
        id={this.props.id}
      >
        {this.props.children}
      </div>
    );
  }

  getCellClasses() {
    let classes = "cell";

    classes = foundationClasses(classes, this.props);

    // Cell for Small Devides
    if (this.props.sm) {
      classes += " small-" + this.props.sm;
    }

    // Cell for Medium Devices
    if (this.props.md) {
      classes += " medium-" + this.props.md;
    }

    // Cell for Large Devices
    if (this.props.lg) {
      classes += " large-" + this.props.lg;
    }

    // Offset for Small Devices
    if (this.props.smo) {
      classes += " small-offset-" + this.props.smo;
    }

    // Offset for Medium Devices
    if (this.props.mdo) {
      classes += " medium-offset-" + this.props.mdo;
    }

    // Offset for Large Devides
    if (this.props.lgo) {
      classes += " large-offset-" + this.props.lgo;
    }

    return classes;
  }
}

export class SingleCellRow extends Component {
  render() {
    return (
      <Grid type={this.props.type}>
        <Row>
          <Cell sm={this.props.sm} md={this.props.md} lg={this.props.lg}>
            {this.props.children}
          </Cell>
        </Row>
      </Grid>
    );
  }
}
