import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Editor } from "react-draft-wysiwyg";
import {
  ContentState,
  EditorState,
  convertFromRaw,
  convertToRaw,
  convertFromHTML,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import "../../../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import axios from "axios";
import { Icon } from "../../../_foundation/_buttons";
import Dropzone from "react-dropzone";
import { Cell, Grid, Row } from "../../../_foundation/_grid";

import ReactTooltip from "react-tooltip";

class ReplyForm extends Component {
  state = {
    _updatableProps: [
      "type",
      "view",
      "thread",
      "post",
      "replyForm",
      "editorState",
    ],
    type: this.props.type,
    thread: this.props.thread,
    replyForm: this.props.replyForm || null,
    editorState: this.props.editorState,
    post: this.props.post || null,
  };

  componentDidMount() {
    let { post, thread } = this.state;
    const _c = this.props.context;

    if (!post || post === "new") {
      post = _c.createEmptyPost(thread);
    }

    let editorState = EditorState.createEmpty();

    if (post.text) {
      editorState = EditorState.createWithContent(convertFromRaw(post.text));
    }

    this.setState({ post, editorState });
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;
    let { editorState } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });

        if (propKey === "post") {
          let editorState = EditorState.createEmpty();

          if (this.props[propKey] && this.props[propKey].text) {
            editorState = EditorState.createWithContent(
              convertFromRaw(this.props[propKey].text)
            );
            this.setState({ editorState });
          } else if (this.props[propKey] && this.props[propKey].text_html) {
            const blocksFromHtml = convertFromHTML(
              this.props[propKey].text_html
            );
            editorState = EditorState.createWithContent(
              ContentState.createFromBlockArray(
                blocksFromHtml.contentBlocks,
                blocksFromHtml.entityMap
              )
            );
            this.setState({ editorState });
          }
        } else if (propKey === "replyForm") {
          let editorHtml = draftToHtml(
            convertToRaw(editorState.getCurrentContent())
          );
          editorHtml = `${editorHtml} ${this.props[propKey]}`;
          const blocksFromHtml = convertFromHTML(editorHtml);
          editorState = EditorState.createWithContent(
            ContentState.createFromBlockArray(
              blocksFromHtml.contentBlocks,
              blocksFromHtml.entityMap
            )
          );
          this.setState({ editorState });
        }
      }
      return null;
    });
  }

  render() {
    const { editorState, type } = this.state;
    const _c = this.props.context;

    let v__buttons;
    if (type === "newThread") {
      v__buttons = (
        <button className="primary button" onClick={this.handle__saveThread}>
          <Icon icon="check" />{" "}
          {_c.translate("community.newThread.form.buttons.create.label")}
        </button>
      );
    } else if (type === "editPost") {
      v__buttons = (
        <button className="primary button" onClick={this.handle__savePost}>
          <Icon icon="check" />{" "}
          {_c.translate("community.post.editPopup.button.label")}
        </button>
      );
    } else {
      v__buttons = (
        <button className="primary button" onClick={this.handle__savePost}>
          <Icon icon="check" /> {_c.translate("community.reply.replyButton")}
        </button>
      );
    }

    return (
      <div className="community-reply-form">
        <Editor
          editorState={editorState}
          wrapperClassName="editor-wrapper"
          editorClassName="editor-editor"
          onEditorStateChange={this.onEditorStateChange}
          localization={{
            locale: "de",
          }}
          toolbar={{
            options: _c.config("community.replyFormOptions"),
            inline: _c.config("community.replyInlineOptions"),
            blockType: _c.config("community.replyBlockTypeOptions"),
          }}
        />
        {this.view__showFiles()}
        {this.view__showUpload()}
        <div className="reply-form-button">{v__buttons}</div>
      </div>
    );
  }

  view__showFiles = () => {
    const { post } = this.state;
    const _c = this.props.context;

    if (
      !post ||
      !post.files ||
      !post.filesFiles ||
      post.filesFiles.length === 0
    ) {
      return null;
    }

    return (
      <div className="community-post-upload-files">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        <ul>
          {post.filesFiles.map((file, index) => {
            let v__output;

            let fileNameShort;
            if (file && file.name) {
              fileNameShort = file.name;
              if (fileNameShort.length > 20) {
                fileNameShort = `${fileNameShort.substring(0, 17)}...`;
              }
            }

            if (file && file.status && file.status === "UPLOADING") {
              v__output = (
                <div
                  className="community-post-upload-file community-post-upload-file-uploading"
                  data-tip={file.name}
                >
                  <div className="wrapper">
                    <div className="center-vertically">
                      <Icon icon="circle-o-notch fa-spin" />
                      <div className="community-post-upload-file-name">
                        {fileNameShort}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (file && file.status && file.status === "ERROR") {
              v__output = (
                <div
                  className="community-post-upload-file community-post-upload-file-error"
                  data-tip={file.name}
                >
                  <div className="wrapper">
                    <div className="center-vertically">
                      <Icon icon="exclamation-triangle" />
                      <div className="community-post-upload-file-name">
                        {fileNameShort}
                      </div>
                    </div>
                  </div>
                </div>
              );
            } else if (file && file.url) {
              const styles = {
                backgroundImage: `url("${file.url}")`,
              };

              let dimensionsClass = "h";
              if (file.width && file.height) {
                if (file.height > file.width) {
                  dimensionsClass = "v";
                } else if (file.width === file.height) {
                  dimensionsClass = "q";
                }
              }

              v__output = (
                <div className="community-post-upload-file-wrapper">
                  <div
                    className={`community-post-upload-file community-post-upload-file-success community-post-upload-dimensions-${dimensionsClass}`}
                    style={styles}
                  >
                    <div className="center-vertically">
                      <span style={{ visibility: "hidden" }}>
                        <Icon icon="check-circle" />
                      </span>
                      <div className="community-post-upload-file-name">
                        <span style={{ visibility: "hidden" }}>
                          {fileNameShort}
                        </span>
                      </div>
                    </div>
                  </div>
                  <button
                    className="community-post-upload-file-delete-button"
                    onClick={() => {
                      if (
                        window.confirm(
                          _c.translate("community.upload.delete.confirm")
                        )
                      ) {
                        let updatedFiles = [];
                        let updatedFilesFiles = [];

                        post.files.map((fileId) => {
                          if (parseInt(fileId) !== parseInt(file.id)) {
                            updatedFiles.push(fileId);
                          }
                          return null;
                        });

                        post.filesFiles.map((communityFile) => {
                          if (
                            parseInt(communityFile.id) !== parseInt(file.id)
                          ) {
                            updatedFilesFiles.push(communityFile);
                          }
                          return null;
                        });

                        post.files = updatedFiles;
                        post.filesFiles = updatedFilesFiles;
                        this.setState({ post });
                      }
                    }}
                  >
                    <Icon icon="trash" left /> {_c.translate("buttons.remove")}
                  </button>
                </div>
              );
            }

            return <li key={index}>{v__output}</li>;
          })}
        </ul>
      </div>
    );
  };

  view__showUpload = () => {
    const _c = this.props.context;

    const communityAlloWUploads = _c.setting("community_allow_uploads");
    if (!communityAlloWUploads) {
      return null;
    }

    return (
      <Dropzone
        accept={"image/*"}
        maxFiles={10}
        onDrop={(acceptedFiles) => {
          acceptedFiles.forEach((file) => {
            this.uploadFile(file, file.name);
          });
        }}
      >
        {({ getRootProps, getInputProps }) => (
          <section>
            <div {...getRootProps()}>
              <input {...getInputProps()} />
              <div id="communityUploadZone">
                <div id="communityUploadZoneText">
                  <Grid type="full">
                    <Row margin="x">
                      <Cell sm={24} md={24}>
                        <div className="wrapper">
                          <div className="center-vertically">
                            <span
                              dangerouslySetInnerHTML={{
                                __html: _c.translate(
                                  "community.upload.dropzone.text"
                                ),
                              }}
                            />
                          </div>
                        </div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
              </div>
            </div>
          </section>
        )}
      </Dropzone>
    );
  };

  uploadFile = (file, fileName) => {
    const { post } = this.state;
    const formData = new FormData();
    const _c = this.props.context;

    let filesIndex = post.filesFiles.length;
    post.files[filesIndex] = "UPLOADING";
    post.filesFiles[filesIndex] = { status: "UPLOADING", name: fileName };
    this.setState({ post });

    formData.append("file", file);
    formData.append("name", fileName);

    const apiUrl = _c.apiUrl("community.upload");

    axios
      .post(apiUrl, formData, {
        headers: _c.headers,
      })
      .then((response) => {
        const { status } = response.data;

        if (status === "SUCCESS") {
          const { file } = response.data.data;
          if (file && file.id) {
            post.filesFiles[filesIndex] = file;
            post.files[filesIndex] = file.id;

            this.setState({ post, changesWereMade: true });
          }
        } else if (status === "ERROR") {
          post.files[filesIndex] = "ERROR";
          post.filesFiles[filesIndex] = { status: "ERROR" };
        }
      })
      .catch((error) => {
        console.log("ERROR", error);
      });
  };

  onEditorStateChange = (editorState) => {
    this.setState({ editorState }, () => {
      const post = this.updatePost();
      if (this.props.onChange) {
        this.props.onChange(post);
      }
    });
  };

  updatePost = () => {
    const { post, editorState } = this.state;

    if (editorState) {
      post.text = convertToRaw(editorState.getCurrentContent());
      post.text_html = draftToHtml(post.text);
    }

    return post;
  };

  handle__saveThread = () => {
    if (this.props.onSaveThread) {
      this.props.onSaveThread();
    }
  };

  handle__savePost = () => {
    let { post } = this.state;
    const _c = this.props.context;

    post = this.updatePost();

    let apiUrl = _c.apiUrl("community.post", {
      postId: post.id,
    });

    axios
      .post(
        apiUrl,
        {
          post: post,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;

          if (status === "SUCCESS") {
            const { thread, post } = response.data.data;

            if (thread) {
              this.onSavePost(thread, post);
            } else {
              return _c.handleError(
                { status: "AXIOS_RESPONSE_ERROR" },
                "community:post:save:empty"
              );
            }
          }
        } catch (error) {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "community:post:save:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "community:post:save");
      });
  };

  onSavePost = (thread, post) => {
    const _c = this.props.context;
    this.setState({
      thread,
      editorState: EditorState.createEmpty(),
    });

    window.setTimeout(() => {
      //console.log("post:", post);
      if (document.getElementById(`post_${post.slug}`)) {
        const postElement = document.getElementById(`post_${post.slug}`);
        const rect = postElement.getBoundingClientRect();
        //console.log("rect", rect);
        if (rect.top) {
          //console.log(parseInt(rect.top + window.scrollY));
          window.scrollTo(0, parseInt(rect.top + window.scrollY));
        }
      }
    }, 100);

    this.props.onSavePost(thread);
    post = _c.createEmptyPost(thread);
    this.setState({ post });
  };
}

export default withContext(ReplyForm);
