import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Icon } from "../../../_foundation/_buttons";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import PersonalMessage from "../PersonalMessage";
import HomeVideo from "../../../home/HomeVideo";
import Audio from "../parts/Audio";
import HomeCountdown from "../../../home/HomeCountdown";

class DefaultCourse extends Component {
  state = {
    course: this.props.course,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    nextLivecall: this.props.nextLivecall,
    homeItemPersonalMessage: this.props.homeItemPersonalMessage,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (prevProps.nextLivecall !== this.props.nextLivecall) {
      this.setState({ nextLivecall: this.props.nextLivecall });
    }

    if (
      prevProps.homeItemPersonalMessage !== this.props.homeItemPersonalMessage
    ) {
      this.setState({
        homeItemPersonalMessage: this.props.homeItemPersonalMessage,
      });
    }
  }

  render() {
    const { lessons } = this.state;
    if (!lessons) {
      return null;
    }

    return (
      <div className={`course-page-truepowerkurs5`}>
        {this.view__showCategories()}
      </div>
    );
  }

  view__showCategories() {
    const { course, lessons } = this.state;
    const _c = this.props.context;

    return (
      <div>
        {lessons.map((category) => {
          let categoryGrid = true;

          category.lessons.map((lesson) => {
            if (
              lesson.type !== "vimeo" ||
              lesson.type !== "youtube" ||
              lesson.type !== "embed"
            ) {
              categoryGrid = true;
            }
          });

          let v__banner;

          if (category.banner) {
            v__banner = <img src={category.banner} alt="" style={{}} />;
          } else if (category.image) {
            v__banner = <img src={category.image} alt="" />;
          }

          if (v__banner) {
            if (category.short_description) {
              v__banner = (
                <a
                  href={category.short_description}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {v__banner}
                </a>
              );
            }

            v__banner = <div>{v__banner}</div>;
          }

          /*let v__description;
          if (category.description_html) {
            v__description = (
              <div
                dangerouslySetInnerHTML={{ __html: category.description_html }}
              />
            );
          }*/

          let livingCourseId = 9; // Art of Living your Truth
          let breathworkCourseId = 6; // True Power meets Breathwork

          return (
            <div className="section" key={category.id}>
              {v__banner}

              <Grid type="full">
                <Row margin="xy">
                  {category.lessons.map((lesson) => {
                    let mdCell = 24;

                    if (categoryGrid) {
                      mdCell = 8;
                    }

                    if (
                      course.id === livingCourseId ||
                      course.id === breathworkCourseId
                    ) {
                      mdCell = 24;
                    }

                    let v__image;
                    if (lesson.image) {
                      v__image = (
                        <div>
                          <img
                            src={lesson.image}
                            alt=""
                            style={{
                              width: "100%",
                              height: "auto",
                              margin: "auto",
                              display: "block",
                            }}
                          />
                        </div>
                      );
                    }

                    let v__media;
                    if (lesson.type === "audio") {
                      v__media = (
                        <div>
                          <div className="course-lesson-audio">
                            <Audio
                              lesson={lesson}
                              onListen={(currentTime) => {
                                this.setState({ audioSeconds: currentTime });
                              }}
                            />
                          </div>
                        </div>
                      );
                    } else if (
                      lesson.type === "vimeo" ||
                      lesson.type === "embed"
                    ) {
                      let url = _c
                        .config("urls.courseLesson")
                        .replace(":courseSlug", course.slug)
                        .replace(":lessonSlug", lesson.slug);
                      let v__thumbnail;

                      if (
                        lesson.access &&
                        lesson.access.hasAccess === false &&
                        lesson.access.showBefore === true
                      ) {
                        v__thumbnail = (
                          <div className="grid-item">
                            <div>
                              <img src={lesson.image} alt="" />
                            </div>
                            <div
                              className="grid-thumbnail-text"
                              style={{
                                display:
                                  course.id === livingCourseId
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <h3>
                                <Icon icon="lock" /> {lesson.name}
                              </h3>
                              {/*<div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />*/}
                            </div>
                          </div>
                        );
                      } else {
                        v__thumbnail = (
                          <a href={url}>
                            <div>
                              <img src={lesson.image} alt="" />
                            </div>
                            <div
                              className="grid-thumbnail-text"
                              style={{
                                display:
                                  course.id === livingCourseId
                                    ? "none"
                                    : "block",
                              }}
                            >
                              <h3>{lesson.name}</h3>
                              {/*<div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />*/}
                            </div>
                          </a>
                        );
                      }
                      v__image = null;

                      v__media = <div>{v__thumbnail}</div>;
                    } else if (lesson.type === "text") {
                      if (lesson.description_html.includes("%countdown%")) {
                        v__media = this.countdown();
                      } else {
                        let description = lesson.description_html;

                        description = description.replace(
                          "%button%",
                          '<a class="large primary button" href="https://www.facebook.com/groups/truepowermai2021" target="_blank" rel="noopener noreferrer"><i class="fa fa-facebook-square"></i> &nbsp;TRUE POWER Facebook Gruppe</a>'
                        );

                        description = description.replace("%button%", "");

                        v__media = (
                          <div
                            dangerouslySetInnerHTML={{
                              __html: description,
                            }}
                          />
                        );
                      }
                    } else {
                      v__media = <HomeVideo video={lesson} />;
                    }

                    return (
                      <Cell sm={24} md={mdCell} key={lesson.id}>
                        <div
                          className={
                            categoryGrid === true ? "truepowerkurs5-grid" : null
                          }
                        >
                          {v__image}
                          {v__media}
                        </div>
                      </Cell>
                    );
                  })}
                </Row>
              </Grid>
            </div>
          );
        })}
      </div>
    );
  }

  render__old() {
    const { lessons } = this.state;
    if (!lessons) {
      return null;
    }

    let tpk5category;
    lessons.map((category) => {
      if (category.internal_key === "21days") {
        tpk5category = category;
      }
    });

    return (
      <div className={`course-page-truepowerkurs5`}>
        {this.view__showHeader(tpk5category)}
        {this.view__showPersonalMessage(tpk5category)}
        {this.view__showGrid(tpk5category)}
      </div>
    );
  }

  countdown = () => {
    const { nextLivecall } = this.state;
    return (
      <div className="text-center">
        <HomeCountdown livecall={nextLivecall} />
      </div>
    );
  };

  view__showHeader(tpk5category) {
    let v__categoryHeader;

    if (tpk5category.banner) {
      v__categoryHeader = (
        <React.Fragment>
          <div className="category-banner">
            <img src={tpk5category.banner} alt={tpk5category.name} />
          </div>
          <div className="category-description">
            <div
              dangerouslySetInnerHTML={{
                __html: tpk5category.description_html,
              }}
            />
          </div>
        </React.Fragment>
      );
    }

    return v__categoryHeader;
  }

  view__showPersonalMessage() {
    const { course, lessons, homeItemPersonalMessage } = this.state;

    let personalmessageLesson;
    lessons.map((category) => {
      category.lessons.map((lesson) => {
        if (lesson.internal_key === "personalmessage") {
          personalmessageLesson = lesson;
        }
      });
    });

    if (personalmessageLesson) {
      return (
        <Grid>
          <Row>
            <Cell sm={24} md={20} mdo={2}>
              <PersonalMessage
                course={course}
                lesson={personalmessageLesson}
                homeItemPersonalMessage={homeItemPersonalMessage}
              />
            </Cell>
          </Row>
        </Grid>
      );
    }
  }

  view__showGrid(tpk5category) {
    const { course } = this.state;
    const _c = this.props.context;

    //console.log("tp5cat", tpk5category);

    return (
      <div className="truepowerkurs5-grid">
        <Grid>
          <Row>
            {tpk5category.lessons.map((lesson) => {
              let url = _c
                .config("urls.courseLesson")
                .replace(":courseSlug", course.slug)
                .replace(":lessonSlug", lesson.slug);
              let v__thumbnail;

              if (
                lesson.access &&
                lesson.access.hasAccess === false &&
                lesson.access.showBefore === true
              ) {
                v__thumbnail = (
                  <div className="grid-item">
                    <div>
                      <img src={lesson.image} alt="" />
                    </div>
                    <div className="grid-thumbnail-text">
                      <h3>
                        <Icon icon="lock" /> {lesson.name}
                      </h3>
                      {/*<div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />*/}
                    </div>
                  </div>
                );
              } else {
                v__thumbnail = (
                  <a href={url}>
                    <div>
                      <img src={lesson.image} alt="" />
                    </div>
                    <div className="grid-thumbnail-text">
                      <h3>{lesson.name}</h3>
                      {/*<div
                        dangerouslySetInnerHTML={{
                          __html: lesson.description_html,
                        }}
                      />*/}
                    </div>
                  </a>
                );
              }

              return (
                <Cell sm={24} md={8} key={lesson.id}>
                  {v__thumbnail}
                </Cell>
              );
            })}
          </Row>
        </Grid>
      </div>
    );
  }
}

export default withContext(DefaultCourse);
