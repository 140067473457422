import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import Box from "../../layout/Box";

class DashboardStatus extends Component {
  state = {
    _updatableProps: ["status"],
    status: this.props.status,
    overallStatus: {
      status: "OK",
      errors: [],
      warnings: [],
      hints: [],
    },
  };

  componentDidMount() {
    this.init();
  }

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] }, () => {
          this.init();
        });
      }
      return null;
    });
  }

  render() {
    const { overallStatus } = this.state;
    const _c = this.props.context;

    let v__output, v__errors, v__warnings, v__hints;
    let classes = "admin-status-content";

    if (overallStatus.status === "OK") {
      v__output = (
        <div
          dangerouslySetInnerHTML={{
            __html: _c.translate("admin.status.ok.text"),
          }}
        />
      );
      classes = `no-error ${classes}`;
    } else {
      let errorCount = overallStatus.errors.length;
      let warningCount = overallStatus.warnings.length;
      let hintCount = overallStatus.hints.length;

      let total = errorCount + warningCount + hintCount;

      if (errorCount > 0) {
        v__errors = (
          <div className="admin-status-errors">
            {overallStatus.errors.map((error, index) => {
              let v__text = _c.translate(`admin.status.errors.${error}.text`);
              return (
                <div className="small alert callout" key={index}>
                  <div className="admin-status-pretitle">
                    {_c.translate(`admin.status.errors.${error}.title`)}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: v__text }} />
                </div>
              );
            })}
          </div>
        );
      }

      if (warningCount > 0) {
        v__warnings = (
          <div className="admin-status-warnings">
            {overallStatus.warnings.map((warning, index) => {
              let v__text = _c.translate(
                `admin.status.warnings.${warning}.text`
              );
              return (
                <div className="small warning callout" key={index}>
                  <div className="admin-status-pretitle">
                    {_c.translate(`admin.status.warnings.${warning}.title`)}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: v__text }} />
                </div>
              );
            })}
          </div>
        );
      }

      if (hintCount > 0) {
        v__hints = (
          <div className="admin-status-hints">
            {overallStatus.hints.map((hint, index) => {
              let v__text = _c.translate(`admin.status.hints.${hint}.text`);
              return (
                <div className="small secondary callout" key={index}>
                  <div className="admin-status-pretitle">
                    {_c.translate(`admin.status.hints.${hint}.title`)}
                  </div>
                  <div dangerouslySetInnerHTML={{ __html: v__text }} />
                </div>
              );
            })}
          </div>
        );
      }

      v__output = (
        <React.Fragment>
          <div
            dangerouslySetInnerHTML={{
              __html: _c
                .translate("admin.status.error.text")
                .replace(
                  "{wurden}",
                  total === 1
                    ? _c.translate("admin.status.error.wurde")
                    : _c.translate("admin.status.error.wurden")
                )
                .replace(
                  "{problems}",
                  total === 1
                    ? _c.translate("admin.status.error.problem")
                    : _c.translate("admin.status.error.problems")
                )
                .replace(
                  "{errors}",
                  errorCount === 1
                    ? _c.translate("admin.status.error.error")
                    : _c.translate("admin.status.error.errors")
                )
                .replace(
                  "{warnings}",
                  warningCount === 1
                    ? _c.translate("admin.status.error.warning")
                    : _c.translate("admin.status.error.warnings")
                )
                .replace(
                  "{hints}",
                  hintCount === 1
                    ? _c.translate("admin.status.error.hint")
                    : _c.translate("admin.status.error.hints")
                )
                .replace("{total}", total)
                .replace("{errorCount}", errorCount)
                .replace("{warningCount}", warningCount)
                .replace("{hintCount}", hintCount),
            }}
          />
          {v__errors}
          {v__warnings}
          {v__hints}
        </React.Fragment>
      );
    }

    return (
      <div className={classes}>
        <Box>
          <h3>{_c.translate("admin.status.title")}</h3>
          {v__output}
        </Box>
      </div>
    );
  }

  init() {
    const { status, overallStatus } = this.state;

    const checkErrors = ["legalinfo", "dataprivacy"];

    const checkWarnings = ["supportemail"];

    const checkHints = ["emailtemplates"];

    checkErrors.map((checkKey) => {
      if (status[checkKey] !== "OK") {
        overallStatus.status = "ERROR";
        overallStatus.errors.push(checkKey);
      }

      return null;
    });

    checkWarnings.map((checkKey) => {
      if (status[checkKey] !== "OK") {
        overallStatus.status = "ERROR";
        overallStatus.warnings.push(checkKey);
      }

      return null;
    });

    checkHints.map((checkKey) => {
      if (status[checkKey] !== "OK") {
        overallStatus.status = "ERROR";
        overallStatus.hints.push(checkKey);
      }

      return null;
    });

    this.setState({ overallStatus });
  }
}

export default withContext(DashboardStatus);
