import membermateConfig from "../config/membermate.config.json";

export default {
  get: (path) => {
    let result = membermateConfig;

    try {
      let pathSteps = path.split(".");

      pathSteps.map((step) => {
        result = result[step];
        return null;
      });
    } catch (exception) {
      result = path;
    }

    //console.log("config", result);

    return result;
  },
};
