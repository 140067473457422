import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Cell, Grid, Row } from "../../_foundation/_grid";
import AdminLayout from "../AdminLayout";
import axios from "axios";
import Loading from "../../_reusables/Loading";

class AdminToolWebhook extends Component {
  state = {
    breadcrumbs: ["admin_dashboard", "admin_tools", "admin_tools_webhook"],
    status: "UPLOAD",
    csv: null,
    courses: null,
    result: null,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;
    if (!_c.getAppSettings()) {
      return null;
    }

    return (
      <AdminLayout nav="tools" breadcrumbs={breadcrumbs}>
        <div className="admin-tools-webhook">
          <Grid type="full">
            <Row>
              <Cell sm={24} md={24}>
                <h1>{_c.translate("admin.tools.webhook.title")}</h1>
                <p
                  className="lead"
                  dangerouslySetInnerHTML={{
                    __html: _c.translate("admin.tools.webhook.text2"),
                  }}
                />
              </Cell>
            </Row>
          </Grid>
          {this.view__showContent()}
        </div>
      </AdminLayout>
    );
  }

  view__showContent() {
    const { status } = this.state;

    if (status === "LOADING") {
      return (
        <div>
          <Loading show={true} />
        </div>
      );
    } else if (status === "UPLOAD") {
      return this.view__showTutorial();
    }
  }

  view__showTutorial() {
    const _c = this.props.context;
    const { webhookUrl, webhookKey, webhookSecret } = this.state;

    return (
      <div>
        <h3>{_c.translate("admin.tools.webhook.title_tutorial")}</h3>
        <div
          dangerouslySetInnerHTML={{
            __html: _c
              .translate("admin.tools.webhook.text_tutorial")
              .replace("{webhookUrl}", `${webhookUrl}/member`)
              .replace("{webhookKey}", webhookKey)
              .replace("{webhookSecret}", webhookSecret),
          }}
        />
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    const apiUrl = _c.apiUrl("admin.support");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            const { webhookUrl, webhookKey, webhookSecret } = apiResponseData;

            this.setState({
              webhookUrl,
              webhookKey,
              webhookSecret,
            });
          }

          _c.setPageTitle("pageTitles.admin.tools.webhook", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "admin:tools:support:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "admin:tools:support:init");
      });
  }
}

export default withContext(AdminToolWebhook);
