import React from "react";

function ColumnSchemaIcon({ schema }) {
  const columns = schema.split(":");

  return (
    <span className="pagebuilder-columnschema-icon">
      {columns.map((column, index) => {
        return (
          <span
            key={index}
            className={`columnschema-icon-column columnschema-icon-column-${column}`}
          />
        );
      })}
    </span>
  );
}

export default ColumnSchemaIcon;
