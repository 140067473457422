import React, { Component } from "react";
import withContext from "../../../../../context/contextHOC";
import { SketchPicker } from "react-color";
import KeyboardEventHandler from "react-keyboard-event-handler";

class ColorPicker extends Component {
  state = {
    _updatableProps: ["color", "size", "reset"],
    color: this.props.color,
    size: this.props.size || "small",
    showColorpicker: false,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    return (
      <React.Fragment>
        <KeyboardEventHandler
          handleKeys={["all"]}
          onKeyEvent={(key, event) => {
            //console.log(key);
            if (key === "esc") {
              if (this.state.showColorpicker === true) {
                this.setState({ showColorpicker: false });
              }
            }
          }}
          handleFocusableElements={true}
        />
        {this.view__showButton()}
        {this.view__showColorpicker()}
      </React.Fragment>
    );
  }

  view__showColorpicker() {
    const { showColorpicker, color } = this.state;
    const _c = this.props.context;

    const styles = {
      position: "absolute",
      background: "white",
      zIndex: "99999",
      display: showColorpicker === true ? "block" : "none",
      padding: "0px 10px 0px 10px",
      boxSizing: "initial",
      borderRadius: "4px",
      boxShadow:
        "rgba(0, 0, 0, 0.15) 0px 0px 0px 1px, rgba(0, 0, 0, 0.15) 0px 8px 16px",
    };

    return (
      <div style={styles} className="colorpicker-popup">
        <SketchPicker
          color={color}
          onChange={this.selectColor}
          onChangeComplete={this.saveColor}
        />
        <button
          className="colorpicker-esc"
          onClick={() => {
            this.setState({ showColorpicker: false });
          }}
        >
          {_c.translate("admin.design.esc.text")}
        </button>
      </div>
    );
  }

  view__showButton() {
    const { color } = this.state;

    let wrapperStyles = {
      border: "1px solid #cccccc",
      borderRadius: "3px",
    };

    wrapperStyles = this.size(wrapperStyles);

    const contentStyles = {
      border: "5px solid rgb(250, 250, 250)",
      width: "100%",
      height: "100%",
      background: color,
      cursor: "pointer",
    };

    return (
      <button style={wrapperStyles} onClick={this.toggleColorpicker}>
        <div style={contentStyles} />
      </button>
    );
  }

  size = (styles) => {
    const { size } = this.state;

    if (size === "small") {
      styles.width = "45px";
      styles.height = "27px";
    } else if (size === "fullwidth") {
      styles.width = "100%";
      styles.height = "35px";
    }

    return styles;
  };

  toggleColorpicker = () => {
    let { showColorpicker } = this.state;
    showColorpicker = showColorpicker === true ? false : true;
    this.setState({ showColorpicker });
  };

  selectColor = (color, event) => {
    let colorValue = this.getColor(color);

    this.setState({ color: colorValue });
  };

  saveColor = (color, event) => {
    let colorValue = this.getColor(color);

    this.setState({ color: colorValue });
    if (this.props.onChange) {
      this.props.onChange(colorValue);
    }
  };

  getColor(color) {
    let colorValue;

    if (color.rgb.a === 1) {
      colorValue = `rgb(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b})`;
    } else {
      colorValue = `rgba(${color.rgb.r}, ${color.rgb.g}, ${color.rgb.b}, ${color.rgb.a})`;
    }

    return colorValue;
  }
}

export default withContext(ColorPicker);
