import { cloneDeep } from "lodash";
import React, { useEffect, useState } from "react";
import { Cell, Grid, Row } from "../../../../_foundation/_grid";
import MMTabs from "../../../../mmcomponents/mmtabs/MMTabs";
import ElementSettings from "./ElementSettings";
import ElementDesign from "./ElementDesign";
import Loading from "../../../../_reusables/Loading";
import TextEditor from "../../controls/components/TextEditor";
import Box from "../../../../layout/Box";
import ImageUpload from "../../controls/components/ImageUpload";
import ControlVideo from "../../controls/ControlVideo";

const tabs = {
  content: {
    name: "Inhalt",
    icon: "pencil-square-o",
  },
  design: {
    name: "Design",
    icon: "paint-brush",
  },
  settings: {
    name: "Einstellungen",
    icon: "cog",
  },
};

function PopupVideo(props) {
  const [element, setElement] = useState(null);
  const [selectedTab, setSelectedTab] = useState("content");

  useEffect(() => {
    setElement(cloneDeep(props.element));
  }, [props.element]);

  useEffect(() => {}, [element]);

  const onEdit = (property, value) => {
    if (props.actions.onEdit) props.actions.onEdit(property, value);
  };
  const actions = {
    onEdit,
  };

  if (!element)
    return (
      <div className="text-center">
        <Loading show={true} />
      </div>
    );

  return (
    <div className="element-popup-video">
      <div className="admin-tabs">
        <MMTabs
          tabs={tabs}
          selected={selectedTab}
          onUpdate={(selectedKeyName) => setSelectedTab(selectedKeyName)}
        />
      </div>
      {selectedTab === "content" && (
        <TabContent element={element} actions={actions} />
      )}
      {selectedTab === "design" && (
        <ElementDesign item={element} itemType="element" actions={actions} />
      )}
      {selectedTab === "settings" && (
        <ElementSettings item={element} actions={actions} />
      )}
    </div>
  );
}

const TabContent = ({ element, actions }) => {
  return (
    <Grid type="full">
      <Row margin="xy">
        <Cell sm={24}>
          <ControlVideo item={element} onUpdate={actions.onEdit} />
        </Cell>
      </Row>
    </Grid>
  );
};

export default PopupVideo;
