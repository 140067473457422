import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";

class PasswordForm extends Component {
  state = {
    _updatableProps: ["status", "show", "input"],
    status: this.props.status,
    show: this.props.show,
    input: this.props.input,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return <React.Fragment>{this.view__showForm()}</React.Fragment>;
  }

  view__showForm() {
    let v__output;

    v__output = (
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={24}>
            <div style={{ marginBottom: "10px" }}>
              {this.view__showFormFieldPassword1()}
            </div>
          </Cell>
        </Row>
        <Row margin="xy">
          <Cell sm={24} md={24}>
            {this.view__showFormFieldPassword2()}
          </Cell>
        </Row>
        <Row margin="x">
          <Cell sm={24}>{this.view__showFormButtons()}</Cell>
        </Row>
      </Grid>
    );

    return v__output;
  }

  view__showFormFieldPassword1() {
    const { input } = this.state;
    const { password1 } = input;
    const _c = this.props.context;

    return (
      <label>
        {_c.translate("auth.signup.form.fields.password1.label")}
        <input
          type="password"
          placeholder={_c.translate(
            "auth.signup.form.fields.password1.placeholder"
          )}
          value={password1}
          onChange={(e) => {
            if (this.props.onUpdateInputChange) {
              this.props.onUpdateInputChange("password1", e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (this.props.onAttemptPassword) {
                this.props.onAttemptPassword();
              }
            }
          }}
        />
      </label>
    );
  }

  view__showFormFieldPassword2() {
    const { input } = this.state;
    const { password2 } = input;
    const _c = this.props.context;

    return (
      <label>
        {_c.translate("auth.signup.form.fields.password2.label")}
        <input
          type="password"
          placeholder={_c.translate(
            "auth.signup.form.fields.password2.placeholder"
          )}
          value={password2}
          onChange={(e) => {
            if (this.props.onUpdateInputChange) {
              this.props.onUpdateInputChange("password2", e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (this.props.onAttemptPassword) {
                this.props.onAttemptPassword();
              }
            }
          }}
        />
      </label>
    );
  }

  view__showFormButtons() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="login-buttons">
          <button
            className="primary button"
            onClick={this.props.onAttemptPassword}
          >
            {_c.translate("auth.signup.form.buttons.password.label")}{" "}
            <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withContext(PasswordForm);
