import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../../_foundation/foundation";

class SignupForm extends Component {
  state = {
    _updatableProps: ["status", "show", "input", "highlighted"],
    status: this.props.status,
    show: this.props.show,
    input: this.props.input,
    highlighted: this.props.highlighted,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { show } = this.state;

    if (!show) {
      return null;
    }

    return <React.Fragment>{this.view__showForm()}</React.Fragment>;
  }

  view__showForm() {
    const _c = this.props.context;

    let v__output;

    let v__firstname, v__lastname;

    if (_c.setting("signup_firstname")) {
      v__firstname = (
        <Row margin="x">
          <Cell sm={24} md={24}>
            <div style={{ marginBottom: "10px" }}>
              {this.view__showFormFieldFirstname()}
            </div>
          </Cell>
        </Row>
      );
    }

    if (_c.setting("signup_lastname")) {
      v__lastname = (
        <Row margin="x">
          <Cell sm={24} md={24}>
            <div style={{ marginBottom: "10px" }}>
              {this.view__showFormFieldLastname()}
            </div>
          </Cell>
        </Row>
      );
    }

    v__output = (
      <Grid type="full">
        <Row margin="x">
          <Cell sm={24} md={24}>
            {v__firstname}
            {v__lastname}
            <div style={{ marginBottom: "10px" }}>
              {this.view__showFormFieldEmail()}
            </div>
          </Cell>
        </Row>

        <Row margin="x">
          <Cell sm={24}>{this.view__showFormButtons()}</Cell>
        </Row>
      </Grid>
    );

    return v__output;
  }

  view__showFormFieldEmail() {
    const { input, highlighted } = this.state;
    const { email } = input;
    const _c = this.props.context;

    let classes;

    if (highlighted && highlighted.email === true) {
      classes = `${classes} highlighted`;
    }

    return (
      <label>
        {_c.translate("auth.signup.form.fields.email.label")}
        <input
          className={classes}
          type="email"
          placeholder={_c.translate(
            "auth.signup.form.fields.email.placeholder"
          )}
          value={email}
          onChange={(e) => {
            if (this.props.onUpdateInputChange) {
              this.props.onUpdateInputChange("email", e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (this.props.onAttemptSignup) {
                this.props.onAttemptSignup();
              }
            }
          }}
        />
      </label>
    );
  }

  view__showFormFieldFirstname() {
    const { input, highlighted } = this.state;
    const { firstname } = input;
    const _c = this.props.context;

    let classes;

    if (highlighted && highlighted.firstname === true) {
      classes = `${classes} highlighted`;
    }

    return (
      <label>
        {_c.translate("auth.signup.form.fields.firstname.label")}
        <input
          className={classes}
          type="text"
          placeholder={_c.translate(
            "auth.signup.form.fields.firstname.placeholder"
          )}
          value={firstname}
          onChange={(e) => {
            if (this.props.onUpdateInputChange) {
              this.props.onUpdateInputChange("firstname", e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (this.props.onAttemptSignup) {
                this.props.onAttemptSignup();
              }
            }
          }}
        />
      </label>
    );
  }

  view__showFormFieldLastname() {
    const { input, highlighted } = this.state;
    const { lastname } = input;
    const _c = this.props.context;

    let classes;

    if (highlighted && highlighted.lastname === true) {
      classes = `${classes} highlighted`;
    }

    return (
      <label>
        {_c.translate("auth.signup.form.fields.lastname.label")}
        <input
          className={classes}
          type="text"
          placeholder={_c.translate(
            "auth.signup.form.fields.lastname.placeholder"
          )}
          value={lastname}
          onChange={(e) => {
            if (this.props.onUpdateInputChange) {
              this.props.onUpdateInputChange("lastname", e.target.value);
            }
          }}
          onKeyUp={(e) => {
            if (e.keyCode === 13) {
              if (this.props.onAttemptSignup) {
                this.props.onAttemptSignup();
              }
            }
          }}
        />
      </label>
    );
  }

  view__showFormButtons() {
    const _c = this.props.context;

    return (
      <React.Fragment>
        <div className="login-buttons">
          <button
            className="primary button"
            onClick={this.props.onAttemptSignup}
          >
            {_c.translate("auth.signup.form.buttons.signup.label")}{" "}
            <Icon icon="angle-right" right />
          </button>
        </div>
      </React.Fragment>
    );
  }
}

export default withContext(SignupForm);
