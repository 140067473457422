import React, { Component } from "react";
//import { Grid, Row, Cell, Button, Icon } from "../../_foundation/foundation";
import axios from "axios";
import withContext from "../../../context/contextHOC";
import qs from "query-string";
import { isUndefined } from "util";
import PasswordResetView from "./views/PasswordResetView";

class PasswordReset extends Component {
  state = {
    status: null,
    token: null,
    input: {
      password1: "",
      password2: "",
    },
    errors: {
      empty: false,
      notequal: false,
      token_invalid: false,
    },
    messages: {
      saved: false,
    },

    tokenIsChecked: false,
    tokenIsValid: false,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.init(true, "pageTitles.forgotten");

    const queryParams = qs.parse(this.props.location.search);
    if (!isUndefined(queryParams.token)) {
      this.setState({ token: queryParams.token }, () => {
        this.checkToken();
      });
    }
  }

  render() {
    const { status, input, token, errors, messages } = this.state;
    const _c = this.props.context;

    return (
      <React.Fragment>
        {_c.loadStylesheets(false)}
        <PasswordResetView
          status={status}
          input={input}
          errors={errors}
          messages={messages}
          token={token}
          onUpdateInputChange={this.onUpdateInputChange}
          onSave={this.save}
        />
      </React.Fragment>
    );
  }

  setError = (errorKey, value) => {
    const { errors } = this.state;
    errors[errorKey] = value;
    this.setState({ errors });
  };

  setMessage = (messageKey, value) => {
    const { messages } = this.state;
    messages[messageKey] = value;
    this.setState({ messages });
  };

  resetErrors = () => {
    const { errors, messages } = this.state;
    Object.keys(errors).map((errorKey) => {
      errors[errorKey] = false;
      return null;
    });

    Object.keys(messages).map((messageKey) => {
      messages[messageKey] = false;
      return null;
    });
  };

  onUpdateInputChange = (property, newValue) => {
    const { input } = this.state;

    input[property] = newValue;
    this.setState({ input });
  };

  handleChange = (name) => (event) => {
    this.setState({ [name]: event.target.value });
  };

  save = () => {
    const { input, token } = this.state;
    const { password1, password2 } = input;
    const _c = this.props.context;

    this.resetErrors();
    this.setState({ status: "LOADING" });

    if (!password1 || !password2) {
      this.setError("empty", true);
      this.setState({ status: null });
      return null;
    } else if (password1 !== password2) {
      this.setError("notequal", true);
      this.setState({ status: null });
      return null;
    }

    let apiUrl = _c.apiUrl("password.reset");
    apiUrl = apiUrl.replace("{token}", token);

    axios
      .patch(apiUrl, {
        token: token,
        newPassword: password1,
      })
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;

        if (status === "NOTFOUND") {
          this.setError("notfound", true);
          this.setState({ status: null });
        } else if (status === "INVALID") {
          this.setError("invalid", true);
          this.setState({ status: null });
        } else if (status === "DENIED") {
          this.setError("denied", true);
          this.setState({ status: null });
        } else if (status === "SUCCESS") {
          this.setMessage("saved", true);
          this.setState({ status: "SUCCESS" });
        }
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };

  checkToken = () => {
    const { token } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("password.checkToken");
    apiUrl = apiUrl.replace("{token}", token);

    axios
      .get(apiUrl)
      .then((response) => {
        if (_c.isDebug()) {
          console.log("API RESPONSE", response.data);
        }

        const { status } = response.data;

        if (status === "TOKEN_INVALID") {
          this.setError("token_invalid", true);
          this.setState({ status: "TOKEN_INVALID" });
        } else if (status === "DENIED") {
          this.setError("denied", true);
        } else if (status === "SUCCESS") {
        }

        _c.initFinished();
      })
      .catch((error) => {
        console.log("Error", error);
      });
  };
}

export default withContext(PasswordReset);
