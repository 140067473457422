const options = {
  fontSize: {
    selector: "fontSize",
  },
  fontWeight: {
    selector: "fontWeight",
  },
  color: {
    selector: "color",
  },
  backgroundColor: {
    selector: "backgroundColor",
  },
  margin: {
    selector: "margin",
  },
  padding: {
    selector: "padding",
  },
  border: {
    selector: "border",
  },
  alignh: {
    selector: "justifyContent",
  },
  boxshadow: {
    selector: "boxShadow",
  },
};

const PageViewHelper = {
  applyOptions: function (item, onlyThese, notThese, mouseHoverStatus) {
    const styles = {};

    Object.keys(options).map((optionKey) => {
      if (onlyThese && !onlyThese.includes(optionKey)) return;
      if (notThese && notThese.includes(optionKey)) return;

      if (item.options[optionKey]) {
        if (["margin", "padding"].includes(optionKey)) {
          styles[
            options[optionKey].selector
          ] = `${item.options[optionKey].t} ${item.options[optionKey].r} ${item.options[optionKey].b} ${item.options[optionKey].l}`;
        } else if (optionKey === "border") {
          const valueObject = item.options[optionKey];
          try {
            if (valueObject.t && valueObject.t.width)
              styles.borderTop = `${valueObject.t.width} ${valueObject.t.style} ${valueObject.t.color}`;
            if (valueObject.r && valueObject.r.width)
              styles.borderRight = `${valueObject.r.width} ${valueObject.r.style} ${valueObject.r.color}`;
            if (valueObject.b && valueObject.b.width)
              styles.borderBottom = `${valueObject.b.width} ${valueObject.b.style} ${valueObject.b.color}`;
            if (valueObject.l && valueObject.l.width)
              styles.borderLeft = `${valueObject.l.width} ${valueObject.l.style} ${valueObject.l.color}`;
          } catch (e) {
            console.log("border error", e);
          }
        } else if (optionKey === "alignh") {
          styles[options[optionKey].selector] = item.options[optionKey];
          styles.display = "flex";
        } else if (optionKey === "boxshadow") {
          console.log("hover", mouseHoverStatus);
          if (
            item.options[optionKey].show === "always" ||
            (item.options[optionKey].show === "hover" && mouseHoverStatus === true) ||
            (!item.options[optionKey].show && mouseHoverStatus === true)
          ) {
            styles[
              options[optionKey].selector
            ] = `${item.options[optionKey].sr} ${item.options[optionKey].sd} ${item.options[optionKey].b} ${item.options[optionKey].s} ${item.options[optionKey].c}`;
          }
        } else {
          styles[options[optionKey].selector] = item.options[optionKey];
        }
      }
      return null;
    });

    return styles;
  },
  getResponsiveClasses: function (responsive) {
    let classes = ``;

    const { sm, md, lg } = responsive;

    if (!sm) classes = `${classes} hide-for-small-only`;
    if (!md) classes = `${classes} hide-for-medium-only`;
    if (!lg) classes = `${classes} hide-for-large`;

    console.log("classes", classes);

    return classes;
  },
};

export default PageViewHelper;
