import React from "react";

const values = {
  left: {
    icon: "align_horizontal_left",
    value: "flex-start",
  },
  center: {
    icon: "align_horizontal_center",
    value: "center",
  },
  right: {
    icon: "align_horizontal_right",
    value: "flex-end",
  },
};

function AlignVPicker({ value, onEdit }) {
  return (
    <div className="align-picker">
      {Object.keys(values).map((valueKey) => {
        return (
          <button
            key={valueKey}
            className={`${
              value === values[valueKey].value && `selected`
            } align-picker-button`}
            onClick={() => onEdit(values[valueKey].value)}
          >
            <span className="mi">{values[valueKey].icon}</span>
          </button>
        );
      })}
    </div>
  );
}

export default AlignVPicker;
