import React, { Component } from "react";
import withContext from "../../../../context/contextHOC";
import Box from "../../../layout/Box";
import { Line } from "rc-progress";
import ReactTooltip from "react-tooltip";

class Progress extends Component {
  state = {
    course: this.props.course,
    where: this.props.where,
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }
  }

  render() {
    return null;
    const { course, where } = this.state;
    const _c = this.props.context;

    if (!course) {
      return null;
    }

    let progress = 0;
    if (course.stats && course.stats.progress) {
      progress = course.stats.progress;
    }

    if (progress > 100) {
      progress = 100;
    }

    let headingColor = _c.design("headingColor");

    let v__output;

    if (where === "overview") {
      v__output = (
        <div className="text-center">
          <span
            data-tip={_c
              .translate("courses.lesson.progressBar.tooltip")
              .replace("{percent}", progress)}
          >
            <Line
              percent={progress}
              strokeWidth="3"
              strokeColor={headingColor}
            />
          </span>
        </div>
      );
    } else {
      v__output = (
        <div className="text-center">
          <Box size="small">
            <span
              data-tip={_c
                .translate("courses.lesson.progressBar.tooltip")
                .replace("{percent}", progress)}
            >
              <Line
                percent={progress}
                strokeWidth="3"
                strokeColor={headingColor}
              />
            </span>
            <div style={{ textTransform: "uppercase" }}>
              <strong>{_c.translate("courses.lesson.progress")}</strong>{" "}
              <strong className="course-progress-percent">{progress}%</strong>
            </div>
          </Box>
        </div>
      );
    }

    return (
      <div className="course-progress">
        <ReactTooltip
          place={_c.getToolTipSetting("position")}
          type={_c.getToolTipSetting("type")}
          effect={_c.getToolTipSetting("effect")}
        />
        {v__output}
      </div>
    );
  }
}

export default withContext(Progress);
