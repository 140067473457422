import React, { Component } from "react";
import { Link } from "react-router-dom";
import { Grid, Row, Cell } from "../_foundation/_grid";
import { Icon } from "../_foundation/_buttons";
import { isUndefined } from "util";
import withContext from "../../context/contextHOC";

class Breadcrumbs extends Component {
  state = {
    breadcrumbs: this.props.breadcrumbs,
    buttons: this.props.buttons,
    menu: this.props.menu,
  };

  componentDidUpdate(prevProps) {
    if (this.props.breadcrumbs !== prevProps.breadcrumbs) {
      this.setState({ breadcrumbs: this.props.breadcrumbs });
    }

    if (this.props.buttons !== prevProps.buttons) {
      this.setState({ buttons: this.props.buttons });
    }

    if (this.props.menu !== prevProps.menu) {
      this.setState({ menu: this.props.menu });
    }
  }

  render() {
    const { breadcrumbs } = this.state;

    if (!breadcrumbs) {
      return null;
    }

    return (
      <React.Fragment>
        <div id="breadcrumbs">{this.showBreadcrumbs()}</div>
      </React.Fragment>
    );
  }

  showBreadcrumbs() {
    const breadcrumbs = this.getBreadcrums();
    const _c = this.props.context;

    let gridType, linePadding;
    if (_c.config("general.showHelp") === true) {
      gridType = "full";
      linePadding = { padding: "0 40px" };
    }

    const v__buttons = this.view__showButtons();
    const v__menu = this.view__showMenu();
    let v__extra;

    if (v__buttons || v__menu) {
      v__extra = (
        <div className="float-right">
          {v__buttons}
          {v__menu}
        </div>
      );
    }

    return (
      <div style={linePadding}>
        <Grid type={gridType}>
          <Row>
            <Cell sm={24}>
              {/*<Icon icon="angle-double-right" breadcrumb />*/}
              {breadcrumbs.map((breadcrumb, index) => {
                let link = "";
                if (isUndefined(breadcrumb.to)) {
                  link = <span>{breadcrumb.name}</span>;
                } else {
                  link = <Link to={breadcrumb.to}>{breadcrumb.name}</Link>;
                }

                if (index !== 0) {
                  return (
                    <React.Fragment key={breadcrumb.id}>
                      <Icon icon="angle-right" breadcrumb />
                      {link}
                    </React.Fragment>
                  );
                } else {
                  return (
                    <React.Fragment key={breadcrumb.id}>{link}</React.Fragment>
                  );
                }
              })}
              {v__extra}
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showButtons() {
    const { buttons } = this.state;
    let output;

    if (buttons) {
      output = (
        <React.Fragment>
          {buttons.map((button, index) => {
            let classes = button.class || "primary";
            let label = button.label || "unknown";
            let onclick, href;

            if (button.onclick) {
              onclick = button.onclick;
            } else if (button.href) {
              href = button.href;
            }

            let v__button;
            if (button.onclick) {
              v__button = (
                <button className={`tiny ${classes} button`} onClick={onclick}>
                  {label}
                </button>
              );
            } else if (button.href) {
              v__button = (
                <Link to={href} className={`tiny ${classes} button`}>
                  {label}
                </Link>
              );
            }

            return <React.Fragment key={index}>{v__button}</React.Fragment>;
          })}
        </React.Fragment>
      );
    }

    return output;
  }

  view__showMenu() {
    return null;
  }

  getBreadcrums() {
    const { breadcrumbs } = this.state;
    const _c = this.props.context;

    let bcs = [];
    if (!isUndefined(breadcrumbs) && breadcrumbs.length > 0) {
      breadcrumbs.map((breadcrumb, index) => {
        let bc = undefined;
        if (breadcrumb === "dashboard") {
          bc = {
            id: "dashboard",
            to: "/",
            name: (
              <span>
                <Icon icon="home" left /> {_c.translate("pageTitles.dashboard")}
              </span>
            ),
          };
        } else if (breadcrumb === "home") {
          bc = {
            id: "home",
            to: _c.config("urls.home"),
            name: (
              <span>
                <Icon icon="home" left /> {_c.translate("pageTitles.home")}
              </span>
            ),
          };
        } else if (breadcrumb === "home_truepower") {
          bc = {
            id: "home_truepower",
            to: _c.config("urls.home.truepower"),
            name: (
              <span>
                <Icon icon="home" left />{" "}
                {_c.translate("pageTitles.home_truepower")}
              </span>
            ),
          };
        } else if (breadcrumb === "legalinfo") {
          bc = {
            id: "legalinfo",
            to: _c.config("urls.legalinfo"),
            name: <span>{_c.translate("pageTitles.legalinfo")}</span>,
          };
        } else if (breadcrumb === "dataprivacy") {
          bc = {
            id: "dataprivacy",
            to: _c.config("urls.dataprivacy"),
            name: <span>{_c.translate("pageTitles.dataprivacy")}</span>,
          };
        } else if (breadcrumb === "courses") {
          bc = {
            id: "courses",
            to: _c.config("urls.courses"),
            name: <span>{_c.translate("topbar.usermenu.courseOverview")}</span>,
          };
        } else if (breadcrumb === "account") {
          bc = {
            id: "account",
            to: _c.config("urls.account"),
            name: (
              <span>
                <Icon icon="user" left />{" "}
                {_c.translate("topbar.usermenu.account")}
              </span>
            ),
          };
        } else if (breadcrumb === "privacy") {
          bc = {
            id: "privacy",
            to: _c.config("urls.privacy"),
            name: <span>{_c.translate("topbar.usermenu.privacy")}</span>,
          };
        } else if (breadcrumb === "password") {
          bc = {
            id: "password",
            to: _c.config("urls.password"),
            name: "Passwort ändern",
          };
        } else if (breadcrumb === "visionboard") {
          bc = {
            id: "visionboard",
            to: _c.config("urls.visionboard"),
            name: <span>{_c.translate("pageTitles.visionboard")}</span>,
          };
        } else if (breadcrumb === "routines") {
          bc = {
            id: "routines",
            to: _c.config("urls.routines"),
            name: <span>{_c.translate("pageTitles.routines")}</span>,
          };
        } else if (breadcrumb === "communityAreas") {
          bc = {
            id: "communityareas",
            to: _c.config("urls.communityAreas"),
            name: <span>{_c.translate("pageTitles.communityAreas")}</span>,
          };
        } else if (breadcrumb === "404") {
          bc = {
            id: "404",
            to: undefined,
            name: _c.translate("general.404"),
          };
        } else if (breadcrumb === "admin_dashboard") {
          bc = {
            id: "admin_dashboard",
            to: _c.config("urls.admin.admin"),
            name: (
              <span>
                <Icon icon="key" left />{" "}
                {_c.translate("pageTitles.admin.administration")}
              </span>
            ),
          };
        } else if (breadcrumb === "admin_members") {
          bc = {
            id: "admin_members",
            to: _c.config("urls.admin.members"),
            name: _c.translate("pageTitles.admin.members"),
          };
        } else if (breadcrumb === "admin_admins") {
          bc = {
            id: "admin_admins",
            to: _c.config("urls.admin.admins"),
            name: _c.translate("pageTitles.admin.admins"),
          };
        } else if (breadcrumb === "admin_license") {
          bc = {
            id: "admin_license",
            to: _c.config("urls.admin.license"),
            name: _c.translate("pageTitles.admin.license"),
          };
        } else if (breadcrumb === "admin_pagebuilder") {
          bc = {
            id: "admin_pagebuilder",
            to: _c.config("urls.admin.pagebuilder"),
            name: "PageBuilder",
          };
        } else if (breadcrumb === "admin_support") {
          bc = {
            id: "admin_support",
            to: _c.config("urls.admin.support"),
            name: _c.translate("pageTitles.admin.support"),
          };
        } else if (breadcrumb === "admin_livecalls") {
          bc = {
            id: "admin_livecalls",
            to: _c.config("urls.admin.livecalls"),
            name: _c.translate("pageTitles.admin.livecalls"),
          };
        } else if (breadcrumb === "admin_visionboard") {
          bc = {
            id: "admin_visionboard",
            to: _c.config("urls.admin.visionboard"),
            name: _c.translate("pageTitles.admin.visionboard"),
          };
        } else if (breadcrumb === "admin_courses") {
          bc = {
            id: "admin_courses",
            to: _c.config("urls.admin.courses"),
            name: _c.translate("pageTitles.admin.courses"),
          };
        } else if (breadcrumb === "admin_community") {
          bc = {
            id: "admin_community",
            to: _c.config("urls.admin.community"),
            name: _c.translate("pageTitles.admin.community"),
          };
        } else if (breadcrumb === "admin_settings") {
          bc = {
            id: "admin_settings",
            to: _c.config("urls.admin.settings"),
            name: _c.translate("pageTitles.admin.settings"),
          };
        } else if (breadcrumb === "admin_design") {
          bc = {
            id: "admin_design",
            to: _c.config("urls.admin.design"),
            name: _c.translate("pageTitles.admin.design"),
          };
        } else if (breadcrumb === "admin_tools") {
          bc = {
            id: "admin_tools",
            to: _c.config("urls.admin.tools.tools"),
            name: _c.translate("pageTitles.admin.tools.tools"),
          };
        } else if (breadcrumb === "admin_tools_import") {
          bc = {
            id: "admin_tools_import",
            to: _c.config("urls.admin.tools.import"),
            name: _c.translate("pageTitles.admin.tools.import"),
          };
        } else if (breadcrumb === "admin_tools_webhook") {
          bc = {
            id: "admin_tools_webhook",
            to: _c.config("urls.admin.tools.webhook"),
            name: _c.translate("pageTitles.admin.tools.webhook"),
          };
        } else {
          if (breadcrumb && breadcrumb.id && breadcrumb.name) {
            bc = breadcrumb;
          } else {
            bc = {
              id: "unknown",
              to: _c.config("urls.home"),
              name: "[Unknown]",
            };
          }
        }

        // Show last entry grey / not clickable
        if (index === breadcrumbs.length - 1) {
          bc.to = undefined;
        }

        bcs.push(bc);
        return null;
      });
    }

    return bcs;
  }
}

export default withContext(Breadcrumbs);
