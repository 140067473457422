import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import { Grid, Row, Cell, Icon } from "../../_foundation/foundation";
import axios from "axios";
import Switch from "react-switch";

import { cloneDeep } from "lodash";
import Box from "../../layout/Box";
import MMPopup from "../../mmcomponents/mmpopup/MMPopup";
import DefaultLayout from "../../layout/DefaultLayout";
import { Link } from "react-router-dom";

import ReactTooltip from "react-tooltip";

class Routines extends Component {
  state = {
    breadcrumbs: ["home", "routines"],
    firstYear: 0,
    lastYear: 0,
    year: this.props.match.params.year || null,
    month: this.props.match.params.month || null,
    weeks: null,
    routines: null,
    originalRoutines: null,
    streaks: null,
    tabs: {},
    originalTabs: {},
    selectedTab: 0,
    selectedDay: null,
    originalSelectedDay: null,
    selectedRoutine: null,
    notesEditRoutine: null,
    changesWereMade: false,
    showFirstRoutine: false,
    newRoutine: null,
    newEntry: null,
    selectedEditRoutine: null,
  };

  componentDidMount() {
    const _c = this.props.context;

    _c.showLoadingScreen();
    _c.setLoadedModule("routines");
    this.init();
  }

  render() {
    const { breadcrumbs } = this.state;

    return (
      <React.Fragment>
        <DefaultLayout
          breadcrumbs={breadcrumbs}
          help="routines"
          page="routines"
        >
          <Grid>
            <Row>
              <Cell sm={24}>
                {this.view__showTitle()}
                {this.view__showTabs()}
                {this.view__showMonthSelect()}
                {this.view__showRoutineHeader()}
                {this.view__showContent()}
                {this.view__showEditRoutine()}
                {this.view__showPopups()}
              </Cell>
            </Row>
          </Grid>
        </DefaultLayout>
      </React.Fragment>
    );
  }

  view__showEditRoutine() {
    const { showFirstRoutine, selectedEditRoutine } = this.state;
    const _c = this.props.context;

    if (!selectedEditRoutine || !selectedEditRoutine.id) {
      return null;
    }

    let v__title, v__text, v__cancelButton;

    if (showFirstRoutine === true) {
      v__title = _c.translate("routines.popup.first.title");
      v__text = _c.translate("routines.popup.first.text");
      v__cancelButton = (
        <Link
          to={_c.config("urls.home")}
          className="small primary hollow button"
        >
          <Icon icon="times" left /> {_c.translate("buttons.cancel")}
        </Link>
      );
    } else {
      if (selectedEditRoutine.id === "new") {
        v__title = _c.translate("routines.popup.new.title");
        v__text = _c.translate("routines.popup.new.text");
        v__cancelButton = (
          <button
            className="small primary hollow button"
            onClick={() => {
              this.setState({ selectedEditRoutine: null });
            }}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
        );
      } else {
        v__title = _c.translate("routines.popup.edit.title");
        v__text = _c.translate("routines.popup.edit.text");
        v__cancelButton = (
          <button
            className="small primary hollow button"
            onClick={() => {
              this.setState({ selectedEditRoutine: null });
            }}
          >
            <Icon icon="times" left /> {_c.translate("buttons.cancel")}
          </button>
        );
      }
    }

    let suggestions = _c.config("routines.suggestions");

    return (
      <MMPopup
        show={true}
        size="medium"
        showCloseButton={false}
        handleClose={() => {
          //
        }}
      >
        <h2>{v__title}</h2>
        <div
          dangerouslySetInnerHTML={{
            __html: v__text,
          }}
        />
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24}>
              <label>
                {_c.translate("routines.popup.form.name.label")}:
                <input
                  type="text"
                  value={selectedEditRoutine.name}
                  placeholder={_c.translate(
                    "routines.popup.form.name.placeholder"
                  )}
                  onChange={(e) => {
                    selectedEditRoutine.name = e.target.value;
                    this.setState({ selectedEditRoutine });
                  }}
                />
              </label>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <div className="popup-buttons">
                {v__cancelButton}
                &nbsp;
                <button
                  className="small primary button"
                  onClick={this.handle__saveRoutine}
                >
                  <Icon icon="check" left /> {_c.translate("buttons.save")}
                </button>
              </div>
            </Cell>
          </Row>
          <Row margin="xy">
            <Cell sm={24}>
              <h3>{_c.translate("routines.popup.suggestions.title")}</h3>
              <Grid type="full">
                <Row>
                  {suggestions.map((suggestion, index) => {
                    let suggestionName = suggestion.name;

                    let translationKey = suggestionName
                      .replace("translate(", "")
                      .replace(")", "");
                    let suggestionIcon;
                    let suggestionTextIcon;
                    if (suggestion.icon) {
                      suggestionIcon = <span>{suggestion.icon}&nbsp;</span>;
                      suggestionTextIcon = `${suggestion.icon} `;
                    }

                    suggestionName = (
                      <span>
                        {suggestionIcon} {_c.translate(translationKey)}
                      </span>
                    );

                    let suggestionTextName = `${suggestionTextIcon}${_c.translate(
                      translationKey
                    )}`;

                    return (
                      <Cell key={index} sm={24} md={12}>
                        <button
                          className="routines-popup-suggestion"
                          onClick={() => {
                            selectedEditRoutine.name = suggestionTextName;
                            this.setState({ selectedEditRoutine });
                          }}
                        >
                          {suggestionName}
                        </button>
                      </Cell>
                    );
                  })}
                </Row>
              </Grid>
              <div>&nbsp;</div>
            </Cell>
          </Row>
        </Grid>
      </MMPopup>
    );
  }

  view__showTitle() {
    const _c = this.props.context;

    return (
      <div className="page-header">
        <h1>{_c.translate("routines.title")}</h1>
      </div>
    );
  }

  view__showTabs() {
    const { tabs, selectedTab, newRoutine } = this.state;
    const _c = this.props.context;

    return (
      <div className="routines-tabs">
        <div className="mmtabs">
          {Object.keys(tabs).map((tabKey, index) => {
            let classes = `mmtab`;
            if (tabKey.toString() === selectedTab.toString()) {
              classes = `selected ${classes}`;
            }
            return (
              <button
                className={classes}
                key={index}
                onClick={() => {
                  this.setState({ selectedTab: tabKey });
                }}
              >
                {tabs[tabKey].name}
              </button>
            );
          })}
          <button
            className="mmtab primary-text-color"
            onClick={() => {
              this.setState({ selectedEditRoutine: cloneDeep(newRoutine) });
            }}
          >
            <span className="show-for-small-only">
              <Icon icon="plus" />
            </span>
            <span className="hide-for-small-only">
              <Icon icon="plus" left />{" "}
              {_c.translate("routines.buttons.new.label")}
            </span>
          </button>
        </div>
      </div>
    );
  }

  view__showMonthSelect() {
    const { year, month, firstYear, lastYear } = this.state;
    const _c = this.props.context;

    if (firstYear === 0 || lastYear === 0) {
      return _c.loading();
    }

    let yearSelectValues = [];
    for (let y = firstYear; y <= lastYear; y++) {
      yearSelectValues.push(y);
    }

    let monthSelectValues = _c.months;
    let showPrevMonthArrow = true;
    let showNextMonthArrow = true;

    if (parseInt(year) === parseInt(firstYear) && parseInt(month) === 1) {
      showPrevMonthArrow = false;
    }

    if (parseInt(year) === parseInt(lastYear) && parseInt(month) === 12) {
      showNextMonthArrow = false;
    }

    return (
      <div className="routines-month-select">
        <Grid type="full">
          <Row>
            <Cell sm={24} md={8} mdo={8}>
              <Grid type="full">
                <Row margin="x">
                  <Cell sm={2} md={2}>
                    <div
                      className="routines-month-select-arrow"
                      style={{ display: showPrevMonthArrow ? "block" : "none" }}
                    >
                      <button onClick={this.handle__prevMonth}>
                        <Icon icon="caret-left" />
                      </button>
                    </div>
                  </Cell>
                  <Cell sm={12} md={12}>
                    <select
                      value={month}
                      onChange={(event) => {
                        this.setState({ month: event.target.value }, () => {
                          this.updateBrowserUrl();
                          this.init();
                        });
                      }}
                    >
                      {Object.keys(monthSelectValues).map((month) => {
                        return (
                          <option key={month} value={month}>
                            {monthSelectValues[month]}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={8} md={8}>
                    <select
                      value={year}
                      onChange={(event) => {
                        this.setState({ year: event.target.value }, () => {
                          this.updateBrowserUrl();
                          this.init();
                        });
                      }}
                    >
                      {yearSelectValues.map((yearSelectValue) => {
                        return (
                          <option key={yearSelectValue} value={yearSelectValue}>
                            {yearSelectValue}
                          </option>
                        );
                      })}
                    </select>
                  </Cell>
                  <Cell sm={2} md={2}>
                    <div
                      className="routines-month-select-arrow"
                      style={{
                        display: showNextMonthArrow ? "block" : "none",
                      }}
                    >
                      <button onClick={this.handle__nextMonth}>
                        <Icon icon="caret-right" />
                      </button>
                    </div>
                  </Cell>
                </Row>
              </Grid>
            </Cell>
          </Row>
        </Grid>
      </div>
    );
  }

  view__showRoutineHeader() {
    const { selectedTab, routines } = this.state;
    const _c = this.props.context;

    if (!routines) {
      return null;
    }

    let selectedRoutine;

    let v__title = _c.translate("routines.allRoutines");
    let v__icons;

    if (selectedTab && parseInt(selectedTab) !== 0) {
      routines.map((routine) => {
        if (parseInt(routine.id) === parseInt(selectedTab)) {
          v__title = routine.name;
          selectedRoutine = routine;
        }
        return null;
      });

      v__icons = (
        <span className="routines-routine-header-icons">
          <button
            className="tiny primary hollow button"
            data-tip={_c.translate("routines.buttons.edit.tooltip")}
            onClick={() => {
              this.setState({
                selectedEditRoutine: cloneDeep(selectedRoutine),
              });
            }}
          >
            {_c.translate("routines.buttons.edit.label")}
          </button>
          <button
            className="tiny primary hollow button"
            data-tip={_c.translate("routines.buttons.delete.tooltip")}
            onClick={() => {
              this.handle__deleteRoutine(selectedRoutine);
            }}
          >
            {_c.translate("routines.buttons.delete.label")}
          </button>
        </span>
      );
    }

    return (
      <div className="routines-routine-header">
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <ReactTooltip
                place={_c.getToolTipSetting("position")}
                type={_c.getToolTipSetting("type")}
                effect={_c.getToolTipSetting("effect")}
              />
              <h2>
                {v__title} {v__icons}
              </h2>
            </Cell>
            {/*<Cell sm={24} md={6}></Cell>*/}
          </Row>
        </Grid>
      </div>
    );
  }

  view__showContent() {
    const { routines } = this.state;
    const _c = this.props.context;

    if (!routines || !routines.entries) {
      return _c.loading();
    }

    return (
      <React.Fragment>
        <Grid type="full">
          <Row margin="xy">
            <Cell sm={24} md={24}>
              <Box>
                {this.view__showMonthTitle()}
                {this.view__showWeeks()}
              </Box>
            </Cell>
            {/*<Cell sm={24} md={6}>
              {this.view__showStreaks()}
    </Cell>*/}
          </Row>
        </Grid>
      </React.Fragment>
    );
  }

  view__showMonthTitle() {
    const { year, month } = this.state;
    const _c = this.props.context;
    const monthNames = _c.months;

    const v__title = `${monthNames[month]} ${year}`;
    return (
      <div className="routines-month-title">
        <h2>{v__title}</h2>
      </div>
    );
  }

  view__showStreaks() {
    const { streaks } = this.state;
    const _c = this.props.context;

    if (!streaks) {
      return _c.loading();
    }

    let streaksFound = false;

    let v__streaks = Object.keys(streaks).map((routineId, index) => {
      let v__length = "nie";
      let v__streakDates;

      if (streaks[routineId].streakLength > 0) {
        v__length = `${streaks[routineId].streakLength}-mal in Folge`;
        v__streakDates = `(${streaks[routineId].streakDates_f})`;
        streaksFound = true;
      }

      return (
        <div className="routines-streak" key={index}>
          <h4>{streaks[routineId].routine.name}</h4>
          <div className="routines-streak-length">{v__length}</div>
          <div className="routines-streak-dates">{v__streakDates}</div>
        </div>
      );
    });

    if (streaksFound === false) {
      v__streaks = (
        <div className="text-center">
          {_c.translate("routines.streaks.noStreaks")}
        </div>
      );
    }

    return (
      <Box>
        <Grid type="full">
          <Row>
            <Cell sm={24}>
              <h3 className="text-center">
                {_c.translate("routines.streaks.title")}
              </h3>

              {v__streaks}
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }

  view__showWeeks() {
    const { weeks, selectedTab } = this.state;
    const _c = this.props.context;

    return (
      <Grid type="full">
        <div className="routines-weekdays">
          <Row>
            <Cell sm={3} md={3}>
              &nbsp;
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.monday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.tuesday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.wednesday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.thursday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.friday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.saturday")}
            </Cell>
            <Cell sm={3} md={3}>
              {_c.translate("general.days.short.sunday")}
            </Cell>
          </Row>
        </div>
        {weeks.map((week, weekIndex) => {
          return (
            <Row key={weekIndex}>
              <Cell sm={3} md={3} className="hide-for-small-only">
                <div className="wrapper">
                  <div className="routines-week-weeknr">
                    {_c.translate("routines.week")} {week.weeknr}
                  </div>
                </div>
              </Cell>
              {week.days.map((day, dayIndex) => {
                let clickable = true;

                let dayClasses = `routines-day`;
                if (day.monthType === "LAST_MONTH") {
                  dayClasses = `past ${dayClasses}`;
                  clickable = false;
                }

                if (day.monthType === "NEXT_MONTH") {
                  dayClasses = `future ${dayClasses}`;
                  clickable = false;
                }

                if (day.type === "FUTURE") {
                  dayClasses = `future ${dayClasses}`;
                  clickable = false;
                }

                if (day.type === "TODAY") {
                  dayClasses = `today ${dayClasses}`;
                }

                if (day.shortDayName === "Sa" || day.shortDayName === "So") {
                  dayClasses = `weekend ${dayClasses}`;
                }

                let v__check; // For Single Routine
                let v__checks = []; // For All Routines

                if (selectedTab === 0 || selectedTab === "0") {
                  if (day.routines) {
                    Object.keys(day.routines).map((routineId) => {
                      let v__notes;
                      if (
                        day.routines[routineId] &&
                        day.routines[routineId].entry
                      ) {
                        let dayEntry = day.routines[routineId].entry;
                        if (dayEntry.status === "FINISHED") {
                          if (dayEntry.notes) {
                            v__notes = <Icon icon="sticky-note-o" />;
                          }
                          dayClasses = `finished ${dayClasses}`;
                          v__checks.push(
                            <div className="routines-day-check-list-entry">
                              <Icon icon="check" />{" "}
                              {day.routines[routineId].name} {v__notes}
                            </div>
                          );
                        } else {
                          if (dayEntry.notes) {
                            v__notes = <Icon icon="sticky-note-o" />;
                            v__checks.push(
                              <div className="routines-day-check-list-entry not-finished">
                                <Icon icon="times" />{" "}
                                {day.routines[routineId].name} {v__notes}
                              </div>
                            );
                          } else {
                            v__checks.push(
                              <div className="routines-day-check-list-entry not-finished"></div>
                            );
                          }
                        }
                      } else {
                        v__checks.push(
                          <div className="routines-day-check-list-entry"></div>
                        );
                      }

                      return null;
                    });
                  }
                } else {
                  if (
                    day.routines &&
                    day.routines[selectedTab] &&
                    day.routines[selectedTab].entry
                  ) {
                    let v__notes;
                    let dayEntry = day.routines[selectedTab].entry;
                    if (dayEntry.notes) {
                      v__notes = (
                        <div className="routines-day-notes">
                          <Icon icon="sticky-note-o" />
                        </div>
                      );
                    }
                    if (dayEntry.status === "FINISHED") {
                      dayClasses = `finished ${dayClasses}`;
                      v__check = (
                        <div className="routines-day-check">
                          <div className="center-vertically">
                            <Icon icon="check" />
                          </div>
                          {v__notes}
                        </div>
                      );
                    } else {
                      v__check = (
                        <div className="routines-day-check">{v__notes}</div>
                      );
                    }
                  }
                }

                return (
                  <Cell sm={6} md={3} key={dayIndex}>
                    <div
                      className={dayClasses}
                      onClick={() => {
                        if (clickable === true) {
                          let selectedRoutine = null;
                          if (selectedTab !== 0 && selectedTab !== "0") {
                            selectedRoutine = selectedTab;
                          }
                          this.setState({
                            selectedDay: day,
                            originalSelectedDay: cloneDeep(day),
                            selectedRoutine,
                          });
                        }
                      }}
                    >
                      <div className="routines-day-shortname show-for-small-only">
                        {day.shortDayName}
                      </div>
                      <div className="routines-day-day">{day.day}</div>
                      {v__check}
                      <div className="routines-day-check-list">
                        {v__checks.map((entry, index) => (
                          <React.Fragment key={index}>{entry}</React.Fragment>
                        ))}
                      </div>
                    </div>
                  </Cell>
                );
              })}
            </Row>
          );
        })}
      </Grid>
    );
  }

  view__showPopups() {
    let { selectedDay } = this.state;
    const {
      originalSelectedDay,
      selectedRoutine,
      routines,
      days,
      notesEditRoutine,
    } = this.state;
    const _c = this.props.context;

    if (!selectedDay) {
      return null;
    }

    let showPopup = false;
    if (selectedDay) {
      showPopup = true;
    }

    let cellSize = 12;
    let size = "medium";
    if (parseInt(selectedRoutine) > 0) {
      size = "small";
      cellSize = 24;
    } else if (routines.length > 6) {
      size = "large";
      cellSize = 8;
    }

    return (
      <MMPopup
        show={showPopup}
        size={size}
        handleClose={() => {
          const { changesWereMade } = this.state;
          if (changesWereMade === false) {
            this.setState({
              selectedRoutine: null,
              selectedDay: null,
              originalSelectedDay: null,
            });
          }
        }}
      >
        <h2>
          {selectedDay.dayName}, {selectedDay.date}
        </h2>

        <div className="routines-popup-routines">
          <Grid type="full">
            <Row margin="xy">
              {routines.map((routine, index) => {
                if (
                  parseInt(selectedRoutine) > 0 &&
                  routine.id !== parseInt(selectedRoutine)
                ) {
                  return null;
                }

                let isChecked = false;
                if (
                  selectedDay &&
                  selectedDay.routines &&
                  selectedDay.routines[routine.id] &&
                  selectedDay.routines[routine.id].entry &&
                  selectedDay.routines[routine.id].entry.status &&
                  selectedDay.routines[routine.id].entry.status === "FINISHED"
                ) {
                  isChecked = true;
                }

                let entryNotes;
                if (
                  selectedDay &&
                  selectedDay.routines &&
                  selectedDay.routines[routine.id] &&
                  selectedDay.routines[routine.id].entry &&
                  selectedDay.routines[routine.id].entry.notes
                ) {
                  entryNotes = selectedDay.routines[routine.id].entry.notes;
                }
                //let originalEntryNotes = entryNotes;

                let v__notes = (
                  <div className="routines-popup-routine-notes-text">
                    <div
                      dangerouslySetInnerHTML={{
                        __html: entryNotes || "&nbsp;",
                      }}
                    />
                  </div>
                );
                if (notesEditRoutine === routine.id) {
                  v__notes = (
                    <div className="routines-popup-routine-notes-edit">
                      <textarea
                        placeholder={_c.translate("routines.notes.placeholder")}
                        rows={8}
                        value={entryNotes}
                        onChange={(event) => {
                          if (
                            selectedDay &&
                            selectedDay.routines &&
                            selectedDay.routines[routine.id] &&
                            selectedDay.routines[routine.id].entry
                          ) {
                            selectedDay.routines[routine.id].entry.notes =
                              event.target.value;
                            this.setState({ days, changesWereMade: true });
                          }
                        }}
                      />
                      <div className="routines-popup-routine-notes-edit-buttons">
                        <button
                          className="tiny primary hollow button"
                          onClick={() => {
                            const { changesWereMade } = this.state;
                            if (changesWereMade === true) {
                              if (
                                window.confirm(
                                  _c.translate("routines.notes.cancel.confirm")
                                )
                              ) {
                                selectedDay = cloneDeep(originalSelectedDay);
                                this.setState({
                                  notesEditRoutine: null,
                                  changesWereMade: false,
                                  selectedDay,
                                });
                              }
                            } else {
                              selectedDay = cloneDeep(originalSelectedDay);
                              this.setState({
                                notesEditRoutine: null,
                                changesWereMade: false,
                                selectedDay,
                              });
                            }
                          }}
                        >
                          <Icon icon="times" left />{" "}
                          {_c.translate("buttons.cancel")}
                        </button>
                        <button
                          className="tiny primary button"
                          onClick={() => {
                            this.handle__saveEntry(
                              selectedDay.routines[routine.id].entry
                            );
                            this.setState({
                              notesEditRoutine: null,
                              changesWereMade: false,
                            });
                          }}
                        >
                          <Icon icon="check" left />{" "}
                          {_c.translate("buttons.save")}
                        </button>
                      </div>
                    </div>
                  );
                }

                return (
                  <Cell key={index} sm={24} md={cellSize}>
                    <Box>
                      <h3>{routine.name}</h3>
                      <div className="routines-popup-routine-switch">
                        <Switch
                          onChange={() => {
                            console.log("selectedDay", selectedDay);
                            if (
                              selectedDay &&
                              selectedDay.routines &&
                              (!selectedDay.routines[routine.id] ||
                                !selectedDay.routines[routine.id].entry)
                            ) {
                            }

                            if (
                              selectedDay &&
                              selectedDay.routines &&
                              selectedDay.routines[routine.id] &&
                              selectedDay.routines[routine.id].entry
                            ) {
                              if (
                                selectedDay.routines[routine.id].entry
                                  .status === "FINISHED"
                              ) {
                                selectedDay.routines[
                                  routine.id
                                ].entry.status = null;
                              } else {
                                selectedDay.routines[routine.id].entry.status =
                                  "FINISHED";
                              }

                              this.setState({ days }, () => {
                                this.handle__saveEntry(
                                  selectedDay.routines[routine.id].entry
                                );
                              });
                            }
                          }}
                          checked={isChecked}
                          className="react-switch"
                          height={26}
                          width={56}
                        />
                      </div>
                      <div className="routines-popup-routine-notes">
                        <div className="routines-popup-routine-notes-label">
                          <Grid type="full">
                            <Row>
                              <Cell sm={12}>
                                {_c.translate("routines.notes.title")}
                              </Cell>
                              <Cell sm={12} className="text-right">
                                <button
                                  className="link"
                                  onClick={() => {
                                    this.setState({
                                      notesEditRoutine: routine.id,
                                    });
                                  }}
                                  style={{
                                    display: notesEditRoutine
                                      ? "none"
                                      : "inline",
                                  }}
                                >
                                  {_c.translate("routines.notes.edit")}
                                </button>
                              </Cell>
                            </Row>
                          </Grid>
                        </div>
                        {v__notes}
                      </div>
                    </Box>
                  </Cell>
                );
              })}
            </Row>
          </Grid>
        </div>
      </MMPopup>
    );
  }

  handle__prevMonth = () => {
    let { year, month } = this.state;

    month--;
    if (month < 1) {
      month = 12;
      year--;
    }

    this.setState({ year, month }, () => {
      this.updateBrowserUrl();
      this.init();
    });
  };

  handle__nextMonth = () => {
    let { year, month } = this.state;

    month++;
    if (month > 12) {
      month = 1;
      year++;
    }

    this.setState({ year, month }, () => {
      this.updateBrowserUrl();
      this.init();
    });
  };

  updateBrowserUrl = () => {
    const { year, month } = this.state;
    const _c = this.props.context;

    const url = _c
      .config("urls.routinesMonth")
      .replace(":year", year)
      .replace(":month", month);

    this.props.history.push(url);
  };

  handle__deleteRoutine(routine) {
    const { year, month, originalTabs } = this.state;
    const _c = this.props.context;

    if (
      window.confirm(
        _c
          .translate("routines.delete.confirm")
          .replace("{routine}", routine.name)
      )
    ) {
      let apiUrl = _c.apiUrl("routines.routine", {
        routine: routine.id,
        year,
        month,
      });

      axios
        .delete(apiUrl, {
          headers: _c.getHeaders(),
        })
        .then((response) => {
          try {
            if (_c.isDebug()) {
              console.log("API RESPONSE", response.data);
            }

            const { status } = response.data;
            _c.handleApiResponse(response.data, true);

            if (status === "SUCCESS") {
              const {
                routines,
                weeks,
                streaks,
                firstYear,
                lastYear,
                newRoutine,
                newEntry,
              } = response.data.data;

              newRoutine.id = "new";
              newEntry.id = "new";

              const originalRoutines = cloneDeep(routines);

              if (routines.length === 0) {
                this.setState({
                  selectedEditRoutine: cloneDeep(newRoutine),
                  showFirstRoutine: true,
                });
              }

              let tabs = cloneDeep(originalTabs);

              routines.map((routine) => {
                const newTab = {
                  name: routine.name,
                };

                tabs[routine.id] = newTab;

                return null;
              });

              this.setState(
                {
                  routines,
                  originalRoutines,
                  weeks,
                  tabs,
                  streaks,
                  firstYear,
                  lastYear,
                  newRoutine,
                  newEntry,
                },
                () => {
                  this.setState({
                    showFirstRoutine: false,
                    selectedTab: 0,
                    selectedEditRoutine: null,
                  });
                  _c.createNotifictation(
                    _c.translate("routines.messages.saved.title"),
                    _c.translate("routines.messages.saved.text"),
                    "success"
                  );
                }
              );
            }
          } catch {
            return _c.handleError(
              { status: "AXIOS RESPONSE ERROR" },
              "routines:delete:response"
            );
          }
        })
        .catch((error) => {
          if (error.response) {
            return _c.handleError(error, "routines:delete");
          }
        });
    }
  }

  handle__saveRoutine = () => {
    const { selectedEditRoutine, tabs, year, month } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("routines.routine", {
      routine: selectedEditRoutine.id,
      year,
      month,
    });

    axios
      .post(
        apiUrl,
        { routine: selectedEditRoutine },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const {
              routines,
              weeks,
              streaks,
              firstYear,
              lastYear,
              newRoutine,
              newEntry,
            } = response.data.data;

            newRoutine.id = "new";
            newEntry.id = "new";

            const originalRoutines = cloneDeep(routines);

            if (routines.length === 0) {
              this.setState({
                selectedEditRoutine: cloneDeep(newRoutine),
                showFirstRoutine: true,
              });
            }

            routines.map((routine) => {
              const newTab = {
                name: routine.name,
              };

              tabs[routine.id] = newTab;

              return null;
            });

            this.setState(
              {
                routines,
                originalRoutines,
                weeks,
                tabs,
                streaks,
                firstYear,
                lastYear,
                newRoutine,
                newEntry,
              },
              () => {
                this.setState({
                  showFirstRoutine: false,
                  selectedEditRoutine: null,
                });
                _c.createNotifictation(
                  _c.translate("routines.messages.saved.title"),
                  _c.translate("routines.messages.saved.text"),
                  "success"
                );
              }
            );
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "routines:save:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "routines:save");
        }
      });
  };

  handle__saveEntry(entry) {
    const { selectedDay } = this.state;
    const _c = this.props.context;

    let dateParts = selectedDay.date.split(".");

    let apiUrl = _c.apiUrl("routines.saveEntry", {
      year: dateParts[2],
      month: dateParts[1],
      day: dateParts[0],
      routineId: entry.routine,
    });

    axios
      .patch(
        apiUrl,
        {
          entry: entry,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
          const { streaks } = response.data.data;
          this.setState({ streaks });
        } catch {}
      })
      .catch((error) => {
        this.setState({ loadingError: true });
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }

  init() {
    let { year, month } = this.state;
    const _c = this.props.context;

    const now = new Date();
    if (!year) {
      year = now.getFullYear();
      this.setState({ year });
    }
    if (!month) {
      month = now.getMonth() + 1;
      this.setState({ month });
    }

    let apiUrl = _c.apiUrl("routines.routines", { year, month });

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const {
              routines,
              weeks,
              streaks,
              firstYear,
              lastYear,
              newRoutine,
              newEntry,
            } = response.data.data;

            newRoutine.id = "new";
            newEntry.id = "new";

            const originalRoutines = cloneDeep(routines);

            if (routines.length === 0) {
              this.setState({
                selectedEditRoutine: cloneDeep(newRoutine),
                showFirstRoutine: true,
              });
            }

            const tabs = {
              0: {
                name: _c.translate("routines.allRoutines"),
              },
            };

            this.setState({ tabs, originalTabs: cloneDeep(tabs) });

            routines.map((routine) => {
              const newTab = {
                name: routine.name,
              };

              tabs[routine.id] = newTab;

              return null;
            });

            this.setState({
              routines,
              originalRoutines,
              weeks,
              tabs,
              streaks,
              firstYear,
              lastYear,
              newRoutine,
              newEntry,
            });
          }

          _c.setPageTitle("pageTitles.routines", true);
        } catch {
          return _c.handleError(
            { status: "AXIOS RESPONSE ERROR" },
            "routines:init:response"
          );
        }
      })
      .catch((error) => {
        if (error.response) {
          return _c.handleError(error, "routines:init");
        }
      });
  }
}

export default withContext(Routines);
