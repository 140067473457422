import React, { Component } from "react";
import { Grid, Row, Cell } from "../_foundation/foundation";
import Box from "../layout/Box";

class AdminLog extends Component {
  state = { logs: this.props.logs };

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.logs !== this.props.logs) {
      this.setState({ logs: this.props.logs });
    }
  }

  render() {
    const { logs } = this.state;

    if (!logs) {
      return null;
    }

    if (logs.length === 0) {
      return (
        <Box>
          <Grid type="full">
            <Row margin="x">
              <Cell sm={24}>
                <h3>Log-Einträge</h3>
              </Cell>
            </Row>
            <Row margin="x">
              <Cell sm={24}>
                <p className="text-center">
                  <em>Keine Log-Einträge vorhanden.</em>
                </p>
              </Cell>
            </Row>
          </Grid>
        </Box>
      );
    }

    let counter = logs.length + 1;

    return (
      <Box>
        <Grid type="full">
          <Row margin="x">
            <Cell sm={24}>
              <h3>Log-Einträge</h3>
            </Cell>
          </Row>
          <Row margin="x">
            <Cell sm={24}>
              <div className="admin-log">
                <div className="admin-list-title">
                  <Grid type="full">
                    <Row>
                      <Cell md={2} className="text-center">
                        <div className="padding">#</div>
                      </Cell>
                      <Cell md={5} className="text-center">
                        <div className="padding">Datum &amp; Zeit</div>
                      </Cell>
                      <Cell md={17}>
                        <div className="padding">Eintrag</div>
                      </Cell>
                    </Row>
                  </Grid>
                </div>
                <ul className="striped admin-list">
                  {logs.map((log, index) => {
                    counter = counter - 1;
                    return (
                      <li className="admin-list-item" key={index}>
                        <Grid type="full">
                          <Row>
                            <Cell md={2} className="text-center">
                              <div className="padding">{counter}</div>
                            </Cell>
                            <Cell md={5} className="text-center">
                              <div className="padding">{log.creation_date}</div>
                            </Cell>
                            <Cell md={17}>
                              <div className="padding">{log.text}</div>
                            </Cell>
                          </Row>
                        </Grid>
                      </li>
                    );
                  })}
                </ul>
              </div>
            </Cell>
          </Row>
        </Grid>
      </Box>
    );
  }
}

export default AdminLog;
