import React, { Component } from "react";
import withContext from "../../context/contextHOC";
import { Icon } from "../_foundation/_buttons";

class Loading extends Component {
  state = {
    show: this.props.show,
    text: this.props.text,
  };

  componentDidUpdate(prevProps, prevState) {
    if (this.props.show !== prevProps.show) {
      this.setState({ show: this.props.show });
    }

    if (this.props.text !== prevProps.text) {
      this.setState({ text: this.props.text });
    }
  }

  render() {
    let { show, text } = this.state;
    const _c = this.props.context;

    if (!show) {
      return null;
    }

    if (!text) {
      text = _c.translate("general.loading.iconText");
    }

    const loadingIconType = _c.config("general.loadingIcon.type");
    const loadingIconIcon = _c.config("general.loadingIcon.icon");

    let v__icon;

    if (loadingIconType === "fa") {
      v__icon = <Icon icon={loadingIconIcon} />;
    }
    v__icon = <div className="loading-icon">{v__icon}</div>;

    let v__text = text;
    v__text = <div className="loading-text">{v__text}</div>;

    return (
      <div className="reusable reusable-loading">
        {v__icon}
        {v__text}
      </div>
    );
  }
}

export default withContext(Loading);
