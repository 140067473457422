import React, { Component } from "react";

class Box extends Component {
  state = {
    size: this.props.size || "default",
    margin: this.props.margin,
    className: this.props.className,
  };

  render() {
    return (
      <div
        className={this.boxClass()}
        style={this.props.style || undefined}
        onClick={this.props.onClick || undefined}
      >
        <div className="box-content">{this.props.children}</div>
      </div>
    );
  }

  boxClass() {
    const { size, margin, className } = this.state;

    let classes = className;

    if (size === "nopadding") {
      classes += " no-padding box";
    } else if (size === "small") {
      classes += " small box";
    } else {
      classes += " box";
    }

    if (margin === false) {
      classes += " no-margin";
    }

    if (this.props.onClick) {
      classes += " clickable-box";
    }

    return classes;
  }
}

export default Box;
