import React, { Component } from "react";
import Vimeo from "@vimeo/player";
import axios from "axios";
import withContext from "../../../../context/contextHOC";
import {
  Player,
  ControlBar,
  ReplayControl,
  ForwardControl,
  CurrentTimeDisplay,
  TimeDivider,
  PlaybackRateMenuButton,
  VolumeMenuButton,
} from "video-react";
import "video-react/dist/video-react.css";

class Video extends Component {
  state = {
    status: this.props.status,
    course: this.props.course,
    lesson: this.props.lesson,
    player: undefined,
    lastUpdate: 0,
  };

  componentDidMount() {
    this.initVimeoPlayer();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState(
        { lesson: this.props.lesson, player: this.props.player },
        () => {
          this.initVimeoPlayer();
        }
      );
    }
  }

  render() {
    const { course, lesson } = this.state;
    if (!course || !lesson) {
      return null;
    }

    return <React.Fragment>{this.view__showVideo()}</React.Fragment>;
  }

  view__showVideo() {
    const { lesson } = this.state;
    const _c = this.props.context;

    if (lesson.type === "vimeo") {
      return (
        <div className="responsive-embed widescreen">
          <div id="vimeoPlayer">
            <div id="vimeoPlayerLoading">
              <div className="wrapper">
                <div className="center-vertically">
                  <i className="fa fa-spin fa-circle-o-notch" />
                </div>
              </div>
            </div>
          </div>
        </div>
      );
    } else if (lesson.type === "youtube") {
      let embedUrl = `https://www.youtube.com/embed/${lesson.content}`;
      return (
        <div className="responsive-embed widescreen">
          <div id="youtubePlayer">
            <iframe
              src={embedUrl}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YoutubePlayer"
            ></iframe>
          </div>
        </div>
      );
    } else if (lesson.type === "embed") {
      return (
        <div className="responsive-embed widescreen">
          <div dangerouslySetInnerHTML={{ __html: lesson.content }} />
        </div>
      );
    } else if (lesson.type === "upload" && lesson.content) {
      let videoFile = _c.apiUrl("files.video", {
        type: "lesson",
        id: lesson.id,
      });

      return (
        <div className="responsive-embed1 widescreen2">
          <Player
            ref={(player) => {
              this.player = player;
            }}
            videoId="video-1"
          >
            <ControlBar>
              <ReplayControl seconds={10} order={1.1} />
              <ForwardControl seconds={30} order={1.2} />
              <CurrentTimeDisplay order={4.1} />
              <TimeDivider order={4.2} />
              <PlaybackRateMenuButton
                rates={[2, 1.75, 1.5, 1.25, 1, 0.5]}
                order={7.1}
              />
              <VolumeMenuButton disabled />
            </ControlBar>
            <source src={videoFile} />
          </Player>
        </div>
      );
    }
  }

  initVimeoPlayer() {
    const { lesson } = this.state;
    const { lessonStatus } = lesson;

    if (lesson.type !== "vimeo") {
      return;
    }

    let options = {
      id: this.state.lesson.content,
      loop: false,
    };

    if (this.state.player) {
      let { player } = this.state;
      player.destroy();
      this.setState({ player });
      this.props.onUpdatePlayer(player);
    }

    let player = new Vimeo("vimeoPlayer", options);

    if (lessonStatus.seconds > 0) {
      player.setCurrentTime(lessonStatus.seconds);
    }

    player.on("play", (data) => {
      lessonStatus.seconds = data.seconds;
      lessonStatus.duration = data.duration;
      this.setState({ lesson }, () => {
        this.handle__updateLessonStatus(lessonStatus);
        this.props.onUpdate(lessonStatus);
      });
    });

    player.on("pause", (data) => {
      lessonStatus.seconds = data.seconds;
      this.setState({ lesson }, () => {
        this.handle__updateLessonStatus(lessonStatus);
        this.props.onUpdate(lessonStatus);
      });
    });

    player.on("timeupdate", (data) => {
      lessonStatus.seconds = data.seconds;
      this.setState({ lesson }, () => {
        // Update Playback Position in Database every 15 seconds
        if (
          this.state.lastUpdate - data.seconds > 15 ||
          data.seconds - this.state.lastUpdate > 15
        ) {
          this.handle__updateLessonStatus(lessonStatus);
          this.props.onUpdate(lessonStatus);
        }
      });
    });

    this.setState({ player }, () => {
      this.props.onUpdatePlayer(player);
    });
  }

  calculateProgress = (lessonStatus) => {
    const duration = parseInt(lessonStatus.duration);
    const seconds = parseInt(lessonStatus.seconds);

    lessonStatus.progress = Math.floor((seconds / duration) * 100);

    return lessonStatus;
  };

  handle__updateLessonStatus(lessonStatus) {
    const { lesson } = this.state;
    const _c = this.props.context;

    this.setState({ lastUpdate: lessonStatus.seconds });
    //console.log("STATUS", lessonStatus);

    lessonStatus = this.calculateProgress(lessonStatus);

    let url = _c.apiUrl("courses.lessonStatus", {
      lessonId: lesson.id,
    });

    axios
      .patch(
        url,
        {
          lessonStatus: lessonStatus,
        },
        {
          headers: _c.getHeaders(),
        }
      )
      .then((response) => {
        try {
        } catch {
          //
        }
      })
      .catch((error) => {
        if (_c.isDebug()) {
          console.log("Error", error);
        }
      });
  }
}

export default withContext(Video);
