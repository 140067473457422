import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";

class CourseDirectAccess extends Component {
  state = {
    courseSlug: this.props.match.params.courseSlug,
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.setLoadedModule("courses");
    this.init();
  }

  render() {
    return <React.Fragment></React.Fragment>;
  }

  init() {
    const { courseSlug } = this.state;
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("courses.course", {
      courseSlug,
    });

    let coursesOverviewUrl = _c.config("urls.courses");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, true);

          if (status === "SUCCESS") {
            const { lessonSlug } = response.data.data;
            let lessonUrl = _c.config("urls.courseLesson");
            lessonUrl = lessonUrl
              .replace(":courseSlug", courseSlug)
              .replace(":lessonSlug", lessonSlug);

            window.location.href = lessonUrl;
          } else {
            window.location.href = { coursesOverviewUrl };
          }
        } catch {
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "course:directaccess:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "course:directaccess:init");
      });
  }
}

export default withContext(CourseDirectAccess);
