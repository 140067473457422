import React, { Component } from "react";
import { Icon } from "../../../_foundation/_buttons";
import closeButton from "../../../../images/close-button.png";
import withContext from "../../../../context/contextHOC";
import DownloadList from "./DownloadList";
import Box from "../../../layout/Box";
import Progress from "./Progress";

class LessonList extends Component {
  state = {
    status: this.props.status,
    course: this.props.course,
    lesson: this.props.lesson,
    lessons: this.props.lessons,
    downloads: this.props.downloads,
    selectedCategory: undefined,
    showLessonListOnMobile: this.props.showLessonListOnMobile,
  };

  componentDidMount() {
    this.autoselectCategory();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.status !== this.props.status) {
      this.setState({ status: this.props.status });
    }

    if (prevProps.course !== this.props.course) {
      this.setState({ course: this.props.course });
    }

    if (prevProps.downloads !== this.props.downloads) {
      this.setState({ downloads: this.props.downloads });
    }

    if (prevProps.lesson !== this.props.lesson) {
      this.setState({ lesson: this.props.lesson }, () => {
        this.autoselectCategory();
      });
    }

    if (prevProps.lessons !== this.props.lessons) {
      this.setState({ lessons: this.props.lessons });
    }

    if (
      prevProps.showLessonListOnMobile !== this.props.showLessonListOnMobile
    ) {
      this.setState({
        showLessonListOnMobile: this.props.showLessonListOnMobile,
      });
    }
  }

  render() {
    const {
      lessons,
      lesson,
      course,
      downloads,
      selectedCategory,
      showLessonListOnMobile,
    } = this.state;
    const _c = this.props.context;

    if (!lessons) {
      return null;
    }

    let lessonUrlPattern = _c.config("urls.courseLesson");

    let v__downloads;
    if (downloads) {
      v__downloads = <DownloadList downloads={downloads} lesson={lesson} />;
    }

    return (
      <div
        className={`${
          showLessonListOnMobile === true ? "mobile-show" : "mobile-hide"
        } smartphone-wrapper`}
      >
        <div className="course-lessonlist">
          <div className="show-for-small-only">
            <div className="float-right hide-for-small-only">
              {this.view__showCloseButton()}
            </div>
            <h2>{_c.translate("courses.lesson.lessonsInCourse")}</h2>
          </div>
          <ul className="course-categories">
            {lessons.map((category, index) => {
              if (category.hide_in_list === true) {
                return null;
              }

              let categoryIsSelected = false;
              let caretIcon = (
                <button className="course-open-category">
                  <Icon icon="caret-up" />
                </button>
              );
              if (selectedCategory === category.id) {
                categoryIsSelected = true;
                caretIcon = <Icon icon="caret-down" />;
              }

              let lessonCounter = 0;

              return (
                <li
                  key={index}
                  className="course-category course-open-category"
                  onClick={() => {
                    this.setState({ selectedCategory: category.id });
                  }}
                >
                  <Box size="small">
                    <div className="course-category-title">
                      <div className="float-right">{caretIcon}</div>
                      <Icon icon="play-circle" left />
                      {category.name}
                    </div>
                    <ul
                      className="course-category-lessons"
                      style={{
                        display: categoryIsSelected === true ? "block" : "none",
                      }}
                    >
                      {category.lessons.map((listLesson, lessonIndex) => {
                        let lessonUrl = lessonUrlPattern
                          .replace(":courseSlug", course.slug)
                          .replace(":lessonSlug", listLesson.slug);
                        //let lessonUrl = `${baseUrl}/${course.slug}/${listLesson.slug}`;

                        let v__checkIcon;
                        if (listLesson.lessonStatus.finished) {
                          v__checkIcon = <Icon icon="check" left />;
                        }

                        let lessonClasses = "course-category-lesson";

                        //let lessonIsSelected = false;
                        if (lesson.id === listLesson.id) {
                          //lessonIsSelected = true;
                          lessonClasses = `selected ${lessonClasses}`;
                        }

                        lessonCounter++;

                        let v__lesson;
                        if (
                          listLesson.access &&
                          listLesson.access.hasAccess === false &&
                          listLesson.access.showBefore === true
                        ) {
                          v__lesson = (
                            <div className="course-lesson-dont-show-before">
                              <Icon icon="lock" /> {listLesson.name}
                            </div>
                          );
                        } else {
                          v__lesson = (
                            <a href={lessonUrl}>
                              {v__checkIcon} {lessonCounter}. {listLesson.name}
                            </a>
                          );
                        }

                        return (
                          <li className={lessonClasses} key={lessonIndex}>
                            {v__lesson}
                          </li>
                        );
                      })}
                    </ul>
                  </Box>
                </li>
              );
            })}
          </ul>
          {v__downloads}
        </div>
      </div>
    );
  }

  autoselectCategory() {
    let { lesson, selectedCategory } = this.state;
    if (selectedCategory === undefined) {
      selectedCategory = lesson.category;
      this.setState({ selectedCategory });
    }
  }

  view__showCloseButton() {
    return (
      <button
        className="ptpopup-close-button"
        onClick={() => {
          this.setState({ showLessonListOnMobile: false }, () => {
            this.props.closeLessonListOnMobile();
          });
        }}
      >
        <img src={closeButton} alt="[Close]" />
      </button>
    );
  }
}

export default withContext(LessonList);
