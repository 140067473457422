import React from "react";
import CoursePage from "../modules/courses/CoursePage";

function SpecialHomePage(props) {
  const coursepageProps = {
    match: {
      params: {
        courseSlug: "loauniverse",
      },
    },
  };

  return <CoursePage {...coursepageProps} />;
}

export default SpecialHomePage;
