import React, { useState } from "react";
import Box from "../../../layout/Box";
import { Cell, Grid, Row } from "../../../_foundation/_grid";
import BoxShadowPicker from "./components/BoxShadowPicker";

const optionsKey = "boxshadow";

const defaultValue = {
  sr: "0px",
  sd: "0px",
  s: "0px",
  b: "0px",
  c: "#000000",
  show: "hover",
};

function ControlBoxShadow({ item, onUpdate }) {
  const getValueObject = () => {
    let valueObject = defaultValue;
    if (item.options && item.options[optionsKey])
      valueObject = item.options[optionsKey];
    return valueObject;
  };

  const getPreviewStyles = () => {
    const styles = {};

    const valueObject = getValueObject();
    styles.boxShadow = `${valueObject.sr} ${valueObject.sd} ${valueObject.b} ${valueObject.s} ${valueObject.c}`;

    return styles;
  };

  const onEdit = (property, value) => {
    const newValue = getValueObject();
    newValue[property] = value;
    onUpdate(`options.${optionsKey}`, newValue);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <Grid type="full">
        <Row margin="xy">
          <Cell sm={24} md={16}>
            <Box>
              <BoxShadowPicker value={getValueObject()} onEdit={onEdit} />
            </Box>
          </Cell>
          <Cell sm={24} md={8}>
            <div
              className="control-boxshadow-preview"
              style={getPreviewStyles()}
            />
          </Cell>
        </Row>
      </Grid>
    </div>
  );
}

export default ControlBoxShadow;
