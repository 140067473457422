import React from "react";
import PageViewHelper from "../PageViewHelper";

function ElementHeading({ element }) {
  return (
    <>
      {element.type === "h1" && (
        <h1 style={PageViewHelper.applyOptions(element)}>{element.content}</h1>
      )}
      {element.type === "h2" && (
        <h2 style={PageViewHelper.applyOptions(element)}>{element.content}</h2>
      )}
      {element.type === "h3" && (
        <h3 style={PageViewHelper.applyOptions(element)}>{element.content}</h3>
      )}
      {element.type === "h4" && (
        <h4 style={PageViewHelper.applyOptions(element)}>{element.content}</h4>
      )}
      {element.type === "h5" && (
        <h5 style={PageViewHelper.applyOptions(element)}>{element.content}</h5>
      )}
      {element.type === "h6" && (
        <h6 style={PageViewHelper.applyOptions(element)}>{element.content}</h6>
      )}
    </>
  );
}

export default ElementHeading;
