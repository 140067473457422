import React from "react";

const optionsKey = "fontSize";

const defaults = {
  h1: "28px",
  h2: "21px",
  h3: "19px",
};

const values = [
  "8px",
  "9px",
  "10px",
  "11px",
  "12px",
  "13px",
  "14px",
  "15px",
  "16px",
  "17px",
  "18px",
  "19px",
  "20px",
  "21px",
  "22px",
  "21px",
  "22px",
  "23px",
  "24px",
  "25px",
  "26px",
  "27px",
  "28px",
  "29px",
  "30px",
  "31px",
  "32px",
  "33px",
  "34px",
  "35px",
  "36px",
  "37px",
  "38px",
  "39px",
  "40px",
  "41px",
  "42px",
  "41px",
  "42px",
  "43px",
  "44px",
  "45px",
  "46px",
  "47px",
  "48px",
  "49px",
  "50px",
];

function ControlFontSize({ item, onUpdate }) {
  const getValue = () => {
    let value = defaults[item.type];
    if (item.options && item.options[optionsKey])
      value = item.options[optionsKey];
    return value;
  };

  const onEdit = (value) => {
    onUpdate(`options.${optionsKey}`, value);
  };

  if (!item) return null;

  return (
    <div className={`pagebuilder-control pagebuilder-control-${optionsKey}`}>
      <select value={getValue()} onChange={(e) => onEdit(e.target.value)}>
        {values.map((valueValue, index) => {
          return (
            <option key={index} value={valueValue}>
              {valueValue}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default ControlFontSize;
