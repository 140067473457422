import React, { Component } from "react";
import withContext from "../../../context/contextHOC";
import axios from "axios";
import { Grid, Row, Cell } from "../../_foundation/_grid";
import Countdown from "../../_reusables/Countdown";
import DefaultLayout from "../../layout/DefaultLayout";
import { DateTime } from "luxon";
import Chat from "../../chat/Chat";

class Live extends Component {
  state = {
    breadcrumbs: ["home"],
    livecall: null,
    status: "INIT",
  };

  componentDidMount() {
    const _c = this.props.context;
    _c.showLoadingScreen();
    _c.setLoadedModule("live");
    this.init();
  }

  render() {
    const { breadcrumbs, livecall } = this.state;
    const _c = this.props.context;

    if (!livecall) {
      return null;
    }

    let buttons, menu;
    if (_c.isAdmin()) {
      buttons = [
        {
          class: "primary hollow",
          label: _c.translate("breadcrumbs.live.edit"),
          href: _c.config("urls.admin.livecall").replace(":id", livecall.id),
        },
      ];
    }

    return (
      <DefaultLayout
        help="live"
        page="live"
        breadcrumbs={breadcrumbs}
        buttons={buttons}
        menu={menu}
      >
        {this.view__showContent()}
      </DefaultLayout>
    );
  }

  view__showContent() {
    const { status, livecall } = this.state;
    const _c = this.props.context;

    if (status !== "LOADED" || !livecall.status) {
      return _c.loading();
    }

    let v__output;
    let v__chat;

    if (livecall.status === "UPCOMING") {
      v__output = this.view__showCountdownPage();
    } else if (livecall.status === "ONAIR") {
      v__output = this.view__showLivestreamPage();
      v__chat = this.view__showChat();
    }

    return (
      <React.Fragment>
        {v__output}
        {v__chat}
      </React.Fragment>
    );
  }

  view__showCountdownPage() {
    const { livecall } = this.state;
    const _c = this.props.context;

    let v__title = (
      <div className="countdown-nextlivecallin">
        {_c.translate("live.countdown.nextLiveIn")}
      </div>
    );

    if (livecall.title) {
      v__title = <h3>{livecall.title}</h3>;
    }

    let v__image;
    if (livecall.image) {
      v__image = (
        <div className="countdown-image">
          <img src={livecall.image} alt="" />
        </div>
      );
    }

    return (
      <div className="wrapper">
        <div className="center-vertically-">
          <div className="live-countdown">
            <Grid type="full">
              <Row>
                <Cell sm={24} md={16} mdo={4}>
                  {v__image}
                  {v__title}

                  <Grid type="full">
                    <Row>
                      <Cell sm={24} md={12} mdo={6}>
                        <Countdown
                          date={livecall.start_obj}
                          onZero={() => {
                            const { livecall } = this.state;
                            livecall.status = "ONAIR";
                            this.setState({ livecall });
                          }}
                        />
                      </Cell>
                    </Row>
                  </Grid>
                </Cell>
              </Row>
            </Grid>
          </div>
          {this.view__showLivecallZoomlink("before")}
        </div>
      </div>
    );
  }

  view__showLivestreamPage() {
    const { livecall } = this.state;
    const _c = this.props.context;

    let v__output = (
      <div className="live-stream-not-found">
        {_c.translate("live.streamNotFound")}
      </div>
    );

    if (livecall.type === "vimeo") {
      v__output = (
        <React.Fragment>
          <header>
            <h1>{livecall.title}</h1>
            {this.view__showLivecallVimeo()}
          </header>
        </React.Fragment>
      );
    } else if (livecall.type === "youtube") {
      v__output = (
        <React.Fragment>
          <header>
            <h1>{livecall.title}</h1>
            {this.view__showLivecallYoutube()}
          </header>
        </React.Fragment>
      );
    } else if (livecall.type === "embed") {
      v__output = (
        <React.Fragment>
          <header>
            <h1>{livecall.title}</h1>
            {this.view__showLivecallEmbed()}
          </header>
        </React.Fragment>
      );
    } else if (livecall.type === "zoom") {
      v__output = (
        <React.Fragment>
          <header>
            <h1>{livecall.title}</h1>
            {this.view__showLivecallZoom()}
          </header>
        </React.Fragment>
      );
    } else if (livecall.type === "zoomlink") {
      v__output = (
        <React.Fragment>
          <header>
            <h1>{livecall.title}</h1>
            {this.view__showLivecallZoomlink("after")}
          </header>
        </React.Fragment>
      );
    }

    return <React.Fragment>{v__output}</React.Fragment>;
  }

  view__showLivecallZoomlink(when) {
    const { livecall } = this.state;
    const _c = this.props.context;

    let v__content;
    let v__contentEmpty;
    let v__contentZoomlink = (
      <div className="live-zoomlink-text">
        <h4>{_c.translate("live.zoomlink.title")}</h4>
        <div dangerouslySetInnerHTML={{ __html: livecall.external_id_f }} />
      </div>
    );

    v__content = v__contentEmpty;
    if (
      (when === "before" && livecall.show_content_before_countdown === true) ||
      when === "after"
    ) {
      v__content = v__contentZoomlink;
    }

    return v__content;
  }

  view__showLivecallVimeo() {
    const { livecall } = this.state;

    const livestreamUrl =
      "https://player.vimeo.com/video/" + livecall.external_id;

    return (
      <div className="live-stream">
        <div className="live-stream-vimeo">
          <div className="responsive-embed widescreen">
            <iframe title="Vimeo Live" src={livestreamUrl} />
          </div>
        </div>
      </div>
    );
  }

  view__showLivecallYoutube() {
    const { livecall } = this.state;

    const livestreamUrl = `https://www.youtube.com/embed/${livecall.external_id}`;
    return (
      <div className="live-stream">
        <div className="live-stream-vimeo">
          <div className="responsive-embed widescreen">
            <div id="youtubePlayer">
              <iframe
                src={livestreamUrl}
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YoutubePlayer"
              ></iframe>
            </div>
          </div>
        </div>
      </div>
    );
  }

  view__showLivecallEmbed() {
    const { livecall } = this.state;

    return (
      <div className="live-stream">
        <div className="live-stream-vimeo">
          <div className="responsive-embed widescreen">
            <div dangerouslySetInnerHTML={{ __html: livecall.external_id }} />
          </div>
        </div>
      </div>
    );
  }

  view__showLivecallZoom() {
    const _c = this.props.context;

    const user = _c.getUser();

    let livestreamUrl = `https://zoom.membermate.net/?meeting=98439682664&pass=682917&name=${user.displayname}`;

    return (
      <div className="live-stream">
        <iframe
          title="membermate Zoom"
          src={livestreamUrl}
          className="live-zoom-iframe"
        />
      </div>
    );
  }

  renderer = ({ days, hours, minutes, seconds, completed }) => {
    const _c = this.props.context;

    if (completed) {
      // Render a complete state
      return <p>Reloading...</p>;
    } else {
      let v__days = days;
      let v__hours = hours;
      let v__minutes = minutes;
      let v__seconds = seconds;

      let v__daysLabel = _c.translate("live.countdown.days");
      let v__hoursLabel = _c.translate("live.countdown.hours");
      let v__minutesLabel = _c.translate("live.countdown.minutes");
      let v__secondsLabel = _c.translate("live.countdown.seconds");

      if (parseInt(days) < 10) {
        v__days = `0${days}`;
      }

      if (parseInt(hours) < 10) {
        v__hours = `0${hours}`;
      }

      if (parseInt(minutes) < 10) {
        v__minutes = `0${minutes}`;
      }

      if (parseInt(seconds) < 10) {
        v__seconds = `0${seconds}`;
      }

      if (parseInt(days) === 1) {
        v__daysLabel = _c.translate("live.countdown.day");
      }

      if (parseInt(hours) === 1) {
        v__hoursLabel = _c.translate("live.countdown.hour");
      }

      if (parseInt(minutes) === 1) {
        v__minutesLabel = _c.translate("live.countdown.minute");
      }

      if (parseInt(seconds) === 1) {
        v__secondsLabel = _c.translate("live.countdown.second");
      }

      // Render a countdown
      return (
        <React.Fragment>
          <Grid type="full">
            <Row>
              <Cell sm={6} md={6}>
                <div className="countdown-number countdown-days">
                  {v__days}
                  <div className="time-label">{v__daysLabel}</div>
                </div>
              </Cell>
              <Cell sm={6} md={6}>
                <div className="countdown-number countdown-hours">
                  {v__hours}
                  <div className="time-label">{v__hoursLabel}</div>
                </div>
              </Cell>
              <Cell sm={6} md={6}>
                <div className="countdown-number countdown-minutes">
                  {v__minutes}
                  <div className="time-label">{v__minutesLabel}</div>
                </div>
              </Cell>
              <Cell sm={6} md={6}>
                <div className="countdown-number countdown-seconds">
                  {v__seconds}
                  <div className="time-label">{v__secondsLabel}</div>
                </div>
              </Cell>
            </Row>
          </Grid>
        </React.Fragment>
      );
    }
  };

  view__showChat() {
    const { livecall } = this.state;
    const _c = this.props.context;
    const user = _c.getUser();
    const { app } = _c;

    if (!livecall.show_chat) {
      return;
    }

    if (!user || !app) {
      return _c.loading();
    }

    const chatUser = {
      id: user.id,
      username: user.username,
      avatar: user.avatar,
      displayname: user.displayname,
      firstname: user.firstname,
      lastname: user.lastname,
    };

    const userSettingChatName = _c.userSetting("chat_name");
    if (userSettingChatName) {
      if (userSettingChatName === "firstname") {
        chatUser.lastname = null;
        chatUser.displayname = chatUser.firstname;
      } else if (userSettingChatName === "lastname") {
        chatUser.firstname = null;
        chatUser.displayname = chatUser.lastname;
      } else {
        // Use default values
      }
    }

    const chatId = `live_${app.instance}_${livecall.id}`;

    const options = {
      showUserList: livecall.chat_show_userlist,
      isChatModerator: _c.isChatModerator(),
    };

    return (
      <div className="live-chat">
        {/*<Chat _c={_c} user={chatUser} chatId={chatId} options={options} />*/}
      </div>
    );
  }

  init() {
    const _c = this.props.context;

    let apiUrl = _c.apiUrl("live.next");

    axios
      .get(apiUrl, {
        headers: _c.getHeaders(),
      })
      .then((response) => {
        try {
          if (_c.isDebug()) {
            console.log("API RESPONSE", response.data);
          }

          const { status } = response.data;
          _c.handleApiResponse(response.data, false);

          if (status === "NOTFOUND") {
            _c.permissionDenied();
          } else if (status === "SUCCESS") {
            const apiResponseData = response.data.data;
            let { livecall } = apiResponseData;

            livecall.start_obj = DateTime.fromISO(livecall.countdown_date, {
              setZone: true,
            });

            _c.setPageTitle(livecall.title, true);

            const newBreadcrumb1 = {
              to: _c.config("urls.live.next"),
              id: "live",
              name: livecall.title,
            };

            let { breadcrumbs } = this.state;
            breadcrumbs.push(newBreadcrumb1);

            this.setState({
              livecall,
              breadcrumbs,
              status: "LOADED",
            });

            _c.initFinished();
          }
        } catch (e) {
          console.log(e);
          return _c.handleError(
            { status: "AXIOS_RESPONSE_ERROR" },
            "live:next:init:response"
          );
        }
      })
      .catch((error) => {
        return _c.handleError(error, "live:next:init");
      });
  }
}

export default withContext(Live);
