import React, { Component } from "react";
import Box from "../../../layout/Box";

class Description extends Component {
  state = {
    _updatableProps: ["lesson"],
    lesson: this.props.lesson,
  };

  componentDidUpdate(prevProps, prevState) {
    const { _updatableProps } = this.state;

    _updatableProps.map((propKey) => {
      if (this.props[propKey] !== prevProps[propKey]) {
        this.setState({ [propKey]: this.props[propKey] });
      }
      return null;
    });
  }

  render() {
    const { lesson } = this.state;
    if (!lesson) {
      return null;
    }

    let v__description;
    if (lesson.description_f) {
      v__description = (
        <Box size="small" className="course-lesson-description-box">
          <div
            className="course-lesson-description"
            dangerouslySetInnerHTML={{ __html: lesson.description_f }}
          />
        </Box>
      );
    }

    return <React.Fragment>{v__description}</React.Fragment>;
  }
}

export default Description;
